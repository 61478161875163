import React, { useEffect } from "react";
import { connect } from "react-redux";
import Header from "../../front-end-global-components/components/Header/Header";
import { QrReader } from "react-qr-reader";
import "./ScanClinic.css";
import {
  clearABdmConnectDetails,
  getScannedClinicDetails
} from "../../redux/abdm/actions";

// clear ABDM connect details
function ScanClinic(props) {
  useEffect(() => {
    props.clearABdmConnectDetails();
    // eslint-disable-next-line
  }, []);

  return (
    <section data-cy="scan-screen" className="inherit-parent-height">
      <Header
        title="Scan your clinic QR code"
        backButtonOnClick={() => {
          props.navigate("/");
        }}
        supportIconOnClick={() => {
          props.navigate("/query");
        }}
        incident={props.app.featureFlags?.incident?.patients}
        incidentOnClick={() => {
          props.navigate("/incidents");
        }}
      />
      <main
        className="max-width-588px remaining-body-height overflow-hidden"
        style={{
          flexBasis: "100%"
        }}
        // onClick={() => {
        //   props.getScannedClinicDetails(
        //     "ANBU-HI",
        //     "JFKQZxngza7pXgC6grxE",
        //     props.navigate
        //   );
        // }}
      >
        <QrReader
          constraints={{ facingMode: "environment" }}
          onResult={(result, error) => {
            if (!!result && props.abdm.loading === false) {
              if (result && result.text) {
                const hipId = new URL(result.text).searchParams.get("hip-id");
                const hipCode = new URL(result.text).searchParams.get(
                  "counter-id"
                );
                props.getScannedClinicDetails(hipId, hipCode, props.navigate);
              } else if (result && result.t) {
                const hipId = new URL(result.text).searchParams.get("hip-id");
                const hipCode = new URL(result.text).searchParams.get(
                  "counter-id"
                );
                props.getScannedClinicDetails(hipId, hipCode, props.navigate);
              }
            }
          }}
          className="inherit-parent-height "
          containerStyle={{
            height: "100%",
            paddingTop: "0"
          }}
          videoContainerStyle={{
            height: "100%",
            paddingTop: "0"
          }}
          videoStyle={{
            position: "none",
            width: "none"
          }}
          style={{ height: "100%" }}
        />
      </main>
      {props.abdm.loading === true && (
        <div className="position-absolute-center-self inherit-parent-height inherit-parent-width dimmed-overlay font-size-medium flex-place-children-page-center ">
          <div className="background-color-white padding-left-medium padding-right-medium padding-top-default padding-bottom-default border-radius-default ">
            <div className="loader" />
          </div>
        </div>
      )}
    </section>
  );
}

const mapStateToProps = function (state) {
  return {
    abdm: state.abdm,
    app: state.app
  };
};

const mapDispatchToProps = function () {
  return {
    clearABdmConnectDetails: () => clearABdmConnectDetails(),
    getScannedClinicDetails: (hipId, hipCode, push) =>
      getScannedClinicDetails(hipId, hipCode, push)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScanClinic);
