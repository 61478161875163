import React, { useEffect, useRef, useState } from "react";
import "./Home.css";
import { connect } from "react-redux";
import { isValidObject, isValidArray } from "../../utils/validators";
import PatientTimeline from "../../front-end-global-components/components/PatientTimeline/PatientTimeline";
import Header from "../../front-end-global-components/components/Header/Header";
import ninto_logo from "../../front-end-global-components/assets/nintoLogo.svg";
import ReArrangeAndAddPrescription from "../../front-end-global-components/components/ReArrangeAndAddPrescription/ReArrangeAndAddPrescription";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.css";
import Button from "../../front-end-global-components/components/Button/Button";
import Modal from "../../front-end-global-components/components/Modal/Modal";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import greaterThanIcon from "../../assets/icons/Next.svg";
import historyIcon from "../../assets/icons/historyIcon.svg";
import { lockerSetup } from "../../utils/constants";
import {
  clearClinicSearchResults,
  searchAbdmClinic,
  setSearchHistory
} from "../../redux/abdm/actions";
import { setErrorStatus } from "../../redux/status/actions";
import {
  getDocumentDownloadUrl,
  getProfilePictureDownloadUrl
} from "../../redux/downloads/actions";
import { setNotificationRead } from "../../redux/notifications/actions";
import {
  AddIcon,
  ArrowIcon,
  ContinueIcon,
  SettingIcon,
  TickIcon
} from "../../front-end-global-components/assets/assets";

import {
  addDocumentFilter,
  removeDocumentFilter,
  retryDataTransfer,
  uploadDocument
} from "../../redux/documents/actions";
import {
  healthLockerAuthorization,
  setTransactionId,
  switchPatientProfile
} from "../../redux/patients/actions";
import ConnectABDM from "../../components/ConnectABDM/ConnectABDM";
import { convertAbdmToNintoDocumentTypes } from "../../front-end-global-components/utils/constants";
import FeatureFlagsFallback from "../../front-end-global-components/components/FeatureFlagsFallback/FeatureFlagsFallback";
import ConditionalRender from "../../front-end-global-components/components/ConditionalRender/ConditionalRender";
import HandleBackClick from "../../front-end-global-components/components/HandleBackClick/HandleBackClick";
import PersonalDataConsentForm from "../../components/PersonalDataConsentForm/PersonalDataConsentForm";
import { setConsentToPersonalData } from "../../redux/patients/actions";
// import ProcessSuccess from "../../components/ProcessSuccess/ProcessSuccess";
function Home(props) {
  const documentUploadRef = useRef(null);
  const [searchClinic, setSearchClinic] = useState();
  const [file, setFile] = useState({ type: "", src: "" });
  const [src, setSrc] = useState([]);
  const searchResultElementRef = useRef(null);
  const cropper = useRef(null);
  const [isSearchOn, setIsSearchOn] = useState(false);
  const [cropperReady, setCropperReady] = useState(false);
  const [showUploadFileDetailsEditModal, setShowUploadFileDetailsEditModal] =
    useState(false);
  const [showRetryButton, setShowRetryButton] = useState(false);
  const [isDataTransferRequested, setIsDataTransferRequested] = useState(true);
  const [healthLockerSubscriptionData, setHealthLockerSubscriptionData] =
    useState(true);
  const [showProfileSwitcher, setShowProfileSwitcher] = useState(false);

  useEffect(() => {
    if (
      isSearchOn === false &&
      props.abdm.clinics.search.results &&
      Object.values(props.abdm.clinics.search.results).length > 0
    ) {
      props.clearClinicSearchResults();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSearchOn]);

  // check file is valid
  useEffect(() => {
    if (
      file &&
      [
        "prescription",
        "report",
        "admission",
        "insurance",
        "receipt",
        "vital",
        "vaccine",
        "consultation",
        "others"
      ].includes(file.type) &&
      file.src &&
      file.src.type !== "application/pdf" &&
      file.src.type !== "application/json"
    ) {
      var canvas = document.getElementById("imageData");
      var ctx = canvas.getContext("2d");
      var maxW = 1024;
      var maxH = 1024;
      var img = new Image();
      img.onload = function () {
        var iw = img.width;
        var ih = img.height;
        var scale = Math.min(maxW / iw, maxH / ih);
        var iwScaled = iw * scale;
        var ihScaled = ih * scale;
        canvas.width = iwScaled;
        canvas.height = ihScaled;
        ctx.drawImage(img, 0, 0, iwScaled, ihScaled);
        cropper.current = new Cropper(canvas, {
          viewMode: 2,
          maxContainerWidth: 500,
          maxCanvasWidth: 100,
          maxCropBoxWidth: 20,
          background: false,
          borderRadius: 50,
          zoomable: true,
          dragMode: "move",
          responsive: true,
          guides: false,
          rotatable: true
        });
        setCropperReady(true);
      };
      img.src = file.src;
    } else if (
      (file.src && file.src?.type === "application/pdf") ||
      file.src?.type === "application/json"
    ) {
      if (file.src.size > 5000000) {
        setErrorStatus({
          code: "input",
          message: "Please upload a file below 5MB"
        });
      } else {
        setShowUploadFileDetailsEditModal(true);
      }
    }
    // eslint-disable-next-line
  }, [file.type, file.src]);

  // get dataTransferData from documents. if any one dataTransferData status is REQUESTED setIsDataTransfer loading.
  // if every dataTransfer data timestamp is greater than 10mins show retry button
  useEffect(() => {
    const dataTransferData =
      props.documents?.dataTransfers &&
      Object.values(props.documents?.dataTransfers)?.filter(
        (data) => data.patientId === props.patients.currentProfile
      );

    if (!isValidObject(dataTransferData)) {
      setIsDataTransferRequested(false);
    }

    if (isValidObject(dataTransferData)) {
      const isGettingDataInComplete = dataTransferData?.some(
        (data) => data.status === "REQUESTED"
      );
      if (isGettingDataInComplete && props.patients.currentProfile) {
        setIsDataTransferRequested(true);
      } else {
        setIsDataTransferRequested(false);
      }
    }

    if (dataTransferData) {
      const showRetryButton = dataTransferData?.every(
        (data) => +new Date() - data.timestamp > 600000
      );

      if (showRetryButton === true) {
        setShowRetryButton(true);
      }
    }

    // eslint-disable-next-line
  }, [props.documents.dataTransfer]);

  // get health locker subscription Data
  useEffect(() => {
    if (
      props.patients.currentProfile &&
      props.patients.subscription &&
      props.patients.subscription?.[props.patients.currentProfile]
    ) {
      const subscription = Object.values(
        props.patients.subscription?.[props.patients.currentProfile]
      ).find(
        (data) =>
          data.clinicId === lockerSetup.locker && data.status === "GRANTED"
      );
      setHealthLockerSubscriptionData(subscription);
    }
  }, [props.patients.subscription, props.patients.currentProfile]);

  function cropHandler() {
    cropper.current.getCroppedCanvas().toBlob(
      (blob) => {
        //for blob to image
        const _file = new File(
          [blob],
          file.src.name ? file.src.name : +new Date(),
          {
            type: "image/png"
          }
        );
        setSrc([...src, _file]);
      },
      "image/png",
      1
    );
    cropper.current?.destroy();
    setFile({ ...file, src: null });
    setCropperReady(false);
  }

  function fileHandler(event) {
    if (
      event.target.files[0] &&
      (event.target.files[0].type === "image/jpeg" ||
        event.target.files[0].type === "image/png")
    ) {
      setFile({ ...file, src: URL.createObjectURL(event.target.files[0]) });
    } else if (isValidArray(src)) {
      setErrorStatus({
        code: "custom",
        message: "Kindly upload a valid File"
      });
    } else if (
      event.target.files[0].type === "application/pdf" ||
      event.target.files[0].type === "application/json"
    ) {
      setFile({
        ...file,
        src: event.target.files[0]
      });
    } else {
      setErrorStatus({
        code: "custom",
        message: "Kindly upload a valid File"
      });
    }
  }

  function handleClose() {
    cropper.current?.destroy();
    setFile({ ...file, src: null });
    setCropperReady(false);
  }

  //document upload handler function
  const onNewDocumentCreate = (documentType) => {
    switch (documentType) {
      //symptom upload button action
      case "symptom": {
        props.navigate("/symptom");
        break;
      }

      //symptom upload button action
      case "vital": {
        // props.navigate("/vital");
        if (documentUploadRef.current) {
          setFile({ ...file, type: "vital" });
          documentUploadRef.current.click();
        }
        break;
      }

      //report upload button action
      case "report": {
        if (documentUploadRef.current) {
          setFile({ ...file, type: "report" });
          documentUploadRef.current.click();
        }
        break;
      }
      //prescription upload button action
      case "prescription": {
        if (documentUploadRef.current) {
          setFile({ ...file, type: "prescription" });
          documentUploadRef.current.click();
        }
        break;
      }
      //receipt upload button action
      case "receipt": {
        if (documentUploadRef.current) {
          setFile({ ...file, type: "receipt" });
          documentUploadRef.current.click();
        }
        break;
      }
      //admission upload button action
      case "admission": {
        if (documentUploadRef.current) {
          setFile({ ...file, type: "admission" });
          documentUploadRef.current.click();
        }
        break;
      }
      //insurance upload button action
      case "insurance": {
        if (documentUploadRef.current) {
          setFile({ ...file, type: "insurance" });
          documentUploadRef.current.click();
        }
        break;
      }
      //vaccine upload button action
      case "vaccine": {
        if (documentUploadRef.current) {
          setFile({ ...file, type: "vaccine" });
          documentUploadRef.current.click();
        }
        break;
      }
      //vaccine upload button action
      case "consultation": {
        if (documentUploadRef.current) {
          setFile({ ...file, type: "consultation" });
          documentUploadRef.current.click();
        }
        break;
      }
      //vaccine upload button action
      case "others": {
        if (documentUploadRef.current) {
          setFile({ ...file, type: "others" });
          documentUploadRef.current.click();
        }
        break;
      }

      default:
        break;
    }
  };

  const documentUploadTitle = (fileType) => {
    switch (fileType) {
      case "reports":
        return "Add Report";
      case "prescriptions":
        return "Add Prescription";
      case "admissions":
        return "Add Admission";
      case "receipts":
        return "Add Receipt";
      case "insurances":
        return "Add Insurance";
      case "vaccine":
        return "Add Vaccine";
      default:
        return "Add Document";
    }
  };

  function returnNotificationCounts(profileId) {
    const notifications = props.notifications.data;
    if (
      typeof profileId === "string" &&
      notifications !== null &&
      notifications[profileId] &&
      isValidObject(notifications[profileId])
    ) {
      const count = Object.values(notifications[profileId]).filter(
        (notification) => notification.read === false
      ).length;
      return count;
    } else {
      return 0;
    }
  }

  const uniqueFileName = () => {
    // isValidArray(src) && src[0]) ?src[0].name : `new ${file.type}`
    if (isValidArray(src) && src[0] && typeof src[0].name === "string") {
      return src[0].name;
    } else {
      return `${+new Date()}`;
    }
  };

  const getHealthLockerSubscriptionRemainingDays = () => {
    const expireDate =
      healthLockerSubscriptionData?.subscription?.period?.to &&
      +new Date(healthLockerSubscriptionData?.subscription?.period?.to);

    const remainingDays = Math.round(
      (expireDate - +new Date()) / (1000 * 60 * 60 * 24)
    );
    return remainingDays;
  };

  return (
    <div
      className="inherit-parent-height"
      data-cy={
        isValidObject(props.patients.profiles)
          ? `profiles-${Object.keys(props.patients.profiles)?.length}`
          : "no-profiles"
      }
    >
      <DocumentUploadModal
        onClickDocumentUpload={(name) => {
          if (Array.isArray(src) && src.length > 0) {
            let _files = [];
            src.forEach((file) => {
              const splittedFileName = file.name.split("/");
              const newFileName = `${name}/${
                splittedFileName[splittedFileName.length - 2]
              }/${splittedFileName[splittedFileName.length - 1]}`;
              _files.push(new File([file], newFileName, { type: file.type }));
            });
            props.uploadDocument({
              documentType: file.type,
              file: { src: _files, type: "image" },
              documentName: name,
              date: new Date().toISOString().split("T")[0],
              time: `${new Date().getHours()}:${new Date().getMinutes()}`
            });
          } else if (!!file.src) {
            const extension = file.src.type.split("/")[1];
            const _file = new File([file.src], `${name}.${extension}`, {
              type: file.src.type
            });
            props.uploadDocument({
              documentType: file.type,
              file: _file,
              documentName: name,
              date: new Date().toISOString().split("T")[0],
              time: `${new Date().getHours()}:${new Date().getMinutes()}`
            });
          }
          setShowUploadFileDetailsEditModal(false);
          setFile({
            type: "",
            src: ""
          });
          setSrc([]);
        }}
        show={showUploadFileDetailsEditModal}
        onClickClose={() => {
          setShowUploadFileDetailsEditModal(false);
        }}
      />
      {/* Document upload input element */}
      <input
        ref={documentUploadRef}
        type="file"
        id="prescription"
        name="fileUploaderInput"
        className="display-none"
        accept={"image/*,application/pdf,application/json"}
        onClick={(event) => {
          event.target.value = "";
        }}
        onChange={(event) => {
          fileHandler(event);
        }}
      />
      {file.src &&
      file.src !== null &&
      file.src.type !== "application/pdf" &&
      file.src.type !== "application/json" ? (
        <div
          data-cy="cropper-modal"
          className="inherit-parent-height inherit-parent-width flex-center-children-horizontally flex-direction-column background-color-black"
        >
          <div className="inherit-parent-height inherit-parent-width max-height-90-percentage padding-top-default">
            <canvas
              className="display-block max-width-100-percentage"
              id="imageData"
              data-cy="cropper-canvas"
            ></canvas>
          </div>
          <footer className="padding-larger inherit-parent-width flex-align-items-center flex-justify-content-space-between">
            <button
              data-cy="crop-img-cancel-button"
              type="submit"
              variant="secondary"
              className="background-transparent font-color-white font-family-gilroy-medium font-size-medium"
              onClick={() => {
                handleClose();
              }}
            >
              Cancel
            </button>
            <button
              data-cy="crop-img-rotate-button"
              type="button"
              className="background-transparent font-color-white  font-family-gilroy-medium font-size-medium"
              variant="secondary"
              onClick={() => {
                cropper.current.rotate(90);
                cropper.current.zoomTo(0);
              }}
            >
              Rotate
            </button>
            <button
              data-cy="crop-img-save-button"
              type="button"
              className="background-transparent font-color-white font-family-gilroy-medium font-size-medium"
              disabled={cropperReady === false}
              onClick={(event) => {
                if (
                  cropper.current.getData(true).width <= 50 ||
                  cropper.current.getData(true).height <= 50
                ) {
                  return;
                }
                cropHandler(event);
              }}
            >
              Save
            </button>
          </footer>
        </div>
      ) : (
        <div className="inherit-parent-height">
          <Header
            displayName={
              props.patients.profiles?.[props.patients.profiles.currentProfile]
                ?.fullName
            }
            title={documentUploadTitle(file.type)}
            loading={false}
            searchKeyword={searchClinic}
            searchPlaceHolder="Search clinic / lab"
            profile-picture-data-cy={"current-profile-image"}
            primary={!isValidArray(src)}
            onProfilePictureClick={() => {
              // if (isValidObject(props.patients.profiles)) {
              //   props.navigate("/settings");
              // }
              setShowProfileSwitcher(true);
            }}
            userProfilePictureSrc={
              props.patients.healthIdData?.[props.patients.currentProfile]
                ? props.patients.healthIdData?.[props.patients.currentProfile]
                    ?.profilePhoto
                  ? `data:image/png;base64, ${
                      props.patients.healthIdData?.[
                        props.patients.currentProfile
                      ]?.profilePhoto
                    }`
                  : null
                : null
            }
            notificationsCount={returnNotificationCounts(
              props.patients.currentProfile
            )}
            hideSupport={true}
            showQr={
              props.patients.accessToken &&
              props.patients.currentProfile &&
              typeof props.patients.accessToken[
                props.patients.currentProfile
              ] === "string"
            }
            onQrClick={() => {
              props.navigate("/abdm/abdmHealthId");
            }}
            backButtonOnClick={() => {
              if (isValidArray(src)) {
                setSrc([]);
              } else {
                props.navigate(-1);
              }
            }}
            onSearchUser={(clinicNameSearchKey) => {
              if (
                clinicNameSearchKey &&
                clinicNameSearchKey !== searchClinic &&
                props.patients.accessToken &&
                !props.abdm.loading
              ) {
                if (props.app.featureFlags?.findClinic) {
                  setSearchClinic(clinicNameSearchKey);
                  props.searchAbdmClinic(clinicNameSearchKey);
                }
              } else {
                setIsSearchOn(false);
              }
            }}
            isSearchOn={isSearchOn}
            background={
              props.patients.backgroundColors?.[props.patients.currentProfile]
            }
            showSearch={
              props.patients.accessToken &&
              props.patients.currentProfile &&
              typeof props.patients.accessToken[
                props.patients.currentProfile
              ] === "string"
            }
            onSearchOn={(_isSearchOpen) => {
              setIsSearchOn(_isSearchOpen);
            }}
            searchResultElementRef={searchResultElementRef}
            incident={props.app.featureFlags?.incident?.patients}
            incidentOnClick={() => {
              props.navigate("/incidents");
            }}
          />
          {/* Patient timeline component */}
          <div className="remaining-body-height overflow-x-scroll">
            <ConditionalRender condition={isValidArray(src)}>
              <ReArrangeAndAddPrescription
                src={src}
                setSrc={setSrc}
                onAddExtraImageClick={() => {
                  documentUploadRef.current.click();
                }}
                fileName={uniqueFileName()}
                onAddPrescriptionClick={(arrayList) => {
                  setShowUploadFileDetailsEditModal(true);
                  setSrc(arrayList);
                }}
                uploadButtonText={documentUploadTitle(file.type)}
                isLoading={props.documents.loading}
              />
            </ConditionalRender>
            <ConditionalRender
              condition={
                isValidObject(healthLockerSubscriptionData) &&
                getHealthLockerSubscriptionRemainingDays() <= 14
              }
            >
              <div
                className="margin-top-medium margin-left-large margin-right-large padding-medium border-1px-e5e5e5 border-radius-default display-flex flex-justify-content-space-between flex-align-items-center"
                onClick={() => {
                  props.healthLockerAuthorization();
                }}
              >
                <div>
                  <div className="font-family-gilroy-medium">
                    Ninto subscription will expired in{""}
                    {getHealthLockerSubscriptionRemainingDays() === 0
                      ? "day"
                      : getHealthLockerSubscriptionRemainingDays() === 1
                      ? "1 day"
                      : `${getHealthLockerSubscriptionRemainingDays()} days`}
                  </div>
                  <div className="padding-top-small font-size-small">
                    Access the features associated with ABDM
                  </div>
                </div>
                <div className="">
                  <ContinueIcon />
                </div>
              </div>
            </ConditionalRender>
            <ConditionalRender
              condition={
                !isValidArray(src) &&
                props.patients.currentProfile &&
                isValidObject(props.patients.accessToken) &&
                props.patients.accessToken?.[props.patients.currentProfile] &&
                !(
                  (!isValidObject(
                    props.patients.careContexts?.[
                      props.patients.currentProfile
                    ]?.[lockerSetup.locker]
                  ) ||
                    (props.patients.careContexts[props.patients.currentProfile][
                      lockerSetup.locker
                    ].careContexts.every((entry) => {
                      if (
                        new Date() - new Date(entry.display.split("-")[1]) >=
                        2592000000
                      ) {
                        return true;
                      } else {
                        return false;
                      }
                    }) &&
                      (props.patients.linkingToken?.[
                        props.patients.currentProfile
                      ]?.limit === 0 ||
                        !isValidObject(healthLockerSubscriptionData) ||
                        props.patients.linkingToken?.[
                          props.patients.currentProfile
                        ]?.status !== "GRANTED"))) &&
                  isSearchOn === false
                )
              }
            >
              <PatientTimeline
                type={"patient"}
                featureFlags={props.app.featureFlags}
                documentFilterMenu={[
                  "prescription",
                  "report",
                  "admission",
                  "vital",
                  "vaccine",
                  "consultation",
                  "others"
                ]}
                kycStatus={
                  props.patients.healthIdData?.[props.patients.currentProfile]
                    ?.kycStatus
                }
                kycStatusClick={() => {
                  props.navigate("/abdm/abhaNumber");
                }}
                removeScrollBar={true}
                hideUploadButton={false}
                loading={props.documents.loading || props.patients.loading}
                downloads={props.downloads.data}
                profilePictures={props.downloads.data.profilePictures}
                documents={
                  isValidObject(props.documents.data) &&
                  Object.values(props.documents.data).filter(
                    (data) =>
                      data.patient.patientId === props.patients.currentProfile
                  )
                }
                transferStatus={isDataTransferRequested}
                lastUpdatedStatus={isDataTransferRequested}
                dataTransfer={
                  props.documents?.dataTransfers &&
                  Object.values(props.documents?.dataTransfers)?.filter(
                    (data) => data.patientId === props.patients.currentProfile
                  )
                }
                linkHealthRecords={() => {
                  setIsSearchOn(true);
                }}
                documentCardOnClick={(data) => {
                  if (["fitness"].includes(data.documentType)) {
                    return;
                  }
                  if (data.consentId) {
                    props.retryDataTransfer({
                      consentId: data.consentId,
                      patientId: props.patients.currentProfile
                    });
                  } else {
                    props.navigate(
                      `${convertAbdmToNintoDocumentTypes(data.documentType)}/${
                        data.documentId
                      }`
                    );
                  }
                }}
                retryButtonOnClick={() => {
                  props.retryDataTransfer({
                    patientId: props.patients.currentProfile
                  });
                }}
                getAudioUrl={(document) => props.getDownloadUrl(document)}
                getDocumentsCardProfilePicture={(type, userId) => {
                  props.getProfilePicture(type, userId);
                }}
                onNewDocumentCreate={(documentType) => {
                  onNewDocumentCreate(documentType);
                }}
                allowedUploadDocuments={[
                  "prescription",
                  "report",
                  "vital",
                  "admission",
                  "vaccine",
                  "consultation",
                  "others"
                ]}
                viewerId={
                  props.patients.currentProfile && props.patients.currentProfile
                }
                patientName={
                  props.patients.currentProfile &&
                  props.patients.profiles?.[props.patients.currentProfile]?.name
                }
                viewerType="patient"
                showExpiringSoon={
                  props.patients?.linkingToken &&
                  props.patients?.currentProfile &&
                  props.patients.linkingToken[props.patients.currentProfile] &&
                  isValidObject(
                    Object.values(
                      props.patients.linkingToken[props.patients.currentProfile]
                    )[0]
                  ) &&
                  dateBeforeSevenDays(
                    Object.values(
                      props.patients.linkingToken[props.patients.currentProfile]
                    )[0].expiryTime
                  )
                }
                showExpired={
                  props.patients.linkingToken &&
                  props.patients.currentProfile &&
                  props.patients.linkingToken[props.patients.currentProfile] &&
                  isValidObject(
                    Object.values(
                      props.patients.linkingToken[props.patients.currentProfile]
                    )[0]
                  ) &&
                  expiringTime(
                    Object.values(
                      props.patients.linkingToken[props.patients.currentProfile]
                    )[0].expiryTime
                  )
                }
                resubscribeOnClick={() => {
                  props.healthLockerAuthorization();
                }}
                resubscribeLoading={props.patients.loading}
                //filter onclick props
                filterState={props.documents.filters}
                onFilterClick={(type, filterValue) => {
                  props.addDocumentFilter(type, filterValue);
                }}
                onClearFilterClick={() => {
                  props.removeDocumentFilter("doctorAndClinic");
                }}
                showRetryButton={
                  (props.documents?.dataTransfer &&
                    Object.values(props.documents?.dataTransfer)) ||
                  showRetryButton
                }
              />
            </ConditionalRender>
            <ConditionalRender
              condition={
                props.patients.loading === true &&
                (props.patients.autoApprovalPolicies === null ||
                  props.patients.linkingToken === null ||
                  props.patients.subscription === null ||
                  props.patients.currentProfile === null)
              }
            >
              <HomePageLoading />
            </ConditionalRender>
            <ConditionalRender
              condition={
                ((props.patients.accessToken &&
                  props.patients.currentProfile &&
                  typeof props.patients.accessToken[
                    props.patients.currentProfile
                  ] !== "string") ||
                  !props.patients.accessToken) &&
                isSearchOn === false &&
                props.patients.autoApprovalPolicies !== null &&
                props.patients.linkingToken !== null &&
                props.patients.subscription !== null &&
                props.patients.profiles !== null &&
                props.patients.loading === false
              }
            >
              <div className="inherit-parent-height padding-larger flex-justify-content-center flex-align-items-center">
                <ConnectABDM
                  connectOnClick={() => {
                    props.navigate("/abdm/login/verify");
                  }}
                />
              </div>
            </ConditionalRender>
            <ConditionalRender
              condition={
                props.patients.currentProfile &&
                isValidObject(props.patients.accessToken) &&
                props.patients.accessToken?.[props.patients.currentProfile] &&
                (!isValidObject(
                  props.patients.careContexts?.[
                    props.patients.currentProfile
                  ]?.[lockerSetup.locker]
                ) ||
                  (props.patients.careContexts[props.patients.currentProfile][
                    lockerSetup.locker
                  ].careContexts.every((entry) => {
                    if (
                      new Date() - new Date(entry.display.split("-")[1]) >=
                      2592000000
                    ) {
                      return true;
                    } else {
                      return false;
                    }
                  }) &&
                    (props.patients.linkingToken?.[
                      props.patients.currentProfile
                    ]?.limit === 0 ||
                      !isValidObject(healthLockerSubscriptionData) ||
                      props.patients.linkingToken?.[
                        props.patients.currentProfile
                      ]?.status !== "GRANTED"))) &&
                isSearchOn === false
              }
            >
              <ConditionalRender
                condition={
                  !props.app.featureFlags?.authorization ||
                  !props.app.featureFlags?.consentManagement
                }
              >
                <FeatureFlagsFallback />
              </ConditionalRender>

              <ConditionalRender
                condition={
                  props.app.featureFlags?.authorization &&
                  props.app.featureFlags?.consentManagement
                }
              >
                {!props.patients?.profiles?.[props.patients.currentProfile]
                  ?.consentToPersonalData?.userAgreed ? (
                  <PersonalDataConsentForm
                    loading={props.patients.loading}
                    agreeClick={() => {
                      props.setConsentToPersonalData();
                    }}
                  />
                ) : (
                  <SubscribeLocker
                    connectOnClick={() => {
                      props.healthLockerAuthorization();
                    }}
                    connectLoading={props.patients.loading}
                  />
                )}
              </ConditionalRender>
            </ConditionalRender>
            <ConditionalRender condition={isSearchOn === true}>
              <section
                ref={searchResultElementRef}
                className="inherit-parent-height"
              >
                <ConditionalRender
                  condition={!props.app.featureFlags?.findClinic}
                >
                  <FeatureFlagsFallback />
                </ConditionalRender>

                <ConditionalRender
                  condition={props.app.featureFlags?.findClinic}
                >
                  <HandleBackClick
                    onClose={() => {
                      setIsSearchOn(false);
                    }}
                    isBackEnabled={isSearchOn}
                  >
                    <ClinicSearchResult
                      loading={props.abdm.loading}
                      searchKey={searchClinic}
                      publicHealthPrograms={
                        props.abdm?.clinics?.search?.publicHealthPrograms
                      }
                      searchResults={
                        isValidObject(props.abdm.clinics.search.results)
                          ? Object.values(props.abdm.clinics.search.results)
                          : []
                      }
                      searchHistory={props.abdm.clinics.search.searchHistory}
                      abdmAccessToken={
                        props.patients.currentProfile &&
                        props.patients.accessToken &&
                        props.patients.accessToken[
                          props.patients.currentProfile
                        ]
                      }
                      clinicCardOnClick={(clinicId, type) => {
                        if (
                          !type &&
                          props.abdm.clinics.search.results[clinicId]
                        ) {
                          props.setSearchHistory({
                            [clinicId]: {
                              ...props.abdm.clinics.search.results[clinicId],
                              timestamp: +new Date()
                            }
                          });
                        }
                        props.navigate(`/abdm/clinic/${clinicId}`);
                      }}
                      connectOnClick={() => {
                        props.navigate("/abdm/login/verify");
                      }}
                    />
                  </HandleBackClick>
                </ConditionalRender>
              </section>
            </ConditionalRender>
          </div>

          <Modal
            show={showProfileSwitcher}
            canIgnore={true}
            onClose={() => {
              setShowProfileSwitcher(false);
            }}
            position="position-fixed bottom-0 left-0 right-0"
            width="inherit-parent-width"
            maxWidth="false"
            height="false"
            background="false"
            boxShadow="false"
            borderRadius="false"
          >
            <div
              className={`${
                isValidObject(props.patients.profiles) &&
                Object.values(props.patients?.profiles).length <= 6
                  ? "height-fit-to-content"
                  : "height-500-px"
              } inherit-parent-width background-white padding-top-large padding-bottom-large padding-left-larger padding-right-larger font-family-gilroy-regular font-color-secondary`}
              data-cy="patients-switch-modal"
            >
              <div className="position-relative inherit-parent-height inherit-parent-width">
                <div className="padding-bottom-large text-align-center font-size-small">
                  SWITCH PROFILE
                </div>

                <div
                  className={`${
                    isValidObject(props.patients.profiles) &&
                    Object.values(props.patients?.profiles).length <= 6
                      ? "height-fit-to-content"
                      : "height-398-px"
                  } overflow-scroll`}
                >
                  {props.patients.currentProfile &&
                    isValidObject(props.patients.profiles) &&
                    Object.values(props.patients?.profiles).map(
                      (data, index) => (
                        <React.Fragment key={index}>
                          <ProfileCard
                            data-cy={`profilePic-${data.name}`}
                            src={
                              props.patients.healthIdData?.[data.documentId]
                                ? props.patients.healthIdData?.[data.documentId]
                                    ?.profilePhoto
                                  ? `data:image/png;base64, ${
                                      props.patients.healthIdData?.[
                                        data.documentId
                                      ]?.profilePhoto
                                    }`
                                  : null
                                : null
                            }
                            selected={
                              props.patients.currentProfile === data.documentId
                            }
                            key={index}
                            data={{
                              name: data?.name,
                              healthId: data?.healthId
                            }}
                            onClick={() => {
                              props.switchPatientProfile(data.documentId);
                              setShowProfileSwitcher(false);
                            }}
                            onSettingClick={() => {
                              props.switchPatientProfile(data.documentId);
                              props.navigate("/settings");
                            }}
                          />
                        </React.Fragment>
                      )
                    )}
                </div>
                <div
                  className={`${
                    isValidObject(props.patients.profiles) &&
                    Object.values(props.patients?.profiles).length <= 6
                      ? ""
                      : "position-absolute bottom-0"
                  } inherit-parent-width  padding-top-default background-color-white`}
                >
                  <ProfileCard
                    type="create"
                    text="Create ABHA address"
                    onClick={() => {
                      if (
                        props.patients.accessToken?.[
                          props.patients.currentProfile
                        ]
                      ) {
                        props.navigate("/abdm/register/verify");
                        props.setTransactionId(null);
                      } else {
                        props.navigate("/abdm/authentication?force=true");
                        props.setTransactionId(null);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </Modal>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = function (state) {
  return {
    abdm: state.abdm,
    profile: state.profile,
    notifications: state.notifications,
    downloads: state.downloads,
    documents: state.documents,
    patients: state.patients,
    app: state.app
  };
};

const mapDispatchToProps = function () {
  return {
    setTransactionId: (data) => setTransactionId(data),
    switchPatientProfile: (profileId) => {
      switchPatientProfile(profileId);
    },
    setSearchHistory: (data) => setSearchHistory(data),
    clearClinicSearchResults: () => clearClinicSearchResults(),
    uploadDocument: (fileType, file, issuedDateTimestamp) =>
      uploadDocument(fileType, file, issuedDateTimestamp),
    getDownloadUrl: (documentId) => getDocumentDownloadUrl(documentId),
    getProfilePicture: (userType, userId) =>
      getProfilePictureDownloadUrl(userType, userId),
    setNotificationRead: (notification) => setNotificationRead(notification),
    addDocumentFilter: (type, filterType) =>
      addDocumentFilter(type, filterType),
    removeDocumentFilter: (clear) => removeDocumentFilter(clear),
    searchAbdmClinic: (searchKey) => searchAbdmClinic(searchKey),
    healthLockerAuthorization: () => healthLockerAuthorization(),
    retryDataTransfer: (data) => retryDataTransfer(data),
    setConsentToPersonalData: (data) => setConsentToPersonalData(data)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);

const DocumentUploadModal = (props) => {
  const [documentDetails, setDocumentDetails] = useState({
    name: "",
    date: ""
  });
  function documentUploadDetailsOnChange(event) {
    setDocumentDetails({
      ...documentDetails,
      [event.target.name]: event.target.value
    });
  }
  return (
    <HandleBackClick onClose={props.onClickClose} isBackEnabled={props.show}>
      <Modal
        onClose={props.onClickClose}
        show={props.show}
        position="position-fixed bottom-0 left-0 right-0"
        boxShadow="none"
        borderRadius="none"
        width="inherit-parent-width"
        background="background-transparent"
        height="height-fit-to-content"
      >
        <form
          onChange={(event) => {
            documentUploadDetailsOnChange(event);
          }}
          onSubmit={(event) => {
            event.preventDefault();

            if (
              !validString(documentDetails.name)
              // || typeof documentDetails.date !== "string"
            )
              return;
            const timestamp = +new Date(documentDetails.date);
            props.onClickDocumentUpload(documentDetails.name, timestamp);
            setDocumentDetails({
              name: "",
              date: ""
            });
          }}
          className="background-white padding-left-larger padding-right-larger font-family-gilroy-medium user-select-none "
        >
          <section className="max-width-588px margin-horizontal-auto">
            <div className="font-color-secondary font-family-gilroy-regular font-size-small text-align-center padding-top-large padding-bottom-medium">
              ADD DOCUMENT
            </div>
            <InputBox
              label="Document name"
              name="name"
              type="text"
              data-cy="documentName"
              className="padding-right-medium margin-bottom-large"
              value={documentDetails.name}
              autoFocus={true}
            />
            {/* <InputBox
            label="Date of issue"
            name="date"
            type="date"
            data-cy="documentIssuedDate"
            className="padding-right-medium margin-bottom-larger"
            value={documentDetails.date}
            autoFocus={false}
          /> */}
            <div className="display-flex padding-bottom-larger">
              <Button
                buttonKey="document-name-add-button"
                data-cy="document-name-add-button"
                boxShadow={false}
                className="margin-right-small font-family-gilroy-medium"
                text="Add"
                disabled={
                  validString(documentDetails.name)
                    ? // &&  typeof documentDetails.date === "string"
                      false
                    : true
                }
                type="submit"
              />
              <Button
                buttonKey={"document-name-cancel-button"}
                boxShadow={false}
                className="margin-left-small font-family-gilroy-regular font-color-secondary border-1px-e5e5e5"
                variant="secondary"
                text="Cancel"
                onClick={() => {
                  if (typeof props.onClickClose === "function") {
                    props.onClickClose();
                    setDocumentDetails({
                      name: null,
                      date: null
                    });
                  }
                }}
              />
            </div>
          </section>
        </form>
      </Modal>
    </HandleBackClick>
  );
};

function validString(value) {
  if (typeof value === "string" && value.trim().length !== 0) {
    return true;
  } else return false;
}

function ClinicSearchResult(props) {
  return (
    <React.Fragment>
      <section className="inherit-parent-height padding-larger ">
        <ConditionalRender
          condition={typeof props.abdmAccessToken !== "string"}
        >
          <div className="inherit-parent-height flex-justify-content-center flex-align-items-center">
            <ConnectABDM
              connectOnClick={() => {
                props.connectOnClick();
              }}
            />
          </div>
        </ConditionalRender>
        <ConditionalRender
          condition={
            typeof props.abdmAccessToken === "string" &&
            props.loading === false &&
            props.searchResults?.length > 0
          }
        >
          <div className="font-size-small flex-justify-content-center">
            HOSPITALS / CLINIC
          </div>

          {props.searchResults?.map(
            (data, index) =>
              (props.publicHealthPrograms
                ? Object.values(props.publicHealthPrograms).some(
                    (healthProgram) =>
                      !(
                        healthProgram.identifier?.name ===
                        data?.identifier?.name
                      )
                  )
                : true) && (
                <div key={index}>
                  <ClinicProfileCard
                    onClick={() => {
                      props.clinicCardOnClick(data?.identifier?.id);
                    }}
                    clinicName={data?.identifier?.name}
                    locality={data?.city ? data?.city : ""}
                  />
                </div>
              )
          )}
        </ConditionalRender>

        <ConditionalRender
          condition={
            typeof props.abdmAccessToken === "string" &&
            props.loading === false &&
            props.searchResults?.length === 0 &&
            props.searchHistory &&
            Object.values(props.searchHistory).length > 0
          }
        >
          <div className="font-size-small flex-justify-content-center">
            SEARCH HISTORY
          </div>
          {props.searchHistory &&
            Object.values(props.searchHistory)
              .sort((first, second) => second.timestamp - first.timestamp)
              .map(
                (data, index) =>
                  index < 3 && (
                    <div key={index}>
                      <ClinicProfileCard
                        onClick={() => {
                          props.clinicCardOnClick(
                            data?.identifier?.id,
                            "history"
                          );
                        }}
                        history={true}
                        clinicName={data?.identifier?.name}
                        locality={data?.city ? data?.city : ""}
                      />
                    </div>
                  )
              )}
        </ConditionalRender>

        <ConditionalRender
          condition={
            typeof props.abdmAccessToken === "string" && props.loading === false
          }
        >
          <div className="font-size-small flex-justify-content-center font-color-secondary">
            HEALTH PROGRAM
          </div>
          {props.publicHealthPrograms &&
            Object.values(props.publicHealthPrograms).map((data, index) => (
              <div
                key={index}
                onClick={() => {
                  props.clinicCardOnClick(
                    data?.identifier?.id,
                    "healthProgram"
                  );
                }}
                className="padding-top-large padding-bottom-large flex-justify-content-space-between font-color-secondary"
              >
                <div>{data.identifier?.name}</div>
                <img
                  className="clinic-connection-remove-icon-height cursor-pointer "
                  src={greaterThanIcon}
                  width="auto"
                  alt="connection-remove-icon"
                />
              </div>
            ))}
        </ConditionalRender>

        <ConditionalRender
          condition={
            typeof props.abdmAccessToken === "string" && props.loading === true
          }
        >
          {[...Array(20)].map((_e, index) => (
            <div key={index}>
              <ClinicProfileCard
                loading={props.loading}
                clinicName={""}
                locality={""}
              />
            </div>
          ))}
        </ConditionalRender>
      </section>
    </React.Fragment>
  );
}

const ClinicProfileCard = ({
  loading,
  profilePictureUrl,
  clinicName,
  locality,
  onClick,
  history
}) => {
  return (
    <React.Fragment key={clinicName}>
      <div className="display-flex margin-vertical-large " data-cy={clinicName}>
        <div
          className="display-flex flex-grow-1 flex-align-items-center font-color-secondary "
          onClick={() => {
            onClick();
          }}
        >
          <RoundedProfilePicture
            userType="corporate"
            loading={loading}
            size="medium"
            src={profilePictureUrl?.[clinicName]}
          />
          {loading ? (
            <div className="inherit-parent-width flex-direction-column display-flex padding-left-medium flex-justify-content-center">
              <span className="border-radius-default shimmer padding-small width-50-percentage margin-bottom-default "></span>
              <span className="shimmer padding-small border-radius-default width-20-percentage"></span>
            </div>
          ) : (
            <div className="flex-grow-1 flex-direction-column display-flex padding-left-medium">
              <span
                className="font-size-medium font-family-gilroy-regular"
                data-cy={clinicName}
              >
                {clinicName}
              </span>
              <span
                className="font-size-small font-family-gilroy-regular text-transform-capitalize padding-top-small"
                data-cy={clinicName}
              >
                {locality}
              </span>
            </div>
          )}
          {!loading && !history && (
            <img
              className="clinic-connection-remove-icon-height cursor-pointer "
              data-cy={`${clinicName}-arrowIcon`}
              src={greaterThanIcon}
              width="auto"
              alt="connection-remove-icon"
            />
          )}
          {history && (
            <img
              className="clinic-connection-remove-icon-height cursor-pointer "
              data-cy={`${clinicName}-arrowIcon`}
              src={historyIcon}
              width="auto"
              alt="history-icon"
            />
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

const SubscribeLocker = (props) => {
  return (
    <section
      data-cy="subscribe-locker"
      className="inherit-parent-height text-align-center inherit-parent-width padding-horizontal-larger display-flex flex-align-items-center flex-direction-column flex-justify-content-center"
    >
      <img
        src={ninto_logo}
        alt="ninto logo"
        draggable={false}
        width="auto"
        height="24"
      />
      <h3 className="padding-vertical-large font-family-gilroy-medium font-size-medium font-color-secondary">
        Ninto health locker
      </h3>

      <div className="font-family-gilroy-regular font-color-secondary font-size-medium line-height-140-percentage">
        Fetch & store your documents such as prescriptions, reports, wellness
        records, receipts, etc. securely
      </div>

      <div className="font-family-gilroy-regular font-color-secondary font-size-small line-height-140-percentage padding-vertical-large">
        By approving, you agree with Ninto's terms of service & privacy
        policies. You can opt out of this any time.
      </div>

      <Button
        boxShadow={false}
        data-cy="connect-now-button"
        className={"width-40-percentage "}
        text="Continue"
        loading={props.connectLoading}
        onClick={props.connectOnClick}
      />
    </section>
  );
};

const dateBeforeSevenDays = (timestamp) => {
  const currentDate = +new Date();

  if (currentDate >= timestamp - 86400000 * 7) {
    return true;
  } else {
    return false;
  }
};

const expiringTime = (timestamp) => {
  const currentDate = +new Date();
  if (currentDate > timestamp) {
    return true;
  } else {
    return false;
  }
};

const HomePageLoading = () => {
  return (
    <section
      className="padding-top-default padding-bottom-large overflow-hidden"
      data-cy="home-page-loading"
    >
      <div className="inherit-parent-width display-flex flex-align-items-center overflow-x-scroll padding-left-default padding-right-default padding-bottom-larger ">
        <div className="shimmer padding-right-larger padding-left-larger padding-top-medium padding-bottom-medium margin-right-default  border-radius-default" />
        <div className="shimmer width-150px padding-medium margin-right-default  border-radius-default" />
        <div className="shimmer width-150px padding-medium margin-right-default border-radius-default" />
        <div className="shimmer width-150px padding-medium margin-right-default border-radius-default" />
      </div>
      <div className="display-flex flex-justify-content-space-between margin-left-large margin-right-large padding-bottom-default">
        <div className="shimmer padding-default border-radius-default width-150px " />
        <div className="shimmer border-radius-default padding-right-larger padding-left-larger padding-top-large ">
          <div className="padding-left-larger"></div>
        </div>
      </div>

      {[...Array(5)].map((_data, index) => (
        <div
          key={index}
          className="padding-top-larger padding-left-large padding-right-large border-bottom-1px-e5e5e5"
        >
          <div className="display-flex flex-justify-content-space-between ">
            <div className="shimmer  padding-default border-radius-default padding-right-larger padding-left-larger ">
              <div className="padding-left-larger padding-right-larger"></div>
            </div>
            <div className="shimmer  border-radius-default padding-right-larger padding-left-larger padding-top-large ">
              <div className="padding-left-larger padding-right-large"></div>
            </div>
          </div>

          <div className="flex-center-children-vertically padding-top-large  padding-bottom-larger">
            <div className="shimmer padding-large border-radius-50-percentage height-width-16px" />
            <div className="margin-left-default">
              <div className="shimmer  padding-default border-radius-default width-200px " />
              <div className="shimmer margin-top-default padding-small border-radius-default width-150px ">
                <div className="padding-smaller" />
              </div>
            </div>
          </div>
        </div>
      ))}

      <div className="shimmer position-absolute bottom-15px right-15px  padding-larger border-radius-default height-width-16px" />
      <div className="shimmer position-absolute bottom-75px right-15px  padding-larger border-radius-default height-width-16px" />
    </section>
  );
};

function ProfileCard(props) {
  return (
    <>
      {!props.type && (
        <div
          data-cy={props.dataCy}
          className="inherit-parent-width flex-justify-content-space-between margin-bottom-large border-radius-default cursor-pointer"
          onClick={props.onClick}
        >
          <div className="flex-center-children-vertically ">
            <RoundedProfilePicture
              size="medium"
              removeResponsive={true}
              src={props.src}
            />
            <div className="padding-left-large">
              <div className=" font-size-large padding-right-default">
                {props.data.name}
              </div>
              <div className="font-size-small">{props.data.healthId}</div>
            </div>
          </div>
          <div className="display-flex">
            <ConditionalRender condition={props.selected === true}>
              <div className="padding-right-large flex-center-children-vertically ">
                <TickIcon color="#00A000" />
              </div>
            </ConditionalRender>
            <div
              data-cy={`${props.data.healthId}-setting-icon`}
              onClick={props.onSettingClick}
              className="flex-center-children-vertically"
            >
              <SettingIcon />
            </div>
          </div>
        </div>
      )}

      {props.type === "create" && (
        <div
          data-cy="create-card"
          className="flex-justify-content-space-between flex-center-children-vertically cursor-pointer inherit-parent-width"
          onClick={props.onClick}
        >
          <div className="display-flex flex-center-children-vertically">
            <div className="background-color-secondary border-radius-100-percentage height-and-width-44px flex-place-children-page-center">
              <AddIcon />
            </div>
            <div className="font-size-medium padding-left-large">
              {props.text}
            </div>
          </div>
          <div className="flex-center-children-vertically">
            <ArrowIcon />
          </div>
        </div>
      )}
    </>
  );
}
