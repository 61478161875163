import { put, takeEvery, all } from "redux-saga/effects";
import { sendOAuthAccessCode } from "../../services/api";
import {
  otpValidate,
  logout,
  otpRequest,
  signInWithAuthCredentials,
  signInWithAuthCustomToken
} from "../../services/authentication";
import store from "../store/store";
import { setErrorStatus } from "../status/actions";
import { login, setOAuthAccessToken } from "./actions";
import { skipWaitingClearCacheAndReload } from "../../utils/cache";
import { isValidObject } from "../../utils/validators";

export const authActionTypes = {
  SEND_OTP: "SEND_OTP",
  VERIFY_OTP: "VERIFY_OTP",
  LOGIN: "LOGIN",
  LOGIN_ERROR: "LOGIN_ERROR",
  LOGOUT: "LOGOUT",
  PUT_AUTH_INFO: "PUT_AUTH_INFO",
  CLEAR_AUTH_CREDENTIALS: "CLEAR_AUTH_CREDENTIALS",
  REFRESH_ACCESS_TOKEN: "REFRESH_ACCESS_TOKEN",
  SET_OAUTH_ACCESS_TOKEN: "SET_OAUTH_ACCESS_TOKEN",
  SEND_OAUTH_ACCESS_CODE: "SEND_OAUTH_ACCESS_CODE",
  SWITCH_WITH_CUSTOM_TOKEN: "SWITCH_WITH_CUSTOM_TOKEN"
};

function* sendOtpWorker(action) {
  try {
    yield setAuthLoading(true);

    if (action.payload.type === "login") {
      const verificationId = yield otpRequest(action.payload.phoneNumber);
      yield put({
        type: "SET_AUTH_VERIFICATION_ID",
        payload: {
          verificationId: verificationId
        }
      });
    }
    yield setAuthLoading(false);
  } catch (error) {
    yield setErrorStatus(error);
    yield setAuthLoading(false);
  }
}

function* verifyOtpWorker(action) {
  try {
    yield setAuthLoading(true);
    const authCredentials = yield otpValidate(
      action.payload.loginOtp,
      store.getState().auth.credentials.verificationId
    );
    yield put({
      type: "SET_AUTH_CREDENTIALS",
      payload: {
        authCredential: authCredentials
      }
    });

    login();
  } catch (error) {
    yield setErrorStatus(error);
    yield setAuthLoading(false);
  }
}

function* loginWorker() {
  try {
    yield setAuthLoading(true);
    const response = yield signInWithAuthCredentials(
      store.getState().auth.credentials.authCredential
    );
    yield put({
      type: "SET_AUTH_INFO",
      payload: {
        accessToken: response.user.accessToken,
        uid: response.user.uid,
        phoneNumber: response.user.phoneNumber,
        displayName: response.user.displayName
      }
    });
    yield setAuthLoading(false);
    if (window.location.pathname === "/login") {
      window.history.pushState({}, null, "/");
    }
  } catch (error) {
    yield setErrorStatus(error);
    yield setAuthLoading(false);
  }
}

function* putAuthInfoWorker(action) {
  try {
    yield setAuthLoading(true);
    yield put({
      type: "SET_AUTH_INFO",
      payload: action.payload.data
    });
  } catch (error) {
    yield setAuthLoading(false);
    yield setErrorStatus(error);
  }
}

function* logoutWorker() {
  try {
    yield setAuthLoading(true);
    yield logout();
    yield skipWaitingClearCacheAndReload();
    yield put({
      type: "RESET"
    });
    yield setAuthLoading(false);
  } catch (error) {
    yield setAuthLoading(false);
    yield setErrorStatus(error);
  }
}

function* clearAuthCredentialsWorker() {
  try {
    yield put({
      type: "REMOVE_AUTH_CREDENTIALS"
    });
  } catch (error) {
    yield setErrorStatus(error);
  }
}

function* accessTokenRefreshWorker(action) {
  try {
    yield put({
      type: "SET_AUTH_TOKEN",
      payload: {
        ...store.getState().auth.data,
        accessToken: action.payload.accessToken,
        phoneNumber: action.payload.phoneNumber
      }
    });
  } catch (error) {
    yield setErrorStatus(error);
  }
}

function* sendOAuthAccessCodeWorker(action) {
  try {
    /**
     * send redirected OAuth access code to backend to fetch the google fit data
     */
    const response = yield sendOAuthAccessCode(
      store.getState().auth.data.accessToken,
      store.getState().profile.currentProfile,
      action.payload.accessCode ? action.payload.accessCode : false
    );
    if (response) {
      if (
        response?.status === 500 &&
        response?.error?.message === "Session Expired"
      ) {
        setOAuthAccessToken("expired");
        return;
      }
      if (action.payload.accessCode) {
        window.history.replaceState({}, document.title, "/");
      }
      setOAuthAccessToken(null);
    }
  } catch (error) {
    setErrorStatus(error);
  }
}

function* setOAuthAccessTokenWorker(action) {
  try {
    yield put({
      type: "SET_OAUTH_TOKEN",
      payload: {
        accessToken: action.payload.accessToken
      }
    });
  } catch (error) {
    setErrorStatus(error);
  }
}
function* switchWithCustomTokenWorker(action) {
  try {
    yield setCustomAuthLoading(true);
    yield logout();
    yield put({
      type: "FORCE_CLEAR_DOWNLOADS"
    });
    yield put({
      type: "FORCE_CLEAR_DOCUMENTS"
    });
    yield put({
      type: "FORCE_CLEAR_NOTIFICATION"
    });
    yield put({
      type: "FORCE_CLEAR_PATIENTS"
    });

    const response = yield signInWithAuthCustomToken(action.payload.token);

    yield put({
      type: "SET_AUTH_INFO",
      payload: {
        ...store.getState().auth.data,
        accessToken: response.user.accessToken,
        uid: response.user.uid,
        phoneNumber: response.user.phoneNumber,
        displayName: response.user.displayName
      }
    });
    const ABHAIdData = store.getState().patients.recentlyCreatedABHAIdData;
    if (isValidObject(ABHAIdData)) {
      yield put({
        type: "SET_ABDM_ACCESS_TOKEN",
        payload: {
          abdmAccessToken: {
            ...store.getState().patients.accessToken,
            [ABHAIdData.patientId]: ABHAIdData.accessToken
          }
        }
      });
    }

    yield put({
      type: "SET_CURRENT_PATIENT_PROFILE_ID",
      payload: { currentProfile: null }
    });
    yield setCustomAuthLoading(false);
  } catch (error) {
    yield setCustomAuthLoading(false);
    setErrorStatus(error);
  }
}

export default function* authWatcher() {
  yield all([
    takeEvery("SEND_OTP", sendOtpWorker),
    takeEvery("PUT_AUTH_INFO", putAuthInfoWorker),
    takeEvery("VERIFY_OTP", verifyOtpWorker),
    takeEvery("LOGIN", loginWorker),
    takeEvery("LOGOUT", logoutWorker),
    takeEvery("CLEAR_AUTH_CREDENTIALS", clearAuthCredentialsWorker),
    takeEvery("REFRESH_ACCESS_TOKEN", accessTokenRefreshWorker),
    takeEvery("SEND_OAUTH_ACCESS_CODE", sendOAuthAccessCodeWorker),
    takeEvery("SET_OAUTH_ACCESS_TOKEN", setOAuthAccessTokenWorker),
    takeEvery("SWITCH_WITH_CUSTOM_TOKEN", switchWithCustomTokenWorker)
  ]);
}

function* setAuthLoading(loadingState) {
  yield put({
    type: "SET_AUTH_LOADING",
    payload: {
      loading: loadingState
    }
  });
}

function* setCustomAuthLoading(loadingState) {
  yield put({
    type: "SET_CUSTOM_AUTH_LOADING",
    payload: {
      loading: loadingState
    }
  });
}
