import React, { useState } from "react";
import { connect } from "react-redux";
import ConsentPinManagement from "../../components/ConsentPinManagement/ConsentPinManagement";
import Header from "../../front-end-global-components/components/Header/Header";
import { clearShowSuccess, consentPin } from "../../redux/patients/actions";
import ConditionalRender from "../../front-end-global-components/components/ConditionalRender/ConditionalRender";
import FeatureFlagsFallback from "../../front-end-global-components/components/FeatureFlagsFallback/FeatureFlagsFallback";

const ChangeConsentPin = (props) => {
  const [forgotPin, setForgotPin] = useState(false);

  return (
    <>
      <Header
        supportIconOnClick={() => {
          props.navigate("/query");
        }}
        title={forgotPin ? "Reset consent PIN" : "Change consent PIN"}
        backButtonOnClick={() => {
          props.navigate(-1);
        }}
        incident={props.app.featureFlags?.incident?.patients}
        incidentOnClick={() => {
          props.navigate("/incidents");
        }}
      />
      <section className=" remaining-body-height max-width-588px margin-horizontal-auto padding-right-larger padding-left-larger overflow-y-auto inherit-parent-width ">
        <ConditionalRender
          condition={!props.app.featureFlags?.consentManagement}
        >
          <FeatureFlagsFallback />
        </ConditionalRender>

        <ConditionalRender
          condition={props.app.featureFlags?.consentManagement}
        >
          <ConsentPinManagement
            error={props.error.message}
            showSuccess={
              props.patients.showSuccess?.consentPin?.forget ||
              props.patients.showSuccess?.consentPin?.create
            }
            successConfirmClick={() => {
              props.navigate(-1);
              props.clearShowSuccess();
            }}
            method={
              props.patients.healthIdData?.[props.patients.currentProfile]
                ?.hasTransactionPin === false
                ? "create"
                : ""
            }
            loading={props.patients.loading}
            onClickSendOtpToResetPin={() => {
              if (forgotPin === false) {
                setForgotPin(true);
              }
              props.manageConsentPin({
                method: "reset"
              });
            }}
            onSubmit={({ newPin, currentPin, otp }) => {
              // { method, newPin, currentPin, otp, historyGoBack }
              if (
                props.patients.healthIdData[props.patients.currentProfile]
                  ?.hasTransactionPin === false
              ) {
                props.manageConsentPin({
                  method: "create",
                  newPin: newPin,
                  navigate: props.navigate
                });
              } else {
                if (forgotPin) {
                  props.manageConsentPin({
                    method: "reset",
                    otp: otp,
                    newPin: newPin,
                    navigate: props.navigate
                  });
                } else {
                  props.manageConsentPin({
                    method: "update",
                    currentPin: currentPin,
                    newPin: newPin,
                    navigate: props.navigate
                  });
                }
              }
            }}
          />
        </ConditionalRender>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  error: state.status,
  patients: state.patients,
  app: state.app
});

const mapDispatchToProps = function () {
  return {
    manageConsentPin: ({ method, newPin, currentPin, otp, navigate }) =>
      consentPin({ method, newPin, currentPin, otp, navigate }),
    clearShowSuccess: () => clearShowSuccess()
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangeConsentPin);
