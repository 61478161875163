import { connect } from "react-redux";
import Header from "../../front-end-global-components/components/Header/Header";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import ConditionalRender from "../../front-end-global-components/components/ConditionalRender/ConditionalRender";
import { ArrowIcon } from "../../front-end-global-components/assets/assets";
import { setSelectedLinkedFacility } from "../../redux/patients/actions";
import { searchAbdmClinic } from "../../redux/abdm/actions";
import { ClinicCard, DocumentCard } from "../../components/Cards/Cards";
import React, { useEffect } from "react";
import FeatureFlagsFallback from "../../front-end-global-components/components/FeatureFlagsFallback/FeatureFlagsFallback";
import HandleBackClick from "../../front-end-global-components/components/HandleBackClick/HandleBackClick";

const LinkedFacilities = (props) => {
  useEffect(() => {
    props.setSelectedLinkedFacility(null);
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Header
        supportIconOnClick={() => {
          props.navigate("/query");
        }}
        title={"Linked Facilities"}
        backButtonOnClick={() => {
          if (props.patients.selectedLinkedFacility) {
            props.setSelectedLinkedFacility(null);
          } else {
            props.navigate("settings");
          }
        }}
        incident={props.app.featureFlags?.incident?.patients}
        incidentOnClick={() => {
          props.navigate("/incidents");
        }}
      />

      <ConditionalRender condition={!props.app.featureFlags?.healthData}>
        <FeatureFlagsFallback height="remaining-body-height" />
      </ConditionalRender>

      <ConditionalRender condition={props.app.featureFlags?.healthData}>
        <ConditionalRender
          condition={typeof props.patients.selectedLinkedFacility !== "string"}
        >
          <div className=" padding-horizontal-larger">
            <ConditionalRender
              condition={
                props.patients.currentProfile &&
                props.patients.careContexts &&
                props.patients.careContexts[props.patients.currentProfile] &&
                Object.values(
                  props.patients.careContexts[props.patients.currentProfile]
                ).filter((data) => data.hip.type === "HIP_AND_HIU").length >= 1
              }
            >
              <div className=" font-size-small display-flex flex-justify-content-center padding-top-large text-uppercase">
                Hospitals / Clinic
              </div>

              {props.patients.currentProfile &&
                props.patients.careContexts?.[props.patients.currentProfile] &&
                Object.values(
                  props.patients.careContexts[props.patients.currentProfile]
                )
                  .filter((data) => data.hip.type === "HIP_AND_HIU")
                  .map((data, index) => (
                    <div
                      className=" display-flex flex-align-items-center flex-justify-content-space-between padding-vertical-medium"
                      data-cy={`hospitals/clinic-${data.hip.id}`}
                      onClick={() => {
                        props.searchAbdmClinic(data.hip.name);
                        props.setSelectedLinkedFacility(data.hip.id);
                      }}
                      key={index}
                    >
                      <div className=" display-flex flex-align-items-center">
                        <RoundedProfilePicture
                          data-cy={`profilePic-${""}`}
                          size={"medium"}
                          src={""}
                          alt=""
                          loading={props.patients.loading}
                        />

                        <div className=" padding-left-large font-color-secondary">
                          <div className=" font-size-default padding-top-small">
                            {data.hip.name}
                          </div>
                          {/* <div className=" font-size-small padding-top-small">
                      {data.hip.id}
                    </div> */}
                        </div>
                      </div>
                      <ArrowIcon />
                    </div>
                  ))}
            </ConditionalRender>

            <ConditionalRender
              condition={
                props.patients.currentProfile &&
                props.patients.careContexts?.[props.patients.currentProfile] &&
                Object.values(
                  props.patients.careContexts[props.patients.currentProfile]
                ).filter((data) => data.hip.type === "HEALTH_LOCKER").length >=
                  1
              }
            >
              <div className=" font-size-small display-flex flex-justify-content-center padding-top-larger text-uppercase">
                Health Lockers
              </div>
            </ConditionalRender>

            {props.patients.currentProfile &&
              props.patients.careContexts?.[props.patients.currentProfile] &&
              Object.values(
                props.patients.careContexts[props.patients.currentProfile]
              )
                .filter((data) => data.hip.type === "HEALTH_LOCKER")
                .map((data, index) => (
                  <div
                    className=" display-flex flex-align-items-center flex-justify-content-space-between padding-vertical-medium"
                    data-cy={`health-locker-${data.hip.id}`}
                    onClick={() => {
                      props.searchAbdmClinic(data.hip.name);
                      props.setSelectedLinkedFacility(data.hip.id);
                    }}
                    key={index}
                  >
                    <div className=" display-flex flex-align-items-center">
                      <RoundedProfilePicture
                        data-cy={`profilePic-${""}`}
                        size={"medium"}
                        src={""}
                        alt=""
                        loading={props.patients.loading}
                      />

                      <div className=" padding-left-large font-color-secondary">
                        <div className=" font-size-default padding-top-small">
                          {data.hip.name}
                        </div>
                        {/* <div className=" font-size-small padding-top-small">
                      {data.hip.id}
                    </div> */}
                      </div>
                    </div>
                    <ArrowIcon />
                  </div>
                ))}
          </div>

          <ConditionalRender
            condition={
              props.patients.currentProfile &&
              props.patients.careContexts?.[props.patients.currentProfile] &&
              Object.values(
                props.patients.careContexts[props.patients.currentProfile]
              ).length === 0
            }
          >
            <div className=" font-family-gilroy-medium font-color-secondary display-flex flex-align-items-center flex-justify-content-center remaining-body-height">
              No linked facilities found
            </div>
          </ConditionalRender>
        </ConditionalRender>

        <ConditionalRender
          condition={typeof props.patients.selectedLinkedFacility === "string"}
        >
          <HandleBackClick
            onClose={() => {
              if (typeof props.patients.selectedLinkedFacility === "string") {
                props.setSelectedLinkedFacility(null);
              }
            }}
            isBackEnabled={
              typeof props.patients.selectedLinkedFacility === "string"
                ? true
                : false
            }
          >
            <>
              <ClinicCard
                // loading={"loading"}
                clinicDetails={
                  props.patients.selectedLinkedFacility &&
                  props.abdm?.clinics?.search?.results?.[
                    props.patients.selectedLinkedFacility
                  ]
                }
                // logoURL={null}
              />
              <div className=" padding-left-small padding-right-large ">
                <div className=" text-align-center font-size-small text-uppercase padding-top-large padding-bottom-larger">
                  Linked records
                </div>

                {props.patients.careContexts?.[props.patients.currentProfile]?.[
                  props.patients.selectedLinkedFacility
                ]?.careContexts.map((data, index) => (
                  <div key={index} data-cy={`document-${index + 1}`}>
                    <DocumentCard data={data} />
                  </div>
                ))}
              </div>
            </>
          </HandleBackClick>
        </ConditionalRender>
      </ConditionalRender>
    </>
  );
};

const mapStateToProps = (state) => ({
  error: state.status,
  patients: state.patients,
  abdm: state.abdm,
  app: state.app
});

const mapDispatchToProps = function () {
  return {
    setSelectedLinkedFacility: (linkedFacilityId) =>
      setSelectedLinkedFacility(linkedFacilityId),
    searchAbdmClinic: (searchKey) => searchAbdmClinic(searchKey)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LinkedFacilities);
