import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Button from "../../front-end-global-components/components/Button/Button";
import CheckBox from "../../front-end-global-components/components/CheckBox/CheckBox";
import Header from "../../front-end-global-components/components/Header/Header";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import Select from "../../front-end-global-components/components/Select/Select";
import "./Abdm.css";
import ConditionalRender from "../../front-end-global-components/components/ConditionalRender/ConditionalRender";
import Modal from "../../front-end-global-components/components/Modal/Modal";
import {
  AbdmConsentHealthInfoTypes,
  consentStatus,
  lockerSetup
} from "../../utils/constants";
import { StatusChip } from "../../components/Chip/Chip";
import { useParams } from "react-router-dom";
import PinInputBox from "../../components/PinInputBox/PinInputBox";
import NotificationBadge from "../../components/NotificationBadge/NotificationBadge";
import { isValidObject } from "../../front-end-global-components/services/validators";
import {
  ArrowIcon,
  TickIcon,
  InfoIcon
} from "../../front-end-global-components/assets/assets";
import { isValidArray } from "../../utils/validators";
import {
  acceptPatientsRequest,
  denyPatientsRequest,
  revokePermission,
  disableAutoApprove,
  enableAutoApprove
} from "../../redux/patients/actions";
import {
  requestsValidation,
  getConsentRequestStatus
} from "../../utils/requestsValidation";
import HandleBackClick from "../../front-end-global-components/components/HandleBackClick/HandleBackClick";
const headerTitle = (abdmRequestType) => {
  switch (abdmRequestType) {
    case "subscription":
      return "Subscription request";
    case "consent":
      return "Consent request";
    case "lockerSetup":
      return "Health locker request";
    case "authorization":
      return "Authorization request";
    default:
      return "Consent request";
  }
};

//Returns the purpose of request text for various abdm request types
const purposeOfRequest = (request) => {
  return !!request?.subscription
    ? request.subscription.purpose?.text
    : request.purpose?.text;
};

const ViewConsent = (props) => {
  const { abdmRequestId, abdmRequestType } = useParams();
  const [isValidRequest, setIsValidRequest] = useState();
  const [moreProvider, setMoreProvider] = useState(false);

  // check request id is valid or not
  useEffect(() => {
    let retryAbdmFetchRequest = false;
    if (
      !!abdmRequestId &&
      props.patients.currentProfile &&
      props.patients.requests?.[props.patients.currentProfile] &&
      Array.isArray(
        props.patients.requests?.[props.patients.currentProfile][
          `${abdmRequestType}s`
        ]
      )
    ) {
      const abdmRequest = props.patients.requests?.[
        props.patients.currentProfile
      ][`${abdmRequestType}s`].find((request) => request.id === abdmRequestId);
      if (!!abdmRequest) {
        setIsValidRequest(true);
      } else if (
        props.patients.loading === false &&
        retryAbdmFetchRequest === false
      ) {
        retryAbdmFetchRequest = true;
      } else {
        setIsValidRequest(false);
      }
    } else {
      setIsValidRequest(false);
    }

    // eslint-disable-next-line
  }, [
    abdmRequestId,
    abdmRequestType,
    // eslint-disable-next-line
    props.patients.requests?.[props.patients.currentProfile]
  ]);

  return (
    <>
      <Header
        title={
          moreProvider ? "All current providers" : headerTitle(abdmRequestType)
        }
        backButtonOnClick={() => {
          moreProvider ? setMoreProvider(false) : props.navigate(-1);
        }}
        incident={props.app.featureFlags?.incident?.patients}
        incidentOnClick={() => {
          props.navigate("/incidents");
        }}
      />
      <section className="max-width-588px remaining-body-height margin-horizontal-auto display-flex flex-direction-column flex-justify-content-space-between">
        <ConditionalRender condition={isValidRequest}>
          <>
            <ConditionalRender condition={abdmRequestType === "consent"}>
              <ConsentRequestManagement
                moreProviderOnclick={(bool) => {
                  setMoreProvider(bool);
                }}
                moreProvider={moreProvider}
                patients={props.patients}
                push={props.navigate}
                goBack={props.goBack}
                abdmRequestId={abdmRequestId}
                abdmRequestType={abdmRequestType}
                acceptRequest={(data, autoApprove, pin) => {
                  props.acceptPatientsRequest({
                    requestData: data,
                    autoApprove: autoApprove,
                    requestId: abdmRequestId,
                    requestType: abdmRequestType,
                    push: props.navigate,
                    pin: pin
                  });
                }}
                revokePermission={(pin) => {
                  props.revokePermission({
                    requestType: abdmRequestType,
                    requestId: abdmRequestId,
                    pin: pin,
                    push: props.navigate
                  });
                }}
                denyRequest={(denyReason) => {
                  props.denyPatientsRequest({
                    requestType: abdmRequestType,
                    requestId: abdmRequestId,
                    denyReason: denyReason,
                    push: props.navigate
                  });
                }}
                disableAutoApprove={(pin, autoApprovalId, requestId) => {
                  props.disableAutoApprove({
                    pin: pin,
                    autoApprovalId: autoApprovalId,
                    requestId: requestId,
                    push: props.navigate
                  });
                }}
              />
            </ConditionalRender>
            <ConditionalRender condition={abdmRequestType === "subscription"}>
              <SubscriptionRequestManagement
                patients={props.patients}
                push={props.navigate}
                goBack={props.goBack}
                abdmRequestId={abdmRequestId}
                abdmRequestType={abdmRequestType}
                moreProviderOnclick={(bool) => {
                  setMoreProvider(bool);
                }}
                moreProvider={moreProvider}
                acceptRequest={(data, autoApprove, pin) => {
                  props.acceptPatientsRequest({
                    edit: false,
                    requestData: data,
                    autoApprove: autoApprove,
                    requestId: abdmRequestId,
                    requestType: abdmRequestType,
                    push: props.navigate,
                    pin: pin
                  });
                }}
                editRequest={(data, pin) => {
                  props.acceptPatientsRequest({
                    edit: true,
                    requestType: abdmRequestType,
                    requestId: abdmRequestId,
                    requestData: data,
                    pin: pin
                  });
                }}
                denyRequest={(denyReason) => {
                  props.denyPatientsRequest({
                    requestType: abdmRequestType,
                    requestId: abdmRequestId,
                    denyReason: denyReason,
                    push: props.navigate
                  });
                }}
                enableAutoApprove={(pin, data, requestId) => {
                  props.enableAutoApprove({
                    pin: pin,
                    requestData: data,
                    requestId: requestId,
                    push: props.navigate
                  });
                }}
                disableAutoApprove={(pin, autoApprovalId, requestId) => {
                  props.disableAutoApprove({
                    pin: pin,
                    autoApprovalId: autoApprovalId,
                    requestId: requestId,
                    push: props.navigate
                  });
                }}
              />
            </ConditionalRender>
            <ConditionalRender condition={abdmRequestType === "lockerSetup"}>
              <LockerSetupRequestManagement
                patients={props.patients}
                push={props.navigate}
                goBack={props.goBack}
                abdmRequestId={abdmRequestId}
                abdmRequestType={abdmRequestType}
                moreProviderOnclick={(bool) => {
                  setMoreProvider(bool);
                }}
                moreProvider={moreProvider}
                acceptRequest={(data, autoApprove) => {
                  props.acceptPatientsRequest({
                    requestData: data,
                    autoApprove: autoApprove,
                    requestId: abdmRequestId,
                    requestType: abdmRequestType,
                    push: props.navigate
                  });
                }}
                ignoreOnClick={() => {
                  props.navigate("/settings");
                }}
              />
            </ConditionalRender>
            <ConditionalRender condition={abdmRequestType === "authorization"}>
              <AuthorizationRequestManagement
                patients={props.patients}
                push={props.navigate}
                goBack={props.goBack}
                abdmRequestId={abdmRequestId}
                abdmRequestType={abdmRequestType}
                acceptRequest={() => {
                  props.acceptPatientsRequest({
                    requestType: abdmRequestType,
                    requestId: abdmRequestId,
                    push: props.navigate
                  });
                }}
                revokePermission={() => {
                  props.revokePermission({
                    requestType: abdmRequestType,
                    requestId: abdmRequestId,
                    push: props.navigate
                  });
                }}
                denyRequest={(denyReason) => {
                  props.denyPatientsRequest({
                    requestType: abdmRequestType,
                    requestId: abdmRequestId,
                    denyReason: denyReason,
                    push: props.navigate
                  });
                }}
              />
            </ConditionalRender>
          </>
        </ConditionalRender>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  patients: state.patients,
  app: state.app
});

const mapDispatchToProps = function () {
  return {
    acceptPatientsRequest: (data) => acceptPatientsRequest(data),
    denyPatientsRequest: (data) => denyPatientsRequest(data),
    revokePermission: (data) => revokePermission(data),
    disableAutoApprove: (data) => disableAutoApprove(data),
    enableAutoApprove: (data) => enableAutoApprove(data)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewConsent);

const ConsentRequestManagement = (props) => {
  const [patientsRequestData, setPatientsRequestData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectAllProviders, setSelectAllProviders] = useState(true);
  const [selectAllHealthLockers, setSelectAllHealthLockers] = useState(true);
  const [selectedProvidersData, setSelectedProvidersData] = useState({});
  // for future feature, set default bool is true
  const [autoApprove] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState({});
  const [checkList, setCheckList] = useState({ allProviders: true });
  const [healthLockerCheckList, setHealthLockerCheckList] = useState({
    allHealthLockers: true
  });
  const [providers, setProviders] = useState([]);
  const [autoApproveData, setAutoApproveData] = useState(null);
  // const [expireMonth, setExpireMonth] = useState(6);
  const healthLockersData = providers?.filter(
    (data) => data.hip.type === "HEALTH_LOCKER"
  );

  // store careContexts data in state
  useEffect(() => {
    props.patients.currentProfile &&
      props.patients.careContexts &&
      setProviders(
        Object.values(
          props.patients.careContexts[props.patients.currentProfile]
        )
      );
  }, [props.patients.careContexts, props.patients.currentProfile]);

  // find request data and store in state
  useEffect(() => {
    if (
      !!props.abdmRequestId &&
      Array.isArray(
        props.patients.requests[props.patients.currentProfile][
          `${props.abdmRequestType}s`
        ]
      )
    ) {
      const getAbdmRequest = requestsValidation(
        props.patients.requests[props.patients.currentProfile],
        props.patients.autoApprovalPolicies &&
          props.patients.autoApprovalPolicies
      )[`${props.abdmRequestType}s`].find(
        (request) => request.id === props.abdmRequestId
      );
      setPatientsRequestData(getAbdmRequest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.abdmRequestId, props.abdmRequestType, props.patients.requests]);

  // store selected provider data in store
  useEffect(() => {
    let defaultData = {
      allProviders: {
        from: patientsRequestData?.permission?.dateRange?.from,
        to: patientsRequestData?.permission?.dateRange?.to,
        requestToAccess: AbdmConsentHealthInfoTypes
      },
      allHealthLockers: {
        from: patientsRequestData?.permission?.dateRange?.from,
        to: patientsRequestData?.permission?.dateRange?.to,
        requestToAccess: AbdmConsentHealthInfoTypes
      }
    };
    if (providers) {
      providers.map(
        (provider, index) =>
          (defaultData = {
            ...defaultData,
            [provider.hip.id]: {
              from: patientsRequestData?.permission?.dateRange?.from,
              to: patientsRequestData?.permission?.dateRange?.to,
              requestToAccess: AbdmConsentHealthInfoTypes
            }
          })
      );
    }
    setSelectedProvidersData(defaultData);
    // eslint-disable-next-line
  }, [providers]);

  //get autoApproveData and store in state
  useEffect(() => {
    const autoApprovalPolicyData = getConsentRequestStatus(
      patientsRequestData,
      props.patients.autoApprovalPolicies
    );
    autoApprovalPolicyData && setAutoApproveData(autoApprovalPolicyData);
  }, [props.patients.autoApprovalPolicies, patientsRequestData]);

  function approveOnClick() {
    let providersApproveData = {};
    let healthLockerApproveData = {};
    if (checkList) {
      Object.keys(checkList).map((checkedId, index) =>
        Object.keys(selectedProvidersData).forEach((selectedId) => {
          if (selectedId === checkedId) {
            providersApproveData = {
              ...providersApproveData,
              [checkedId]: selectedProvidersData[checkedId]
            };
          }
        })
      );

      if (healthLockerCheckList) {
        Object.keys(healthLockerCheckList).map((checkedId, index) =>
          Object.keys(selectedProvidersData).forEach((selectedId) => {
            if (selectedId === checkedId) {
              healthLockerApproveData = {
                ...healthLockerApproveData,
                [checkedId]: selectedProvidersData[checkedId]
              };
            }
          })
        );
      }

      props.acceptRequest(
        {
          providers: providersApproveData,
          healthLockers: healthLockerApproveData
        },
        autoApprove,
        patientsRequestData.pin
      );
    }
  }

  function changeCheckList(checked, providerId) {
    if (checked) {
      setCheckList({
        ...checkList,
        [providerId]: providerId
      });
    } else {
      const data = checkList;
      data && delete data[providerId];
      setCheckList({ ...data });
    }
  }

  function changeHealthLockerCheckList(checked, providerId) {
    if (checked) {
      setHealthLockerCheckList({
        ...healthLockerCheckList,
        [providerId]: providerId
      });
    } else {
      const data = healthLockerCheckList;
      data && delete data[providerId];
      setHealthLockerCheckList({ ...data });
    }
  }

  const ProvidersList = (props) => {
    const providersData = providers.filter(
      (data) => data.hip.type !== "HEALTH_LOCKER"
    );
    const showProviders = props.limit
      ? providersData.slice(0, 2)
      : providersData;

    const showHealthLockers = props.limit
      ? healthLockersData.slice(0, 2)
      : healthLockersData;
    return (
      <>
        <ConditionalRender condition={props.providers}>
          {props.limit && isValidArray(providersData) && (
            <div>
              <div className={`${fontSmallUpperCase}`}>From Providers</div>
              <div className=" display-flex flex-align-items-center margin-bottom-large">
                {patientsRequestData.status === consentStatus.pending && (
                  <div className=" display-flex ">
                    <CheckBox
                      name="selectAllProviders"
                      id="selectAllProviders"
                      checked={selectAllProviders}
                      onChange={(event) => {
                        setSelectAllProviders(event.target.checked);
                        event.target.checked
                          ? setCheckList({
                              allProviders: event.target.checked
                            })
                          : setCheckList({});
                      }}
                    />
                  </div>
                )}
                <div
                  className=" inherit-parent-width flex-justify-content-space-between flex-align-items-center "
                  onClick={() => {
                    setSelectedProvider({
                      id: "allProviders",
                      name: "All providers"
                    });
                    setShowModal("providersModal");
                  }}
                >
                  <div className=" font-color-secondary font-family-gilroy-regular font-size-medium padding-left-default ">
                    All providers
                  </div>
                  <ArrowIcon />
                </div>
              </div>
            </div>
          )}
          {isValidArray(showProviders) &&
            showProviders.map((data, index) => (
              <div
                key={index}
                className="display-flex flex-justify-content-space-between flex-align-items-center padding-bottom-large"
                data-cy="providersList"
              >
                <div
                  className={`display-flex ${
                    selectAllProviders && "opacity-half"
                  }`}
                >
                  {patientsRequestData.status === consentStatus.pending && (
                    <CheckBox
                      name={data.hip.name}
                      id={data.hip.name}
                      checked={
                        Object.keys(checkList).some(
                          (id) => id === data.hip.id
                        ) || selectAllProviders
                      }
                      onChange={(event) => {
                        changeCheckList(event.target.checked, data.hip.id);
                      }}
                    />
                  )}
                </div>
                <div
                  className=" inherit-parent-width flex-justify-content-space-between flex-align-items-center"
                  onClick={() => {
                    if (
                      !selectAllProviders ||
                      patientsRequestData.status === consentStatus.approved ||
                      patientsRequestData.status === consentStatus.autoApproved
                    ) {
                      setSelectedProvider({
                        id: data.hip.id,
                        name: data.hip.name
                      });
                      setShowModal("providersModal");
                    }
                  }}
                >
                  <div className=" font-color-secondary font-family-gilroy-regular font-size-medium padding-left-default ">
                    {data.hip.name}
                  </div>
                  {patientsRequestData.status === consentStatus.pending ? (
                    <ArrowIcon />
                  ) : (
                    <InfoIcon />
                  )}
                </div>
              </div>
            ))}
          {isValidArray(providersData) &&
            props.limit &&
            providersData.length > props.limit &&
            patientsRequestData.status === consentStatus.pending && (
              <div className=" padding-bottom-medium display-flex flex-align-items-center font-family-gilroy-medium font-color-primary">
                <div
                  onClick={() => {
                    props.moreProviderOnclick(true);
                  }}
                >
                  {`+${providersData.length - props.limit} more provider${
                    providersData.length - props.limit > 1 ? "s" : ""
                  }`}
                </div>
                <ArrowIcon color="#00A000" />
              </div>
            )}
        </ConditionalRender>

        <ConditionalRender condition={props.healthLockers}>
          {props.limit && isValidArray(healthLockersData) && (
            <div
              className={`${
                isValidArray(providersData) ? "padding-top-large" : ""
              }`}
            >
              <div className={`${fontSmallUpperCase}`}>Health lockers</div>

              <div className=" display-flex flex-align-items-center margin-bottom-large">
                {patientsRequestData.status === consentStatus.pending && (
                  <div className=" display-flex ">
                    <CheckBox
                      name="selectAllHealthLockers"
                      id="selectAllHealthLockers"
                      checked={selectAllHealthLockers}
                      onChange={(event) => {
                        setSelectAllHealthLockers(event.target.checked);
                        event.target.checked
                          ? setHealthLockerCheckList({
                              allHealthLockers: event.target.checked
                            })
                          : setHealthLockerCheckList({});
                      }}
                      data-cy={"selectAllHealthLockers"}
                    />
                  </div>
                )}
                <div
                  className=" inherit-parent-width flex-justify-content-space-between flex-align-items-center"
                  onClick={() => {
                    setSelectedProvider({
                      id: "allHealthLockers",
                      name: "All health lockers"
                    });
                    setShowModal("providersModal");
                  }}
                >
                  <div className=" font-color-secondary font-family-gilroy-regular font-size-medium padding-left-default">
                    All health lockers
                  </div>
                  <ArrowIcon />
                </div>
              </div>
            </div>
          )}

          {isValidArray(showHealthLockers) &&
            showHealthLockers.map((data, index) => (
              <div
                key={index}
                className="display-flex flex-justify-content-space-between flex-align-items-center padding-bottom-large"
                data-cy="healthLockerList"
              >
                <div
                  className={`display-flex ${
                    selectAllHealthLockers && "opacity-half"
                  }`}
                >
                  {patientsRequestData.status === consentStatus.pending && (
                    <CheckBox
                      name={data.hip.name}
                      id={data.hip.name}
                      data-cy={`${data.hip.name}-checkBox`}
                      checked={
                        Object.keys(healthLockerCheckList).some(
                          (id) => id === data.hip.id
                        ) || selectAllHealthLockers
                      }
                      onChange={(event) => {
                        changeHealthLockerCheckList(
                          event.target.checked,
                          data.hip.id
                        );
                      }}
                    />
                  )}
                </div>
                <div
                  className=" inherit-parent-width flex-justify-content-space-between flex-align-items-center"
                  data-cy={`${data.hip.name}-options`}
                  onClick={() => {
                    if (
                      !selectAllHealthLockers ||
                      patientsRequestData.status === consentStatus.approved ||
                      patientsRequestData.status === consentStatus.autoApproved
                    ) {
                      setSelectedProvider({
                        id: data.hip.id,
                        name: data.hip.name
                      });
                      setShowModal("providersModal");
                    }
                  }}
                >
                  <div className=" font-color-secondary font-family-gilroy-regular font-size-medium padding-left-default">
                    {data.hip.name}
                  </div>

                  {patientsRequestData.status === consentStatus.pending ? (
                    <ArrowIcon />
                  ) : (
                    <InfoIcon />
                  )}
                </div>
              </div>
            ))}
          {isValidArray(healthLockersData) &&
            props.limit &&
            healthLockersData.length > props.limit &&
            patientsRequestData.status === consentStatus.pending && (
              <div className=" padding-bottom-medium display-flex flex-align-items-center font-family-gilroy-medium font-color-primary">
                <div
                  onClick={() => {
                    props.moreProviderOnclick(true);
                  }}
                >
                  {`+${healthLockersData.length - props.limit} more provider${
                    healthLockersData.length - props.limit > 1 ? "s" : ""
                  }`}
                </div>
                <ArrowIcon color="#00A000" />
              </div>
            )}
        </ConditionalRender>
      </>
    );
  };

  return (
    <>
      <ConditionalRender
        condition={
          !isValidArray(providers) ||
          !isValidObject(patientsRequestData) ||
          !Object.values(selectedProvidersData).length > 2
        }
      >
        <RequestSuspense />
      </ConditionalRender>
      <ConditionalRender
        condition={
          isValidArray(providers) &&
          isValidObject(patientsRequestData) &&
          Object.values(selectedProvidersData).length > 2
        }
      >
        <ConditionalRender condition={!props.moreProvider}>
          <section className=" overflow-y-auto  padding-left-large padding-right-large ">
            <ConsentHeader
              status={patientsRequestData?.status}
              consentRequesterName={patientsRequestData?.requester?.name}
              consentRequesterSubName={patientsRequestData?.hiu?.id}
            />
            <section>
              <div className="padding-left-large padding-right-large">
                <div className={`${fontSmallUpperCase}`}>
                  Purpose of request
                </div>
                <div className=" font-color-secondary font-family-gilroy-regular font-size-medium padding-bottom-large">
                  {patientsRequestData?.purpose?.text}
                </div>

                {patientsRequestData?.status === consentStatus?.pending && (
                  <ProvidersList
                    limit={2}
                    providers
                    healthLockers
                    moreProviderOnclick={(bool) => {
                      props.moreProviderOnclick(bool);
                    }}
                  />
                )}

                {(patientsRequestData?.status === consentStatus?.approved ||
                  patientsRequestData.status ===
                    consentStatus.autoApproved) && (
                  <ProvidersList providers healthLockers />
                )}

                {/* <ConditionalRender
                condition={patientsRequestData.status === consentStatus.pending}
              >
                <div className="  font-size-smaller font-color-secondary">
                  ACCESS EXPIRES IN
                </div>
                <div className=" padding-top-smaller display-flex padding-bottom-larger">
                  {[3, 6, 12].map((month, index) => (
                    <MonthsCard
                      key={index}
                      months={month}
                      selected={expireMonth === month}
                      monthsCardOnClick={() => {
                        setExpireMonth(month);
                      }}
                    />
                  ))}
                </div>
              </ConditionalRender> */}

                <ConditionalRender
                  condition={
                    patientsRequestData.status === consentStatus.approved ||
                    patientsRequestData.status === consentStatus.autoApproved
                  }
                >
                  <div className="  font-size-smaller font-color-secondary">
                    ACCESS EXPIRES ON
                  </div>

                  {patientsRequestData?.permission?.dateRange?.to && (
                    <div className=" margin-bottom-larger padding-top-small font-size-medium font-color-secondary">
                      {`${new Date(
                        patientsRequestData.permission.dateRange?.to
                      ).getDate()}/${new Date(
                        patientsRequestData.permission.dateRange?.to
                      ).getMonth()}/${new Date(
                        patientsRequestData.permission.dateRange?.to
                      ).getFullYear()}`}{" "}
                      {remainingDateConversion(
                        patientsRequestData.permission.dateRange?.to
                      )}
                    </div>
                  )}
                </ConditionalRender>
              </div>
              {/* <ConditionalRender
              condition={patientsRequestData.status === consentStatus.pending}
            >
              <div className=" display-flex flex-align-items-start padding-large border-tertiaryColor border-radius-default">
                <div>
                  <CheckBox
                    name="autoApprove"
                    id="autoApprove"
                    checked={
                      isValidObject(checkList) && isValidArray(providers)
                        ? autoApprove
                        : false
                    }
                    disabled={
                      isValidObject(checkList) && isValidArray(providers)
                        ? false
                        : true
                    }
                    onChange={(event) => {
                      setAutoApprove(event.target.checked);
                    }}
                  />
                </div>
                <div className=" font-color-secondary font-family-gilroy-regular font-size-medium ">
                  I would like to{" "}
                  <span className="font-family-gilroy-medium">
                    {" "}
                    auto-approve{" "}
                  </span>{" "}
                  all future consent requests from{" "}
                  <span className="font-family-gilroy-medium">
                    {" "}
                    {patientsRequestData?.requester?.name}{" "}
                  </span>
                </div>
              </div>
            </ConditionalRender> */}

              <ConditionalRender
                condition={
                  patientsRequestData.status === consentStatus.autoApproved
                }
              >
                <div
                  className=" margin-top-medium margin-left-large margin-right-large padding-medium border-1px-e5e5e5 border-radius-default"
                  onClick={() => {
                    setShowModal("disableAutoApproveModal");
                  }}
                >
                  This request was approved automatically due to an
                  auto-approval policy. To disable and manually approve future
                  consent requests{" "}
                  <span className=" font-color-primary"> Click here </span>
                </div>
              </ConditionalRender>
            </section>
          </section>
          <ConsentFooter
            loading={props.patients.loading}
            approveButtonDisabled={
              isValidObject(checkList) && isValidArray(providers) ? false : true
            }
            approveOnClick={() => {
              setShowModal("verifyPinModal");
            }}
            status={patientsRequestData?.status}
            denyOnClick={() => {
              setShowModal("denyConsentModal");
            }}
            revokeAccessOnClick={() => {
              setShowModal("revokeAccessModal");
            }}
            type={patientsRequestData?.type}
          />
        </ConditionalRender>

        <ConditionalRender condition={props.moreProvider}>
          <div>
            <div className="padding-top-large padding-bottom-large padding-left-larger padding-right-larger overflow-hidden overflow-scroll">
              <ProvidersList providers />
            </div>
            <div className=" display-flex padding-larger background-color-white position-absolute bottom-0 inherit-parent-width ">
              <Button
                text="Save"
                boxShadow={false}
                className=" margin-right-small"
                onClick={() => {
                  props.moreProviderOnclick(false);
                }}
              />
              <Button
                text="Back"
                boxShadow={false}
                variant="secondary"
                className=" margin-left-small"
                onClick={() => {
                  props.moreProviderOnclick(false);
                }}
              />
            </div>
          </div>
        </ConditionalRender>

        <ConditionalRender condition={props.moreHealthLockers}>
          <div>
            <div className="padding-top-large padding-bottom-large padding-left-larger padding-right-larger overflow-hidden overflow-scroll">
              <ProvidersList healthLockers />
            </div>
            <div className=" display-flex padding-larger background-color-white position-absolute bottom-0 inherit-parent-width ">
              <Button
                text="Save"
                boxShadow={false}
                className=" margin-right-small"
                onClick={() => {
                  props.moreHealthLockersOnclick(false);
                }}
              />
              <Button
                text="Back"
                boxShadow={false}
                variant="secondary"
                className=" margin-left-small"
                onClick={() => {
                  props.moreHealthLockersOnclick(false);
                }}
              />
            </div>
          </div>
        </ConditionalRender>
      </ConditionalRender>

      <HandleBackClick
        onClose={() => {
          setShowModal(false);
        }}
        isBackEnabled={showModal !== false}
      >
        <Modal
          onClose={() => {
            setShowModal(false);
          }}
          canIgnore={true}
          show={showModal !== false}
          position="position-fixed bottom-0 left-0 right-0"
          boxShadow="none"
          borderRadius="none"
          width="inherit-parent-width"
          background="background-transparent"
          height="height-fit-to-content"
        >
          <div className="background-white font-family-gilroy-medium user-select-none ">
            <section className=" max-width-588px margin-horizontal-auto">
              <ConditionalRender condition={showModal === "providersModal"}>
                <ProviderDetails
                  consentRequesterName={
                    selectedProvider?.name && selectedProvider.name
                  }
                  requesterId={selectedProvider?.id && selectedProvider.id}
                  status={patientsRequestData?.status}
                  type={patientsRequestData?.type}
                  requestToAccess={
                    selectedProvidersData &&
                    selectedProvider?.id &&
                    selectedProvidersData[selectedProvider.id] &&
                    selectedProvidersData[selectedProvider.id].requestToAccess
                  }
                  from={
                    selectedProvidersData &&
                    selectedProvider?.id &&
                    selectedProvidersData[selectedProvider.id] &&
                    selectedProvidersData[selectedProvider.id].from
                  }
                  to={
                    selectedProvidersData &&
                    selectedProvider?.id &&
                    selectedProvidersData[selectedProvider.id] &&
                    selectedProvidersData[selectedProvider.id].to
                  }
                  healthLockersData={healthLockersData}
                  onChangeCheckBox={(checked) => {
                    setSelectedProvidersData({
                      ...selectedProvidersData,
                      [selectedProvider.id]: {
                        ...selectedProvidersData[selectedProvider.id],
                        clone: checked
                      }
                    });
                  }}
                  dataClone={
                    selectedProvidersData &&
                    selectedProvider.id &&
                    selectedProvidersData[selectedProvider.id]?.clone &&
                    selectedProvidersData[selectedProvider.id].clone
                  }
                  continueOnClick={(props) => {
                    setSelectedProvidersData({
                      ...selectedProvidersData,
                      [selectedProvider.id]: {
                        ...selectedProvidersData[selectedProvider.id],
                        requestToAccess: props.requestToAccess,
                        from: props.from,
                        to: props.to
                      }
                    });
                    setShowModal(false);
                  }}
                  backOnClick={() => {
                    setShowModal(false);
                  }}
                />
              </ConditionalRender>
              <ConditionalRender condition={showModal === "verifyPinModal"}>
                <PinInputSection
                  consentPinExists={
                    props.patients.healthIdData?.[props.patients.currentProfile]
                      ?.hasTransactionPin
                  }
                  resetPinOnClick={() => {
                    props.push("/abdm/changeconsentpin");
                    setShowModal(false);
                  }}
                  createPinOnClick={() => {
                    props.push("/abdm/changeconsentpin");
                  }}
                  pinOnChangeValue={(pinValue) => {
                    setPatientsRequestData({
                      ...patientsRequestData,
                      pin: pinValue
                    });
                  }}
                  loading={props.patients.loading}
                  approveOnClick={() => {
                    approveOnClick();
                  }}
                />
              </ConditionalRender>
              <ConditionalRender condition={showModal === "denyConsentModal"}>
                <AbdmRequestDenyReasonInputSection
                  loading={props.patients.loading}
                  denyOnClick={() => {
                    if (typeof props.denyRequest === "function") {
                      props.denyRequest(patientsRequestData.requestDenyReason);
                    }
                  }}
                  cancelOnClick={() => {
                    setShowModal(false);
                    setPatientsRequestData({
                      ...patientsRequestData,
                      requestDenyReason: " "
                    });
                  }}
                  reasonInputOnChange={(event) => {
                    setPatientsRequestData({
                      ...patientsRequestData,
                      requestDenyReason: event.target.value
                    });
                  }}
                />
              </ConditionalRender>
              <ConditionalRender condition={showModal === "revokeAccessModal"}>
                <RevokeAccessSection
                  loading={props.patients.loading}
                  getConsentPin={true}
                  revokeOnClick={(pin) => {
                    props.revokePermission(pin);
                  }}
                  cancelOnClick={() => {
                    setShowModal(false);
                  }}
                  resetPinOnClick={() => {
                    props.push("/abdm/changeconsentpin");
                    setShowModal(false);
                  }}
                />
              </ConditionalRender>
              <ConditionalRender
                condition={showModal === "disableAutoApproveModal"}
              >
                <DisableAutoApproveSection
                  loading={props.patients.loading}
                  hospitalName={
                    patientsRequestData?.hiu?.name
                      ? patientsRequestData?.hiu?.name
                      : patientsRequestData?.hiu?.id
                  }
                  disableOnclick={(pin) => {
                    props.disableAutoApprove(
                      pin,
                      autoApproveData.autoApprovalId,
                      patientsRequestData.id
                    );
                  }}
                  cancelOnClick={() => {
                    setShowModal(false);
                  }}
                  resetPinOnClick={() => {
                    props.push("/abdm/changeconsentpin");
                    setShowModal(false);
                  }}
                />
              </ConditionalRender>
            </section>
          </div>
        </Modal>
      </HandleBackClick>
    </>
  );
};

const SubscriptionRequestManagement = (props) => {
  const [patientsRequestData, setPatientsRequestData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectAllProviders, setSelectAllProviders] = useState(true);
  const [selectedProvidersData, setSelectedProvidersData] = useState({});
  const [autoApprove, setAutoApprove] = useState(false);
  const [autoApprovePoliciesData, setAutoApprovePoliciesData] = useState(null);
  const [selectedProvider, setSelectedProvider] = useState({});
  const [checkList, setCheckList] = useState({ allProviders: true });
  const [providers, setProviders] = useState([]);
  const [editRequest, setEditRequest] = useState(false);

  // store careContexts data in state
  useEffect(() => {
    if (
      props.patients.currentProfile &&
      props.patients.careContexts &&
      props.patients.careContexts?.[props.patients.currentProfile]
    ) {
      setProviders(
        Object.values(
          props.patients.careContexts?.[props.patients.currentProfile]
        )
      );
    }
  }, [props.patients.careContexts, props.patients.currentProfile]);

  // find request data and store in state
  useEffect(() => {
    if (
      !!props.abdmRequestId &&
      Array.isArray(
        props.patients.requests[props.patients.currentProfile][
          `${props.abdmRequestType}s`
        ]
      )
    ) {
      const abdmRequest = props.patients.requests[
        props.patients.currentProfile
      ][`${props.abdmRequestType}s`].find(
        (request) => request.id === props.abdmRequestId
      );
      setPatientsRequestData(abdmRequest);
    }
    // eslint-disable-next-line
  }, [props.abdmRequestId, props.abdmRequestType, props.patients.requests]);

  // store selected provider data in store
  useEffect(() => {
    let defaultData = {
      allProviders: {
        from: new Date().toISOString(),
        to: new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 6,
          new Date().getDate()
        ).toISOString(),
        requestToAccess: AbdmConsentHealthInfoTypes
      }
    };

    if (providers) {
      providers.map(
        (provider, index) =>
          (defaultData = {
            ...defaultData,
            [provider.hip.id]: {
              from: new Date().toISOString(),
              to: new Date(
                new Date().getFullYear(),
                new Date().getMonth() + 6,
                new Date().getDate()
              ).toISOString(),
              requestToAccess: AbdmConsentHealthInfoTypes
            }
          })
      );
    }
    setSelectedProvidersData(defaultData);

    // eslint-disable-next-line
  }, [providers]);

  function approveOnClick() {
    let approveData = {};
    if (checkList) {
      Object.keys(checkList).map((checkedId, index) =>
        Object.keys(selectedProvidersData).forEach((selectedId) => {
          if (selectedId === checkedId) {
            approveData = {
              ...approveData,
              [checkedId]: selectedProvidersData[checkedId]
            };
          }
        })
      );

      if (editRequest) {
        props.editRequest(approveData, patientsRequestData.pin);
      } else {
        props.acceptRequest(approveData, autoApprove, patientsRequestData.pin);
      }
    }
  }

  useEffect(() => {
    if (
      props.patients.autoApprovalPolicies &&
      isValidObject(props.patients.autoApprovalPolicies)
    ) {
      setAutoApprovePoliciesData(props.patients.autoApprovalPolicies);
    }
  }, [props.patients.autoApprovalPolicies]);

  function changeCheckList(checked, providerId) {
    if (checked) {
      setCheckList({
        ...checkList,
        [providerId]: providerId
      });
    } else {
      const data = checkList;
      data && delete data[providerId];
      setCheckList({ ...data });
    }
  }

  const ProvidersList = (props) => {
    const providersData = props.limit
      ? providers.slice(0, props.limit)
      : providers;

    return (
      <>
        {isValidArray(providersData) &&
          providersData.map((data, index) => (
            <div
              key={index}
              className="display-flex flex-justify-content-space-between flex-align-items-center padding-bottom-large"
              data-cy="providersList"
            >
              <div className="display-flex ">
                {(patientsRequestData.status === consentStatus.pending ||
                  editRequest) && (
                  <CheckBox
                    name={data.hip.name}
                    id={data.hip.name}
                    checked={
                      Object.keys(checkList).some((id) => id === data.hip.id) ||
                      selectAllProviders
                    }
                    onChange={(event) => {
                      changeCheckList(event.target.checked, data.hip.id);
                    }}
                  />
                )}
              </div>
              <div
                className=" inherit-parent-width flex-justify-content-space-between flex-align-items-center padding-left-default"
                onClick={() => {
                  if (
                    !selectAllProviders ||
                    (patientsRequestData.status === consentStatus.approved &&
                      !editRequest)
                  ) {
                    setSelectedProvider({
                      id: data.hip.id,
                      name: data.hip.name
                    });
                    setShowModal("providersModal");
                  }
                }}
              >
                <div className=" font-color-secondary font-family-gilroy-regular font-size-medium">
                  {data.hip.name}
                </div>

                {patientsRequestData.status === consentStatus.pending ||
                editRequest ? (
                  <ArrowIcon />
                ) : (
                  <InfoIcon />
                )}
              </div>
            </div>
          ))}
      </>
    );
  };

  return (
    <>
      <ConditionalRender
        condition={
          !isValidArray(providers) ||
          !isValidObject(patientsRequestData) ||
          !Object.values(selectedProvidersData).length > 1
        }
      >
        <RequestSuspense />
      </ConditionalRender>
      <ConditionalRender
        condition={
          isValidArray(providers) &&
          isValidObject(patientsRequestData) &&
          Object.values(selectedProvidersData).length > 1
        }
      >
        <ConditionalRender condition={!props.moreProvider}>
          <section className="overflow-y-auto padding-left-large padding-right-large ">
            <ConsentHeader
              status={patientsRequestData?.status}
              consentRequesterName={patientsRequestData?.hiu?.name}
              consentRequesterSubName={patientsRequestData?.hiu?.id}
            />
            <section className="">
              <div className="font-color-secondary font-family-gilroy-regular font-size-medium padding-bottom-large">
                Requesting access to automatically fetch & store your Health
                records
              </div>
              <div className="padding-left-large padding-right-large">
                <div className={`${fontSmallUpperCase}`}>
                  Purpose of request
                </div>
                <div className=" font-color-secondary font-family-gilroy-regular font-size-medium padding-bottom-large">
                  {patientsRequestData?.purpose?.text}
                </div>

                <div className={`${fontSmallUpperCase}`}>From Providers</div>
                {(patientsRequestData.status === consentStatus.pending ||
                  editRequest) && (
                  <div className=" display-flex flex-align-items-center margin-bottom-large">
                    <div className=" display-flex ">
                      <CheckBox
                        name="selectAllProviders"
                        id="selectAllProviders"
                        checked={selectAllProviders}
                        onChange={(event) => {
                          setSelectAllProviders(event.target.checked);
                          event.target.checked
                            ? setCheckList({
                                allProviders: event.target.checked
                              })
                            : setCheckList({});
                        }}
                      />
                    </div>

                    <div
                      className=" inherit-parent-width flex-justify-content-space-between flex-align-items-center padding-left-default"
                      onClick={() => {
                        setSelectedProvider({
                          id: "allProviders",
                          name: "All providers"
                        });
                        setShowModal("providersModal");
                      }}
                    >
                      <div className=" font-color-secondary font-family-gilroy-regular font-size-medium ">
                        All providers
                      </div>

                      <ArrowIcon />
                    </div>
                  </div>
                )}

                <div
                  className={` ${
                    selectAllProviders && "opacity-half"
                  }  overflow-hidden`}
                >
                  {(patientsRequestData.status === consentStatus.pending ||
                    editRequest) && <ProvidersList limit={2} />}

                  {patientsRequestData.status === consentStatus.approved &&
                    !editRequest && <ProvidersList />}
                </div>

                {providers?.length > 2 &&
                  (patientsRequestData.status === consentStatus.pending ||
                    editRequest) && (
                    <div className=" padding-bottom-medium display-flex flex-align-items-center font-family-gilroy-medium font-color-primary">
                      <div
                        onClick={() => {
                          props.moreProviderOnclick(true);
                        }}
                      >
                        {`+${providers.length - 2} more provider${
                          providers.length - 2 > 1 ? "s" : ""
                        }`}
                      </div>
                      <ArrowIcon color="#00A000" />
                    </div>
                  )}
              </div>

              {(patientsRequestData.status === consentStatus.pending ||
                editRequest) && (
                <div className=" display-flex flex-align-items-start padding-large border-tertiaryColor border-radius-default">
                  <div>
                    <CheckBox
                      name="autoApprove"
                      id="autoApprove"
                      checked={
                        editRequest &&
                        isValidObject(
                          autoApprovePoliciesData &&
                            Object.values(autoApprovePoliciesData).find(
                              (e) =>
                                e.data.hiu.id === patientsRequestData.hiu.id &&
                                e.patient.healthId ===
                                  patientsRequestData.patient.id &&
                                e.enabled === true
                            )
                        )
                          ? true
                          : isValidObject(checkList) && isValidArray(providers)
                          ? autoApprove
                          : false
                      }
                      disabled={
                        isValidObject(checkList) && isValidArray(providers)
                          ? false
                          : true
                      }
                      onChange={(event) => {
                        if (event.target.checked === false) {
                          if (
                            isValidObject(
                              autoApprovePoliciesData &&
                                Object.values(autoApprovePoliciesData).find(
                                  (e) =>
                                    e.data.hiu.id ===
                                      patientsRequestData.hiu.id &&
                                    e.patient.healthId ===
                                      patientsRequestData.patient.id &&
                                    e.enabled === true
                                )
                            )
                          ) {
                            setShowModal("disableAutoApproveModal");
                          }
                          setAutoApprove(event.target.checked);
                        } else {
                          setShowModal("enableAutoApproveModal");
                          setAutoApprove(event.target.checked);
                        }
                      }}
                    />
                  </div>
                  <div className=" font-color-secondary font-family-gilroy-regular font-size-medium padding-left-default ">
                    I would like to{" "}
                    <span className="font-family-gilroy-medium">
                      {" "}
                      auto-approve{" "}
                    </span>{" "}
                    all future consent requests from{" "}
                    <span className="font-family-gilroy-medium">
                      {" "}
                      {patientsRequestData?.hiu?.name}{" "}
                    </span>
                  </div>
                </div>
              )}
            </section>
          </section>

          <ConsentFooter
            loading={props.patients.loading}
            approveButtonDisabled={
              isValidObject(checkList) && isValidArray(providers) ? false : true
            }
            approveOnClick={() => {
              if (autoApprove && !editRequest) {
                setShowModal("verifyPinModal");
              } else {
                approveOnClick();
              }
            }}
            denyOnClick={() => {
              setShowModal("denyConsentModal");
            }}
            editOption={editRequest}
            editOnClick={() => {
              setEditRequest(true);
            }}
            editRequest_cancelOnClick={() => {
              setEditRequest(false);
            }}
            revokeAccessOnClick={() => {
              setShowModal("revokeAccessModal");
            }}
            status={patientsRequestData?.status}
            type={patientsRequestData?.type}
          />
        </ConditionalRender>

        <ConditionalRender condition={props.moreProvider}>
          <div>
            <div
              className={`${
                selectAllProviders && "opacity-half"
              } padding-top-large padding-bottom-large padding-left-larger padding-right-larger overflow-hidden overflow-scroll `}
            >
              <ProvidersList />
            </div>
            <div className=" display-flex padding-larger background-color-white position-absolute bottom-0 inherit-parent-width ">
              <Button
                text="Save"
                boxShadow={false}
                className=" margin-right-small"
                onClick={() => {
                  props.moreProviderOnclick(false);
                }}
              />
              <Button
                text="Back"
                boxShadow={false}
                variant="secondary"
                className=" margin-left-small"
                onClick={() => {
                  props.moreProviderOnclick(false);
                }}
              />
            </div>
          </div>
        </ConditionalRender>
      </ConditionalRender>

      <HandleBackClick
        onClose={() => {
          setShowModal(false);
        }}
        isBackEnabled={showModal !== false}
      >
        <Modal
          onClose={() => {
            setShowModal(false);
          }}
          canIgnore={false}
          show={showModal !== false}
          position="position-fixed bottom-0 left-0 right-0"
          boxShadow="none"
          borderRadius="none"
          width="inherit-parent-width"
          background="background-transparent"
          height="height-fit-to-content"
        >
          <div className="background-white font-family-gilroy-medium user-select-none ">
            <section className=" max-width-588px margin-horizontal-auto">
              <ConditionalRender condition={showModal === "providersModal"}>
                <ProviderDetails
                  edit={editRequest}
                  consentRequesterName={
                    selectedProvider.name && selectedProvider.name
                  }
                  status={patientsRequestData?.status}
                  type={patientsRequestData?.type}
                  requestToAccess={
                    selectedProvidersData &&
                    selectedProvider.id &&
                    selectedProvidersData[selectedProvider.id] &&
                    selectedProvidersData[selectedProvider.id].requestToAccess
                  }
                  to={
                    selectedProvidersData &&
                    selectedProvider.id &&
                    selectedProvidersData[selectedProvider.id] &&
                    selectedProvidersData[selectedProvider.id].to
                  }
                  continueOnClick={({ requestToAccess, from, to }) => {
                    setSelectedProvidersData({
                      ...selectedProvidersData,
                      [selectedProvider.id]: {
                        ...selectedProvidersData[selectedProvider.id],
                        requestToAccess: requestToAccess,
                        from: from,
                        to: to
                      }
                    });
                    setShowModal(false);
                  }}
                  backOnClick={() => {
                    setShowModal(false);
                  }}
                />
              </ConditionalRender>
              <ConditionalRender condition={showModal === "verifyPinModal"}>
                <PinInputSection
                  consentPinExists={
                    props.patients.healthIdData?.[props.patients.currentProfile]
                      ?.hasTransactionPin
                  }
                  resetPinOnClick={() => {
                    props.push("/abdm/changeconsentpin");
                    setShowModal(false);
                  }}
                  createPinOnClick={() => {
                    props.push("/abdm/changeconsentpin");
                  }}
                  pinOnChangeValue={(pinValue) => {
                    setPatientsRequestData({
                      ...patientsRequestData,
                      pin: pinValue
                    });
                  }}
                  loading={props.patients.loading}
                  approveOnClick={() => {
                    approveOnClick();
                  }}
                />
              </ConditionalRender>
              <ConditionalRender condition={showModal === "denyConsentModal"}>
                <AbdmRequestDenyReasonInputSection
                  loading={props.patients.loading}
                  denyOnClick={() => {
                    if (typeof props.denyRequest === "function") {
                      props.denyRequest(patientsRequestData.requestDenyReason);
                    }
                  }}
                  cancelOnClick={() => {
                    setShowModal(false);
                    setPatientsRequestData({
                      ...patientsRequestData,
                      requestDenyReason: " "
                    });
                  }}
                  reasonInputOnChange={(event) => {
                    setPatientsRequestData({
                      ...patientsRequestData,
                      requestDenyReason: event.target.value
                    });
                  }}
                />
              </ConditionalRender>
              <ConditionalRender condition={showModal === "revokeAccessModal"}>
                <RevokeAccessSection
                  loading={props.patients.loading}
                  revokeOnClick={() => {
                    props.revokePermission();
                  }}
                  cancelOnClick={() => {
                    setShowModal(false);
                  }}
                  resetPinOnClick={() => {
                    props.push("/abdm/changeconsentpin");
                    setShowModal(false);
                  }}
                />
              </ConditionalRender>
              <ConditionalRender
                condition={showModal === "disableAutoApproveModal"}
              >
                <DisableAutoApproveSection
                  loading={props.patients.loading}
                  hospitalName={
                    patientsRequestData?.hiu?.name
                      ? patientsRequestData?.hiu?.name
                      : patientsRequestData?.hiu?.id
                  }
                  disableOnclick={(pin) => {
                    props.disableAutoApprove(
                      pin,
                      autoApprovePoliciesData &&
                        Object.values(autoApprovePoliciesData)
                          ?.filter((e) => {
                            return (
                              e.data.hiu.id === patientsRequestData.hiu.id &&
                              e.patient.healthId ===
                                patientsRequestData.patient.id &&
                              e.enabled === true
                            );
                          })
                          .map((e) => e.documentId),
                      patientsRequestData.id
                    );
                    setShowModal(false);
                  }}
                  cancelOnClick={() => {
                    setShowModal(false);
                  }}
                  resetPinOnClick={() => {
                    props.push("/abdm/changeconsentpin");
                    setShowModal(false);
                  }}
                />
              </ConditionalRender>
              <ConditionalRender
                condition={showModal === "enableAutoApproveModal"}
              >
                <EnableAutoApproveSection
                  loading={props.patients.loading}
                  hospitalName={
                    patientsRequestData?.hiu?.name
                      ? patientsRequestData?.hiu?.name
                      : patientsRequestData?.hiu?.id
                  }
                  enableOnclick={(pin) => {
                    let approveData = {};
                    if (checkList) {
                      Object.keys(checkList).map((checkedId, _index) =>
                        Object.keys(selectedProvidersData).forEach(
                          (selectedId) => {
                            if (selectedId === checkedId) {
                              approveData = {
                                ...approveData,
                                [checkedId]: selectedProvidersData[checkedId]
                              };
                            }
                          }
                        )
                      );
                      props.enableAutoApprove(
                        pin,
                        approveData,
                        patientsRequestData.id
                      );
                    }
                    setShowModal(false);
                  }}
                  cancelOnClick={() => {
                    setShowModal(false);
                  }}
                  resetPinOnClick={() => {
                    props.push("/abdm/changeconsentpin");
                    setShowModal(false);
                  }}
                />
              </ConditionalRender>
            </section>
          </div>
        </Modal>
      </HandleBackClick>
    </>
  );
};

const LockerSetupRequestManagement = (props) => {
  const [patientsRequestData, setPatientsRequestData] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [selectAllProviders, setSelectAllProviders] = useState(true);
  const [selectedProvidersData, setSelectedProvidersData] = useState({});
  const [autoApprove, setAutoApprove] = useState(true);
  const [selectedProvider, setSelectedProvider] = useState({});
  const [checkList, setCheckList] = useState({ allProviders: true });
  const [providers, setProviders] = useState([]);
  // store careContexts data in state
  useEffect(() => {
    if (
      props.patients.currentProfile &&
      props.patients.careContexts?.[props.patients.currentProfile]
    ) {
      setProviders(
        Object.values(
          props.patients.careContexts?.[props.patients.currentProfile]
        )
      );
    }
  }, [props.patients.careContexts, props.patients.currentProfile]);

  // find request data and store in state
  useEffect(() => {
    if (
      !!props.abdmRequestId &&
      Array.isArray(
        props.patients.requests[props.patients.currentProfile][
          `${props.abdmRequestType}s`
        ]
      )
    ) {
      const abdmRequest = props.patients.requests[
        props.patients.currentProfile
      ][`${props.abdmRequestType}s`].find(
        (request) => request.id === props.abdmRequestId
      );
      if (
        isValidObject(abdmRequest) &&
        isValidObject(abdmRequest.subscription) &&
        isValidObject(abdmRequest.authorization)
      ) {
        setPatientsRequestData({
          ...patientsRequestData,
          ...abdmRequest.subscription,
          status: abdmRequest.status,
          LockerAuthorizationRequestId: abdmRequest.authorization.requestId,
          LockerRequestId: abdmRequest.id,
          lockerId: abdmRequest.authorization.requester.id
        });
      }
    }
    // eslint-disable-next-line
  }, [props.abdmRequestId, props.abdmRequestType, props.patients.requests]);

  // store selected provider data in store
  useEffect(() => {
    let defaultData = {
      allProviders: {
        from: new Date(
          new Date().getFullYear(),
          new Date().getMonth() - 6,
          new Date().getDate()
        ).toISOString(),
        to: new Date(
          new Date().getFullYear(),
          new Date().getMonth() + 6,
          new Date().getDate()
        ).toISOString(),
        requestToAccess: AbdmConsentHealthInfoTypes
      }
    };

    if (providers) {
      providers.map(
        (provider, index) =>
          (defaultData = {
            ...defaultData,
            [provider.hip.id]: {
              from: new Date(
                new Date().getFullYear(),
                new Date().getMonth() - 6,
                new Date().getDate()
              ).toISOString(),
              to: new Date(
                new Date().getFullYear(),
                new Date().getMonth() + 6,
                new Date().getDate()
              ).toISOString(),
              requestToAccess: AbdmConsentHealthInfoTypes
            }
          })
      );
    }
    setSelectedProvidersData(defaultData);

    // eslint-disable-next-line
  }, [providers]);

  function approveOnClick() {
    let approveData = {};
    if (checkList) {
      Object.keys(checkList).map((checkedId, index) =>
        Object.keys(selectedProvidersData).forEach((selectedId) => {
          if (selectedId === checkedId) {
            approveData = {
              ...approveData,
              [checkedId]: selectedProvidersData[checkedId]
            };
          }
        })
      );
      props.acceptRequest(approveData, autoApprove, props.push);
    }
  }

  function changeCheckList(checked, providerId) {
    if (checked) {
      setCheckList({
        ...checkList,
        [providerId]: providerId
      });
    } else {
      const data = checkList;
      data && delete data[providerId];
      setCheckList({ ...data });
    }
  }

  const ProvidersList = (props) => {
    const providersData = props.limit
      ? providers.slice(0, props.limit)
      : providers;
    return (
      <>
        {isValidArray(providersData) &&
          providersData.map((data, index) => (
            <div
              key={index}
              className="display-flex flex-justify-content-space-between flex-align-items-center padding-bottom-large"
              data-cy="providersList"
            >
              <div className="display-flex ">
                {patientsRequestData.status === consentStatus.pending && (
                  <CheckBox
                    name={data.hip.name}
                    id={data.hip.name}
                    checked={
                      Object.keys(checkList).some((id) => id === data.hip.id) ||
                      selectAllProviders
                    }
                    onChange={(event) => {
                      changeCheckList(event.target.checked, data.hip.id);
                    }}
                  />
                )}
              </div>
              <div
                className=" inherit-parent-width flex-justify-content-space-between flex-align-items-center  padding-left-default"
                onClick={() => {
                  if (
                    !selectAllProviders ||
                    patientsRequestData.status === consentStatus.approved
                  ) {
                    setSelectedProvider({
                      id: data.hip.id,
                      name: data.hip.name
                    });
                    setShowModal("providersModal");
                  }
                }}
              >
                <div className="font-color-secondary font-family-gilroy-regular font-size-medium">
                  {data.hip.name}
                </div>
                {patientsRequestData.status === consentStatus.pending ? (
                  <ArrowIcon />
                ) : (
                  <InfoIcon />
                )}
              </div>
            </div>
          ))}
      </>
    );
  };

  return (
    <>
      <ConditionalRender
        condition={
          !isValidArray(providers) ||
          !isValidObject(patientsRequestData) ||
          !Object.values(selectedProvidersData).length > 1
        }
      >
        <RequestSuspense />
      </ConditionalRender>
      <ConditionalRender
        condition={
          isValidArray(providers) &&
          isValidObject(patientsRequestData) &&
          Object.values(selectedProvidersData).length > 1
        }
      >
        <ConditionalRender condition={!props.moreProvider}>
          <section className=" overflow-y-auto  padding-left-large padding-right-large ">
            <ConsentHeader
              status={patientsRequestData?.status}
              consentRequesterName={patientsRequestData?.hiu?.name}
              consentRequesterSubName={patientsRequestData?.hiu?.id}
            />
            <section className="">
              <div className="font-color-secondary font-family-gilroy-regular font-size-medium padding-bottom-large">
                Requesting access to automatically fetch & store your Health
                records
              </div>
              <div className="padding-left-large padding-right-large">
                <div className={`${fontSmallUpperCase}`}>
                  Purpose of request
                </div>
                <div className=" font-color-secondary font-family-gilroy-regular font-size-medium padding-bottom-large">
                  {patientsRequestData?.purpose?.text}
                </div>

                <div className={`${fontSmallUpperCase}`}>From Providers</div>
                {patientsRequestData.status === consentStatus.pending && (
                  <div className=" display-flex flex-align-items-center margin-bottom-large">
                    <div className=" display-flex ">
                      <CheckBox
                        name="selectAllProviders"
                        id="selectAllProviders"
                        checked={selectAllProviders}
                        onChange={(event) => {
                          setSelectAllProviders(event.target.checked);
                          event.target.checked
                            ? setCheckList({
                                allProviders: event.target.checked
                              })
                            : setCheckList({});
                        }}
                      />
                    </div>
                    <div
                      className=" inherit-parent-width flex-justify-content-space-between flex-align-items-center "
                      onClick={() => {
                        setSelectedProvider({
                          id: "allProviders",
                          name: "All providers"
                        });
                        setShowModal("providersModal");
                      }}
                    >
                      <div className=" font-color-secondary font-family-gilroy-regular font-size-medium padding-left-default">
                        All providers
                      </div>
                      <ArrowIcon />
                    </div>
                  </div>
                )}

                <div
                  className={` ${
                    selectAllProviders && "opacity-half"
                  }   overflow-hidden`}
                >
                  {patientsRequestData.status === consentStatus.pending && (
                    <ProvidersList limit={2} />
                  )}

                  {patientsRequestData.status === consentStatus.approved && (
                    <ProvidersList />
                  )}
                </div>
                {providers?.length > 2 &&
                  patientsRequestData.status === consentStatus.pending && (
                    <div className=" padding-bottom-medium display-flex flex-align-items-center font-family-gilroy-medium font-color-primary">
                      <div
                        onClick={() => {
                          props.moreProviderOnclick(true);
                        }}
                      >
                        {`+${providers.length - 2} more provider${
                          providers.length - 2 > 1 ? "s" : ""
                        }`}
                      </div>
                      <ArrowIcon color="#00A000" />
                    </div>
                  )}
              </div>

              {patientsRequestData.status === consentStatus.pending && (
                <div className=" display-flex flex-align-items-start padding-large border-tertiaryColor border-radius-default">
                  <div>
                    <CheckBox
                      name="autoApprove"
                      id="autoApprove"
                      checked={
                        isValidObject(checkList) && isValidArray(providers)
                          ? autoApprove
                          : false
                      }
                      disabled={
                        isValidObject(checkList) && isValidArray(providers)
                          ? false
                          : true
                      }
                      onChange={(event) => {
                        setAutoApprove(event.target.checked);
                      }}
                    />
                  </div>
                  <div className=" font-color-secondary font-family-gilroy-regular font-size-medium padding-left-default ">
                    I would like to{" "}
                    <span className="font-family-gilroy-medium">
                      {" "}
                      auto-approve{" "}
                    </span>{" "}
                    all future consent requests from{" "}
                    <span className="font-family-gilroy-medium">
                      {" "}
                      {patientsRequestData?.hiu?.name}{" "}
                    </span>
                  </div>
                </div>
              )}
            </section>
          </section>
          <ConsentFooter
            loading={props.patients.loading}
            approveButtonDisabled={
              isValidObject(checkList) && isValidArray(providers) ? false : true
            }
            approveOnClick={() => {
              approveOnClick();
            }}
            status={patientsRequestData?.status}
            ignoreOnClick={() => {
              props.ignoreOnClick();
            }}
            type={patientsRequestData?.type}
          />
        </ConditionalRender>

        <ConditionalRender condition={props.moreProvider}>
          <div>
            <div
              className={`${
                selectAllProviders && "opacity-half"
              } padding-top-large padding-bottom-large padding-left-larger padding-right-larger overflow-hidden overflow-scroll `}
            >
              <ProvidersList />
            </div>
            <div className=" display-flex padding-larger background-color-white position-absolute bottom-0 inherit-parent-width ">
              <Button
                text="Save"
                boxShadow={false}
                className=" margin-right-small"
                onClick={() => {
                  props.moreProviderOnclick(false);
                }}
              />
              <Button
                text="Back"
                boxShadow={false}
                variant="secondary"
                className=" margin-left-small"
                onClick={() => {
                  props.moreProviderOnclick(false);
                }}
              />
            </div>
          </div>
        </ConditionalRender>
      </ConditionalRender>

      <HandleBackClick
        onClose={() => {
          setShowModal(false);
        }}
        isBackEnabled={showModal !== false}
      >
        <Modal
          onClose={() => {
            setShowModal(false);
          }}
          canIgnore={true}
          show={showModal !== false}
          position="position-fixed bottom-0 left-0 right-0"
          boxShadow="none"
          borderRadius="none"
          width="inherit-parent-width"
          background="background-transparent"
          height="height-fit-to-content"
        >
          <div className="background-white font-family-gilroy-medium user-select-none ">
            <section className=" max-width-588px margin-horizontal-auto">
              <ConditionalRender condition={showModal === "providersModal"}>
                <ProviderDetails
                  consentRequesterName={
                    selectedProvider.name && selectedProvider.name
                  }
                  status={patientsRequestData?.status}
                  type={patientsRequestData?.type}
                  requestToAccess={
                    selectedProvidersData &&
                    selectedProvider.id &&
                    selectedProvidersData[selectedProvider.id] &&
                    selectedProvidersData[selectedProvider.id].requestToAccess
                  }
                  to={
                    selectedProvidersData &&
                    selectedProvider.id &&
                    selectedProvidersData[selectedProvider.id] &&
                    selectedProvidersData[selectedProvider.id].to
                  }
                  continueOnClick={({ requestToAccess, from, to }) => {
                    setSelectedProvidersData({
                      ...selectedProvidersData,
                      [selectedProvider.id]: {
                        ...selectedProvidersData[selectedProvider.id],
                        requestToAccess: requestToAccess,
                        from: from,
                        to: to
                      }
                    });
                    setShowModal(false);
                  }}
                  backOnClick={() => {
                    setShowModal(false);
                  }}
                />
              </ConditionalRender>
              <ConditionalRender condition={showModal === "verifyPinModal"}>
                <PinInputSection
                  consentPinExists={
                    props.patients.healthIdData?.[props.patients.currentProfile]
                      ?.hasTransactionPin
                  }
                  resetPinOnClick={() => {
                    props.push("/abdm/changeconsentpin");
                    setShowModal(false);
                  }}
                  createPinOnClick={() => {
                    props.push("/abdm/changeconsentpin");
                  }}
                  pinOnChangeValue={(pinValue) => {
                    setPatientsRequestData({
                      ...patientsRequestData,
                      pin: pinValue
                    });
                  }}
                  loading={props.patients.loading}
                  approveOnClick={() => {}}
                />
              </ConditionalRender>
              <ConditionalRender condition={showModal === "denyConsentModal"}>
                <AbdmRequestDenyReasonInputSection
                  loading={props.patients.loading}
                  denyOnClick={() => {
                    if (typeof props.denyRequest === "function") {
                      props.denyRequest(patientsRequestData.requestDenyReason);
                    }
                  }}
                  cancelOnClick={() => {
                    setShowModal(false);
                    setPatientsRequestData({
                      ...patientsRequestData,
                      requestDenyReason: " "
                    });
                  }}
                  reasonInputOnChange={(event) => {
                    setPatientsRequestData({
                      ...patientsRequestData,
                      requestDenyReason: event.target.value
                    });
                  }}
                />
              </ConditionalRender>
              <ConditionalRender condition={showModal === "revokeAccessModal"}>
                <RevokeAccessSection
                  loading={props.patients.loading}
                  revokeOnClick={() => {
                    props.revokePermission();
                  }}
                  cancelOnClick={() => {
                    setShowModal(false);
                  }}
                  resetPinOnClick={() => {
                    props.push("/abdm/changeconsentpin");
                    setShowModal(false);
                  }}
                />
              </ConditionalRender>
            </section>
          </div>
        </Modal>
      </HandleBackClick>
    </>
  );
};

const AuthorizationRequestManagement = (props) => {
  const [patientsRequestData, setPatientsRequestData] = useState({});
  const [showModal, setShowModal] = useState(false);

  // find request data and store in state
  useEffect(() => {
    if (
      !!props.abdmRequestId &&
      Array.isArray(
        props.patients.requests[props.patients.currentProfile][
          `${props.abdmRequestType}s`
        ]
      )
    ) {
      const abdmRequest = props.patients.requests[
        props.patients.currentProfile
      ][`${props.abdmRequestType}s`].find(
        (request) => request.id === props.abdmRequestId
      );

      if (!!abdmRequest) {
        setPatientsRequestData({
          ...patientsRequestData,
          ...abdmRequest
        });
      }
    }
    // eslint-disable-next-line
  }, [
    props.abdmRequestId,
    props.abdmRequestType,
    props.patients.requests,
    props.patients.careContexts
  ]);
  return (
    <>
      <ConditionalRender condition={!isValidObject(patientsRequestData)}>
        <RequestSuspense />
      </ConditionalRender>
      <ConditionalRender condition={isValidObject(patientsRequestData)}>
        <section className=" overflow-y-auto  padding-left-large padding-right-large ">
          <ConsentHeader
            status={patientsRequestData?.status}
            consentRequesterName={patientsRequestData?.requester?.name}
          />
          <section className="padding-left-large padding-right-large">
            <div className={fontSmallUpperCase}>Purpose of request</div>
            <div className=" font-color-secondary font-family-gilroy-regular font-size-medium padding-bottom-large">
              {purposeOfRequest(patientsRequestData)}
            </div>
            <div className=" display-flex font-family-gilroy-regular  margin-top-default margin-bottom-larger">
              <NotificationBadge
                count={1}
                variant={
                  patientsRequestData?.status === consentStatus.pending
                    ? "primary"
                    : "secondary"
                }
                className={" margin-right-default margin-top-small"}
              />
              <div className="font-size-medium font-color-secondary width-fit-content line-height-140-percentage">
                Authorize{" "}
                <span className=" font-family-gilroy-medium ">
                  {patientsRequestData?.requester?.name}
                </span>{" "}
                to access your profile details & validate your ABHA address.
              </div>
            </div>
            <div className=" display-flex font-family-gilroy-regular margin-bottom-larger">
              <NotificationBadge
                count={2}
                variant={
                  patientsRequestData?.status === consentStatus.pending
                    ? "primary"
                    : "secondary"
                }
                className={" margin-right-default margin-top-small"}
              />
              <div className="font-size-medium font-color-secondary width-fit-content line-height-140-percentage">
                Authorize{" "}
                <span className=" font-family-gilroy-medium ">
                  {patientsRequestData?.requester?.name}
                </span>{" "}
                to add records in your ABHA address.
              </div>
            </div>
            <div className=" display-flex font-family-gilroy-regular margin-bottom-larger">
              <NotificationBadge
                count={3}
                variant={
                  patientsRequestData?.status === consentStatus.pending
                    ? "primary"
                    : "secondary"
                }
                className={" margin-right-default margin-top-small"}
              />
              <div className="font-size-medium font-color-secondary width-fit-content line-height-140-percentage">
                Authorize{" "}
                <span className=" font-family-gilroy-medium ">
                  {patientsRequestData?.requester?.name}
                </span>{" "}
                CAN NOT view, store or share any record without your consent.
              </div>
            </div>
            <ConditionalRender
              condition={patientsRequestData?.status === consentStatus.pending}
            >
              <div className=" font-color-secondary font-size-small font-family-gilroy-regular line-height-140-percentage">
                By approving, you Authorize{" "}
                <span className=" font-family-gilroy-medium ">
                  {patientsRequestData?.requester?.name}
                </span>{" "}
                to access your ABHA Address details & link records with their
                respective terms of service & privacy policies. You can opt out
                of this any time.
              </div>
            </ConditionalRender>
          </section>
        </section>
        <ConsentFooter
          loading={props.patients.loading}
          approveOnClick={() => {
            props.acceptRequest();
          }}
          denyOnClick={() => {
            setShowModal("denyConsentModal");
          }}
          revokeAccessOnClick={() => {
            setShowModal("revokeAccessModal");
          }}
          status={patientsRequestData?.status}
          type={patientsRequestData?.type}
        />
      </ConditionalRender>
      <HandleBackClick
        onClose={() => {
          setShowModal(false);
        }}
        isBackEnabled={showModal !== false}
      >
        <Modal
          onClose={() => {
            setShowModal(false);
          }}
          canIgnore={true}
          show={showModal !== false}
          position="position-fixed bottom-0 left-0 right-0"
          boxShadow="none"
          borderRadius="none"
          width="inherit-parent-width"
          background="background-transparent"
          height="height-fit-to-content"
        >
          <div className="background-white font-family-gilroy-medium user-select-none ">
            <section className=" max-width-588px margin-horizontal-auto">
              <ConditionalRender condition={showModal === "providersModal"}>
                <ProviderDetails
                  consentRequesterName={patientsRequestData?.selectedHip}
                  type={patientsRequestData?.type}
                  status={patientsRequestData?.status}
                  requestToAccess={
                    patientsRequestData?.connections?.[
                      patientsRequestData.selectedHip
                    ]?.hiTypes
                  }
                  from={
                    patientsRequestData?.connections?.[
                      patientsRequestData.selectedHip
                    ]?.period.from
                  }
                  to={
                    patientsRequestData?.connections?.[
                      patientsRequestData.selectedHip
                    ]?.period.to
                  }
                  continueOnClick={({ requestToAccess, from, to }) => {
                    setPatientsRequestData({
                      ...patientsRequestData,
                      connections: {
                        ...patientsRequestData.connections,
                        [patientsRequestData.selectedHip]: {
                          ...patientsRequestData.connections[
                            patientsRequestData.selectedHip
                          ],
                          hiTypes: requestToAccess,
                          period: {
                            from: from,
                            to: to
                          }
                        }
                      }
                    });
                    setShowModal(false);
                  }}
                  backOnClick={() => {
                    setShowModal(false);
                  }}
                />
              </ConditionalRender>
              <ConditionalRender condition={showModal === "verifyPinModal"}>
                <PinInputSection
                  consentPinExists={
                    props.patients.healthIdData?.[props.patients.currentProfile]
                      ?.hasTransactionPin
                  }
                  resetPinOnClick={() => {
                    props.push("/abdm/changeconsentpin");
                    setShowModal(false);
                  }}
                  createPinOnClick={() => {
                    props.push("/abdm/changeconsentpin");
                  }}
                  pinOnChangeValue={(pinValue) => {
                    setPatientsRequestData({
                      ...patientsRequestData,
                      pin: pinValue
                    });
                  }}
                  loading={props.patients.loading}
                  approveOnClick={() => {}}
                />
              </ConditionalRender>
              <ConditionalRender condition={showModal === "denyConsentModal"}>
                <AbdmRequestDenyReasonInputSection
                  loading={props.patients.loading}
                  denyOnClick={() => {
                    if (typeof props.denyRequest === "function") {
                      props.denyRequest(patientsRequestData.requestDenyReason);
                    }
                  }}
                  cancelOnClick={() => {
                    setPatientsRequestData({
                      ...patientsRequestData,
                      requestDenyReason: " "
                    });
                    setShowModal(false);
                  }}
                  reasonInputOnChange={(event) => {
                    setPatientsRequestData({
                      ...patientsRequestData,
                      requestDenyReason: event.target.value
                    });
                  }}
                />
              </ConditionalRender>
              <ConditionalRender condition={showModal === "revokeAccessModal"}>
                <RevokeAccessSection
                  loading={props.patients.loading}
                  revokeOnClick={() => {
                    props.revokePermission();
                  }}
                  cancelOnClick={() => {
                    setShowModal(false);
                  }}
                />
              </ConditionalRender>
            </section>
          </div>
        </Modal>
      </HandleBackClick>
    </>
  );
};

const ConsentFooter = (props) => {
  return (
    <>
      {props.status === consentStatus.approved &&
        (props.editOnClick ? (
          !props.editOption && (
            <div>
              <section
                onClick={() => {
                  if (props.loading) return;
                  props.editOnClick();
                }}
                className=" padding-top-large padding-bottom-large  padding-left-large padding-right-large  font-size-small font-family-gilroy-regular  text-align-center line-height-140-percentage"
              >
                Need to edit this service to access the above mentioned <br />{" "}
                records?
                <span className=" font-color-primary padding-left-small">
                  Edit access
                </span>
              </section>
            </div>
          )
        ) : (
          <div>
            {typeof props.revokeAccessOnClick === "function" && (
              <section
                onClick={() => {
                  if (props.loading) return;
                  props.revokeAccessOnClick();
                }}
                className=" padding-top-large padding-bottom-large  padding-left-large padding-right-large  font-size-small font-family-gilroy-regular  text-align-center line-height-140-percentage"
              >
                Don’t want this service to access the above mentioned <br />{" "}
                records?
                <span className=" font-color-red padding-left-small">
                  Revoke access
                </span>
              </section>
            )}
          </div>
        ))}
      {props.status === consentStatus.pending || props.editOption ? (
        <section className=" padding-left-larger padding-right-larger padding-bottom-larger ">
          {props.type !== "authorization" && (
            <div className="padding-bottom-larger text-align-center font-size-small font-family-gilroy-regular font-color-secondary ">
              By approving, you agree with Ninto’s terms of service & privacy
              policies. You can opt out of this any time.
            </div>
          )}
          <div className=" display-flex">
            <Button
              type={"submit"}
              boxShadow={false}
              text={props.editOption ? "Confirm" : "Approve"}
              disabled={props.approveButtonDisabled}
              onClick={(event) => {
                props.approveOnClick(event);
              }}
              loading={props.loading}
              className={
                " font-family-gilroy-medium border-1px-e5e5e5 margin-right-small"
              }
              data-cy={`accept-button`}
            />
            {typeof props.denyOnClick === "function" && !props.editOption && (
              <Button
                loading={props.loading}
                disabled={props.approveButtonDisabled}
                boxShadow={false}
                text="Deny"
                variant={"secondary"}
                onClick={(event) => {
                  props.denyOnClick(event);
                }}
                className={
                  " font-color-red border-1px-e5e5e5 margin-left-small"
                }
                data-cy={`deny-button`}
              />
            )}
            {typeof props.ignoreOnClick === "function" && !props.editOption && (
              <Button
                loading={props.loading}
                disabled={props.approveButtonDisabled}
                boxShadow={false}
                text="Ignore"
                variant={"secondary"}
                onClick={(event) => {
                  props.ignoreOnClick(event);
                }}
                className={"  border-1px-e5e5e5 margin-left-small"}
                data-cy={`Ignore-button`}
              />
            )}

            {props.editOption && (
              <Button
                loading={props.loading}
                boxShadow={false}
                text="Cancel"
                variant={"secondary"}
                onClick={(event) => {
                  props.editRequest_cancelOnClick(event);
                }}
                className={"  border-1px-e5e5e5 margin-left-small"}
                data-cy={`cancel-button`}
              />
            )}
          </div>
        </section>
      ) : null}
    </>
  );
};

const ConsentHeader = (props) => {
  /**
   * consentRequesterName -> consent requester name
   * status -> consent status
   */
  return (
    <>
      <div className="display-flex padding-top-large padding-bottom-large">
        <div className="display-flex flex-grow-1 flex-align-items-center">
          <RoundedProfilePicture size={"medium"} />
          <div className="flex-grow-1 flex-direction-column display-flex padding-left-medium">
            <span className="font-size-medium font-family-gilroy-regular font-color-secondary">
              {props.consentRequesterName}
            </span>
            <span
              className="font-size-small font-family-gilroy-regular text-transform-capitalize padding-top-small"
              //   data-cy={clinicName}
            >
              {props.consentRequesterSubName}
            </span>
          </div>
          <StatusChip status={props.status} />
        </div>
      </div>
    </>
  );
};

const fontSmallUpperCase =
  " font-color-secondary font-family-gilroy-regular font-size-smaller text-transform-uppercase letter-spacing-4-percentage padding-bottom-small";

const Chip = (props) => {
  return (
    <div
      data-cy={`${props.text}-chip`}
      className="background-color-grey font-family-gilroy-regular font width-fit-content border-radius-default font-size-smaller padding-left-medium padding-right-medium height-fit-to-content padding-top-small padding-bottom-small text-transform-uppercase letter-spacing-4-percentage margin-right-default margin-bottom-default"
    >
      {props.text}
    </div>
  );
};

const ProviderDetails = (props) => {
  const [providerData, setProviderData] = useState({
    requestToAccess: props.requestToAccess,
    from: "",
    to: ""
  });
  const monthCalculator = (month) => {
    return new Date(
      new Date().getFullYear(),
      new Date().getMonth() + month,
      new Date().getDate()
    ).toISOString();
  };

  const [date, setDate] = useState({ from: props.from, to: props.to });
  const [expireMonth, setExpireMonth] = useState(0);
  const [showCheckBox, setShowCheckBox] = useState(false);

  // set expire month
  useEffect(() => {
    props.to === monthCalculator(3)
      ? setExpireMonth(3)
      : props.to === monthCalculator(6)
      ? setExpireMonth(6)
      : props.to === monthCalculator(12) && setExpireMonth(12);

    // eslint-disable-next-line
  }, []);

  // set showCheckbox
  useEffect(() => {
    if (isValidArray(props.healthLockersData)) {
      setShowCheckBox(
        props.healthLockersData.some(
          (data) =>
            data.hip.id === props.requesterId &&
            props.requesterId === lockerSetup.locker &&
            data.hip.type === "HEALTH_LOCKER"
        )
      );
    }

    // eslint-disable-next-line
  }, []);
  return (
    <section className=" padding-bottom-larger padding-right-large padding-left-large ">
      <div className="display-flex flex-grow-1 flex-align-items-center padding-bottom-large padding-top-large ">
        <RoundedProfilePicture size={"medium"} />
        <div className=" padding-left-medium font-size-medium font-family-gilroy-regular font-color-secondary">
          {props.consentRequesterName}
        </div>
      </div>
      <HiTypes
        status={props.status}
        edit={props.edit}
        hiTypes={props.requestToAccess}
        selectedHips={(hiTypes) => {
          setProviderData({ ...providerData, requestToAccess: hiTypes });
        }}
      />
      <ConditionalRender
        condition={
          (props.status === consentStatus.pending || props.edit) &&
          props.type !== "consent"
        }
      >
        <div className=" padding-top-large font-size-small font-color-secondary">
          ACCESS EXPIRES IN
        </div>
        <div className=" padding-top-small display-flex padding-bottom-larger">
          {[3, 6, 12].map((month, index) => (
            <MonthsCard
              key={index}
              months={month}
              selected={expireMonth === month}
              monthsCardOnClick={() => {
                expireMonth !== month
                  ? setExpireMonth(month)
                  : setExpireMonth(0);
              }}
            />
          ))}
        </div>
      </ConditionalRender>

      <ConditionalRender
        condition={
          props.status === consentStatus.pending && props.type === "consent"
        }
      >
        <div className=" display-flex padding-vertical-default">
          <InputBox
            className={
              "inherit-parent-width border-radius-default padding-right-medium"
            }
            label={"DATING FROM"}
            size={"small"}
            value={date.from && date.from.split("T")[0]}
            type="date"
            onChange={(event) => {
              setDate({
                ...date,
                from: new Date(event.target.value).toISOString()
              });
            }}
          />

          <InputBox
            className={
              "inherit-parent-width border-radius-default padding-left-medium"
            }
            label="TO"
            size={"small"}
            value={date.to && date.to.split("T")[0]}
            type="date"
            maxDate={
              new Date(
                new Date(date.to).getFullYear(),
                new Date(date.to).getMonth() + 6,
                new Date(date.to).getDate()
              )
                .toISOString()
                .split("T")[0]
            }
            onChange={(event) => {
              setDate({
                ...date,
                to: new Date(event.target.value).toISOString()
              });
            }}
          />
        </div>
      </ConditionalRender>

      <ConditionalRender condition={showCheckBox}>
        <div className=" display-flex flex-align-items-center margin-bottom-large">
          <div className=" display-flex ">
            <CheckBox
              name="allowClones"
              id="allowClones"
              checked={props.dataClone}
              onChange={(event) => {
                props.onChangeCheckBox(event.target.checked);
              }}
              data-cy={"allowClones-checkBox"}
            />
          </div>

          {/* <div
              className=" inherit-parent-width flex-justify-content-space-between "
              onClick={() => {}}
            > */}
          <div className=" font-color-secondary font-family-gilroy-regular font-size-medium ">
            Allow access to synced records
          </div>

          {/* </div> */}
        </div>
      </ConditionalRender>

      <ConditionalRender
        condition={props.status === consentStatus.approved && !props.edit}
      >
        <div className="  font-size-smaller font-color-secondary">
          ACCESS EXPIRES ON
        </div>
        <div className=" margin-bottom-larger padding-top-small font-size-medium font-color-secondary">
          {`${new Date(props.to).getDate()}/${new Date(
            props.to
          ).getMonth()}/${new Date(props.to).getFullYear()}`}{" "}
          {remainingDateConversion(props.to)}
        </div>
      </ConditionalRender>

      <ConditionalRender
        condition={props.status === consentStatus.pending || props.edit}
      >
        <Button
          className={" font-family-gilroy-medium "}
          data-cy={"continue-button"}
          boxShadow={false}
          text="Continue"
          disabled={props.type !== "consent" && expireMonth === 0}
          onClick={() => {
            if (props.type !== "consent") {
              props.continueOnClick({
                ...providerData,
                from: new Date().toISOString(),
                to: new Date(
                  new Date().getFullYear(),
                  new Date().getMonth() + expireMonth,
                  new Date().getDate()
                ).toISOString()
              });
            } else {
              props.continueOnClick({
                requestToAccess: providerData.requestToAccess,
                from: date.from,
                to: date.to
              });
            }
          }}
        />
      </ConditionalRender>
      <ConditionalRender
        condition={props.status !== consentStatus.pending && !props.edit}
      >
        <Button
          text="Back"
          boxShadow={false}
          className=" font-family-gilroy-medium max-width-150px margin-horizontal-auto display-block"
          onClick={() => {
            props.backOnClick();
          }}
        />
      </ConditionalRender>
    </section>
  );
};

function remainingDateConversion(date) {
  const timeStamp = +new Date(date) - +new Date();
  const remainingMonth = Math.round(timeStamp / (1000 * 60 * 60 * 24 * 30));
  const remainingDays = Math.round(timeStamp / (1000 * 60 * 60 * 24));

  if (remainingMonth < 1) {
    return `(${remainingDays} Day${remainingDays !== 1 ? "s" : ""})`;
  } else {
    return `(${remainingMonth} Month${remainingMonth !== 1 ? "s" : ""})`;
  }
}

const MonthsCard = (props) => {
  return (
    <div
      className={`${
        props.selected ? "border-primaryColor" : "border-tertiaryColor"
      } font-family-gilroy-regular font-size-small border-radius-default width-fit-content padding-small display-flex flex-align-items-center margin-right-medium`}
      onClick={() => {
        props.monthsCardOnClick();
      }}
    >
      {props.selected && <TickIcon color={"#00A000"} width={12} />}

      <div
        className={props.selected && "padding-left-small font-color-primary"}
      >
        {props.months} months
      </div>
    </div>
  );
};
// { status, hiTypes, selectedHips }
const HiTypes = (props) => {
  const [hips, setHips] = useState(props.hiTypes);

  return props.status === consentStatus.pending || props.edit ? (
    <Select
      onChipAdded={(chip) => {
        if (chip?.length > 0) {
          props.selectedHips(chip);
          setHips([...chip]);
        }
      }}
      modalTitle="Choose your Health info type"
      multiSelect={true}
      name="health-info"
      label="Request to access"
      labelClassName=" letter-spacing-4-percentage padding-bottom-default "
      data-cy="health-info-select-box-comp"
      showAddOption={false}
      options={AbdmConsentHealthInfoTypes}
      chips={hips}
    />
  ) : (
    <>
      <div className={fontSmallUpperCase}>Requested to access</div>
      <div className="display-flex flex-wrap-wrap padding-bottom-larger ">
        {hips &&
          hips.map((type, index) => (
            <React.Fragment key={`${type}${index}`}>
              <Chip text={type} />
            </React.Fragment>
          ))}
      </div>
    </>
  );
};

const PinInputSection = ({
  consentPinExists,
  pinOnChangeValue,
  loading,
  approveOnClick,
  resetPinOnClick,
  buttonText,
  createPinOnClick
}) => {
  const [pinValue, setPinValue] = useState("");
  return consentPinExists ? (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        if (typeof approveOnClick === "function") {
          approveOnClick();
        }
      }}
    >
      <section className="font-color-secondary font-family-gilroy-medium font-color-secondary text-align-center padding-large ">
        <section className="padding-bottom-default padding-right-large padding-left-large ">
          <PinInputBox
            label="Enter Consent pin"
            labelClassName=" text-transform-uppercase letter-spacing-4-percentage"
            otpFocus={true}
            digits={4}
            setValue={(value) => {
              if (typeof pinOnChangeValue === "function") {
                pinOnChangeValue(value);
                setPinValue(value);
              }
            }}
          />
          <div
            className=" font-family-gilroy-regular font-size-small margin-top-larger "
            onClick={() => {
              resetPinOnClick();
            }}
          >
            Forgot your consent PIN?
            <span className=" font-family-gilroy-medium font-color-primary ">
              {" "}
              Reset now
            </span>
          </div>
          <Button
            loading={loading}
            boxShadow={false}
            type="submit"
            text={buttonText ? buttonText : "Approve"}
            disabled={pinValue.length !== 4}
            // onClick={() => {
            //   if (typeof approveOnClick === "function") {
            //     approveOnClick();
            //   }
            // }}
            className="  border-1px-e5e5e5 margin-left-small margin-top-larger font-family-gilroy-medium"
          />
        </section>
      </section>
    </form>
  ) : (
    <div className="font-color-secondary font-family-gilroy-medium font-color-secondary text-align-center padding-large ">
      <div className=" font-size-small">Create consent pin</div>
      <div className=" font-size-small padding-large">
        Your profile doesn’t have consent pin.
        <br /> Create a new consent pin to proceed further.
      </div>
      <Button
        boxShadow={false}
        type="submit"
        text={"Create"}
        onClick={() => {
          createPinOnClick();
        }}
        className="  border-1px-e5e5e5 margin-left-small  font-family-gilroy-medium"
      />
    </div>
  );
};

const AbdmRequestDenyReasonInputSection = ({
  reasonInputOnChange,
  loading,
  denyOnClick,
  cancelOnClick
}) => {
  const [reason, setReason] = useState("");
  return (
    <form
      onSubmit={(event) => {
        event.preventDefault();
        if (typeof denyOnClick === "function") {
          denyOnClick();
        }
      }}
    >
      <section className=" padding-large">
        <section className="padding-bottom-large padding-right-large padding-left-large  ">
          <div className=" font-size-medium  text-align-center font-family-gilroy-medium font-color-secondary ">
            Why you are denying this request?
          </div>
          <InputBox
            className=" margin-vertical-large"
            type="text"
            value={reason}
            name="requestDenyReason"
            onChange={(event) => {
              if (typeof reasonInputOnChange === "function") {
                reasonInputOnChange(event);
              }
              setReason(event.target.value);
            }}
            label="Specify reason "
            labelClassName="letter-spacing-4-percentage"
          />
          <section className=" display-flex padding-top-default ">
            <Button
              loading={loading}
              boxShadow={false}
              type="submit"
              variant={"danger"}
              text="Deny"
              data-cy={`deny-confirm-button`}
              disabled={!reason}
              className={
                " border-1px-e5e5e5 margin-right-small font-family-gilroy-medium "
              }
            />
            <Button
              boxShadow={false}
              text="Cancel"
              variant={"secondary"}
              onClick={() => {
                if (typeof cancelOnClick === "function") {
                  cancelOnClick();
                }
              }}
              className={
                " font-color-secondary border-1px-e5e5e5 margin-left-small"
              }
            />
          </section>
        </section>
      </section>
    </form>
  );
};

const RevokeAccessSection = (props) => {
  const [pinValue, setPinValue] = useState("");

  return (
    <section className="font-color-secondary font-family-gilroy-medium font-color-secondary text-align-center padding-large">
      <section className="padding-bottom-large padding-right-large padding-left-large ">
        <div className=" font-size-medium  ">
          Revoke access to these records?
        </div>
        <div className=" font-size-small padding-top-large">
          By revoking access to this service, They won’t be able to access the
          mentioned records. Are you sure?
        </div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            if (props.getConsentPin && pinValue.length === 4) {
              props.revokeOnClick(pinValue);
            } else {
              props.revokeOnClick();
            }
          }}
        >
          {props.getConsentPin && (
            <div>
              <PinInputBox
                label="Enter Consent pin"
                labelClassName=" text-transform-uppercase letter-spacing-4-percentage"
                otpFocus={true}
                digits={4}
                setValue={(value) => {
                  setPinValue(value);
                }}
              />
              <div
                className=" font-family-gilroy-regular font-size-small margin-top-larger "
                onClick={() => {
                  props.resetPinOnClick();
                }}
              >
                Forgot your consent PIN?
                <span className=" font-family-gilroy-medium font-color-primary ">
                  {" "}
                  Reset now
                </span>
              </div>
            </div>
          )}
          <section className=" display-flex padding-top-larger ">
            <Button
              loading={props.loading}
              boxShadow={false}
              type="submit"
              variant={"danger"}
              text="Revoke access"
              disabled={props.getConsentPin ? pinValue.length !== 4 : false}
              className={" border-1px-e5e5e5 margin-right-small"}
              data-cy={"revoke-access-button"}
            />
            <Button
              boxShadow={false}
              text="Cancel"
              variant={"secondary"}
              onClick={() => {
                if (typeof props.cancelOnClick === "function") {
                  props.cancelOnClick();
                }
              }}
              className={
                " font-color-secondary border-1px-e5e5e5 margin-left-small"
              }
              data-cy={"cancel-revoke-button"}
            />
          </section>
        </form>
      </section>
    </section>
  );
};

const DisableAutoApproveSection = (props) => {
  const [pinValue, setPinValue] = useState("");

  return (
    <section className="font-color-secondary font-family-gilroy-medium font-color-secondary text-align-center padding-large">
      <section className="padding-bottom-large padding-right-large padding-left-large ">
        <div className=" ">Disable auto-approval</div>
        <div className=" font-size-small padding-top-large">
          Disabling this auto-approval policy would stop consent requests from{" "}
          <span className=" font-family-gilroy-bold">
            {" "}
            {props.hospitalName}{" "}
          </span>{" "}
          to be automatically approved.
        </div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            if (pinValue.length === 4) {
              props.disableOnclick(pinValue);
            }
          }}
        >
          <div className=" padding-top-large">
            <PinInputBox
              label="Enter Consent pin"
              labelClassName=" text-transform-uppercase letter-spacing-4-percentage"
              otpFocus={true}
              digits={4}
              setValue={(value) => {
                setPinValue(value);
              }}
            />
            <div
              className=" font-family-gilroy-regular font-size-small margin-top-larger "
              onClick={() => {
                props.resetPinOnClick();
              }}
            >
              Forgot your consent PIN?
              <span className=" font-family-gilroy-medium font-color-primary ">
                {" "}
                Reset now
              </span>
            </div>
          </div>
          <section className=" display-flex padding-top-larger ">
            <Button
              loading={props.loading}
              boxShadow={false}
              type="submit"
              variant={"danger"}
              text="Disable"
              disabled={pinValue.length !== 4 ? true : false}
              className={" border-1px-e5e5e5 margin-right-small"}
            />
            <Button
              boxShadow={false}
              text="Cancel"
              variant={"secondary"}
              onClick={() => {
                props.cancelOnClick();
              }}
              className={
                " font-color-secondary border-1px-e5e5e5 margin-left-small"
              }
            />
          </section>
        </form>
      </section>
    </section>
  );
};

const EnableAutoApproveSection = (props) => {
  const [pinValue, setPinValue] = useState("");

  return (
    <section className="font-color-secondary font-family-gilroy-medium font-color-secondary text-align-center padding-large">
      <section className="padding-bottom-large padding-right-large padding-left-large ">
        <div className=" ">Enable auto-approval</div>
        <div className=" font-size-small padding-top-large">
          Enabling this auto-approval policy would initiate consent requests
          from{" "}
          <span className=" font-family-gilroy-bold">
            {" "}
            {props.hospitalName}{" "}
          </span>{" "}
          to be automatically approved.
        </div>
        <form
          onSubmit={(event) => {
            event.preventDefault();
            if (pinValue.length === 4) {
              props.enableOnclick(pinValue);
            }
          }}
        >
          <div className=" padding-top-large">
            <PinInputBox
              label="Enter Consent pin"
              labelClassName=" text-transform-uppercase letter-spacing-4-percentage"
              otpFocus={true}
              digits={4}
              setValue={(value) => {
                setPinValue(value);
              }}
            />
            <div
              className=" font-family-gilroy-regular font-size-small margin-top-larger "
              onClick={() => {
                props.resetPinOnClick();
              }}
            >
              Forgot your consent PIN?
              <span className=" font-family-gilroy-medium font-color-primary ">
                {" "}
                Reset now
              </span>
            </div>
          </div>
          <section className=" display-flex padding-top-larger ">
            <Button
              loading={props.loading}
              boxShadow={false}
              type="submit"
              variant={"primary"}
              text="Enable"
              disabled={pinValue.length !== 4 ? true : false}
              className={" border-1px-e5e5e5 margin-right-small"}
            />
            <Button
              boxShadow={false}
              text="Cancel"
              variant={"secondary"}
              onClick={() => {
                props.cancelOnClick();
              }}
              className={
                "font-color-secondary border-1px-e5e5e5 margin-left-small"
              }
            />
          </section>
        </form>
      </section>
    </section>
  );
};

const RequestSuspense = () => {
  return (
    <section
      className=" remaining-body-height padding-left-large padding-right-large overflow-y-auto padding-top-medium "
      data-cy="view-filled-form"
    >
      {[...Array(10)].map((val, index) => (
        <span
          key={index}
          className=" padding-left-large padding-right-large margin-vertical-larger"
        >
          <div className=" shimmer width-30-percentage padding-small margin-vertical-default border-radius-default"></div>
          <div className=" shimmer width-90-percent padding-default border-radius-default margin-bottom-large"></div>
        </span>
      ))}
    </section>
  );
};
