import React, { useEffect, useState } from "react";
import { isValidArray } from "../../utils/validators";
import maxProfileFallBack from "../../assets/images/Max-profile-fallback.svg";
import { connect } from "react-redux";
import {
  clearHealthIdCreatedStatus,
  createHealthId,
  setAuthModes,
  setTransactionId
} from "../../redux/patients/actions";
import Header from "../../front-end-global-components/components/Header/Header";

function ABDMList(props) {
  const [authMode, setAuthMode] = useState(null);
  const [healthIdData, setHealthIdData] = useState(null);

  useEffect(() => {
    if (!props.patients?.transaction?.id) {
      props.navigate("/abdm/authentication");
    }
    // eslint-disable-next-line
  }, [props.patients?.transaction?.id]);

  useEffect(() => {
    const data = new URLSearchParams(window.location.search)?.get("authMode");
    if (data) {
      setAuthMode(data);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      isValidArray(props.patients.usedHealthId) ||
      isValidArray(props.patients.unusedHealthId)
    ) {
      if (
        isValidArray(props.patients.usedHealthId) &&
        isValidArray(props.patients.unusedHealthId)
      ) {
        return setHealthIdData([
          ...props.patients.usedHealthId,
          ...props.patients.unusedHealthId
        ]);
      } else if (isValidArray(props.patients.usedHealthId)) {
        return setHealthIdData([...props.patients.usedHealthId]);
      } else if (isValidArray(props.patients.unusedHealthId)) {
        return setHealthIdData([...props.patients.unusedHealthId]);
      }
    }
  }, [props.patients.usedHealthId, props.patients.unusedHealthId]);

  return (
    <>
      <Header
        title={"ABDM List"}
        backButtonOnClick={() => {
          props.navigate("/abdm/authentication");
          if (props.patients?.transaction?.id !== null) {
            props.setTransactionId(null);
            props.setAuthModes(null);
          }
        }}
        hideSupport={true}
        incident={props.app.featureFlags?.incident?.patients}
        incidentOnClick={() => {
          props.navigate("/incidents");
        }}
      />
      <section className="remaining-body-height max-width-588px margin-horizontal-auto overflow-y-auto inherit-parent-width ">
        <div className="padding-left-larger padding-right-larger inherit-parent-height flex-direction-column flex-justify-content-space-between">
          <div className="inherit-parent-width display-flex flex-justify-content-center padding-top-larger">
            <img src={maxProfileFallBack} alt="" height={100} width={"auto"} />
          </div>
          <div className="text-align-center letter-spacing-large font-family-gilroy-regular font-size-medium font-color-secondary padding-top-larger padding-bottom-large">
            Choose an existing ABHA address for login or create a new ABHA
            address.
          </div>
          <div className="text-align-center letter-spacing-large font-family-gilroy-regular font-size-small font-color-secondary padding-bottom-large">
            EXISTING ABHA ADDRESS ARE LISTED BELOW
          </div>
          {isValidArray(healthIdData) ? (
            <div className="adaptive-height-healthId-list overflow-scroll hide-scroll-bar">
              {healthIdData.map((data, index) => (
                <div
                  key={index}
                  onClick={() => {
                    props.createHealthId(
                      {
                        phrAddress: data,
                        alreadyExistedPHR: true,
                        transactionId: props.patients?.transaction?.id,
                        ...(!window.location.search.includes("force=true") &&
                        props.patients.currentProfile
                          ? { patientId: props.patients.currentProfile }
                          : {})
                      },
                      authMode,
                      props.navigate,
                      true
                    );
                  }}
                  className="cursor-pointer display-flex flex-justify-content-space-between margin-top-default font-color-secondary font-family-gilroy-medium"
                >
                  <div>
                    <div className="font-size-medium padding-top-large">
                      {data}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className=" font-color-secondary inherit-parent-height flex-align-items-center flex-justify-content-center">
              There is no Existing ABHA Address
            </div>
          )}

          <div>
            <div className="flex-center-children-vertically margin-vertical-large ">
              <hr
                style={{ width: "100%" }}
                className="display-inline-block border-color-secondary-lite border-top-left-right-none flex-basis-1 "
              />
              <span className=" margin-horizontal-default display-inline-block font-color-tertiary font-size-small">
                OR
              </span>
              <hr
                style={{ width: "100%" }}
                className="display-inline-block border-color-secondary-lite border-top-left-right-none flex-basis-1 "
              />
            </div>

            <div className="inherit-parent-width padding-bottom-large">
              <div className="font-weight-normal font-family-gilroy-medium font-size-small font-color-secondary inherit-parent-width text-align-center">
                Still want to create a new ABHA address?
                <span
                  onClick={() => {
                    const mobile = new URLSearchParams(
                      window.location.search
                    )?.get("mobile");
                    if (mobile) {
                      props.navigate(
                        `/abdm/create/?authMode=${authMode}&mobile=${mobile.toString()}`
                      );
                    } else {
                      props.navigate(`/abdm/create/?authMode=${authMode}`);
                    }
                    props.clearHealthIdCreatedStatus();
                  }}
                  className="font-color-primary padding-left-small cursor-pointer"
                >
                  Create
                </span>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

const mapStateToProps = (state) => ({
  abdm: state.abdm,
  patients: state.patients,
  app: state.app
});

const mapDispatchToProps = function () {
  return {
    createHealthId: (data, requestMethod, push, removeMobile) =>
      createHealthId(data, requestMethod, push, removeMobile),
    clearHealthIdCreatedStatus: () => clearHealthIdCreatedStatus(),
    setTransactionId: (data) => setTransactionId(data),
    setAuthModes: (data) => setAuthModes(data)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ABDMList);
