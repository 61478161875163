import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Header from "../../front-end-global-components/components/Header/Header";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import {
  isValidArray,
  isValidObject,
  validation
} from "../../utils/validators";
import {
  ArrowIcon,
  CircleWithTickIcon,
  ExclamatoryIcon
} from "../../front-end-global-components/assets/assets";
import Modal from "../../front-end-global-components/components/Modal/Modal";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import Button from "../../front-end-global-components/components/Button/Button";
import {
  searchHealthIdAuthMode,
  generateAbhaAddressOtp,
  confirmLinkOrUnLinkAbhaAddressOtp,
  setAuthModes,
  linkHealthIdNumber,
  setTransactionId,
  clearShowSuccess
} from "../../redux/patients/actions";
import {
  registerNewAbhaNumber,
  setAbdmTransactionId
} from "../../redux/abdm/actions";
import ConditionalRender from "../../front-end-global-components/components/ConditionalRender/ConditionalRender";
import OTPInputBox from "../../front-end-global-components/components/OTPInputBox/OTPInputBox";
import CreateAbhaNumber from "../../components/CreateAbhaNumber/CreateAbhaNumber";
import FeatureFlagsFallback from "../../front-end-global-components/components/FeatureFlagsFallback/FeatureFlagsFallback";
import ProcessSuccess from "../../components/ProcessSuccess/ProcessSuccess";

const AbhaNumber = (props) => {
  const [healthIdData, setHealthIdData] = useState({});
  const [authModes, setAuthModes] = useState([]);
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [showUnlinkModal, setShowUnlinkModal] = useState(false);
  const [showCreateAbhaNumberModal, setShowCreateAbhaNumberModal] =
    useState(false);
  const [selectedAuthMode, setSelectedAuthMode] = useState("");
  const [showOtpBox, setShowOtpBox] = useState(false);
  const [OTP, setOTP] = useState("");
  const [abhaNumber, setAbhaNumber] = useState("");
  const [counter, setCounter] = useState(null);
  const [running, setRunning] = useState(false);

  useEffect(() => {
    let counterTimer;
    if (
      props.patients?.transaction?.id &&
      counter &&
      typeof counter === "number" &&
      running
    ) {
      counterTimer = setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
    } else {
      setCounter(null);
      setRunning(false);
      return () => clearTimeout(counterTimer);
    }

    // eslint-disable-next-line
  }, [counter]);
  useEffect(() => {
    if (
      props.patients?.transaction?.id &&
      typeof props.patients?.transaction?.id === "string" &&
      !running
    ) {
      setCounter(60);
      setRunning(true);
    } else {
      setCounter(null);
      setRunning(false);
    }
    // eslint-disable-next-line
  }, [props.patients?.transaction?.id]);

  useEffect(() => {
    if (
      props.patients.currentProfile &&
      props.patients.healthIdData?.[props.patients.currentProfile]
    ) {
      setHealthIdData(
        props.patients.healthIdData[props.patients.currentProfile]
      );
    }
  }, [props.patients.currentProfile, props.patients.healthIdData]);

  useEffect(() => {
    if (isValidArray(props.patients.authModes)) {
      setAuthModes(props.patients.authModes);
    } else {
      setAuthModes([]);
    }
  }, [props.patients.authModes]);

  const profilePhoto = () => {
    if (isValidObject(healthIdData) && healthIdData.profilePhoto) {
      return `data:image/png;base64, ${healthIdData.profilePhoto}`;
    } else {
      return null;
    }
  };
  return (
    <>
      <Header
        supportIconOnClick={() => {
          props.navigate("/query");
        }}
        title="ABHA Number"
        backButtonOnClick={() => {
          props.navigate(-1);
        }}
        incident={props.app.featureFlags?.incident?.patients}
        incidentOnClick={() => {
          props.navigate("/incidents");
        }}
      />

      <ConditionalRender condition={!props.app.featureFlags?.abhaService}>
        <FeatureFlagsFallback height={"remaining-body-height"} />
      </ConditionalRender>

      <ConditionalRender condition={props.app.featureFlags?.abhaService}>
        <div className=" padding-larger border-bottom-1px-e5e5e5">
          <div className=" display-flex flex-align-items-center">
            <RoundedProfilePicture
              data-cy={`profilePic-${""}`}
              size={"medium"}
              src={profilePhoto()}
              alt=""
              loading={props.patients.loading}
            />

            <div className=" font-size-small padding-left-large font-color-secondary">
              <div>{healthIdData?.fullName ? healthIdData.fullName : " "}</div>
              <div className=" padding-top-small">
                ABHA Number -{" "}
                {healthIdData?.healthId ? healthIdData.healthId : "Nil"}
              </div>
              <div className=" padding-top-small">
                ABHA Address - {healthIdData?.id ? healthIdData.id : "Nil"}
              </div>
            </div>
          </div>

          <div className=" font-size-small display-flex flex-align-items-center flex-justify-content-center padding-top-large">
            <div>
              {healthIdData?.kycStatus && healthIdData?.kycStatus === "VERIFIED"
                ? "KYC Verified"
                : "Self Declared"}
            </div>
            <div className="display-flex flex-align-items-center padding-left-default">
              {healthIdData?.kycStatus &&
              healthIdData?.kycStatus === "VERIFIED" ? (
                <CircleWithTickIcon
                  circleColor={"white"}
                  circleOutline={"#00a000"}
                  tickColor={"#00a000"}
                />
              ) : (
                <ExclamatoryIcon />
              )}
            </div>
          </div>
        </div>

        <div className=" padding-horizontal-larger">
          {!healthIdData?.healthId && (
            <div
              className="display-flex flex-grow-1 flex-align-items-center padding-top-larger "
              data-cy="link-abha-address"
            >
              <div
                className="flex-grow-1 flex-direction-column display-flex font-color-secondary"
                onClick={() => {
                  props.setAuthModes(null);
                  setShowLinkModal(true);
                }}
              >
                <span className="font-size-medium font-family-gilroy-regular">
                  Link ABHA Number
                </span>
                <span className="font-size-small font-family-gilroy-regular text-transform-capitalize padding-top-small">
                  Link selected ABHA address with your ABHA number
                </span>
              </div>
              <ArrowIcon />
            </div>
          )}

          {healthIdData?.healthId && (
            <div
              className="display-flex flex-grow-1 flex-align-items-center padding-top-larger "
              data-cy="unLink-abha-address"
            >
              <div
                className="flex-grow-1 flex-direction-column display-flex font-color-secondary"
                onClick={() => {
                  props.setAuthModes(null);
                  setShowUnlinkModal(true);
                }}
              >
                <span className="font-size-medium font-family-gilroy-regular">
                  Unlink ABHA Number
                </span>
                <span className="font-size-small font-family-gilroy-regular text-transform-capitalize padding-top-small">
                  Unlink selected ABHA address from your ABHA number
                </span>
              </div>
              <ArrowIcon />
            </div>
          )}

          <div
            className="display-flex flex-grow-1 flex-align-items-center padding-top-larger "
            data-cy="create-abha-number"
          >
            <div
              className="flex-grow-1 flex-direction-column display-flex font-color-secondary"
              onClick={() => {
                props.setAuthModes(null);
                setShowCreateAbhaNumberModal(true);
                if (typeof props.abdm.transactionId === "string") {
                  props.setAbdmTransactionId(null);
                }
              }}
            >
              <span className="font-size-medium font-family-gilroy-regular">
                Create ABHA Number
              </span>
              <span className="font-size-small font-family-gilroy-regular text-transform-capitalize padding-top-small">
                Create a new ABHA number
              </span>
            </div>
            <ArrowIcon />
          </div>
        </div>
      </ConditionalRender>

      <Modal
        show={showUnlinkModal}
        onClose={() => {
          setShowUnlinkModal(false);
          setCounter(null);
          setRunning(false);
          setShowOtpBox(false);
          props.setTransactionId(null);
          props.setAuthModes(null);
        }}
        canIgnore={true}
        position="position-fixed bottom-0 left-0 right-0"
        boxShadow="none"
        width="inherit-parent-width"
        background="background-transparent"
        height="height-fit-to-content"
      >
        <div className="background-white padding-larger box-shadow-default font-color-secondary">
          <ConditionalRender
            condition={
              !isValidArray(authModes) &&
              typeof props.patients?.transaction?.id !== "string" &&
              props.patients.showSuccess?.healthId?.link === false &&
              props.patients.showSuccess?.healthId?.unLink === false
            }
          >
            <div className=" text-align-center font-family-gilroy-regular font-size-small ">
              CONFIRMATION
            </div>

            <div className=" text-align-center font-family-gilroy-regular padding-top-larger">
              Are you sure you want to unLink ABHA number{" "}
              {healthIdData?.healthId} from {healthIdData?.id} ?
            </div>

            <div className=" text-align-center font-family-gilroy-regular font-size-smaller font-color-red padding-top-large">
              You can share records through ABHA address even after unlinking
              ABHA number
            </div>

            <div className=" display-flex padding-top-larger">
              <Button
                text="Yes"
                boxShadow={false}
                required
                type="button"
                className={"margin-top-default margin-right-default"}
                data-cy={"create-new-demographic-button"}
                loading={props.patients.loading}
                onClick={() => {
                  // props.searchHealthIdAuthMode(healthIdData.id);
                  props.linkHealthIdNumber(
                    { abhaNumber: healthIdData.healthId },
                    "search"
                  );
                }}
              />
              <Button
                text="No"
                variant={"secondary"}
                boxShadow={false}
                required
                type="button"
                className={"margin-top-default margin-left-default"}
                data-cy={"cancel-create-demographic-button"}
                onClick={() => {
                  setShowUnlinkModal(false);
                }}
              />
            </div>
          </ConditionalRender>

          <ConditionalRender
            condition={
              isValidArray(authModes) &&
              !showOtpBox &&
              props.patients.showSuccess?.healthId?.link === false &&
              props.patients.showSuccess?.healthId?.unLink === false
            }
          >
            <div className=" text-align-center font-size-small padding-bottom-large">
              Validate ABHA Number by selecting any one of the options
            </div>
            <ConditionalRender condition={authModes?.includes("MOBILE_OTP")}>
              <div
                className="display-flex flex-grow-1 flex-align-items-center "
                data-cy="phone"
              >
                <div
                  className="flex-grow-1 flex-direction-column display-flex font-color-secondary"
                  onClick={() => {
                    setSelectedAuthMode("MOBILE_OTP");
                    // props.generateAbhaAddressOtp(healthIdData.id, "MOBILE_OTP");
                    props.linkHealthIdNumber(
                      {
                        abhaNumber: healthIdData.healthId,
                        authMethod: "MOBILE_OTP"
                      },
                      "init"
                    );
                    setShowOtpBox(true);
                  }}
                >
                  <span className="font-size-medium font-family-gilroy-regular">
                    Mobile OTP
                  </span>
                  <span className="font-size-small font-family-gilroy-regular text-transform-capitalize padding-top-small">
                    OTP will be sent to your registered Phone number
                  </span>
                </div>
                <ArrowIcon />
              </div>
            </ConditionalRender>

            <ConditionalRender condition={authModes?.includes("AADHAAR_OTP")}>
              <div
                className="display-flex flex-grow-1 flex-align-items-center padding-top-large "
                data-cy="Aadhar"
              >
                <div
                  className="flex-grow-1 flex-direction-column display-flex font-color-secondary"
                  onClick={() => {
                    setSelectedAuthMode("AADHAAR_OTP");
                    props.linkHealthIdNumber(
                      {
                        abhaNumber: healthIdData.healthId,
                        authMethod: "AADHAAR_OTP"
                      },
                      "init"
                    );
                    setShowOtpBox(true);
                  }}
                >
                  <span className="font-size-medium font-family-gilroy-regular">
                    Aadhar OTP
                  </span>
                  <span className="font-size-small font-family-gilroy-regular text-transform-capitalize padding-top-small">
                    OTP will be sent to your registered Aadhar number
                  </span>
                </div>
                <ArrowIcon />
              </div>
            </ConditionalRender>
          </ConditionalRender>

          <ConditionalRender
            condition={
              (typeof props.patients?.transaction?.id === "string" ||
                showOtpBox) &&
              props.patients.showSuccess?.healthId?.link === false &&
              props.patients.showSuccess?.healthId?.unLink === false
            }
          >
            <form
              onSubmit={(event) => {
                event.preventDefault();
                props.linkHealthIdNumber(
                  {
                    otp: OTP,
                    transactionId: props.patients?.transaction?.id
                  },
                  "verifyOtpDeLink"
                );
                setShowOtpBox(false);
              }}
            >
              <OTPInputBox
                name="otp"
                autoFocus={true}
                value={OTP}
                setValue={(otpValue) => {
                  setOTP(otpValue);
                }}
                required
                className="margin-bottom-large"
              />

              <div className="display-flex flex-justify-content-space-between font-size-medium margin-bottom-larger">
                <div>
                  {counter &&
                    typeof counter === "number" &&
                    `${
                      counter?.toString().length === 1 ? "0" : ""
                    }${counter?.toString()} seconds`}
                </div>
                <div
                  className={`${
                    counter || props.patients.loading
                      ? "font-color-tertiary"
                      : "font-color-primary"
                  }`}
                  onClick={() => {
                    props.linkHealthIdNumber(
                      {
                        abhaNumber: healthIdData.healthId,
                        authMethod: selectedAuthMode
                      },
                      "init"
                    );
                  }}
                >
                  Resend OTP
                </div>
              </div>

              <Button
                text="Continue"
                variant={"primary"}
                boxShadow={false}
                required
                type="submit"
                disabled={OTP.length !== 6 ? true : false}
                className={"margin-top-default margin-left-default"}
                data-cy={"Continue-button"}
                loading={props.patients.loading}
              />
            </form>
          </ConditionalRender>

          <ConditionalRender
            condition={
              props.patients.showSuccess?.healthId?.link === true ||
              props.patients.showSuccess?.healthId?.unLink === true
            }
          >
            <ProcessSuccess
              title={"ABHA number is unlinked from existing ABHA address"}
              description={`YOUR ABHA ADDRESS IS ${healthIdData?.id}`}
              confirmClick={() => {
                props.clearShowSuccess();
                setShowUnlinkModal(false);
              }}
            />
          </ConditionalRender>
        </div>
      </Modal>

      <Modal
        show={showLinkModal}
        onClose={() => {
          setShowLinkModal(false);
          setCounter(null);
          setRunning(false);
          setShowOtpBox(false);
          props.setTransactionId(null);
          props.setAuthModes(null);
        }}
        canIgnore={true}
        position="position-fixed bottom-0 left-0 right-0"
        boxShadow="none"
        width="inherit-parent-width"
        background="background-transparent"
        height="height-fit-to-content"
      >
        <div className="background-white padding-larger box-shadow-default font-color-secondary">
          <ConditionalRender
            condition={
              !isValidArray(authModes) &&
              typeof props.patients?.transaction?.id !== "string" &&
              props.patients.showSuccess?.healthId?.link === false &&
              props.patients.showSuccess?.healthId?.unLink === false
            }
          >
            <div className=" text-align-center font-family-gilroy-regular font-size-small text-uppercase">
              LINK ABHA NUMBER
            </div>

            <form
              onChange={(event) => {
                setAbhaNumber(event.target.value);
              }}
              onSubmit={(event) => {
                event.preventDefault();
                props.linkHealthIdNumber(
                  { abhaNumber: "abhaNumber" },
                  "search"
                );
              }}
            >
              <InputBox
                className="inherit-parent-width border-radius-default margin-vertical-large"
                name="abhaNumber"
                label="ABHA Number"
                value={abhaNumber}
                type="number"
                autoComplete="off"
                required
                data-cy="abha-number-field"
                validation={(value) => validation("abhaNumber", value)}
              />

              <Button
                text="Continue"
                variant="primary"
                boxShadow={false}
                required
                type="submit"
                disabled={abhaNumber.length !== 14 ? true : false}
                className={"margin-top-default"}
                data-cy={"Link-abha-address-button"}
                loading={props.patients.loading}
              />
            </form>
          </ConditionalRender>

          <ConditionalRender
            condition={
              isValidArray(authModes) &&
              !showOtpBox &&
              props.patients.showSuccess?.healthId?.link === false &&
              props.patients.showSuccess?.healthId?.unLink === false
            }
          >
            <ConditionalRender condition={authModes?.includes("MOBILE_OTP")}>
              <div
                className="display-flex flex-grow-1 flex-align-items-center "
                data-cy="phone"
              >
                <div
                  className="flex-grow-1 flex-direction-column display-flex font-color-secondary"
                  onClick={() => {
                    setSelectedAuthMode("MOBILE_OTP");
                    props.linkHealthIdNumber(
                      { abhaNumber: abhaNumber, authMethod: "MOBILE_OTP" },
                      "init"
                    );
                    setShowOtpBox(true);
                  }}
                >
                  <span className="font-size-medium font-family-gilroy-regular">
                    Mobile OTP
                  </span>
                  <span className="font-size-small font-family-gilroy-regular text-transform-capitalize padding-top-small">
                    OTP will be sent to your registered Phone number
                  </span>
                </div>
                <ArrowIcon />
              </div>
            </ConditionalRender>

            <ConditionalRender condition={authModes?.includes("AADHAAR_OTP")}>
              <div
                className="display-flex flex-grow-1 flex-align-items-center padding-top-large "
                data-cy="Aadhar"
              >
                <div
                  className="flex-grow-1 flex-direction-column display-flex font-color-secondary"
                  onClick={() => {
                    setSelectedAuthMode("AADHAAR_OTP");
                    props.linkHealthIdNumber(
                      { abhaNumber: abhaNumber, authMethod: "AADHAAR_OTP" },
                      "init"
                    );
                    setShowOtpBox(true);
                  }}
                >
                  <span className="font-size-medium font-family-gilroy-regular">
                    Aadhar
                  </span>
                  <span className="font-size-small font-family-gilroy-regular text-transform-capitalize padding-top-small">
                    OTP will be sent to your registered Aadhar number
                  </span>
                </div>
                <ArrowIcon />
              </div>
            </ConditionalRender>
          </ConditionalRender>

          <ConditionalRender
            condition={
              (typeof props.patients?.transaction?.id === "string" ||
                showOtpBox) &&
              props.patients.showSuccess?.healthId?.link === false &&
              props.patients.showSuccess?.healthId?.unLink === false
            }
          >
            <form
              onSubmit={(event) => {
                event.preventDefault();
                props.linkHealthIdNumber(
                  {
                    otp: OTP,
                    transactionId: props.patients?.transaction?.id
                  },
                  "verifyOtpLink"
                );
                setShowOtpBox(false);
              }}
            >
              <OTPInputBox
                name="otp"
                autoFocus={true}
                value={OTP}
                setValue={(otpValue) => {
                  setOTP(otpValue);
                }}
                required
                className="margin-bottom-large"
              />

              <div className="display-flex flex-justify-content-space-between font-size-medium margin-bottom-larger">
                <div>
                  {counter &&
                    typeof counter === "number" &&
                    `${
                      counter?.toString().length === 1 ? "0" : ""
                    }${counter?.toString()} seconds`}
                </div>
                <div
                  className={`${
                    counter || props.patients.loading
                      ? "font-color-tertiary"
                      : "font-color-primary"
                  }`}
                  onClick={() => {
                    if (!counter && !props.patients.loading) {
                      props.linkHealthIdNumber(
                        {
                          abhaNumber: abhaNumber,
                          authMethod: selectedAuthMode
                        },
                        "init"
                      );
                    }
                  }}
                >
                  Resend OTP
                </div>
              </div>

              <Button
                text="Continue"
                variant={"primary"}
                boxShadow={false}
                required
                type="submit"
                disabled={OTP.length !== 6 ? true : false}
                className={"margin-top-default margin-left-default"}
                data-cy={"Continue-button"}
                loading={props.patients.loading}
              />
            </form>
          </ConditionalRender>

          <ConditionalRender
            condition={
              props.patients.showSuccess?.healthId?.link === true ||
              props.patients.showSuccess?.healthId?.unLink === true
            }
          >
            <ProcessSuccess
              title={
                "Congratulations! Your ABHA number is now linked to your existing ABHA address. ABHA number is visible on your profile."
              }
              alternative={true}
              description={`YOUR ABHA ADDRESS IS ${healthIdData?.id}`}
              confirmClick={() => {
                props.clearShowSuccess();
                setShowLinkModal(false);
              }}
            />
          </ConditionalRender>
        </div>
      </Modal>

      <CreateAbhaNumber
        showModal={showCreateAbhaNumberModal}
        registerNewAbhaNumber={(data) => {
          props.registerNewAbhaNumber(data);
        }}
        getOtp={typeof props.abdm.transactionId === "string" ? true : false}
        transactionId={props.abdm.transactionId}
        getMobileNumber={
          typeof props.abdm.transactionId === "string" &&
          props.abdm.transactionId.includes("aadhaarVerified")
        }
        getMobileOtp={
          typeof props.abdm.transactionId === "string" &&
          props.abdm.transactionId.includes("getMobileOtp")
        }
        abhaNumberDetails={props.abdm.newHealthNumberData}
        loading={props.abdm.loading}
        closeModal={() => {
          props.setAuthModes(null);
          setShowCreateAbhaNumberModal(false);
          if (props.abdm.newHealthNumberData) {
            props.registerNewAbhaNumber({ clearNewHealthNumberData: true });
          }
        }}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  error: state.status,
  patients: state.patients,
  abdm: state.abdm,
  app: state.app
});

const mapDispatchToProps = function () {
  return {
    linkHealthIdNumber: (data, type, modal) =>
      linkHealthIdNumber(data, type, modal),
    searchHealthIdAuthMode: (abhaNumber) => searchHealthIdAuthMode(abhaNumber),
    generateAbhaAddressOtp: (healthId, mode) =>
      generateAbhaAddressOtp(healthId, mode),
    confirmLinkOrUnLinkAbhaAddressOtp: (
      transactionId,
      otp,
      mode,
      linkAddress,
      navigate
    ) =>
      confirmLinkOrUnLinkAbhaAddressOtp(
        transactionId,
        otp,
        mode,
        linkAddress,
        navigate
      ),
    registerNewAbhaNumber: (data) => registerNewAbhaNumber(data),
    setAuthModes: (data) => setAuthModes(data),
    clearShowSuccess: () => clearShowSuccess(),
    setTransactionId: (id) => setTransactionId(id),
    setAbdmTransactionId: (id) => setAbdmTransactionId(id)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AbhaNumber);
