import React from "react";
import { connect } from "react-redux";
import accessibleIcon from "../../assets/icons/accessible.svg";
import nextIconGreen from "../../assets/icons/nextIconGreen.svg";
import phrIcon from "../../assets/icons/phr.svg";
import unificationIcon from "../../assets/icons/unification.svg";
import uniqueIdentityIcon from "../../assets/icons/uniqueIdentity.svg";
import ConditionalRender from "../../front-end-global-components/components/ConditionalRender/ConditionalRender";
import FeatureFlagsFallback from "../../front-end-global-components/components/FeatureFlagsFallback/FeatureFlagsFallback";
import Header from "../../front-end-global-components/components/Header/Header";
import "./Abdm.css";
import {
  clearHealthIdCreatedStatus,
  setTransactionId
} from "../../redux/patients/actions";

const AbdmAuthentication = (props) => {
  return (
    <>
      <Header
        title="Link your ABHA address"
        backButtonOnClick={() => {
          props.navigate("/settings");
        }}
        hideSupport={true}
        incident={props.app.featureFlags?.incident?.patients}
        incidentOnClick={() => {
          props.navigate("/incidents");
        }}
      />
      <section className="remaining-body-height max-width-588px margin-horizontal-auto overflow-y-auto inherit-parent-width ">
        <ConditionalRender condition={!props.app.featureFlags.abhaService}>
          <FeatureFlagsFallback />
        </ConditionalRender>

        <ConditionalRender condition={props.app?.featureFlags?.abhaService}>
          <section className="display-flex flex-justify-content-center padding-top-larger padding-right-larger padding-left-larger">
            <div
              className="cursor-pointer display-flex flex-direction-column flex-align-items-center width-fit-content padding-horizontal-large border-1px-solid-f1f1f1 border-radius-default margin-right-larger"
              data-cy="healthId-login"
              onClick={() => {
                props.setTransactionId(null);
                props.navigate("/abdm/login/verify?force=true");
              }}
            >
              <div className="display-flex flex-align-items-center padding-top-larger padding-bottom-small ">
                <span className="font-size-medium font-family-gilroy-medium font-color-primary  padding-right-default">
                  Login
                </span>
                <img src={nextIconGreen} alt="" height="10" width="6" />
              </div>
              <div className="font-size-small font-family-gilroy-regular font-color-secondary padding-bottom-larger text-align-center padding-left-small padding-right-small">
                Existing ABHA Address
              </div>
            </div>

            <div
              className="cursor-pointer display-flex flex-direction-column flex-align-items-center width-fit-content padding-bottom-small  padding-horizontal-large border-1px-solid-f1f1f1 border-radius-default"
              data-cy="healthId-register"
              onClick={() => {
                props.setTransactionId(null);
                props.clearHealthIdCreatedStatus();
                props.navigate("/abdm/register/verify");
              }}
            >
              <div className="display-flex flex-align-items-center padding-top-larger padding-bottom-small ">
                <span className="font-size-medium font-family-gilroy-medium font-color-primary padding-right-default">
                  Create
                </span>
                <img src={nextIconGreen} alt="" height="10" width="6" />
              </div>
              <div className="font-size-small font-family-gilroy-regular font-color-secondary padding-bottom-larger text-align-center padding-left-default padding-right-default">
                New ABHA Address
              </div>
            </div>
          </section>
          <div className="text-align-center letter-spacing-large font-family-gilroy-regular font-size-small font-color-secondary  padding-top-larger margin-top-larger">
            ABHA Address - benefits
          </div>
          {HealthIdBenefitsArray.map((tile) => (
            <div key={tile.title}>
              <HealthIdBenefitsTile
                src={tile.src}
                alt={tile.alt}
                title={tile.title}
                description={tile.desc}
              />
            </div>
          ))}
        </ConditionalRender>
      </section>
    </>
  );
};

const mapStateToProps = (state) => ({
  app: state.app
});

const mapDispatchToProps = function () {
  return {
    setTransactionId: (data) => setTransactionId(data),
    clearHealthIdCreatedStatus: (data) => clearHealthIdCreatedStatus(data)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AbdmAuthentication);

const HealthIdBenefitsTile = (props) => {
  return (
    <section className="display-flex flex-align-items-center padding-large">
      <div className="padding-medium border-radius-default display-flex flex-align-items-center">
        <img
          src={props.src}
          alt={props.alt}
          className="health-id-benefits-icons-size"
        />
      </div>
      <div className="padding-left-large">
        <div className="font-family-gilroy-regular font-size-medium font-color-secondary padding-bottom-small ">
          {props.title}
        </div>
        <div className="font-family-gilroy-regular font-size-small font-color-secondary ">
          {props.description}
        </div>
      </div>
    </section>
  );
};

const HealthIdBenefitsArray = [
  {
    src: uniqueIdentityIcon,
    alt: "",
    title: "Unique identity",
    desc: "Unique identity across different healthcare providers within the healthcare ecosystem"
  },
  {
    src: unificationIcon,
    alt: "",
    title: "Unification",
    desc: "Link all healthcare benefits to your unique ABHA number"
  },
  {
    src: accessibleIcon,
    alt: "",
    title: "Accessible",
    desc: "Avoid spending time & energy for registration in healthcare facilities across the country"
  },
  {
    src: phrIcon,
    alt: "",
    title: "Easy PHR",
    desc: "Seamless Personal Health Records connection such as Ninto for Health data sharing"
  }
];
