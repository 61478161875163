const patientsReducer = (
  state = {
    abhaNumberData: null,
    accessToken: null,
    activeMenu: null,
    authModes: null,
    autoApprovalPolicies: null,
    careContexts: null,
    currentProfile: null,
    healthIdData: null,
    isMultipleLogin: false,
    isHealthIdCreated: false,
    linkingToken: null,
    linkedProfiles: null,
    loading: false,
    profiles: null,
    requests: null,
    requestsFilter: {
      clinics: [],
      filterType: "",
      requests: [],
      status: []
    },
    showSuccess: {
      healthId: {
        created: false,
        link: false,
        unLink: false
      },
      consentPin: {
        forget: false,
        create: false
      }
    },
    selectedLinkedFacility: null,
    subscription: null,
    transaction: { id: null, phoneNumber: null },
    unusedHealthId: null,
    usedHealthId: null
  },

  action
) => {
  switch (action.type) {
    case "SET_PATIENTS_LOADING":
      return {
        ...state,
        loading: action.payload.loading
      };

    case "SET_PATIENTS_AUTH_TYPE":
      return {
        ...state,
        authType: action.payload.authMode
      };

    case "SET_LINKED_PROFILES":
      return {
        ...state,
        linkedProfiles: action.payload.linkedProfiles
      };

    case "SET_RECENTLY_CREATED_ABHA_ID_DATA":
      return {
        ...state,
        recentlyCreatedABHAIdData: action.payload.recentlyCreatedABHAIdData
      };

    case "SET_HEALTH_ID_CREATED":
      return {
        ...state,
        isHealthIdCreated: action.payload.data
      };

    case "SET_IS_MULTIPLE_LOGIN":
      return {
        ...state,
        isMultipleLogin: action.payload.data
      };

    case "SET_SHOW_SUCCESS":
      return {
        ...state,
        showSuccess: { ...state.showSuccess, ...action.payload.data }
      };

    case "SET_PATIENTS_LOGIN_TYPE":
      return {
        ...state,
        loginType: action.payload.loginType
      };

    case "SET_TRANSACTION_ID":
      return {
        ...state,
        transaction: {
          id: action.payload.transactionId,
          phoneNumber: action.payload.phoneNumber
        }
      };
    case "SET_PATIENTS_DEMOGRAPHICS":
      return {
        ...state,
        profiles: action.payload
      };

    case "SET_CURRENT_PATIENT_PROFILE_ID":
      return {
        ...state,
        currentProfile: action.payload.currentProfile
      };

    case "SET_ABDM_ACCESS_TOKEN":
      return {
        ...state,
        accessToken: action.payload.abdmAccessToken
      };

    case "PUT_AUTH_MODE":
      return {
        ...state,
        authModes: action.payload.data
      };

    case "SET_ABHA_NUMBER_DATA":
      return {
        ...state,
        abhaNumberData: action.payload.data
      };

    case "REMOVE_ABDM_ACCESS_TOKEN": {
      const currentAccessTokens = JSON.parse(JSON.stringify(state.accessToken));
      delete currentAccessTokens[action.payload.patientId];
      return {
        ...state,
        accessToken: currentAccessTokens ? currentAccessTokens : null
      };
    }
    case "SET_LINKING_TOKEN_DETAILS":
      return {
        ...state,
        linkingToken: action.payload.linkingTokenData
      };

    case "SET_ABDM_UNUSED_HEALTH_ID":
      return {
        ...state,
        unusedHealthId: action.payload.healthId
      };

    case "SET_ABDM_USED_HEALTH_ID":
      return {
        ...state,
        usedHealthId: action.payload.healthId
      };

    case "SET_PATIENTS_REQUESTS":
      return {
        ...state,
        requests: action.payload
      };

    case "SET_PATIENTS_CARE_CONTEXTS":
      return {
        ...state,
        careContexts: action.payload
      };

    case "SET_PATIENT_SUBSCRIPTION":
      return {
        ...state,
        subscription: action.payload.subscription
      };

    case "SET_AUTO_APPROVAL_POLICIES":
      return {
        ...state,
        autoApprovalPolicies: action.payload.autoApprovalPolicies
      };

    case "SET_HEALTH_ID_INFORMATION":
      return {
        ...state,
        healthIdData: {
          ...state.healthIdData,
          ...action.payload.data
        }
      };

    case "SET_FILTER_TYPE":
      return {
        ...state,
        requestsFilter: {
          ...state.requestsFilter,
          filterType: action.payload.id
        }
      };

    case "SET_CLINICS_FILTER":
      return {
        ...state,
        requestsFilter: {
          ...state.requestsFilter,
          clinics: action.payload.clinics
        }
      };

    case "SET_REQUESTS_FILTER":
      return {
        ...state,
        requestsFilter: {
          ...state.requestsFilter,
          requests: action.payload.requests
        }
      };

    case "SET_STATUS_FILTER":
      return {
        ...state,
        requestsFilter: {
          ...state.requestsFilter,
          status: action.payload.status
        }
      };

    case "SET_SELECTED_LINKED_FACILITY":
      return {
        ...state,
        selectedLinkedFacility: action.payload.linkedFacilityId
      };

    case "SET_ACTIVE_SETTINGS_MENU":
      return {
        ...state,
        activeMenu: action.payload.menu
      };

    case "CLEAR_REQUEST_FILTER":
      return {
        ...state,
        requestsFilter: {
          filterType: "",
          clinics: [],
          requests: [],
          status: []
        }
      };

    case "FORCE_CLEAR_PATIENTS":
      return {
        ...state,
        healthIdData: null,
        accessToken: null,
        linkingToken: null,
        careContexts: null,
        selectedLinkedFacility: null,
        usedHealthId: null,
        unusedHealthId: null,
        authModes: null,
        abhaNumberData: null,
        requests: null,
        subscription: null,
        autoApprovalPolicies: null,
        showSuccess: {
          healthId: {
            created: false,
            link: false,
            unLink: false
          },
          consentPin: {
            forget: false,
            create: false
          }
        },
        requestsFilter: {
          filterType: "",
          clinics: [],
          requests: [],
          status: []
        }
      };

    case "RESET":
      return {
        loading: false,
        transactionId: null,
        profiles: null,
        currentProfile: null,
        activeMenu: null,
        healthIdData: null,
        accessToken: null,
        linkingToken: null,
        careContexts: null,
        selectedLinkedFacility: null,
        usedHealthId: null,
        unusedHealthId: null,
        authModes: null,
        abhaNumberData: null,
        requests: null,
        subscription: null,
        autoApprovalPolicies: null,
        showSuccess: {
          healthId: {
            created: false,
            link: false,
            unLink: false
          },
          consentPin: {
            forget: false,
            create: false
          }
        },
        requestsFilter: {
          filterType: "",
          clinics: [],
          requests: [],
          status: []
        }
      };
    default:
      return state;
  }
};

export default patientsReducer;
