import { all, put, takeEvery } from "@redux-saga/core/effects";
import { setErrorStatus } from "../status/actions";
import store from "../store/store";

export const appActionTypes = {
  PUT_ONLINE: "PUT_ONLINE",
  PUT_NOTIFICATION_PERMISSION: "PUT_NOTIFICATION_PERMISSION",
  PUT_FEATURE_FLAGS: "PUT_FEATURE_FLAGS",
  PUT_INCIDENTS: "PUT_INCIDENTS",
  PUT_SELECTED_INCIDENTS: "PUT_SELECTED_INCIDENTS"
};

function* putOnlineWorker(action) {
  try {
    if (store.getState().app.online !== action.payload.online) {
      yield put({
        type: "SET_ONLINE",
        payload: {
          online: action.payload.online
        }
      });
    }
  } catch (error) {
    setErrorStatus(error);
  }
}

function* putNotificationWorker(action) {
  try {
    yield put({
      type: "SET_NOTIFICATION_PERMISSION",
      payload: {
        notification: action.payload.notification === "true" ? true : false
      }
    });

    action.payload.navigate("/");
  } catch (error) {
    setErrorStatus(error);
  }
}

function* setFeatureFlagsWorker(action) {
  try {
    yield put({
      type: "SET_FEATURE_FLAGS",
      payload: {
        featureFlags: action.payload.featureFlags
      }
    });
  } catch (error) {
    setErrorStatus(error);
  }
}

function* setIncidentsWorker(action) {
  try {
    yield put({
      type: "SET_INCIDENTS",
      payload: {
        incidents: action.payload.incidents
      }
    });
  } catch (error) {
    setErrorStatus(error);
  }
}

function* setSelectedIncidentWorker(action) {
  try {
    yield put({
      type: "SET_SELECTED_INCIDENTS",
      payload: {
        incidentId: action.payload.incidentId
      }
    });
  } catch (error) {
    setErrorStatus(error);
  }
}

export default function* appWatcher() {
  yield all([
    takeEvery("PUT_ONLINE", putOnlineWorker),
    takeEvery("PUT_NOTIFICATION_PERMISSION", putNotificationWorker),
    takeEvery("PUT_FEATURE_FLAGS", setFeatureFlagsWorker),
    takeEvery("PUT_INCIDENTS", setIncidentsWorker),
    takeEvery("PUT_SELECTED_INCIDENTS", setSelectedIncidentWorker)
  ]);
}
