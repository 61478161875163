import { useEffect } from "react";

export default function HandleBackClick(props) {
  function handleClose() {
    return props?.onClose();
  }

  useEffect(() => {
    window.addEventListener("popstate", handleClose);

    return () => {
      window.removeEventListener("popstate", handleClose);

      if (window.history.state === "fake-route") {
        window.history.back();
      }
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (props.isBackEnabled) {
      window.history.pushState(
        "fake-route",
        document.title,
        window.location.href
      );
    }
  }, [props.isBackEnabled]);

  return props.children;
}
