import store from "../store/store";
import { all, put, takeEvery } from "@redux-saga/core/effects";
import { bucketNames, getFileUrl } from "../../services/storage";
import { isValidArray, isValidObject } from "../../utils/validators";
import { validDocumentType } from "../../utils/constants";
import { setErrorStatus } from "../status/actions";

export const downloadsActionTypes = {
  GET_PROFILE_PICTURE_DOWNLOAD_URL: "GET_PROFILE_PICTURE_DOWNLOAD_URL",
  GET_DOCUMENT_DOWNLOAD_URL: "GET_DOCUMENT_DOWNLOAD_URL"
};

function* getProfilePicturesUrlWorker(action) {
  try {
    yield put({
      type: "SET_DOWNLOADS_LOADING",
      payload: {
        loading: true
      }
    });
    if (
      typeof action.payload.profileType === "string" &&
      (action.payload.uid
        ? typeof action.payload.uid === "string" &&
          action.payload.uid.length > 0
        : true) &&
      typeof action.payload.profileId === "string"
    ) {
      switch (action.payload.profileType) {
        case "patient":
          {
            const url = yield getFileUrl(
              `gs://${bucketNames.nintoProfilePictures}/patients/${
                store.getState().auth.data.phoneNumber
              }/${action.payload.profileId}.png`
            );
            yield put({
              type: "SET_PATIENT_PROFILE_PICTURE_URL",
              payload: {
                [action.payload.profileId]: url
              }
            });
          }
          break;

        case "doctor":
          {
            const url = yield getFileUrl(
              `gs://${bucketNames.nintoProfilePictures}/doctors/${action.payload.profileId}.png`
            );
            yield put({
              type: "SET_DOCTOR_PROFILE_PICTURE_URL",
              payload: {
                [action.payload.profileId]: url
              }
            });
          }
          break;

        case "clinic":
          {
            const url = yield getFileUrl(
              `gs://${bucketNames.nintoProfilePictures}/clinics/${action.payload.profileId}.png`
            );
            yield put({
              type: "SET_CLINIC_PROFILE_PICTURE_URL",
              payload: {
                [action.payload.profileId]: url
              }
            });
          }
          break;

        default:
          console.warn("invalid profile type for profile picture");
          break;
      }
    }
    yield put({
      type: "SET_DOWNLOADS_LOADING",
      payload: {
        loading: false
      }
    });
  } catch (error) {
    yield put({
      type: "SET_DOWNLOADS_LOADING",
      payload: {
        loading: false
      }
    });
    if (error.message.includes("storage/object-not-found")) {
      yield put({
        type: "SET_PROFILE_PRICTURES_URL",
        payload: {
          [action.payload.profileId]: null
        }
      });
    } else {
      setErrorStatus(error);
    }
  }
}

function* getDocumentsDownloadUrl(action) {
  try {
    yield put({
      type: "SET_DOWNLOADS_LOADING",
      payload: {
        loading: true
      }
    });
    //check the document data and payloads are valid
    if (
      store.getState().documents.latest === true &&
      typeof action.payload.documentId === "string" &&
      isValidObject(store.getState().documents.data)
    ) {
      const document =
        store.getState().documents.data[action.payload.documentId];
      const documentUrl =
        validDocumentType(document.documentType) === "symptoms"
          ? document.url &&
            typeof document.url.audio === "string" &&
            document.url.audio
          : typeof document.url === "string"
          ? document.url
          : "";
      let url;
      if (documentUrl.length > 0) {
        url = yield getFileUrl(documentUrl);
      }

      //mock reports data to compare previous
      let reports = store.getState().downloads.data.reports
        ? store.getState().downloads.data.reports
        : {};
      //mock symptoms data to compare previous
      let symptoms = store.getState().downloads.data.symptoms
        ? store.getState().downloads.data.symptoms
        : {};
      //mock prescriptions data to compare previous
      let prescriptions = store.getState().downloads.data.prescriptions
        ? store.getState().downloads.data.prescriptions
        : {};
      //mock insurances data to compare previous
      let insurances = store.getState().downloads.data.insurances
        ? store.getState().downloads.data.insurances
        : {};
      //mock admissions data to compare previous
      let admissions = store.getState().downloads.data.admissions
        ? store.getState().downloads.data.admissions
        : {};
      //mock receipts data to compare previous
      let receipts = store.getState().downloads.data.receipts
        ? store.getState().downloads.data.receipts
        : {};
      //mock vital data to compare previous
      let vital = store.getState().downloads.data.vital
        ? store.getState().downloads.data.vital
        : {};
      //mock consultation data to compare previous
      let vaccine = store.getState().downloads.data.vaccine
        ? store.getState().downloads.data.vaccine
        : {};
      //mock consultation data to compare previous
      let consultation = store.getState().downloads.data.consultation
        ? store.getState().downloads.data.consultation
        : {};
      //mock health document record to compare previous
      let others = store.getState().downloads.data.others
        ? store.getState().downloads.data.others
        : {};
      validDocumentType(document.documentType);
      //dispatches reports url
      if (
        validDocumentType(document.documentType) === "reports" &&
        (!reports.hasOwnProperty(action.payload.documentId) ||
          (reports.hasOwnProperty(action.payload.documentId) &&
            typeof reports[action.payload.documentId] !== "string"))
      ) {
        //getting download url if notes exists in reports
        let newNotesObj = {};
        if (isValidObject(document.notes)) {
          const doctorsId = document.notes;
          //Looping all doctors in Notes (with doctorsId) and accessing pages
          for (const doctorId in doctorsId) {
            const splittedString = document.url.split("/");
            const defaultPdfName = splittedString[splittedString.length - 1];
            const convertedUrlPath = document.url.replace(
              defaultPdfName,
              `${doctorId}.pdf`
            );
            const downloadUrl = yield getFileUrl(convertedUrlPath);
            //Creating notes Object
            newNotesObj[doctorId] = downloadUrl;
          }
        }
        reports[action.payload.documentId] = {
          downloadUrl: url,
          notes: isValidObject(newNotesObj) ? newNotesObj : null
        };

        // reports[action.payload.documentId] = url;
        yield put({
          type: "SET_REPORTS_URL",
          payload: {
            reportsUrl: reports
          }
        });
      }
      //dispatches symptoms url
      else if (
        validDocumentType(document.documentType) === "symptoms" &&
        (!symptoms.hasOwnProperty(action.payload.documentId) ||
          (symptoms.hasOwnProperty(action.payload.documentId) &&
            typeof symptoms[action.payload.documentId] !== "string"))
      ) {
        let newImagesList = [];
        if (isValidObject(document.url) && isValidArray(document.url.images)) {
          const imageUrls = document.url.images;
          //Looping all doctors in Notes (with doctorsId) and accessing pages
          for (const imageUrl in imageUrls) {
            const downloadUrl = yield getFileUrl(imageUrls[imageUrl]);
            //Creating notes Object
            newImagesList = [...newImagesList, downloadUrl];
          }
        }

        symptoms[action.payload.documentId] = {
          downloadUrl: url,
          notes: null,
          images: newImagesList
        };
        yield put({
          type: "SET_SYMPTOMS_URL",
          payload: {
            symptomsUrl: symptoms
          }
        });
      }
      //dispatches prescriptions url
      else if (
        validDocumentType(document.documentType) === "prescriptions" &&
        (!prescriptions.hasOwnProperty(action.payload.documentId) ||
          (prescriptions.hasOwnProperty(action.payload.documentId) &&
            typeof prescriptions[action.payload.documentId] !== "string"))
      ) {
        prescriptions[action.payload.documentId] = {
          downloadUrl: url,
          notes: null
        };
        yield put({
          type: "SET_PRESCRIPTIONS_URL",
          payload: {
            prescriptionsUrl: prescriptions
          }
        });
      } else if (
        validDocumentType(document.documentType) === "insurances" &&
        (!insurances.hasOwnProperty(action.payload.documentId) ||
          (insurances.hasOwnProperty(action.payload.documentId) &&
            typeof insurances[action.payload.documentId] !== "string"))
      ) {
        insurances[action.payload.documentId] = {
          downloadUrl: url,
          notes: null
        };
        yield put({
          type: "SET_INSURANCES_URL",
          payload: {
            insurancesUrl: insurances
          }
        });
      } else if (
        validDocumentType(document.documentType) === "admissions" &&
        (!admissions.hasOwnProperty(action.payload.documentId) ||
          (admissions.hasOwnProperty(action.payload.documentId) &&
            typeof admissions[action.payload.documentId] !== "string"))
      ) {
        admissions[action.payload.documentId] = {
          downloadUrl: url,
          notes: null
        };
        yield put({
          type: "SET_ADMISSIONS_URL",
          payload: {
            admissionsUrl: admissions
          }
        });
      } else if (
        validDocumentType(document.documentType) === "receipts" &&
        (!receipts.hasOwnProperty(action.payload.documentId) ||
          (receipts.hasOwnProperty(action.payload.documentId) &&
            typeof receipts[action.payload.documentId] !== "string"))
      ) {
        receipts[action.payload.documentId] = {
          downloadUrl: url,
          notes: null
        };
        yield put({
          type: "SET_RECEIPTS_URL",
          payload: {
            receiptsUrl: receipts
          }
        });
      } else if (
        validDocumentType(document.documentType) === "vital" &&
        (!vital.hasOwnProperty(action.payload.documentId) ||
          (vital.hasOwnProperty(action.payload.documentId) &&
            typeof vital[action.payload.documentId] !== "string"))
      ) {
        //getting download url if notes exists in vital
        let newNotesObj = {};
        if (isValidObject(document.notes)) {
          const doctorsId = document.notes;
          //Looping all doctors in Notes (with doctorsId) and accessing pages
          for (const doctorId in doctorsId) {
            const splittedString = document.url.split("/");
            const defaultPdfName = splittedString[splittedString.length - 1];
            const convertedUrlPath = document.url.replace(
              defaultPdfName,
              `${doctorId}.pdf`
            );
            const downloadUrl = yield getFileUrl(convertedUrlPath);
            //Creating notes Object
            newNotesObj[doctorId] = downloadUrl;
          }
        }
        vital[action.payload.documentId] = {
          downloadUrl: url,
          notes: isValidObject(newNotesObj) ? newNotesObj : null
        };

        // vital[action.payload.documentId] = url;
        yield put({
          type: "SET_VITAL_URL",
          payload: {
            vital: vital
          }
        });
      } else if (
        validDocumentType(document.documentType) === "vaccine" &&
        (!vaccine.hasOwnProperty(action.payload.documentId) ||
          (vaccine.hasOwnProperty(action.payload.documentId) &&
            typeof vaccine[action.payload.documentId] !== "string"))
      ) {
        //getting download url if notes exists in vaccine
        let newNotesObj = {};
        if (isValidObject(document.notes)) {
          const doctorsId = document.notes;
          //Looping all doctors in Notes (with doctorsId) and accessing pages
          for (const doctorId in doctorsId) {
            const splittedString = document.url.split("/");
            const defaultPdfName = splittedString[splittedString.length - 1];
            const convertedUrlPath = document.url.replace(
              defaultPdfName,
              `${doctorId}.pdf`
            );
            const downloadUrl = yield getFileUrl(convertedUrlPath);
            //Creating notes Object
            newNotesObj[doctorId] = downloadUrl;
          }
        }
        vaccine[action.payload.documentId] = {
          downloadUrl: url,
          notes: isValidObject(newNotesObj) ? newNotesObj : null
        };

        // vital[action.payload.documentId] = url;
        yield put({
          type: "SET_VACCINE_URL",
          payload: {
            vaccine: vaccine
          }
        });
      } else if (
        validDocumentType(document.documentType) === "consultation" &&
        (!consultation.hasOwnProperty(action.payload.documentId) ||
          (consultation.hasOwnProperty(action.payload.documentId) &&
            typeof consultation[action.payload.documentId] !== "string"))
      ) {
        //getting download url if notes exists in consultation
        let newNotesObj = {};
        if (isValidObject(document.notes)) {
          const doctorsId = document.notes;
          //Looping all doctors in Notes (with doctorsId) and accessing pages
          for (const doctorId in doctorsId) {
            const splittedString = document.url.split("/");
            const defaultPdfName = splittedString[splittedString.length - 1];
            const convertedUrlPath = document.url.replace(
              defaultPdfName,
              `${doctorId}.pdf`
            );
            const downloadUrl = yield getFileUrl(convertedUrlPath);
            //Creating notes Object
            newNotesObj[doctorId] = downloadUrl;
          }
        }
        consultation[action.payload.documentId] = {
          downloadUrl: url,
          notes: isValidObject(newNotesObj) ? newNotesObj : null
        };

        // vital[action.payload.documentId] = url;
        yield put({
          type: "SET_CONSULTATION_URL",
          payload: {
            consultation: consultation
          }
        });
      } else if (
        validDocumentType(document.documentType) === "others" &&
        (!others.hasOwnProperty(action.payload.documentId) ||
          (others.hasOwnProperty(action.payload.documentId) &&
            typeof others[action.payload.documentId] !== "string"))
      ) {
        //getting download url if notes exists in others
        let newNotesObj = {};
        if (isValidObject(document.notes)) {
          const doctorsId = document.notes;
          //Looping all doctors in Notes (with doctorsId) and accessing pages
          for (const doctorId in doctorsId) {
            const splittedString = document.url.split("/");
            const defaultPdfName = splittedString[splittedString.length - 1];
            const convertedUrlPath = document.url.replace(
              defaultPdfName,
              `${doctorId}.pdf`
            );
            const downloadUrl = yield getFileUrl(convertedUrlPath);
            //Creating notes Object
            newNotesObj[doctorId] = downloadUrl;
          }
        }
        others[action.payload.documentId] = {
          downloadUrl: url,
          notes: isValidObject(newNotesObj) ? newNotesObj : null
        };

        // vital[action.payload.documentId] = url;
        yield put({
          type: "SET_OTHERS_URL",
          payload: {
            others: others
          }
        });
      } else return;
    } else return;
    yield put({
      type: "SET_DOWNLOADS_LOADING",
      payload: {
        loading: false
      }
    });
  } catch (error) {
    yield put({
      type: "SET_DOWNLOADS_LOADING",
      payload: {
        loading: false
      }
    });
    setErrorStatus(error);
  }
}

export default function* downloadsWatcher() {
  yield all([
    takeEvery("GET_PROFILE_PICTURE_DOWNLOAD_URL", getProfilePicturesUrlWorker),
    takeEvery("GET_DOCUMENT_DOWNLOAD_URL", getDocumentsDownloadUrl)
  ]);
}
