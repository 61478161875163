import { put, takeEvery, all } from "redux-saga/effects";
import {
  addSymptom,
  createVitals,
  updateForm,
  updateSymptom
} from "../../services/database";
import { throwError } from "../../services/error";
import { uploadFile } from "../../services/storage";
import { documentSchema } from "../../utils/models";
import {
  isValidArray,
  isValidProfileId,
  isValidObject
} from "../../utils/validators";
import { setErrorStatus, setSuccessStatus } from "../status/actions";
import store from "../store/store";
import { retryDataTransfer, uploadDocument } from "../../services/api";
import { validDocumentType } from "../../utils/constants";
import { getPatientsCareContexts } from "../patients/actions";

export const documentsActionTypes = {
  ADD_DOCUMENTS: "ADD_DOCUMENTS",
  ADD_SYMPTOM: "ADD_SYMPTOM",
  UPDATE_SYMPTOM: "UPDATE_SYMPTOM",
  UPLOAD_DOCUMENT: "UPLOAD_DOCUMENT",
  ADD_CURRENT_DOCUMENT: "ADD_CURRENT_DOCUMENT",
  REMOVE_CURRENT_DOCUMENT: "REMOVE_CURRENT_DOCUMENT",
  ADD_DOCUMENT_FILTER: "ADD_DOCUMENT_FILTER",
  ADD_DATA_TRANSFER: "ADD_DATA_TRANSFER",
  REMOVE_DOCUMENT_FILTER: "REMOVE_DOCUMENT_FILTER",
  CREATE_VITALS: "CREATE_VITALS",
  UPDATE_FORM: "UPDATE_FORM",
  RETRY_DATA_TRANSFER: "RETRY_DATA_TRANSFER"
};

//set documents listener
function* setDocumentsWorker(action) {
  try {
    //Documents data for all profiles
    if (Array.isArray(action.payload.documents)) {
      //Sort documents data with corresponding profileId's
      let documents = {};

      action.payload.documents.forEach((data) => {
        documents = { ...documents, [data.documentId]: data };
      });

      yield put({
        type: "SET_DOCUMENTS_DATA",
        payload: {
          documentsData: documents
        }
      });
    }
  } catch (error) {
    setErrorStatus(error);
  }
}

//set dataTransfer listener
function* setDataTransfersWorker(action) {
  try {
    //Documents data for all profiles
    if (Array.isArray(action.payload.dataTransfer)) {
      //Sort documents data with corresponding profileId's
      let dataTransfers = {};

      action.payload.dataTransfer.forEach((data) => {
        dataTransfers = { ...dataTransfers, [data.documentId]: data };
      });

      yield put({
        type: "SET_DATA_TRANSFER_DATA",
        payload: {
          dataTransfersData: dataTransfers
        }
      });
    }
  } catch (error) {
    setErrorStatus(error);
  }
}

//upload document worker
function* uploadDocumentWorker(action) {
  try {
    yield setDocumentLoading(true);

    const documentType = validDocumentType(action.payload.data.documentType);
    const documentName = action.payload.data.documentName;
    const issueDate = `${action.payload.data.date} ${action.payload.data.time}`;
    const accessToken =
      store.getState().patients.accessToken[
        store.getState().patients?.currentProfile
      ];
    const firebaseToken = store.getState().auth.data.accessToken;
    let data = new FormData();

    let response;

    if (action.payload.data.file.type === "image") {
      if (isValidArray(action.payload.data.file.src)) {
        for (let i = 0; i < action.payload.data.file.src.length; i++) {
          data.append("files", action.payload.data.file.src[i]);
        }
        data.append("issueDate", issueDate);
        data.append("documentType", documentType);
        data.append("documentName", documentName);
        data.append("fileConversion", true);

        response = yield uploadDocument(data, firebaseToken, accessToken);
      } else {
        throw throwError("custom", "Invalid File");
      }
    } else {
      data.append("issueDate", issueDate);
      data.append("files", action.payload.data.file);
      data.append("documentType", documentType);
      data.append("documentName", documentName);
      data.append("fileConversion", true);

      response = yield uploadDocument(data, firebaseToken, accessToken);
    }

    if (response?.success) {
      setSuccessStatus("Document uploaded successfully");
      getPatientsCareContexts();
    }

    if (response?.error && response.error.name === "requestError") {
      setErrorStatus({ code: "custom", message: response.error.message });
    } else {
      throw response.error;
    }

    yield setDocumentLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield setDocumentLoading(false);
  }
}

//add symptom worker
function* addSymptomWorker(action) {
  try {
    yield setDocumentLoading(true);
    const pid = store.getState().profile.currentProfile;
    const data = store.getState().profile.data;
    const profile = data.hasOwnProperty(pid) && data[pid];

    if (isValidProfileId(pid) && isValidObject(profile)) {
      if (
        isValidObject(action.payload.data.url) &&
        typeof action.payload.data.url.audio === "object"
      ) {
        const metaData = {
          uploaderId: pid,
          uploaderUserName: profile.public.fullName,
          uploaderUserType: "patient"
        };
        const response = yield uploadFile(
          profile.phoneNumber,
          pid,
          "symptoms",
          [action.payload.data.url.audio],
          metaData
        );
        response
          ? (action.payload.data.url.audio = `gs://${response.metadata.bucket}/${response.metadata.fullPath}`)
          : delete action.payload.data.url.audio;
      }
      if (
        isValidObject(action.payload.data.url) &&
        isValidArray(action.payload.data.url.images)
      ) {
        const metaData = {
          uploaderId: pid,
          uploaderUserName: profile.public.fullName,
          uploaderUserType: "patient"
        };
        let imagesUrl = [];
        for (
          let index = 0;
          index < action.payload.data.url.images.length;
          index++
        ) {
          const response = yield uploadFile(
            profile.phoneNumber,
            pid,
            "symptoms",
            [action.payload.data.url.images[index]],
            metaData
          );
          if (response) {
            imagesUrl = [
              ...imagesUrl,
              `gs://${response.metadata.bucket}/${response.metadata.fullPath}`
            ];
          }
        }
        action.payload.data.url.images = [...imagesUrl];
      }
      action.payload.data.phoneNumber = profile.phoneNumber;
      action.payload.data.fullName =
        store.getState().auth.data.displayName.patient;
      const data = documentSchema(action.payload.data, "create", "symptom");
      //database function call
      const addSymptomResponse = yield addSymptom(data);
      yield put({
        type: "SET_DOCUMENTS_LOADING",
        payload: {
          loading: false
        }
      });
      if (typeof addSymptomResponse === "string") {
        if (isValidObject(action.payload.navigate)) {
          action.payload.navigate(-1);
        }
        setSuccessStatus("Symptom added successfully");
      }
    } else {
      throwError("custom", "Current profile is empty");
    }
  } catch (error) {
    yield setDocumentLoading(false);
    setErrorStatus(error);
  }
}

//update symptom worker
function* updateSymptomWorker(action) {
  try {
    yield setDocumentLoading(true);
    action.payload.data.phoneNumber = store.getState().auth.data.phoneNumber;
    action.payload.data.fullName =
      store.getState().auth.data.displayName.patient;
    //data need to update
    const symptomData = action.payload.data;
    const currentDocument = store.getState().documents.currentDocument;
    const currentProfileId = store.getState().profile.currentProfile;
    const data = store.getState().profile.data;
    //validating the data before using
    if (
      isValidObject(currentDocument) &&
      isValidObject(data) &&
      isValidProfileId(currentProfileId) &&
      isValidObject(data[currentProfileId])
    ) {
      //delete the voice note and update
      // if (symptomData.audio.length === 0) {
      //   yield deleteFile(currentDocument.audio);
      //   delete symptomData.audio;
      // }

      // //while uploaded new voice note
      // else if (symptomData.audio !== currentDocument.audio) {
      //   if (!!symptomData.audio && typeof symptomData.audio === "object") {
      //     const metaData = {
      //       uploaderId: currentProfileId,
      //       uploaderUserName: data[currentProfileId].public.fullName,
      //       uploaderUserType: "patient"
      //     };
      //     const response = yield uploadFile(
      //       data[currentProfileId].phoneNumber,
      //       currentProfileId,
      //       "symptoms",
      //       symptomData.audio,
      //       metaData
      //     );
      //     response
      //       ? (symptomData.audio = `gs://${response.metadata.bucket}/${response.metadata.fullPath}`)
      //       : delete symptomData.audio;
      //   }
      // }
      const modelResponseData = documentSchema(
        symptomData,
        "update",
        "symptom"
      );

      const res = yield updateSymptom(
        symptomData.documentId,
        modelResponseData
      );
      if (typeof res === "string") {
        if (isValidObject(action.payload.navigate)) {
          action.payload.navigate(-1);
        }
        setSuccessStatus("Symptom updated successfully");
      }
    }
    yield setDocumentLoading(false);
  } catch (error) {
    yield setDocumentLoading(false);
    setErrorStatus(error);
  }
}

//update symptom worker
function* setCurrentDocumentWorker(action) {
  try {
    yield setDocumentLoading(true);
    if (
      isValidArray(store.getState().documents.data) &&
      store
        .getState()
        .documents.data.flat()
        .some((document) => document.documentId === action.payload.documentId)
    ) {
      const currentDocument = store
        .getState()
        .documents.data.flat()
        .find((data) => data.documentId === action.payload.documentId);
      yield put({
        type: "SET_CURRENT_DOCUMENT",
        payload: {
          currentDocument: currentDocument
        }
      });
    }
    yield setDocumentLoading(false);
  } catch (error) {
    yield setDocumentLoading(false);
    setErrorStatus(error);
  }
}

function* removeCurrentDocumentWorker() {
  try {
    yield setDocumentLoading(true);
    yield put({
      type: "SET_CURRENT_DOCUMENT",
      payload: {
        currentDocument: {}
      }
    });
    yield setDocumentLoading(false);
  } catch (error) {
    yield setDocumentLoading(false);
    setErrorStatus(error);
  }
}

function* addDocumentFilterWorker(action) {
  try {
    let filters = store.getState().documents.filters;
    switch (action.payload.type) {
      //Filter by document type
      case "document":
        //previous document type filter states
        let documentsType = filters.documentType;
        //checks document type filter already exist and remove if exists
        if (action.payload.filterValue === "allDocuments") {
          documentsType = [];
        } else if (documentsType.includes(action.payload.filterValue)) {
          const index = documentsType.indexOf(action.payload.filterValue);
          if (index > -1) {
            documentsType.splice(index, 1);
          }
        } else {
          documentsType.push(action.payload.filterValue);
        }
        //reducer dispatch to store the documentFilter
        yield put({
          type: "FILTER_BY_DOCUMENTS",
          payload: {
            documentsType: documentsType
          }
        });
        return;

      case "doctor":
        //previous document type filter states
        let doctorsName = filters.doctors;
        //checks document type filter already exist and remove if exists
        if (doctorsName.includes(action.payload.filterValue)) {
          const index = doctorsName.indexOf(action.payload.filterValue);
          if (index > -1) {
            doctorsName.splice(index, 1);
          }
        } else {
          doctorsName.push(action.payload.filterValue);
        }
        yield put({
          type: "FILTER_BY_DOCTORS",
          payload: {
            doctorsName: doctorsName
          }
        });
        return;

      case "clinic":
        //previous document type filter states
        let clinicsName = filters.clinics;
        //checks document type filter already exist and remove if exists
        if (clinicsName.includes(action.payload.filterValue)) {
          const index = clinicsName.indexOf(action.payload.filterValue);
          if (index > -1) {
            clinicsName.splice(index, 1);
          }
        } else {
          clinicsName.push(action.payload.filterValue);
        }
        yield put({
          type: "FILTER_BY_CLINICS",
          payload: {
            clinicsName: clinicsName
          }
        });
        return;

      default:
        return;
    }
  } catch (error) {
    setErrorStatus(error);
  }
}

function* removeDocumentFilterWorker(action) {
  try {
    switch (action.payload.clear) {
      case "doctorAndClinic":
        yield put({
          type: "FILTER_BY_DOCTORS",
          payload: {
            doctorsName: []
          }
        });
        yield put({
          type: "FILTER_BY_CLINICS",
          payload: {
            clinicsName: []
          }
        });
        return;
      default:
        break;
    }
  } catch (error) {
    setErrorStatus(error);
  }
}

function* createVitalsWorker(action) {
  try {
    yield setDocumentLoading(true);
    action.payload.vitalsData.from = {
      name: store.getState().auth.data.displayName.patient,
      type: "patient",
      id: store.getState().profile.currentProfile
    };
    action.payload.vitalsData.to = {
      fullName: store.getState().auth.data.displayName.patient,
      phoneNumber: store.getState().auth.data.phoneNumber,
      id: store.getState().profile.currentProfile
    };
    action.payload.vitalsData.phoneNumber =
      store.getState().auth.data.phoneNumber;
    const vitalsSchemaResponse = documentSchema(
      action.payload.vitalsData,
      "create",
      "vitals"
    );
    const vitalsResponse = yield createVitals(vitalsSchemaResponse);
    if (typeof vitalsResponse === "string") {
      if (isValidObject(action.payload.navigate)) {
        action.payload.navigate(-1);
      }
      setSuccessStatus("Vitals added successfully");
    } else {
      throwError("custom", "Something went wrong, please try again later");
    }
    yield setDocumentLoading(false);
  } catch (error) {
    yield setDocumentLoading(false);
    setErrorStatus(error);
  }
}

function* updateFormWorker(action) {
  try {
    yield setDocumentLoading(true);
    //update clinic form for patient admission
    const response = yield updateForm(
      action.payload.documentId,
      action.payload.data
    );
    if (response === true) {
      yield action.payload.navigate("/");
      yield put({
        type: "SET_DOCUMENTS_LOADING",
        payload: {
          loading: false
        }
      });
    }
  } catch (error) {
    yield setDocumentLoading(false);
    setErrorStatus(error);
  }
}

export function* retryDataTransferWorker(action) {
  try {
    yield setDocumentLoading(true);

    if (!store.getState().app.featureFlags?.healthData) {
      throwError(
        "custom",
        "Under Maintenance! Please check back after sometimes"
      );
    }

    const getSubscriptionData = () => {
      if (
        store.getState().patients.subscription[
          store.getState().patients.currentProfile
        ] &&
        isValidObject(
          store.getState().patients.subscription[
            store.getState().patients.currentProfile
          ]
        )
      ) {
        return Object.values(
          store.getState().patients.subscription[
            store.getState().patients.currentProfile
          ]
        )[0];
      } else {
        throw new Error("Invalid subscription consents");
      }
    };

    const subscriptionData = yield getSubscriptionData();

    const accessToken = store.getState().auth.data.accessToken;
    const abdmAccessToken =
      store.getState().patients.accessToken[
        store.getState().patients.currentProfile
      ];
    const consentId = action.payload?.consentId;

    const response = yield retryDataTransfer(abdmAccessToken, accessToken, {
      hiuClinicId: subscriptionData.clinicId,
      subscriptionId: subscriptionData.documentId,
      patientId: action.payload.patientId,
      ...(consentId ? { consentId: consentId } : {})
    });
    if (response.success) {
      setSuccessStatus("Retrying...");
    }

    yield setDocumentLoading(false);
  } catch (error) {
    setErrorStatus(error);
    yield setDocumentLoading(false);
  }
}

export default function* documentsWatcher() {
  yield all([
    takeEvery("ADD_DOCUMENTS", setDocumentsWorker),
    takeEvery("ADD_DATA_TRANSFER", setDataTransfersWorker),
    takeEvery("UPDATE_FORM", updateFormWorker),
    takeEvery("ADD_CURRENT_DOCUMENT", setCurrentDocumentWorker),
    takeEvery("REMOVE_CURRENT_DOCUMENT", removeCurrentDocumentWorker),
    takeEvery("ADD_SYMPTOM", addSymptomWorker),
    takeEvery("CREATE_VITALS", createVitalsWorker),
    takeEvery("UPDATE_SYMPTOM", updateSymptomWorker),
    takeEvery("UPLOAD_DOCUMENT", uploadDocumentWorker),
    takeEvery("ADD_DOCUMENT_FILTER", addDocumentFilterWorker),
    takeEvery("REMOVE_DOCUMENT_FILTER", removeDocumentFilterWorker),
    takeEvery("RETRY_DATA_TRANSFER", retryDataTransferWorker)
  ]);
}

function* setDocumentLoading(bool) {
  yield put({
    type: "SET_DOCUMENTS_LOADING",
    payload: {
      loading: bool
    }
  });
}
