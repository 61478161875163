import React from "react";
import { connect } from "react-redux";

import Header from "../../front-end-global-components/components/Header/Header";

import {
  IncidentsCard,
  SelectedIncident
} from "../../front-end-global-components/components/Incidents/Incidents";
import { setSelectedIncidents } from "../../redux/app/actions";
import HandleBackClick from "../../front-end-global-components/components/HandleBackClick/HandleBackClick";

function Incidents(props) {
  return (
    <div className="inherit-parent-height">
      <Header
        title={
          props.app.selectedIncident ? "Incident management" : "All Incidents"
        }
        backButtonOnClick={() => {
          if (props.app.selectedIncident) {
            setSelectedIncidents(null);
          } else {
            props.navigate(-1);
          }
        }}
        hideSupport={true}
      />

      <div className=" remaining-body-height padding-large overflow-scroll">
        {!props.app.selectedIncident && (
          <IncidentsCard
            incidents={props.app.incidents}
            incidentOnClick={(incidentId) => {
              props.setSelectedIncidents(incidentId);
            }}
            userType="patients"
          />
        )}

        {props.app.selectedIncident && (
          <HandleBackClick
            onClose={() => {
              props.setSelectedIncidents(null);
            }}
            isBackEnabled={props.app.selectedIncident}
          >
            <SelectedIncident
              incident={props.app.incidents[props.app.selectedIncident]}
            />
          </HandleBackClick>
        )}
      </div>
    </div>
  );
}

const mapStateToProps = function (state) {
  return {
    app: state.app
  };
};

const mapDispatchToProps = function () {
  return {
    setSelectedIncidents: (incidentId) => setSelectedIncidents(incidentId)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Incidents);
