import React, { useEffect, useState } from "react";
import CheckBox from "../../front-end-global-components/components/CheckBox/CheckBox";
import Button from "../../front-end-global-components/components/Button/Button";
import arrow from "../../front-end-global-components/assets/DropDownArrow.svg";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import OTPInputBox from "../../front-end-global-components/components/OTPInputBox/OTPInputBox";
import NativeSelect from "../../front-end-global-components/components/NativeSelect/NativeSelect";
import CreateAbhaNumber from "../../components/CreateAbhaNumber/CreateAbhaNumber";
import { Regex } from "../../utils/regex";
import Modal from "../../front-end-global-components/components/Modal/Modal";
import HandleBackClick from "../../front-end-global-components/components/HandleBackClick/HandleBackClick";
import phone from "../../assets/icons/phoneLogo.svg";
import mail from "../../assets/icons/mailLogo.svg";
import number from "../../assets/icons/abhaNumberLogo.svg";
import {
  isValidArray,
  isValidObject,
  validation
} from "../../utils/validators";
import {
  getValidAbhaNumber,
  returnYearArrayForSelectBox
} from "../../utils/constants";
import {
  ArrowIcon,
  CircleWithTickIcon,
  InfoIcon
} from "../../front-end-global-components/assets/assets";
import at from "../../assets/icons/atLogo.svg";
import { connect } from "react-redux";
import {
  clearAbdmAuthCredentials,
  registerNewAbhaNumber,
  setAbdmTransactionId
} from "../../redux/abdm/actions";
import Header from "../../front-end-global-components/components/Header/Header";
import {
  clearLinkedProfiles,
  getModesForAbdmAuth,
  sendOtpForAbdmAuthentication,
  setAuthModes,
  setTransactionId,
  verifyAbdmAuthenticationOtp
} from "../../redux/patients/actions";
import ConditionalRender from "../../front-end-global-components/components/ConditionalRender/ConditionalRender";
import FeatureFlagsFallback from "../../front-end-global-components/components/FeatureFlagsFallback/FeatureFlagsFallback";
import ProcessSuccess from "../../components/ProcessSuccess/ProcessSuccess";

function ABDMVerify(props) {
  const [counter, setCounter] = useState(null);
  const [running, setRunning] = useState(false);
  const [authType, setAuthType] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [authenticationMode, setAuthenticationMode] = useState({
    name: "Phone",
    value: "phoneNumber",
    logo: phone,
    description: "LOGIN VIA PHONE NO. CONNECTED WITH ABHA"
  });
  const [showConnectModal, setShowConnectModal] = useState({
    value: false,
    type: null
  });
  const [showAuthModeModal, setShowAuthModeModal] = useState(false);
  const [showLoginModes, setShowLoginModes] = useState(false);
  const [loginFormData, setLoginFormData] = useState({
    healthId:
      props.patients.currentProfile &&
      props.patients.profiles?.[props.patients.currentProfile]?.healthId &&
      !window.location.search.includes("force=true")
        ? props.patients.profiles?.[props.patients.currentProfile]?.healthId
        : "",
    abhaNumber: "",
    email: "",
    otp: "",
    agreeTo: false,
    yearOfBirth: "",
    aadhaarNumber: "",
    phoneNumber: props.auth.data?.phoneNumber
      ? props.auth.data?.phoneNumber
      : "+91",
    password: ""
  });
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [showLinkedProfileModal, setShowLinkedProfileModal] = useState(false);

  const [showCreateAbhaNumberModal, setShowCreateAbhaNumberModal] =
    useState(false);

  const [authProcess, setAuthProcess] = useState(null);

  useEffect(() => {
    const pathSegments = window.location.pathname.split("/");
    if (pathSegments.length >= 3) {
      setAuthProcess(pathSegments[2]);
    }
    // eslint-disable-next-line
  }, [window.location.pathname]);

  useEffect(() => {
    if (
      window.location.pathname.includes("login") &&
      props.patients.accessToken?.[props.patients.currentProfile]
    ) {
      props.navigate("/");
    }
    // eslint-disable-next-line
  }, [props.patients.accessToken]);

  useEffect(() => {
    let counterTimer;
    if (counter && typeof counter === "number" && running) {
      counterTimer = setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
    } else {
      setCounter(null);
      setRunning(false);
      props.clearAbdmAuthCredentials();
      return () => {
        clearTimeout(counterTimer);
      };
    }

    // eslint-disable-next-line
  }, [counter]);

  useEffect(() => {
    if (showConnectModal.value) {
      setShowConnectModal({
        ...showConnectModal,
        value: false
      });
    }

    if (showLoginModes) {
      setShowLoginModes(false);
    }
    props.setTransactionId(null);
    props.clearLinkedProfiles(null);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      props.patients.currentProfile &&
      props.patients.profiles?.[props.patients.currentProfile]?.healthId &&
      !window.location.search.includes("force=true") &&
      window.location.pathname.includes("login")
    ) {
      setAuthenticationMode({
        name: "ABHA Address",
        value: "healthId",
        logo: at,
        description: `${
          window.location.pathname.includes("login") ? "LOGIN" : "REGISTER"
        }  VIA ABHA ADDRESS CONNECTED WITH YOUR PROFILE`
      });
    } else {
      setAuthenticationMode({
        name: "Phone",
        value: "phoneNumber",
        logo: phone,
        description: `${
          window.location.pathname.includes("login") ? "LOGIN" : "REGISTER"
        }  VIA PHONE ${
          window.location.pathname.includes("login")
            ? "NO. CONNECTED WITH ABHA"
            : "NUMBER"
        }`
      });
    }

    if (
      authenticationMode.value === "healthId" &&
      window.location.pathname.includes("register")
    ) {
      setAuthenticationMode({
        name: "Phone",
        value: "phoneNumber",
        logo: phone,
        description: `${
          window.location.pathname.includes("login") ? "LOGIN" : "REGISTER"
        }  VIA PHONE NUMBER`
      });
    }

    // eslint-disable-next-line
  }, [props.patients.currentProfile, window.location.pathname]);

  useEffect(() => {
    if (isValidArray(props.patients.authModes)) {
      setShowLoginModes(true);
    }
  }, [props.patients.authModes]);

  useEffect(() => {
    if (
      showConnectModal.type === null &&
      props.patients?.transaction?.id &&
      typeof props.patients?.transaction?.id === "string"
    ) {
      setCounter(60);
      setRunning(true);
      return;
    }
    if (
      showConnectModal.type &&
      showConnectModal.type !== "Password" &&
      props.patients?.transaction?.id &&
      typeof props.patients?.transaction?.id === "string" &&
      !running
    ) {
      setCounter(60);
      setRunning(true);
    } else {
      setCounter(null);
      setRunning(false);
      props.clearAbdmAuthCredentials();
    }
    // eslint-disable-next-line
  }, [props.patients?.transaction?.id]);

  useEffect(() => {
    if (
      props.patients?.transaction?.id &&
      (authenticationMode.value === "healthId" ||
        authenticationMode.value === "abhaNumber")
    ) {
      setShowConnectModal({
        ...showConnectModal,
        value: true
      });
    }
    // eslint-disable-next-line
  }, [props.patients?.transaction?.id]);

  const disableContinueButton = () => {
    if (authenticationMode?.value === "healthId" && loginFormData?.healthId) {
      if (
        props.patients.currentProfile &&
        !props.patients.profiles[props.patients.currentProfile]?.healthId
      ) {
        if (loginFormData?.agreeTo) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }

    if (
      authenticationMode?.value === "abhaNumber" &&
      loginFormData?.abhaNumber &&
      loginFormData.yearOfBirth
    ) {
      if (
        props.patients.currentProfile &&
        !props.patients.profiles[props.patients.currentProfile]?.healthId
      ) {
        if (loginFormData?.agreeTo) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
    if (
      typeof props.patients?.transaction?.id === "string" &&
      loginFormData.otp.length === 6
    ) {
      if (
        props.patients.currentProfile &&
        !props.patients.profiles[props.patients.currentProfile]?.healthId
      ) {
        if (loginFormData?.agreeTo) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }

    if (loginFormData?.agreeTo) {
      return false;
    } else {
      return true;
    }
  };

  const buttonDisableHandler = () => {
    if (counter && typeof counter === "number") {
      return true;
    }
    switch (authenticationMode.value) {
      case "healthId":
        if (window.location.pathname.includes("login")) {
          if (loginFormData[authenticationMode.value].trim().length > 4) {
            return false;
          } else {
            return true;
          }
        } else {
          Regex.healthId?.test(loginFormData[authenticationMode.value]);
        }
        break;

      default:
        return !Regex[authenticationMode.value].test(
          loginFormData[authenticationMode.value]
        );
    }
  };

  const abdmAuthOnSubmitHandler = () => {
    if (
      authenticationMode.value === "healthId" &&
      loginFormData[authenticationMode.value]
    ) {
      props.getModesForAbdmAuth(
        authProcess,
        authenticationMode.name,
        loginFormData[authenticationMode.value]
      );
      return;
    }

    if (
      authenticationMode.value === "abhaNumber" &&
      loginFormData[authenticationMode.value]
    ) {
      props.getModesForAbdmAuth(
        authProcess,
        authenticationMode.name,
        loginFormData[authenticationMode.value],
        loginFormData.yearOfBirth
      );
      return;
    }

    if (
      typeof props.patients?.transaction?.id === "string" &&
      loginFormData.otp.length === 6
    ) {
      props.verifyAbdmAuthenticationOtp(
        authProcess,
        authenticationMode.name,
        loginFormData.otp,
        props.navigate,
        null,
        loginFormData.healthId,
        loginFormData.phoneNumber ? loginFormData.phoneNumber : null
      );
    } else {
      props.sendOtpForAbdmAuthentication(
        authProcess,
        authType,
        authenticationMode.name,
        loginFormData[authenticationMode.value]
      );
    }
  };

  return (
    <>
      <Header
        title={authProcess === "login" ? "ABDM Login" : "ABDM Register"}
        backButtonOnClick={() => {
          props.navigate(-1);
          if (props.patients?.transaction?.id !== null) {
            props.setTransactionId(null);
            props.setAuthModes(null);
          }
        }}
        hideSupport={true}
        incident={props.app.featureFlags?.incident?.patients}
        incidentOnClick={() => {
          props.navigate("/incidents");
        }}
      />

      <ConditionalRender condition={!props.app.featureFlags.abhaService}>
        <FeatureFlagsFallback height="remaining-body-height max-width-588px" />
      </ConditionalRender>

      <ConditionalRender condition={props.app.featureFlags.abhaService}>
        <section className="remaining-body-height max-width-588px margin-horizontal-auto overflow-y-auto inherit-parent-width ">
          <form
            className="inherit-parent-height flex-justify-content-space-between flex-direction-column"
            onChange={(event) => {
              if (event.target.name === "agreeTo") {
                setLoginFormData({
                  ...loginFormData,
                  [event.target.name]: event.target.checked
                });
              } else {
                setLoginFormData({
                  ...loginFormData,
                  [event.target.name]: event.target.value
                });
              }
            }}
            onSubmit={(event) => {
              event.preventDefault();
              abdmAuthOnSubmitHandler();
            }}
          >
            <section>
              <AuthModeSwitcher
                disable={
                  props.patients.profiles?.[props.patients.currentProfile]
                    ?.healthId &&
                  window.location.pathname.includes("login") &&
                  !window.location.search.includes("force=true")
                    ? true
                    : false
                }
                data={authenticationMode}
                downArrowOnClick={() => {
                  setShowAuthModeModal(true);
                }}
              />

              <div className="padding-right-larger padding-left-larger">
                {(authenticationMode.name === "Phone" ||
                  authenticationMode.name === "Email") && (
                  <>
                    <InputBox
                      className="inherit-parent-width border-radius-default margin-bottom-default"
                      name={
                        authenticationMode.name === "Phone"
                          ? "phoneNumber"
                          : "email"
                      }
                      label={
                        authenticationMode.name === "Phone"
                          ? "Phone Number"
                          : "Email"
                      }
                      type={
                        authenticationMode.name === "Phone" ? "tel" : "email"
                      }
                      otpFocus={true}
                      autoFocus={true}
                      value={
                        authenticationMode.name === "Phone"
                          ? loginFormData.phoneNumber
                          : loginFormData.email
                      }
                      buttonClassName=" background-color-white"
                      buttonTile={
                        typeof counter === "number" && counter !== 0
                          ? `${
                              counter?.toString().length === 1 ? "0" : ""
                            }${counter?.toString()} seconds`
                          : props.patients?.transaction?.id && counter === null
                          ? "Resend OTP"
                          : "Get OTP"
                      }
                      // disabled={
                      //   authenticationMode.name === "Phone" &&
                      //   ((props.patients.currentProfile &&
                      //     props.patients.profiles[props.patients.currentProfile]
                      //       ?.phoneNumber) ||
                      //     props.auth.data.phoneNumber)
                      //     ? true
                      //     : false
                      // }
                      suffixButtonDisabled={buttonDisableHandler()}
                      buttonType={
                        props.patients?.transaction?.id &&
                        loginFormData.otp.length === 6
                          ? "button"
                          : "submit"
                      }
                      data-cy={`abha-login-${
                        authenticationMode.name === "Phone"
                          ? "phone-number"
                          : "email"
                      }-field`}
                      validation={(value) =>
                        validation(
                          authenticationMode.name === "Phone"
                            ? "phoneNumber"
                            : "email",
                          value
                        )
                      }
                      required
                    />

                    {props.patients?.transaction?.id &&
                      typeof props.patients?.transaction?.id === "string" && (
                        <OTPInputBox
                          name="otp"
                          autoFocus={true}
                          value={loginFormData.otp}
                          setValue={(otpValue) => {
                            setLoginFormData({
                              ...loginFormData,
                              otp: otpValue
                            });
                          }}
                          required
                          className="margin-bottom-larger"
                        />
                      )}
                  </>
                )}

                {authenticationMode.name === "ABHA Address" && (
                  <InputBox
                    // inputSuffixPlaceHolder={"@sbx"}
                    labelClassName="letter-spacing-4-percentage"
                    className="inherit-parent-width border-radius-default margin-bottom-default"
                    label="ABHA Address"
                    type="text"
                    name="healthId"
                    autoFocus={true}
                    value={loginFormData.healthId}
                    // disabled={
                    //   props.patients.currentProfile &&
                    //   props.patients.profiles[props.patients.currentProfile]
                    //     ?.healthId &&
                    //   !window.location.search.includes("force=true")
                    //     ? true
                    //     : false
                    // }
                    inputNotesClassName="font-color-primary font-size-smaller padding-top-small"
                  />
                )}

                {authenticationMode.name === "ABHA Number" && (
                  <>
                    <InputBox
                      className="inherit-parent-width border-radius-default margin-bottom-default"
                      name="abhaNumber"
                      label="ABHA Number"
                      type="text"
                      autoFocus={true}
                      value={[loginFormData.abhaNumber]}
                      required
                      disabled={props.patients.loading}
                    />
                    <NativeSelect
                      removeResponsive={true}
                      name="yearOfBirth"
                      data-cy="demographics-patient-year-of-birth"
                      label="Year Of Birth"
                      defaultValue={loginFormData.yearOfBirth}
                      options={["", ...returnYearArrayForSelectBox(1900)]}
                      required
                    />
                    <div
                      className=" font-size-small font-color-primary text-align-right"
                      onClick={() => {
                        setShowCreateAbhaNumberModal(true);
                        if (typeof props.abdm.transactionId === "string") {
                          props.setAbdmTransactionId(null);
                        }
                      }}
                    >
                      Create ABHA Number
                    </div>
                  </>
                )}
              </div>
              {props.auth.data.phoneNumber !== loginFormData.phoneNumber &&
                loginFormData.phoneNumber.length === 13 && (
                  <div className="display-flex padding-left-larger flex-align-items-center">
                    <InfoIcon />
                    <div className="padding-left-default font-size-smaller padding-right-larger">
                      The Phone number entered is different from the number used
                      to login. You can access this ABHA Address by switching to
                      the respective phone number on Ninto
                    </div>
                  </div>
                )}
            </section>
            <section className="padding-left-larger padding-right-larger">
              {
                // props.patients.currentProfile &&
                //   !props.patients.profiles?.[props.patients.currentProfile]
                //     ?.healthId &&

                <div className="inherit-parent-width display-flex padding-top-large text-align-left margin-bottom-default flex-align-items-center">
                  <CheckBox
                    name="agreeTo"
                    label={
                      <div className="font-size-small">
                        I agree to voluntarily share my identity information
                        with Byepo Technologies to create my ABHA Address{" "}
                        {
                          <span
                            className="cursor-pointer font-family-RHD-regular padding-left-small font-color-primary"
                            onClick={() => {
                              setShowModal(true);
                            }}
                          >
                            User Information Agreement
                          </span>
                        }
                      </div>
                    }
                  />
                </div>
              }

              <Button
                text="Continue"
                data-cy="abdm-login-button"
                boxShadow={false}
                required
                type={
                  authenticationMode?.value === "healthId" &&
                  loginFormData?.healthId
                    ? "submit"
                    : authenticationMode?.value === "abhaNumber" &&
                      loginFormData?.abhaNumber
                    ? "submit"
                    : props.patients?.transaction?.id &&
                      loginFormData.otp.length !== 6
                    ? "button"
                    : "submit"
                }
                disabled={disableContinueButton()}
                loading={props.patients.loading}
              />
              <AuthModeSwitch
                authMode={window.location.pathname.includes("login")}
                onClick={() => {
                  if (
                    typeof props.abdm?.auth?.otp?.requestMethod === "string"
                  ) {
                    props.clearAbdmAuthCredentials();
                  }
                  props.setTransactionId(null);
                  setCounter(null);
                  setRunning(false);
                  props.setAuthModes(null);
                  if (window.location.pathname.includes("login")) {
                    props.navigate("/abdm/register/verify");
                  } else {
                    props.navigate("/abdm/login/verify");
                  }
                }}
              />
            </section>
          </form>
          {props.patients.isMultipleLogin &&
            isValidArray(props.patients?.linkedProfiles) &&
            props.patients?.linkedProfiles.every(
              (data) => typeof data.customToken === "string"
            ) && (
              <>
                <ProcessSuccess
                  title="ABHA Address Logged in Successfully"
                  description={`YOUR ABHA ADDRESS IS ${loginFormData?.healthId}@sbx`}
                  confirmClick={() => {
                    isValidArray(props.patients?.linkedProfiles) &&
                    isValidObject(
                      props.patients?.linkedProfiles?.find(
                        (ele) => ele.customToken
                      )
                    )
                      ? setShowLinkedProfileModal(true)
                      : props.confirmOnclick();
                  }}
                />

                <Modal
                  show={showLinkedProfileModal}
                  onClose={() => {
                    setShowLinkedProfileModal(!showLinkedProfileModal);
                  }}
                  canIgnore={false}
                  position="position-fixed bottom-0 left-0 right-0"
                  boxShadow="none"
                  borderRadius="none"
                  width="inherit-parent-width"
                  background="background-transparent"
                  height="height-fit-to-content"
                >
                  <div className="background-white padding-large font-family-gilroy-medium user-select-none">
                    <div className="inherit-parent-width inherit-parent-height">
                      {typeof selectedIndex !== "number" && (
                        <>
                          <div className="text-align-center font-size-small padding-medium font-color-secondary">
                            SELECT TO VIEW CONNECTED HEALTH ID
                          </div>
                          {props.patients.linkedProfiles?.map((data, index) => (
                            <div
                              onClick={() => {
                                setSelectedIndex(index);
                              }}
                              className="display-flex flex-justify-content-space-between padding-bottom-default"
                              key={index}
                            >
                              <div>{data.phoneNumber}</div>
                              <ArrowIcon />
                            </div>
                          ))}

                          <div>
                            <div className="flex-center-children-vertically margin-vertical-large ">
                              <hr
                                style={{ width: "100%" }}
                                className="display-inline-block border-color-secondary-lite border-top-left-right-none flex-basis-1 "
                              />
                              <span className=" margin-horizontal-default display-inline-block font-color-tertiary font-size-small">
                                OR
                              </span>
                              <hr
                                style={{ width: "100%" }}
                                className="display-inline-block border-color-secondary-lite border-top-left-right-none flex-basis-1 "
                              />
                            </div>
                          </div>
                          <div>
                            <div className="font-size-small text-align-center">
                              Create health ID with same number?
                              <span className="font-color-primary padding-left-small">
                                Click here{" "}
                              </span>{" "}
                            </div>
                          </div>
                        </>
                      )}
                      {typeof selectedIndex === "number" && (
                        <>
                          <div className="text-align-center font-size-small padding-medium font-color-secondary">
                            CONNECTED HEALTH ID
                          </div>
                          <div className="text-align-center font-size-small font-color-secondary">
                            You will now logout from registered phone number
                            then login to this number to access the shown health
                            id(s)
                          </div>

                          <div className="padding-top-large">
                            {
                              props.patients.linkedProfiles?.[selectedIndex]
                                ?.phoneNumber
                            }
                          </div>

                          <div
                            style={{
                              height: "100px"
                            }}
                            className="flex-direction-row gap-0-point-5em margin-top-small flex-wrap overflow-auto padding-top-large padding-bottom-default padding-right-medium"
                          >
                            {props.patients.linkedProfiles?.[
                              selectedIndex
                            ]?.linkedAbhaAddress.map((chip, index) => (
                              <div
                                className={`font-size-small text-overflow-ellipsis padding-small border-2px-lite-grey border-radius-default font-size-small font-family-gilroy-regular font-color-secondary`}
                                key={index}
                              >
                                <span>{chip}</span>
                              </div>
                            ))}
                          </div>

                          <section className="display-flex padding-top-larger ">
                            <Button
                              boxShadow={false}
                              type="button"
                              text="Continue"
                              className={"border-1px-e5e5e5 margin-right-small"}
                              onClick={() => {
                                props.loginWithDifferentNumber(
                                  props.patients.linkedProfiles?.[selectedIndex]
                                    ?.customToken
                                );
                                props.navigate("/");
                              }}
                              data-cy="confirm-logout-button"
                            />
                            <Button
                              boxShadow={false}
                              text="Back"
                              variant={"secondary"}
                              onClick={() => {
                                setSelectedIndex(null);
                              }}
                              className={
                                "font-color-secondary border-1px-e5e5e5 margin-left-small"
                              }
                              data-cy={"cancel-logout-button"}
                            />
                          </section>
                        </>
                      )}
                    </div>
                  </div>
                </Modal>
              </>
            )}
          <CreateAbhaNumber
            showModal={showCreateAbhaNumberModal}
            registerNewAbhaNumber={(data) => {
              props.registerNewAbhaNumber(data);
            }}
            getOtp={typeof props.abdm.transactionId === "string" ? true : false}
            transactionId={props.abdm.transactionId}
            getMobileNumber={
              typeof props.abdm.transactionId === "string" &&
              props.abdm.transactionId.includes("aadhaarVerified")
            }
            getMobileOtp={
              typeof props.abdm.transactionId === "string" &&
              props.abdm.transactionId.includes("getMobileOtp")
            }
            abhaNumberDetails={props.abdm.newHealthNumberData}
            loading={props.abdm.loading}
            closeModal={() => {
              setShowCreateAbhaNumberModal(false);
              if (props.abdm.newHealthNumberData) {
                props.registerNewAbhaNumber({
                  clearNewHealthNumberData: true
                });
              }
            }}
          />
          <HandleBackClick
            onClose={() => {
              setShowAuthModeModal(false);
              props.setAuthModes(null);
            }}
            isBackEnabled={showAuthModeModal}
          >
            <Modal
              show={showAuthModeModal}
              onClose={() => {
                setShowAuthModeModal(!showAuthModeModal);
                props.setAuthModes(null);
              }}
              canIgnore={false}
              position="position-fixed bottom-0 left-0 right-0"
              boxShadow="none"
              borderRadius="none"
              width="inherit-parent-width"
              background="background-transparent"
              height="height-fit-to-content"
            >
              <div
                className="inherit-parent-width inherit-parent-height "
                style={{ flexBasis: "1" }}
              />
              <footer
                data-cy="abdm-auth-switcher-modal"
                className="inherit-parent-width background-white"
              >
                <div className="padding-large">
                  <div className="text-align-center padding-bottom-default font-size-smaller">
                    {`SELECT TO ${
                      window.location.pathname.includes("login")
                        ? "LOGIN"
                        : "REGISTER"
                    } WITH YOUR ABHA ADDRESS`}
                  </div>
                  {Object.values(authModes).map(
                    (data, index) =>
                      (window.location.pathname.includes("login") ||
                        (window.location.pathname.includes("register") &&
                          !(data.value === "healthId"))) && (
                        <div
                          key={index}
                          className="inherit-parent-width display-flex flex-justify-content-space-between flex-center-children-vertically padding-bottom-large"
                          data-cy={`login-mode-${data.value}`}
                          onClick={() => {
                            setAuthenticationMode(data);
                            setAuthType(data.name);
                            setShowAuthModeModal(!showAuthModeModal);
                            props.setTransactionId(null);
                          }}
                        >
                          <div className="display-flex flex-direction-row">
                            <img
                              className="padding-right-default"
                              src={data.logo}
                              alt=""
                            />
                            <div className="inherit-parent-width padding-default">
                              <div className="font-family-gilroy-regular padding-bottom-smaller">
                                {data.name}
                              </div>
                              <div className="font-size-smaller">
                                {data.description}
                              </div>
                            </div>
                          </div>
                          {authenticationMode.name === data.name ? (
                            <CircleWithTickIcon
                              circleColor={"white"}
                              circleOutline={"#00a000"}
                              tickColor={"#00a000"}
                            />
                          ) : (
                            <div className="rotate-90">
                              <img src={arrow} alt="arrow" />
                            </div>
                          )}
                        </div>
                      )
                  )}
                </div>
              </footer>
            </Modal>
          </HandleBackClick>

          <HandleBackClick
            onClose={() => {
              setShowLoginModes(false);
              props.setAuthModes(null);
            }}
            isBackEnabled={showLoginModes}
          >
            {/* Modal for abha login types */}
            <Modal
              show={showLoginModes}
              onClose={() => {
                setShowLoginModes(false);
                props.setAuthModes(null);
              }}
              canIgnore={false}
              position="position-fixed bottom-0 left-0 right-0"
              boxShadow="none"
              borderRadius="none"
              width="inherit-parent-width"
              background="background-transparent"
              height="height-fit-to-content"
            >
              <div
                className="inherit-parent-width inherit-parent-height "
                style={{ flexBasis: "1" }}
              />
              <footer
                data-cy="login-mode-select-modal"
                className="inherit-parent-width background-white"
              >
                <div className="padding-large">
                  <div className="text-align-center padding-bottom-default font-size-smaller">
                    CHOOSE HOW YOU WANT TO LOGIN
                  </div>
                  {Object.values(authTypes).map(
                    (data, index) =>
                      props.patients.authModes &&
                      props.patients.authModes.includes(data.value) && (
                        <div
                          key={index}
                          data-cy={`login-mode-${data["data-cy"]}`}
                          className="inherit-parent-width display-flex flex-justify-content-space-between flex-center-children-vertically padding-bottom-large"
                          onClick={() => {
                            setAuthType(data.name);
                            setShowConnectModal({
                              value: false,
                              type: data.name
                            });

                            props.sendOtpForAbdmAuthentication(
                              authProcess,
                              data.name,
                              authenticationMode.name,
                              authenticationMode.name === "ABHA Number"
                                ? getValidAbhaNumber(
                                    loginFormData[authenticationMode.value]
                                  )
                                : loginFormData[authenticationMode.value]
                            );
                            setShowLoginModes(!showLoginModes);
                          }}
                        >
                          <div className="display-flex flex-direction-row">
                            <div className="inherit-parent-width padding-default">
                              <div className="font-family-gilroy-regular padding-bottom-smaller">
                                {data.name}
                              </div>
                              <div className="font-size-smaller">
                                {data.description}
                              </div>
                            </div>
                          </div>
                          <div className="rotate-90">
                            <img src={arrow} alt="arrow" />
                          </div>
                        </div>
                      )
                  )}
                </div>
              </footer>
            </Modal>
          </HandleBackClick>

          <HandleBackClick
            onClose={() => {
              setShowConnectModal({
                ...showConnectModal,
                value: false
              });
              setShowLoginModes(false);
              props.setAuthModes(null);
            }}
            isBackEnabled={showConnectModal.value}
          >
            {/* Modal for login types */}

            <Modal
              show={
                showConnectModal.value &&
                typeof props.patients?.transaction?.id === "string"
                  ? true
                  : false
              }
              onClose={() => {
                setShowConnectModal({
                  ...showConnectModal,
                  value: false
                });
                setShowLoginModes(false);
                props.setAuthModes(null);
              }}
              canIgnore={false}
              position="position-fixed bottom-0 left-0 right-0"
              boxShadow="none"
              borderRadius="none"
              width="inherit-parent-width"
              background="background-transparent"
              height="height-fit-to-content"
            >
              <div
                className="inherit-parent-width inherit-parent-height "
                style={{ flexBasis: "1" }}
              />
              <footer className="inherit-parent-width background-white">
                <div className="padding-large">
                  <div className="text-align-center padding-bottom-default font-size-smaller">
                    {showConnectModal.type === "Password" &&
                      "ENTER YOUR PASSWORD"}
                  </div>
                  <form
                    onSubmit={(event) => {
                      event.preventDefault();
                      props.verifyAbdmAuthenticationOtp(
                        authProcess,
                        authenticationMode.name,
                        loginFormData.otp
                          ? loginFormData.otp
                          : loginFormData.password,
                        props.navigate,
                        loginFormData.password ? true : false,
                        loginFormData.healthId,
                        null
                      );
                    }}
                  >
                    {showConnectModal.type === "Password" && (
                      <InputBox
                        className="inherit-parent-width border-radius-default margin-bottom-default"
                        name="password"
                        label="Password"
                        type="password"
                        otpFocus={true}
                        autoFocus={true}
                        value={loginFormData.password}
                        onChange={(event) => {
                          setLoginFormData({
                            ...loginFormData,
                            password: event.target.value
                          });
                        }}
                        // validation={(value) => validation("password", value)}
                        required
                        disabled={props.patients.loading}
                      />
                    )}
                    {(showConnectModal.type === "Phone" ||
                      showConnectModal.type === "Aadhar" ||
                      showConnectModal.type === "Email") &&
                      props.patients?.transaction?.id &&
                      typeof props.patients?.transaction?.id === "string" && (
                        <>
                          <OTPInputBox
                            name="otp"
                            autoFocus={true}
                            value={loginFormData.otp}
                            setValue={(otpValue) => {
                              setLoginFormData({
                                ...loginFormData,
                                otp: otpValue
                              });
                            }}
                            required
                            className="margin-bottom-large"
                          />
                          <div className="display-flex flex-justify-content-space-between font-size-medium margin-bottom-larger">
                            {counter &&
                            counter !== 0 &&
                            typeof counter === "number" ? (
                              <div>
                                {`${
                                  counter?.toString().length === 1 ? "0" : ""
                                }${counter?.toString()} seconds`}
                              </div>
                            ) : (
                              <div></div>
                            )}
                            <div
                              className={`${
                                counter
                                  ? "font-color-tertiary"
                                  : "font-color-primary"
                              }`}
                              onClick={() => {
                                if (
                                  counter &&
                                  typeof counter === "number" &&
                                  running
                                ) {
                                  return;
                                } else {
                                  props.sendOtpForAbdmAuthentication(
                                    authProcess,
                                    authType,
                                    authenticationMode.name,
                                    authenticationMode.name === "ABHA Number"
                                      ? getValidAbhaNumber(
                                          loginFormData[
                                            authenticationMode.value
                                          ]
                                        )
                                      : loginFormData[authenticationMode.value]
                                  );
                                }
                              }}
                            >
                              Resend OTP
                            </div>
                          </div>
                        </>
                      )}

                    <Button
                      text="Login"
                      data-cy="abha-login-button"
                      boxShadow={false}
                      required
                      type="submit"
                      className="margin-top-default margin-bottom-medium"
                      disabled={
                        loginFormData.password
                          ? false
                          : loginFormData.otp.length === 6 &&
                            props.patients?.transaction?.id
                          ? false
                          : true
                      }
                      loading={props.patients.loading}
                    />
                  </form>
                </div>
              </footer>
            </Modal>
          </HandleBackClick>

          <HandleBackClick
            onClose={() => {
              setShowModal(false);
            }}
            isBackEnabled={showModal}
          >
            <Modal
              show={showModal}
              onClose={() => {
                setShowModal(!showModal);
              }}
              canIgnore={false}
              position="position-fixed bottom-0 left-0 right-0"
              boxShadow="none"
              borderRadius="none"
              width="inherit-parent-width"
              background="background-transparent"
            >
              <div className="background-white padding-default font-family-gilroy-medium user-select-none">
                <div className="inherit-parent-width inherit-parent-height padding-bottom-large">
                  <div className="text-align-center font-size-small padding-medium font-color-secondary">
                    TERMS AND CONDITION
                  </div>
                  <div
                    style={{ height: "280px" }}
                    className="hide-scroll-bar inherit-parent-height"
                  >
                    <p
                      style={{ lineHeight: "20px", textAlign: "justify" }}
                      className="padding-bottom-default font-size-small padding-left-default padding-right-default font-color-secondary"
                    >
                      &nbsp; &nbsp; &nbsp; &nbsp; I, hereby declare that I am
                      voluntarily sharing my Aadhaar Number and demographic
                      information issued by UIDAI, with Byepo Technologies for
                      the sole purpose of creation of ABHA number . I understand
                      that my ABHA number can be used and shared for purposes as
                      may be notified by ABDM from time to time including
                      provision of healthcare services. Further, I am aware that
                      my personal identifiable information (Name, Address, Age,
                      Date of Birth, Gender and Photograph) may be made
                      available to the entities working in the National Digital
                      Health Ecosystem (NDHE) which inter alia includes
                      stakeholders and entities such as healthcare professionals
                      (e.g. doctors), facilities (e.g. hospitals, laboratories)
                      and data fiduciaries (e.g. health programmes), which are
                      registered with or linked to the Ayushman Bharat Digital
                      Mission (ABDM), and various processes there under.
                    </p>
                    <p
                      style={{ lineHeight: "20px", textAlign: "justify" }}
                      className="padding-bottom-larger font-size-small padding-left-default padding-right-default font-color-secondary"
                    >
                      I authorize Byepo Technologies to use my Aadhaar number
                      for performing Aadhaar based authentication with UIDAI as
                      per the provisions of the Aadhaar (Targeted Delivery of
                      Financial and other Subsidies, Benefits and Services) Act,
                      2016 for the aforesaid purpose. I understand that UIDAI
                      will share my e-KYC details, or response of “Yes” with
                      Byepo Technologies upon successful authentication. I have
                      been duly informed about the option of using other IDs
                      apart from Aadhaar; however, I consciously choose to use
                      Aadhaar number for the purpose of availing benefits across
                      the NDHE. I am aware that my personal identifiable
                      information excluding Aadhaar number / VID number can be
                      used and shared for purposes as mentioned above. I reserve
                      the right to revoke the given consent at any point of time
                      as per provisions of Aadhaar Act and Regulations.
                    </p>
                  </div>
                </div>
              </div>
            </Modal>
          </HandleBackClick>
        </section>
      </ConditionalRender>
    </>
  );
}

const mapStateToProps = (state) => ({
  abdm: state.abdm,
  auth: state.auth,
  patients: state.patients,
  app: state.app
});

const mapDispatchToProps = function () {
  return {
    sendOtpForAbdmAuthentication: (authMode, authType, method, value) =>
      sendOtpForAbdmAuthentication(authMode, authType, method, value),
    getModesForAbdmAuth: (auth, method, value, yearOfBirth) =>
      getModesForAbdmAuth(auth, method, value, yearOfBirth),
    verifyAbdmAuthenticationOtp: (
      auth,
      method,
      otp,
      push,
      skipHealthInfo,
      healthId,
      phoneNumber
    ) =>
      verifyAbdmAuthenticationOtp(
        auth,
        method,
        otp,
        push,
        skipHealthInfo,
        healthId,
        phoneNumber
      ),
    clearAbdmAuthCredentials: () => clearAbdmAuthCredentials(),
    setAuthModes: () => setAuthModes(),
    setTransactionId: (transactionId) => setTransactionId(transactionId),
    clearLinkedProfiles: () => clearLinkedProfiles(),
    registerNewAbhaNumber: (data) => registerNewAbhaNumber(data),
    setAbdmTransactionId: (data) => setAbdmTransactionId(data)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ABDMVerify);

const AuthModeSwitch = (props) => {
  return (
    <div
      className="cursor-pointer font-size-small text-align-center margin-top-large padding-bottom-large"
      onClick={() => {
        props.onClick();
      }}
    >
      <span className="font-family-gilroy-regular font-color-secondary padding-right-smaller">
        {props.authMode
          ? "Don’t have a ABHA address?"
          : "Already have a ABHA address? "}
      </span>
      <span className="font-family-gilroy-medium font-color-primary">
        {props.authMode ? "Register" : "Login"}
      </span>
    </div>
  );
};

const AuthModeSwitcher = (props) => {
  return (
    <div
      className="inherit-parent-width padding-top-large margin-bottom-larger display-flex flex-justify-content-space-between flex-center-children-vertically border-bottom-1px-e5e5e5"
      data-cy="abdm-auth-switcher"
      onClick={props.disable ? () => {} : props.downArrowOnClick}
    >
      <div
        data-cy={`login-auth-mode-${props.data.value}`}
        className="padding-left-large padding-right-default display-flex flex-direction-row"
      >
        <img className="padding-right-default" src={props.data.logo} alt="" />
        <div className="inherit-parent-width padding-default">
          <div className="font-family-gilroy-regular padding-bottom-smaller">
            {props.data.name}
          </div>
          <div className="font-size-smaller">{props.data.description}</div>
        </div>
      </div>
      {!props.disable && (
        <div className="padding-right-large">
          <img src={arrow} alt="arrow" />
        </div>
      )}
    </div>
  );
};

const authModes = {
  phone: {
    name: "Phone",
    value: "phoneNumber",
    logo: phone,
    "data-cy": "auth-mode-phoneNumber",
    description: `${
      window.location.pathname.includes("login") ? "LOGIN" : "REGISTER"
    } VIA PHONE ${
      window.location.pathname.includes("login")
        ? "NO. CONNECTED WITH ABHA"
        : "NUMBER"
    }`
  },
  abhaAddress: {
    name: "ABHA Address",
    value: "healthId",
    logo: at,
    "data-cy": "auth-mode-healthId",
    description: `${
      window.location.pathname.includes("login") ? "LOGIN" : "REGISTER"
    } VIA ABHA ADDRESS CONNECTED WITH YOUR PROFILE`
  },
  abhaNumber: {
    name: "ABHA Number",
    value: "abhaNumber",
    logo: number,
    "data-cy": "auth-mode-abhaNumber",
    description: `${
      window.location.pathname.includes("login") ? "LOGIN" : "REGISTER"
    } VIA ABHA ${
      window.location.pathname.includes("login")
        ? "NO. CONNECTED WITH ABHA"
        : "NUMBER"
    }`
  },
  mail: {
    name: "Email",
    value: "email",
    logo: mail,
    "data-cy": "auth-mode-email",
    description: `${
      window.location.pathname.includes("login") ? "LOGIN" : "REGISTER"
    } VIA MAIL ${
      window.location.pathname.includes("login")
        ? "ID CONNECTED WITH ABHA"
        : "ID"
    }`
  }
};

const authTypes = {
  phone: {
    name: "Phone",
    value: "MOBILE_OTP",
    "data-cy": "auth-type-mobile",
    description: "Enter your OTP which is sent to your registered Phone number"
  },
  password: {
    name: "Password",
    value: "PASSWORD",
    "data-cy": "auth-type-password",
    description: "Enter your password to continue using ABHA address"
  },
  aadhar: {
    name: "Aadhar",
    value: "AADHAAR_OTP",
    "data-cy": "auth-type-aadhar",
    description: "Enter your OTP which is sent to your registered Aadhar number"
  },
  email: {
    name: "Email",
    value: "EMAIL_OTP",
    "data-cy": "auth-type-email",
    description: "Enter your OTP which is sent to your registered Email ID"
  }
};
