import {
  RecaptchaVerifier,
  signOut,
  updateProfile,
  PhoneAuthProvider,
  signInWithCredential,
  signInWithCustomToken
} from "firebase/auth";
import { auth } from "./firebase";
import { useEffect, useRef } from "react";
import LogRocket from "logrocket";
import { refreshAccessToken } from "../redux/authentication/actions";

//invisible recaptcha verifier
export function reCaptchaVerifier() {
  if (window.Cypress || process.env.REACT_APP_STAGING === "local") {
    auth.settings.appVerificationDisabledForTesting = true;
  }
  return new RecaptchaVerifier(
    "recaptcha-placeholder",
    {
      size: "invisible"
    },
    auth
  );
}

export async function otpRequest(phoneNumber) {
  const appVerifier = window.reCaptchaVerifier;

  const authProvider = new PhoneAuthProvider(auth);
  return await authProvider.verifyPhoneNumber(phoneNumber, appVerifier);
}

export function getCurrentUser() {
  return auth.currentUser;
}

export async function otpValidate(loginOtp, verificationId) {
  const authCredentials = PhoneAuthProvider.credential(
    verificationId,
    loginOtp
  );
  return authCredentials;
}

export async function signInWithAuthCredentials(authCredentials) {
  const response = await signInWithCredential(auth, authCredentials);
  return response;
}

//SET USER DISPLAY NAME
export async function setDisplayName(userName) {
  const currentUser = getCurrentUser();
  let displayNames;
  try {
    if (currentUser.displayName === null) {
      displayNames = {};
    } else {
      displayNames = JSON.parse(currentUser.displayName);
    }
  } catch (error) {
    displayNames = {};
  }
  displayNames.patient = userName;
  await updateProfile(currentUser, {
    displayName: JSON.stringify(displayNames)
  });
  return displayNames;
}

//signup using auth credentials
export async function signUpWithAuthCredentials(authCredentials, userName) {
  const response = await signInWithCredential(auth, authCredentials);
  if (response.user.displayName === null) {
    const displayNames = {
      doctor: null,
      patient: userName,
      clinic: null
    };
    await updateProfile(response.user, {
      displayName: JSON.stringify(displayNames)
    });
  } else {
    let existingDisplayNames;
    try {
      existingDisplayNames = JSON.parse(response.user.displayName);
    } catch (error) {
      existingDisplayNames = {};
    }
    const displayNames = {
      ...existingDisplayNames,
      patient: userName
    };
    await updateProfile(response.user, {
      displayName: JSON.stringify(displayNames)
    });
  }
  return response;
}

export async function signInWithAuthCustomToken(customToken) {
  const response = await signInWithCustomToken(auth, customToken);
  return response;
}

//auth subscriber
export function useAuthSubscriber(onAuthStateChange) {
  const isAuthed = useRef(false);

  useEffect(() => {
    auth.onIdTokenChanged(async (user) => {
      if (user) {
        const accessToken = await user.getIdToken();
        refreshAccessToken(accessToken, user.phoneNumber);
        if (isAuthed.current !== true) {
          isAuthed.current = true;
          LogRocket.identify(user.uid, {
            phoneNumber: user.phoneNumber
          });
          onAuthStateChange(isAuthed.current);
        }
      } else if (!user && isAuthed.current === true) {
        logout();
        isAuthed.current = false;
        onAuthStateChange(isAuthed.current);
      }
    });
    // eslint-disable-next-line
  }, []);
}

//logout subscriber
export const logout = async () => {
  await signOut(auth);
};
