import React, { useRef, useState, useEffect } from "react";
import "./OTPInputBox.css";
import PropTypes from "prop-types";
import ConditionalRender from "../ConditionalRender/ConditionalRender";

function OTPInputBox(props) {
  const [otpValue, setSetOtpValue] = useState(["", "", "", "", "", ""]);
  const [currentFocus, setCurrentFocus] = useState(0);
  const inputBoxes = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];
  //for focus
  useEffect(() => {
    inputBoxes[currentFocus].current.focus();
    inputBoxes[currentFocus].current.select();
    // eslint-disable-next-line
  }, [currentFocus]);

  //set value of OTP
  useEffect(() => {
    typeof props.setValue === "function" && props.setValue(otpValue.join(""));
    // eslint-disable-next-line
  }, [otpValue]);

  const changeOTPValue = (key, refIndex) => {
    if (refIndex === 5) {
      inputBoxes[5].current.blur();
    }
    if (key === "") {
      let newOTPValue = [...otpValue];
      newOTPValue[refIndex] = "";
      setSetOtpValue(newOTPValue);
      if (refIndex > 0) {
        setCurrentFocus(refIndex - 1);
      }
      return;
    }
    if (/^\d+$/.test(key)) {
      if (refIndex < 5) {
        let newOTPValue = [...otpValue];
        for (const [index, number] of key.split("").entries()) {
          if (refIndex + index > 5) {
            break;
          }
          newOTPValue[refIndex + index] = number;
        }
        setSetOtpValue(newOTPValue);
        refIndex + key.length <= 5
          ? setCurrentFocus(refIndex + key.length)
          : setCurrentFocus(5);
      } else if (refIndex === 5) {
        let newOTPValue = [...otpValue];
        newOTPValue[refIndex] = key[key.length - 1];
        setSetOtpValue(newOTPValue);
        setCurrentFocus(refIndex);
      }
    }
  };

  const className =
    "flex-justify-content-space-between inherit-parent-width margin-top-small";
    return (
      <>
        <ConditionalRender condition={!props.removeDefaultLabel}>
          <p className="text-align-center font-size-smaller letter-spacing-large text-transform-uppercase ">
            {props.label ? props.label : "PLEASE ENTER OTP"}
          </p>
        </ConditionalRender>
        <div
          className={`${className} ${props.className ? props.className : ""}`}
          data-cy={"OTP-box"}
        >
          <input
            inputMode="numeric"
            autoFocus={props.otpFocus}
            pattern="[0-9]*"
            data-cy="otp1"
            onPaste={(event) => {
              event.preventDefault();
              const clipboardData = (
                event.clipboardData || window.clipboardData
              ).getData("text");
              if (
                typeof clipboardData === "string" &&
                clipboardData.trim().length !== 0 &&
                clipboardData.trim().length >= 6 &&
                /^\d+$/.test(clipboardData.trim())
              ) {
                setSetOtpValue(clipboardData.trim().split(""));
                setCurrentFocus(5);
              }
            }}
            className={`${
              props.size === "small"
                ? "otp-input-box-size-small"
                : "otp-input-box-size"
            } max-width-54px padding-default ${
              props.error
                ? "border-bottom-otp-box-error"
                : "border-bottom-otp-box"
            } background-white font-size-medium flex-center-children-horizontally text-align-center font-family-gilroy-medium`}
            ref={inputBoxes[0]}
            type="text"
            onClick={(event) => {
              event.preventDefault();
            }}
            onInput={(event) => {
              event.preventDefault();
              event.stopPropagation();
              changeOTPValue(event.target.value, 0);
            }}
            onFocus={(event) => {
              event.preventDefault();
              inputBoxes[currentFocus].current.focus();
            }}
            value={otpValue[0]}
            autoComplete={"off"}
          />
          <input
            inputMode="numeric"
            pattern="[0-9]*"
            data-cy="otp2"
            ref={inputBoxes[1]}
            className={`${
              props.size === "small"
                ? "otp-input-box-size-small"
                : "otp-input-box-size"
            } max-width-54px padding-default ${
              props.error
                ? "border-bottom-otp-box-error"
                : "border-bottom-otp-box"
            } background-white font-size-medium flex-center-children-horizontally text-align-center font-family-gilroy-medium`}
            type="text"
            onClick={(event) => {
              event.preventDefault();
            }}
            onInput={(event) => {
              event.preventDefault();
              event.stopPropagation();
              changeOTPValue(event.target.value, 1);
            }}
            onKeyUp={(event) => {
              event.preventDefault();
              if (event.key === "Backspace" && event.target.value === "") {
                setCurrentFocus(0);
              }
            }}
            onFocus={(event) => {
              event.preventDefault();
              inputBoxes[currentFocus].current.focus();
            }}
            value={otpValue[1]}
            autoComplete={"off"}
          />
          <input
            inputMode="numeric"
            pattern="[0-9]*"
            data-cy="otp3"
            ref={inputBoxes[2]}
            className={`${
              props.size === "small"
                ? "otp-input-box-size-small"
                : "otp-input-box-size"
            } max-width-54px padding-default ${
              props.error
                ? "border-bottom-otp-box-error"
                : "border-bottom-otp-box"
            } background-white font-size-medium flex-center-children-horizontally text-align-center font-family-gilroy-medium`}
            type="text"
            onClick={(event) => {
              event.preventDefault();
            }}
            onInput={(event) => {
              event.preventDefault();
              event.stopPropagation();
              changeOTPValue(event.target.value, 2);
            }}
            onKeyUp={(event) => {
              event.preventDefault();
              if (event.key === "Backspace" && event.target.value === "") {
                setCurrentFocus(1);
              }
            }}
            onFocus={(event) => {
              event.preventDefault();
              inputBoxes[currentFocus].current.focus();
            }}
            value={otpValue[2]}
            autoComplete={"off"}
          />
          <input
            inputMode="numeric"
            pattern="[0-9]*"
            data-cy="otp4"
            ref={inputBoxes[3]}
            className={`${
              props.size === "small"
                ? "otp-input-box-size-small"
                : "otp-input-box-size"
            } ${
              props.error
                ? "border-bottom-otp-box-error"
                : "border-bottom-otp-box"
            } max-width-54px padding-default  background-white font-size-medium flex-center-children-horizontally text-align-center font-family-gilroy-medium`}
            type="text"
            onClick={(event) => {
              event.preventDefault();
            }}
            onInput={(event) => {
              event.preventDefault();
              event.stopPropagation();
              changeOTPValue(event.target.value, 3);
            }}
            onKeyUp={(event) => {
              event.preventDefault();
              if (event.key === "Backspace" && event.target.value === "") {
                setCurrentFocus(2);
              }
            }}
            onFocus={(event) => {
              event.preventDefault();
              inputBoxes[currentFocus].current.focus();
            }}
            value={otpValue[3]}
            autoComplete={"off"}
          />
          <input
            inputMode="numeric"
            pattern="[0-9]*"
            data-cy="otp5"
            ref={inputBoxes[4]}
            className={`${
              props.size === "small"
                ? "otp-input-box-size-small"
                : "otp-input-box-size"
            } max-width-54px padding-default ${
              props.error
                ? "border-bottom-otp-box-error"
                : "border-bottom-otp-box"
            } background-white font-size-medium flex-center-children-horizontally text-align-center font-family-gilroy-medium`}
            type="text"
            onClick={(event) => {
              event.preventDefault();
            }}
            onInput={(event) => {
              event.preventDefault();
              event.stopPropagation();
              changeOTPValue(event.target.value, 4);
            }}
            onKeyUp={(event) => {
              event.preventDefault();
              if (event.key === "Backspace" && event.target.value === "") {
                setCurrentFocus(3);
              }
            }}
            onFocus={(event) => {
              event.preventDefault();
              inputBoxes[currentFocus].current.focus();
            }}
            value={otpValue[4]}
            autoComplete={"off"}
          />
          <input
            inputMode="numeric"
            pattern="[0-9]*"
            data-cy="otp6"
            ref={inputBoxes[5]}
            className={`${
              props.size === "small"
                ? "otp-input-box-size-small"
                : "otp-input-box-size"
            } max-width-54px padding-default ${
              props.error
                ? "border-bottom-otp-box-error"
                : "border-bottom-otp-box"
            } background-white font-size-medium flex-center-children-horizontally text-align-center font-family-gilroy-medium`}
            type="text"
            value={otpValue[5]}
            onClick={(event) => {
              event.preventDefault();
            }}
            onInput={(event) => {
              event.preventDefault();
              event.stopPropagation();
              changeOTPValue(event.target.value, 5);
            }}
            onKeyUp={(event) => {
              event.preventDefault();
              if (event.key === "Backspace" && event.target.value === "") {
                setCurrentFocus(4);
              }
            }}
            onFocus={(event) => {
              event.preventDefault();
              inputBoxes[currentFocus].current.focus();
            }}
            autoComplete={"off"}
            maxLength={1}
          />
        </div>
      </>
    );
}

OTPInputBox.propTypes = {
  /**
   * function which give OTP value as arguments
   */
  setValue: PropTypes.func,
  /**
   * which is used to style to style the parent element
   */
  className: PropTypes.string,
  /**
   * label text property
   */
  label: PropTypes.string
};

OTPInputBox.defaultProps = {
  setValue: undefined,
  className: "",
  label: "PLEASE ENTER OTP"
};
export default OTPInputBox;
