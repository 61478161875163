import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import statusReducer from "./status/reducer";
import appReducer from "./app/reducer";
import abdmReducer from "./abdm/reducer";
import authReducer from "./authentication/reducer";
import downloadsReducer from "./downloads/reducer";
import queryReducer from "./query/reducer";
import notificationsReducer from "./notifications/reducer";
import documentsReducer from "./documents/reducer";
import patientsReducer from "./patients/reducer";
import intentReducer from "./intent/reducer";

const abdmPersistConfig = {
  key: "abdm",
  storage: storage,
  blacklist: [
    "loading",
    "healthIdExists",
    "healthId",
    "transactionId",
    "newHealthNumberData"
  ]
};

const authPersistConfig = {
  key: "auth",
  storage: storage,
  blacklist: ["loading", "credentials", "oAuth"]
};

const documentsPersistConfig = {
  key: "documents",
  storage: storage,
  blacklist: ["loading", "latest"]
};

const statusPersistConfig = {
  key: "status",
  storage: storage,
  blacklist: ["code", "message"]
};

const notificationsPersistConfig = {
  key: "notifications",
  storage: storage,
  blacklist: ["loading", "data", "paginationLoading", "pagination"]
};

const queryPersistConfig = {
  key: "query",
  storage: storage,
  blacklist: ["loading"]
};

const downloadsPersistConfig = {
  key: "downloads",
  storage: storage,
  blacklist: ["loading"]
};

const appPersistConfig = {
  key: "app",
  storage: storage,
  blacklist: []
};

const intentPersistConfig = {
  key: "intent",
  storage: storage,
  blacklist: ["loading"]
};

const patientsPersistConfig = {
  key: "patients",
  storage: storage,
  blacklist: ["loading", "transactionId", "authModes", "abhaNumberData"]
};

const rootPersistConfig = {
  key: "root",
  storage: storage,
  blacklist: [
    "abdm",
    "auth",
    "profile",
    "documents",
    "notifications",
    "query",
    "downloads",
    "status",
    "app",
    "patients"
  ]
};

//root reducer
const rootReducer = combineReducers({
  abdm: persistReducer(abdmPersistConfig, abdmReducer),
  auth: persistReducer(authPersistConfig, authReducer),
  documents: persistReducer(documentsPersistConfig, documentsReducer),
  intent: persistReducer(intentPersistConfig, intentReducer),
  status: persistReducer(statusPersistConfig, statusReducer),
  notifications: persistReducer(
    notificationsPersistConfig,
    notificationsReducer
  ),
  query: persistReducer(queryPersistConfig, queryReducer),
  downloads: persistReducer(downloadsPersistConfig, downloadsReducer),
  app: persistReducer(appPersistConfig, appReducer),
  patients: persistReducer(patientsPersistConfig, patientsReducer)
});

export const persistedReducer = persistReducer(rootPersistConfig, rootReducer);
