import store from "../store/store";
import { intentActionTypes } from "./saga";

/**
 * @module IntentSaga
 */

/**
 * put REG TOKEN DATA
 * @param {object} data
 */
export function putRegistrationData(data, navigate) {
  store.dispatch({
    type: intentActionTypes.PUT_REGISTRATION_DATA,
    payload: {
      data: data,
      navigate: navigate
    }
  });
}

/**
 * ADD REG TOKEN DATA
 * @param {object} data
 */
export function addRegistrationData(data) {
  store.dispatch({
    type: intentActionTypes.ADD_REGISTRATION_DATA,
    payload: {
      data: data
    }
  });
}
