import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import Header from "../../front-end-global-components/components/Header/Header";
import emptyDocument from "../../assets/images/fallback.svg";
import "./Abdm.css";
import Button from "../../front-end-global-components/components/Button/Button";
import { isValidObject } from "../../utils/validators";
import Modal from "../../front-end-global-components/components/Modal/Modal";
import OTPInputBox from "../../front-end-global-components/components/OTPInputBox/OTPInputBox";
import {
  clearAbdmAuthCredentials,
  clearIsRecordFetched,
  clearLinkRefNumber,
  confirmOtpToLinkAbdmCareContext,
  discoverCareContextWithClinicId,
  getScannedClinicDetails,
  sendOtpToLinkAbdmCareContext,
  setSearchHistory,
  shareProfile
} from "../../redux/abdm/actions";
import { ArrowIcon } from "../../front-end-global-components/assets/assets";
import ConditionalRender from "../../front-end-global-components/components/ConditionalRender/ConditionalRender";
import { getValidGender } from "../../utils/constants";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import { ClinicCard, DocumentCard } from "../../components/Cards/Cards";
import HandleBackClick from "../../front-end-global-components/components/HandleBackClick/HandleBackClick";
import CheckBox from "../../front-end-global-components/components/CheckBox/CheckBox";
import ProcessSuccess from "../../components/ProcessSuccess/ProcessSuccess";

export const ViewSearchedClinicCareContext = (props) => {
  const { clinicId } = useParams();
  const [OTPValue, setOTPValue] = useState(null);
  const [showOTPModal, setShowOTPModal] = useState(false);
  const [careContext, setCareContext] = useState({});
  const [clinicDetails, setClinicDetails] = useState({});
  const [selectedCareContexts, setSelectedCareContexts] = useState({});
  const [menu, setMenu] = useState("menu");
  const [isAgreed, setIsAgreed] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [options, setOptions] = useState([
    {
      name: "Fetch Record",
      description:
        "You can fetch records which has been uploaded by your clinic",
      "data-cy": "fetchRecords-menu",
      onClick: () => {
        setMenu("patientDetails");
      }
    }
    // {
    //   name: "Book Appointment",
    //   description: "You can able to book appointment with your clinic",
    //   "data-cy": "bookAppointment-menu",
    //   onClick: () => {
    //     setMenu("bookAppointment");
    //   }
    // },
    // {
    //   name: "Blood Request",
    //   description:
    //     "You can able to donate your blood in your nearest or requested clinic",
    //   "data-cy": "bloodRequest-menu",
    //   onClick: () => {
    //     setMenu("bloodRequest");
    //   }
    // }
  ]);

  useEffect(() => {
    if (props.abdm.clinics?.search?.linkRefNumber) {
      setShowOTPModal(true);
    } else {
      setShowOTPModal(false);
    }
    // eslint-disable-next-line
  }, [props.abdm.clinics.search.linkRefNumber]);

  useEffect(() => {
    props.clearLinkRefNumber();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (
      clinicDetails.hipCode &&
      !isValidObject(options.find((ele) => ele.name === "Share Profile"))
    ) {
      setOptions([
        ...options,
        {
          name: "Share Profile",
          description: "You can share your profile information with the clinic",
          "data-cy": "shareProfile-menu",
          onClick: () => {
            setMenu("share");
          }
        }
      ]);
    }

    if (clinicDetails.hipCode && clinicDetails.token && menu === "share") {
      setMenu("shareSuccess");
    }
    // eslint-disable-next-line
  }, [clinicDetails, menu]);

  useEffect(() => {
    if (props.abdm.clinics.search.isRecordFetched && menu === "fetchRecords") {
      setMenu("fetchSuccess");
    }
  }, [props.abdm.clinics.search.isRecordFetched, menu]);

  function fetchCareContextDetails() {
    if (
      !!clinicId &&
      isValidObject(props.abdm.clinics.search.careContexts?.[clinicId])
    ) {
      setCareContext(props.abdm.clinics.search.careContexts[clinicId]);
    }
  }

  useEffect(() => {
    if (
      isValidObject(props.abdm.clinics.search.results) &&
      props.abdm.clinics.search.results[clinicId] &&
      isValidObject(props.abdm.clinics.search.results[clinicId])
    ) {
      return;
    } else if (
      isValidObject(props.abdm.clinics.search.searchHistory) &&
      props.abdm.clinics.search.searchHistory[clinicId] &&
      isValidObject(props.abdm.clinics.search.searchHistory[clinicId])
    ) {
      return;
    } else {
      props.searchAbdmClinicsWithHipId(clinicId, "search");
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (clinicId) {
      if (
        isValidObject(props.abdm.clinics.search.results) &&
        isValidObject(props.abdm.clinics.search.results[clinicId])
      ) {
        setClinicDetails(props.abdm.clinics.search.results[clinicId]);
        if (
          props.abdm.clinics.search.searchHistory?.[clinicId] &&
          !isValidObject(props.abdm.clinics.search.searchHistory[clinicId])
        ) {
          props.setSearchHistory({
            [clinicId]: {
              ...props.abdm.clinics.search.results[clinicId],
              timestamp: +new Date()
            }
          });
        }
      } else if (
        isValidObject(props.abdm.clinics.search.searchHistory) &&
        isValidObject(props.abdm.clinics.search.searchHistory[clinicId])
      ) {
        setClinicDetails(props.abdm.clinics.search.searchHistory[clinicId]);
      } else if (
        isValidObject(props.abdm.clinics.search.publicHealthPrograms) &&
        isValidObject(props.abdm.clinics.search.publicHealthPrograms[clinicId])
      ) {
        setClinicDetails(
          props.abdm.clinics.search.publicHealthPrograms[clinicId]
        );
      } else {
        // props.navigate(-1);
      }
    } else {
      props.navigate(-1);
    }
    // eslint-disable-next-line
  }, [
    clinicId,
    props.abdm.clinics.search.results,
    props.abdm.clinics.search.searchHistory,
    props.abdm.clinics.search.publicHealthPrograms
  ]);

  useEffect(() => {
    fetchCareContextDetails();
    // eslint-disable-next-line
  }, [clinicId, props.abdm.clinics.search.careContexts]);

  useEffect(() => {
    if (
      props.abdm.clinics.search.publicHealthPrograms &&
      props.abdm.clinics.search.publicHealthPrograms[clinicId] &&
      isValidObject(props.abdm.clinics.search.publicHealthPrograms[clinicId])
    ) {
      setMenu("patientDetails");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [healthData, setHealthData] = useState(null);
  const [patientId, setPatientId] = useState(null);

  useEffect(() => {
    if (
      isValidObject(props.patients.healthIdData) &&
      props.patients.currentProfile
    ) {
      setHealthData(props.patients.healthIdData[props.patients.currentProfile]);
    }
  }, [props.patients.healthIdData, props.patients.currentProfile]);

  const patientData = [
    {
      name: "VERIFIED MOBILE NUMBER",
      value: healthData?.mobile ? `+91${healthData?.mobile}` : "Nill"
    },
    {
      name: "ABHA ADDRESS",
      value: healthData?.id ? healthData?.id : "Nill"
    },
    {
      name: "ABHA NUMBER",
      value: healthData?.healthId ? healthData?.healthId : "Nill"
    },
    {
      name: "NAME",
      value: healthData?.fullName ? healthData?.fullName : "Nill"
    },
    {
      name: "GENDER",
      value: healthData?.gender ? getValidGender(healthData?.gender) : "Nill"
    },
    {
      name: "YEAR OF BIRTH",
      value: healthData?.dateOfBirth?.year
        ? healthData?.dateOfBirth?.year
        : "Nill"
    }
  ];

  return (
    <React.Fragment>
      <Header
        title={
          menu === "menu"
            ? "Hospital / lab"
            : menu === "share"
            ? "Connect with your clinic"
            : menu === "fetchRecords" || menu === "patientDetails"
            ? "Fetch Records"
            : menu === "bloodRequest"
            ? "Blood Request"
            : menu === "bookAppointment" && "Book Appointment"
        }
        backButtonOnClick={() => {
          if (
            props.abdm.clinics.search.publicHealthPrograms &&
            props.abdm.clinics.search.publicHealthPrograms[clinicId] &&
            isValidObject(
              props.abdm.clinics.search.publicHealthPrograms[clinicId]
            )
          ) {
            return props.navigate(-1);
          }
          if (menu === "menu") {
            props.navigate("/");
          } else if (menu === "fetchRecords") {
            setMenu("patientDetails");
          } else {
            setMenu("menu");
          }
        }}
        supportIconOnClick={() => {
          props.navigate("/query");
        }}
        incident={props.app.featureFlags?.incident?.patients}
        incidentOnClick={() => {
          props.navigate("/incidents");
        }}
      />

      <section className="remaining-body-height max-width-588px margin-horizontal-auto ">
        <>
          <ClinicCard
            loading={props.abdm.loading}
            clinicDetails={clinicDetails}
            logoURL={null}
          />

          <ConditionalRender condition={props.abdm.loading}>
            <DocumentCardLoading />
          </ConditionalRender>

          <ConditionalRender condition={menu === "menu" && !props.abdm.loading}>
            {options.map((data, index) => (
              <div key={index} className="padding-large">
                <div
                  className="display-flex flex-align-items-center flex-justify-content-space-between "
                  data-cy={data["data-cy"]}
                  onClick={data.onClick}
                >
                  <div>
                    <div>{data.name}</div>
                    <div className="font-size-small padding-top-default padding-right-large">
                      {data.description}
                    </div>
                  </div>
                  <ArrowIcon />
                </div>
              </div>
            ))}
          </ConditionalRender>

          <ConditionalRender
            condition={menu === "patientDetails" && !props.abdm.loading}
          >
            {/* <HandleBackClick
              onClose={() => {
                setMenu("menu");
              }}
              isBackEnabled={menu === "patientDetails" ? true : false}
            > */}
            <section className="remaining-height-of-abdm-clinic-care-context-section flex-justify-content-space-between display-flex flex-direction-column">
              <div>
                <div className="padding-top-default">
                  {patientData.map((data, index) => (
                    <div
                      key={index}
                      className="padding-left-large padding-right-large padding-bottom-default padding-top-default font-size-medium"
                    >
                      <div className="padding-bottom-small">{data.name}</div>
                      <div
                        className={`${
                          data.name === "ABHA ADDRESS" ? "" : "text-capitalize"
                        } font-size-small`}
                      >
                        {data.value}
                      </div>
                    </div>
                  ))}
                </div>
                <form
                  className="flex-justify-content-space-between flex-direction-column"
                  onChange={(event) => {
                    setPatientId(event.target.value);
                  }}
                >
                  <InputBox
                    labelClassName="letter-spacing-4-percentage"
                    className="inherit-parent-width border-radius-default padding-left-large padding-right-large"
                    name="patientId"
                    label={`${
                      clinicDetails?.attributes?.hipAttributes
                        ?.discoveryAdditionalFieldLabel
                        ? clinicDetails?.attributes?.hipAttributes
                            ?.discoveryAdditionalFieldLabel
                        : "PATIENT ID (OPTIONAL)"
                    }`}
                    type="text"
                    required
                  />
                </form>
              </div>
              <div className="inherit-parent-width padding-left-large padding-right-large padding-left-large padding-top-larger padding-bottom-large">
                <Button
                  text="Continue"
                  data-cy="continue-to-fetch-records"
                  boxShadow={false}
                  onClick={() => {
                    setMenu("fetchRecords");
                    props.fetchCareContexts(clinicId, patientId);
                  }}
                />
              </div>
            </section>
            {/* </HandleBackClick> */}
          </ConditionalRender>

          <ConditionalRender
            condition={menu === "share" && !props.abdm.loading}
          >
            {/* <HandleBackClick
              onClose={() => {
                setMenu("menu");
              }}
              isBackEnabled={menu === "share" ? true : false}
            > */}
            <section className="remaining-height-of-abdm-clinic-care-context-section display-flex flex-justify-content-space-between flex-direction-column">
              <div>
                <div className="padding-top-default">
                  {patientData.map((data, index) => (
                    <div
                      key={index}
                      className="padding-left-large padding-right-large padding-bottom-default padding-top-default font-size-medium"
                    >
                      <div className="padding-bottom-small">{data.name}</div>
                      <div
                        className={`${
                          data.name === "ABHA ADDRESS" ? "" : "text-capitalize"
                        }  font-size-small`}
                      >
                        {data.value}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              <div>
                <div className="inherit-parent-width display-flex text-align-left padding-left-large font-size-small font-family-gilroy-medium flex-align-items-center white-space-no-wrap">
                  <CheckBox
                    name="agreeTo"
                    onChange={(event) => {
                      setIsAgreed(event.target.checked);
                    }}
                    checked={isAgreed}
                    label="I agree with"
                    required={true}
                  />
                  <div
                    onClick={() => {
                      setShowModal(true);
                    }}
                    className="text-underline-hover font-color-primary padding-left-small cursor-pointer"
                  >
                    ABDM's terms and conditions
                  </div>
                </div>
                <div className="inherit-parent-width padding-left-large padding-right-large padding-left-large padding-top-larger padding-bottom-larger">
                  <Button
                    text="Continue"
                    data-cy="continue-to-fetch-records"
                    boxShadow={false}
                    onClick={() => {
                      if (isAgreed) {
                        props.shareProfile(clinicId, props.navigate);
                      }
                    }}
                    loading={props.abdm.loading}
                    disabled={!isAgreed}
                  />
                </div>
              </div>
            </section>
            {/* </HandleBackClick> */}
          </ConditionalRender>

          <ConditionalRender
            condition={menu === "fetchRecords" && !props.abdm.loading}
          >
            {/* <HandleBackClick
              onClose={() => {
                setMenu("patientDetails");
              }}
              isBackEnabled={menu === "fetchRecords" ? true : false}
            > */}
            <section className="remaining-height-of-abdm-clinic-care-context-section ">
              <PatientRecords
                clinicName={careContext?.clinicName}
                location={careContext?.location}
                otpLoading={props.abdm.OTPLoading}
                loading={props.abdm.loading}
                records={careContext}
                selectedCareContexts={selectedCareContexts}
                setSelectedCareContexts={setSelectedCareContexts}
                fetchOnClick={(data) => {
                  props.sendOtpToLinkCareContext(data, clinicId);
                }}
                retryOnClick={() => {
                  props.fetchCareContexts(clinicId, patientId);
                }}
              />
            </section>
            {/* </HandleBackClick> */}
          </ConditionalRender>

          <ConditionalRender
            condition={menu === "shareSuccess" && !props.abdm.loading}
          >
            {/* <HandleBackClick
              onClose={() => {
                setMenu("share");
              }}
              isBackEnabled={menu === "shareSuccess" ? true : false}
            > */}
            <section className="remaining-height-of-abdm-clinic-care-context-section inherit-parent-width">
              <ProcessSuccess
                title="Profile Shared Successfully"
                description={`${
                  clinicDetails.token
                    ? `YOUR TOKEN NUMBER IS ${clinicDetails.token},`
                    : ""
                } PLEASE SHARE YOUR TOKEN WITH CLINIC TO PROCEED FURTHER`}
                confirmClick={() => {
                  props.navigate("/");
                }}
              />
            </section>
            {/* </HandleBackClick> */}
          </ConditionalRender>
          <ConditionalRender
            condition={menu === "fetchSuccess" && !props.abdm.loading}
          >
            {/* <HandleBackClick
              onClose={() => {
                setMenu("fetchRecords");
                props.clearIsRecordFetched();
              }}
              isBackEnabled={menu === "fetchSuccess" ? true : false}
            > */}
            <section className="remaining-height-of-abdm-clinic-care-context-section inherit-parent-width">
              <ProcessSuccess
                title="Records Are Successfully Linked"
                description={`PLEASE WAIT A FEW MINUTES FOR YOUR RECORDS TO SHOW UP IN NINTO`}
                confirmClick={() => {
                  setMenu("fetchRecords");
                  props.navigate("/");
                }}
              />
            </section>
            {/* </HandleBackClick> */}
          </ConditionalRender>
        </>

        {/* <HandleBackClick
          onClose={() => {
            setShowOTPModal(false);
            props.clearLinkRefNumber();
          }}
          isBackEnabled={showOTPModal}
        > */}
        <OTPModal
          onClose={() => {
            setShowOTPModal(false);
            props.clearLinkRefNumber();
          }}
          onConfirmClick={() => {
            props.confirmOtpToLinkCareContext(OTPValue, props.navigate);
          }}
          disableButton={OTPValue?.length === 6 ? false : true}
          loading={props.abdm.OTPLoading}
          showOTPModal={showOTPModal}
          resendOTPOnClick={() => {
            props.clearLinkRefNumber();
            props.sendOtpToLinkCareContext(selectedCareContexts, clinicId);
          }}
          setOTPValue={(otp) => setOTPValue(otp)}
        />
        {/* </HandleBackClick> */}

        <HandleBackClick
          onClose={() => {
            setShowModal(false);
          }}
          isBackEnabled={showModal}
        >
          <Modal
            show={showModal}
            onClose={() => {
              setShowModal(!showModal);
            }}
            canIgnore={false}
            position="position-fixed bottom-0 left-0 right-0"
            boxShadow="none"
            borderRadius="none"
            width="inherit-parent-width"
            background="background-transparent"
          >
            <div className="background-white padding-default font-family-gilroy-medium user-select-none">
              <div className="inherit-parent-width inherit-parent-height padding-bottom-large">
                <div className="text-align-center font-size-small padding-medium font-color-secondary">
                  TERMS AND CONDITION
                </div>
                <div
                  style={{ height: "280px" }}
                  className="hide-scroll-bar inherit-parent-height"
                >
                  <p
                    style={{ lineHeight: "20px", textAlign: "justify" }}
                    className="padding-bottom-default font-size-small padding-left-default padding-right-default font-color-secondary"
                  >
                    &nbsp; &nbsp; &nbsp; &nbsp; I, hereby declare that I am
                    voluntarily sharing my Aadhaar Number and demographic
                    information issued by UIDAI, with Byepo Technologies for the
                    sole purpose of creation of ABHA number . I understand that
                    my ABHA number can be used and shared for purposes as may be
                    notified by ABDM from time to time including provision of
                    healthcare services. Further, I am aware that my personal
                    identifiable information (Name, Address, Age, Date of Birth,
                    Gender and Photograph) may be made available to the entities
                    working in the National Digital Health Ecosystem (NDHE)
                    which inter alia includes stakeholders and entities such as
                    healthcare professionals (e.g. doctors), facilities (e.g.
                    hospitals, laboratories) and data fiduciaries (e.g. health
                    programmes), which are registered with or linked to the
                    Ayushman Bharat Digital Mission (ABDM), and various
                    processes there under.
                  </p>
                  <p
                    style={{ lineHeight: "20px", textAlign: "justify" }}
                    className="padding-bottom-larger font-size-small padding-left-default padding-right-default font-color-secondary"
                  >
                    I authorize Byepo Technologies to use my Aadhaar number for
                    performing Aadhaar based authentication with UIDAI as per
                    the provisions of the Aadhaar (Targeted Delivery of
                    Financial and other Subsidies, Benefits and Services) Act,
                    2016 for the aforesaid purpose. I understand that UIDAI will
                    share my e-KYC details, or response of “Yes” with Byepo
                    Technologies upon successful authentication. I have been
                    duly informed about the option of using other IDs apart from
                    Aadhaar; however, I consciously choose to use Aadhaar number
                    for the purpose of availing benefits across the NDHE. I am
                    aware that my personal identifiable information excluding
                    Aadhaar number / VID number can be used and shared for
                    purposes as mentioned above. I reserve the right to revoke
                    the given consent at any point of time as per provisions of
                    Aadhaar Act and Regulations.
                  </p>
                </div>
              </div>
            </div>
          </Modal>
        </HandleBackClick>
      </section>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  abdm: state.abdm,
  patients: state.patients,
  app: state.app
});

const mapDispatchToProps = function () {
  return {
    shareProfile: (clinicId) => shareProfile(clinicId),
    clearIsRecordFetched: () => clearIsRecordFetched(),
    setSearchHistory: (data) => setSearchHistory(data),
    searchAbdmClinicsWithHipId: (searchKey, type) =>
      getScannedClinicDetails(searchKey, null, null, type),
    fetchCareContexts: (clinicId, patientId) =>
      discoverCareContextWithClinicId(clinicId, patientId),
    clearAbdmAuthCredentials: () => clearAbdmAuthCredentials(),
    sendOtpToLinkCareContext: (data, clinicId) =>
      sendOtpToLinkAbdmCareContext(data, clinicId),
    clearLinkRefNumber: () => clearLinkRefNumber(),
    confirmOtpToLinkCareContext: (otp, navigate) =>
      confirmOtpToLinkAbdmCareContext(otp, navigate)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewSearchedClinicCareContext);

const PatientRecords = ({
  clinicName,
  location,
  records,
  retryOnClick,
  fetchOnClick,
  loading,
  otpLoading,
  selectedCareContexts,
  setSelectedCareContexts
}) => {
  return (
    <section className="inherit-parent-height ">
      {loading && <DocumentCardLoading />}

      {!loading &&
        Array.isArray(records?.careContexts) &&
        records?.careContexts.length > 0 && (
          <section
            className={`inherit-parent-height display-flex flex-direction-column flex-justify-content-space-between padding-top-large padding-bottom-large padding-right-larger padding-left-larger`}
          >
            <div className="overflow-y-auto ">
              <div className="padding-bottom-large text-align-center letter-spacing-4-percentage font-family-gilroy-regular font-color-secondary font-size-small position-sticky top-0 background-white z-index-1 ">
                NEW RECORDS FOUND
              </div>
              <ul className="abdm-care-context-document-card-border-bottom list-style-none overflow-y-auto  ">
                {records?.careContexts.map((careContext) => (
                  <li key={careContext?.referenceNumber}>
                    <DocumentCard
                      data={careContext}
                      // dateOfIssued={dateAndTimeConverter(+new Date())}
                      clinicLocation={location}
                      // documentType="Report"
                      checkBoxOnChange={(event) => {
                        if (event.target.checked === true) {
                          setSelectedCareContexts({
                            [careContext?.referenceNumber]: careContext
                          });
                        } else if (event.target.checked === false) {
                          const removeKey = (key, { [key]: _, ...rest }) =>
                            rest;
                          const data = removeKey(
                            careContext?.referenceNumber,
                            selectedCareContexts
                          );
                          setSelectedCareContexts(data);
                        }
                      }}
                    />
                  </li>
                ))}
              </ul>
            </div>
            <Button
              text="Link selected"
              data-cy="send-otp-to-fetch-records"
              boxShadow={false}
              // className="font-family-gilroy-medium  padding-bottom-large"
              disabled={
                isValidObject(selectedCareContexts) &&
                Object.keys(selectedCareContexts).length >= 1
                  ? false
                  : true
              }
              loading={otpLoading}
              onClick={(e) => {
                if (typeof fetchOnClick !== "function") {
                  return;
                } else {
                  fetchOnClick(selectedCareContexts);
                }
              }}
            />
          </section>
        )}

      {!loading &&
        !(
          Array.isArray(records?.careContexts) &&
          records?.careContexts.length > 0
        ) && (
          <NoDocumentsFound
            onClick={(event) => {
              if (typeof retryOnClick === "function") {
                retryOnClick(event);
              }
            }}
          />
        )}
    </section>
  );
};

const DocumentCardLoading = () => {
  return (
    <section
      className="padding-left-larger padding-right-larger padding-top-large padding-bottom-large"
      data-cy="document-card-loading"
    >
      <div className="inherit-parent-width display-flex flex-justify-content-center padding-bottom-large">
        <div className="shimmer width-150px padding-small  border-radius-default ">
          <div className="padding-small  border-radius-default" />
        </div>
      </div>

      {[...Array(5)].map((_data, index) => (
        <section
          key={index}
          className="flex-center-children-vertically padding-bottom-larger"
        >
          <div className="shimmer padding-small border-radius-25-percentage height-width-16px" />
          <div>
            <div className="shimmer margin-left-larger padding-default border-radius-default width-200px " />
            <div className="shimmer margin-left-larger margin-top-default padding-small border-radius-default width-150px ">
              <div className="padding-smaller" />
            </div>
          </div>
        </section>
      ))}
    </section>
  );
};

// const SearchFallBack = () => {
//   return (
//     <section
//       className="padding-left-larger padding-right-larger padding-top-large margin-top-larger padding-bottom-large"
//       data-cy="document-card-loading"
//     >
//       {[...Array(5)].map((data) => (
//         <section
//           key={data}
//           className="flex-center-children-vertically padding-bottom-larger"
//         >
//           <div className="shimmer padding-large border-radius-100-percentage" />
//           <div>
//             <div className="shimmer margin-left-larger padding-default border-radius-default width-200px " />
//             <div className="shimmer margin-left-larger margin-top-default padding-small border-radius-default width-150px ">
//               <div className="padding-smaller" />
//             </div>
//           </div>
//         </section>
//       ))}
//     </section>
//   );
// };

const NoDocumentsFound = ({ onClick }) => {
  return (
    <section className="inherit-parent-height display-flex flex-direction-column flex-justify-content-space-between overflow-y-auto">
      <section></section>
      <section
        className="text-align-center flex-place-children-page-center "
        data-cy="empty-documents-fallback"
      >
        <img
          src={emptyDocument}
          alt="empty-doc"
          width="100%"
          height="150px"
          className="padding-default margin-bottom-large"
        />
        <div className="font-family-gilroy-bold font-size-medium font-color-secondary padding-default">
          No records found.
        </div>
        <div className="font-family-gilroy-regular font-size-medium font-color-secondary padding-top-default padding-bottom-default padding-right-large padding-left-large line-height-140-percentage">
          Try fetching records from this clinic / lab to get any record
          associated with you in your health locker
        </div>
      </section>
      <Button
        text="Retry"
        boxShadow={false}
        className="font-family-gilroy-medium max-width-150px margin-horizontal-auto margin-bottom-larger margin-top-large"
        onClick={(e) => {
          if (typeof onClick !== "function") return;
          onClick(e);
        }}
      />
    </section>
  );
};

// const SuggestClinic = () => {
//   return (
//     <div className="flex-center-children-horizontally flex-direction-column">
//       <div className="text-align-center  padding-top-large">
//         <img
//           className="health-id-benefits-icons-size"
//           src={searchIcon}
//           alt="searchIcon"
//         />
//       </div>
//       <div className="text-align-center padding-top-large">
//         Can’t find your clinic / lab?
//       </div>
//       <div className="text-align-center padding-top-large">
//         Suggest the clinic or lab you have records linked. We will try our best
//         to onboard them to Ninto as soon as possible.
//       </div>
//       <Button
//         text="Suggest clinic"
//         boxShadow={false}
//         className="font-family-gilroy-medium max-width-150px padding-top-large margin-horizontal-auto margin-bottom-larger margin-top-large"
//         // onClick={(e) => {
//         //   if (typeof onClick !== "function") return;
//         //   onClick(e);
//         // }}
//       />
//     </div>
//   );
// };

// function dateAndTimeConverter(timestamp) {
//   if (!timestamp) return;
//   let dateObject = new Date(timestamp);
//   const date =
//     timestamp >= new Date().setHours(0, 0, 0, 0)
//       ? "Today"
//       : timestamp >= new Date().setHours(0, 0, 0, 0) - 86400000 &&
//         timestamp < new Date().setHours(0, 0, 0, 0)
//         ? "Yesterday"
//         : dateObject.toLocaleString("en-IN", { day: "2-digit"}) +
//         ""+
//         dateObject.toLocaleString("en-IN", { month: "short"}) +
//         ""+
//         dateObject.toLocaleString("en-IN", { year: "numeric"});
//   return date;
//   // date + ","+ dateObject.toLocaleString("en-IN", { timeStyle: "short"})
// }

const OTPModal = (props) => {
  return (
    <Modal
      canIgnore={true}
      onClose={props.onClose}
      show={props.showOTPModal}
      position="position-fixed bottom-0"
      boxShadow="none"
      borderRadius="none"
      width="inherit-parent-width"
      background="background-transparent"
      height="height-fit-to-content"
    >
      <div
        data-cy="otp-verify-modal"
        className="background-white border-top-radius-default padding-default font-family-gilroy-medium font-color-secondary flex-justify-content-space-between flex-align-items-center flex-direction-column font-size-medium padding-larger "
      >
        <OTPInputBox
          name="otp"
          setValue={props.setOTPValue}
          className="margin-bottom-larger"
          required
        />
        <h2 className="font-weight-normal font-family-gilroy-medium font-size-small font-color-secondary inherit-parent-width text-align-center">
          Didn’t receive an OTP?
          <span
            className={`margin-left-default cursor-pointer font-color-primary`}
            onClick={() => {
              if (typeof resendOTPOnClick !== "function") {
                return;
              } else {
                props.resendOTPOnClick();
              }
            }}
          >
            Resend OTP
          </span>
        </h2>
        <Button
          text="Fetch records"
          boxShadow={false}
          loading={props.loading}
          disabled={props.disableButton}
          data-cy="confirm-otp-to-fetch-records"
          className="font-family-gilroy-medium max-width-150px margin-horizontal-auto margin-top-larger"
          onClick={props.onConfirmClick}
        />
      </div>
    </Modal>
  );
};
