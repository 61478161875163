import { all, put, takeEvery, takeLatest } from "@redux-saga/core/effects";
import { isValidArray, isValidObject } from "../../utils/validators";
import store from "../store/store";
import { throwError } from "../../services/error";
import {
  consentStatus,
  getValidAbhaMethod,
  getValidAuthMode,
  lockerSetup
} from "../../utils/constants";
import {
  confirmLoginForAbdmAuth,
  verifyOtpForAbdmAuth,
  createHealthId,
  getPatientRequests,
  acceptConsentRequest,
  acceptSubscriptionRequest,
  editSubscriptionRequest,
  acceptLockerAuthorizationRequest,
  acceptAuthorizationRequest,
  acceptAutoApprove,
  createConsentPin,
  updateConsentPin,
  sendOtpToResetConsentPin,
  verifyResetConsentPinOtp,
  lockerAuthorizationRequest,
  getLockerAuthorizationRequest,
  denyRequests,
  getCareContexts,
  revokeConsentRequest,
  revokeAuthorizationRequest,
  disableAutoApprovalPolicy,
  getHealthIdData,
  editHealthIdData,
  generateOtpForAbdmAuth,
  getModesForAbdmAuth,
  confirmUnlinkAbhaAddressFromAbhaNumber,
  generateOtpForUnlinkAbhaAddressFromAbhaNumber,
  linkOrUnlinkAbhaAddressFromAbhaNumber,
  generateOTPForUpdatePhoneNumberOrEmail,
  verifyOTPForUpdatePhoneNumberOrEmail,
  abhaAddressResetPassword,
  linkAbhaNumber,
  deLinkAbhaNumber
} from "../../services/api";
import {
  addConsentAgreeToPersonalData,
  createDemographic
} from "../../services/database";
import { isValidString } from "../../front-end-global-components/services/validators";
import {
  setAbdmAuthCredentials,
  setTransactionId,
  switchPatientProfile,
  getHealthIdInformation,
  setAuthModes
} from "./actions";
import { setErrorStatus, setSuccessStatus } from "../status/actions";
import { putAuthInfo } from "../authentication/actions";
import {
  logout,
  signInWithAuthCustomToken
} from "../../services/authentication";

export const patientsActionTypes = {
  ADD_PATIENTS_DEMOGRAPHICS: "ADD_PATIENTS_DEMOGRAPHICS",
  ADD_ABDM_AUTH_CREDENTIALS: "ADD_ABDM_AUTH_CREDENTIALS",
  GET_MODES_FOR_ABDM_AUTH: "GET_MODES_FOR_ABDM_AUTH",
  SWITCH_PATIENT_PROFILE: "SWITCH_PATIENT_PROFILE",
  RESET_ABHA_PASSWORD: "RESET_ABHA_PASSWORD",
  SEND_OTP_FOR_ABDM_AUTHENTICATION: "SEND_OTP_FOR_ABDM_AUTHENTICATION",
  VERIFY_ABDM_AUTHENTICATION_OTP: "VERIFY_ABDM_AUTHENTICATION_OTP",
  LOGOUT_HEALTH_ID: "LOGOUT_HEALTH_ID",
  CLEAR_ABDM_UNUSED_HEALTH_ID: "CLEAR_ABDM_UNUSED_HEALTH_ID",
  CLEAR_ABDM_LINKED_PROFILES: "CLEAR_ABDM_LINKED_PROFILES",
  CLEAR_HEALTH_ID_CREATED_STATUS: "CLEAR_HEALTH_ID_CREATED_STATUS",
  CLEAR_SHOW_SUCCESS: "CLEAR_SHOW_SUCCESS",
  CHECK_CONSENT_PIN_EXISTS: "CHECK_CONSENT_PIN_EXISTS",
  MANAGE_CONSENT_PIN: "MANAGE_CONSENT_PIN",
  GET_PATIENTS_CARE_CONTEXTS: "GET_PATIENTS_CARE_CONTEXTS",
  LOGIN_WITH_HEALTH_ID: "LOGIN_WITH_HEALTH_ID",
  LINKING_TOKEN_DETAILS: "LINKING_TOKEN_DETAILS",
  CREATE_PATIENTS_DEMOGRAPHIC: "CREATE_PATIENTS_DEMOGRAPHIC",
  PUT_TRANSACTION_ID: "PUT_TRANSACTION_ID",
  CREATE_HEALTH_ID: "CREATE_HEALTH_ID",
  GET_PATIENTS_REQUESTS: "GET_PATIENTS_REQUESTS",
  ACCEPT_CONSENT_REQUEST: "ACCEPT_CONSENT_REQUEST",
  ACCEPT_SUBSCRIPTION_REQUEST: "ACCEPT_SUBSCRIPTION_REQUEST",
  ACCEPT_LOCKER_REQUEST: "ACCEPT_LOCKER_REQUEST",
  ACCEPT_AUTHORIZATION_REQUEST: "ACCEPT_AUTHORIZATION_REQUEST",
  HEALTH_LOCKER_AUTHORIZATION: "HEALTH_LOCKER_AUTHORIZATION",
  DENY_CONSENT_REQUEST: "DENY_CONSENT_REQUEST",
  DENY_SUBSCRIPTION_REQUEST: "DENY_SUBSCRIPTION_REQUEST",
  DENY_AUTHORIZATION_REQUEST: "DENY_AUTHORIZATION_REQUEST",
  ADD_PATIENTS_SUBSCRIPTION: "ADD_PATIENTS_SUBSCRIPTION",
  ADD_AUTO_APPROVAL_POLICIES: "ADD_AUTO_APPROVAL_POLICIES",
  REVOKE_CONSENT_REQUEST: "REVOKE_CONSENT_REQUEST",
  REVOKE_AUTHORIZATION_REQUEST: "REVOKE_AUTHORIZATION_REQUEST",
  DISABLE_CONSENT_AUTO_APPROVE: "DISABLE_CONSENT_AUTO_APPROVE",
  ENABLE_CONSENT_AUTO_APPROVE: "ENABLE_CONSENT_AUTO_APPROVE",
  GET_HEALTH_ID_INFORMATION: "GET_HEALTH_ID_INFORMATION",
  EDIT_PROFILE: "EDIT_PROFILE",
  GENERATE_OTP_TO_UPDATE_PHONE_NUMBER_OR_EMAIL:
    "GENERATE_OTP_TO_UPDATE_PHONE_NUMBER_OR_EMAIL",
  VERIFY_OTP_TO_UPDATE_PHONE_NUMBER_OR_EMAIL:
    "VERIFY_OTP_TO_UPDATE_PHONE_NUMBER_OR_EMAIL",
  SET_AUTH_MODES: "SET_AUTH_MODES",
  SET_REQUESTS_FILTER_DATA: "SET_REQUESTS_FILTER_DATA",
  SEARCH_HEALTH_ID_AUTH_MODE: "SEARCH_HEALTH_ID_AUTH_MODE",
  GENERATE_LINK_OR_UNLINK_ABHA_ADDRESS_OTP:
    "GENERATE_LINK_OR_UNLINK_ABHA_ADDRESS_OTP",
  CONFIRM_LINK_OR_UNLINK_ABHA_ADDRESS: "CONFIRM_LINK_OR_UNLINK_ABHA_ADDRESS",
  SELECTED_LINKED_FACILITY: "SELECTED_LINKED_FACILITY",
  LINK_HEALTH_ID_NUMBER: "LINK_HEALTH_ID_NUMBER",
  ACTIVE_SETTINGS_MENU: "ACTIVE_SETTINGS_MENU",
  SET_CONSENT_TO_PERSONAL_DATA: "SET_CONSENT_TO_PERSONAL_DATA"
};

function* setAbdmAuthCredentialsWorker(action) {
  try {
    yield put({
      type: "SET_ABDM_OTP_CREDENTIALS",
      payload: {
        requestMethod: action.payload.requestMethod,
        transactionId: action.payload.transactionId
      }
    });
  } catch (error) {
    setErrorStatus(error);
  }
}

function* addPatientsDemographicsWorker(action) {
  try {
    yield put({
      type: "SET_PATIENTS_DEMOGRAPHICS",
      payload: action.payload
    });
  } catch (error) {
    yield setPatientsLoading(false);
    setErrorStatus(error);
  }
}

function* switchPatientsWorker(action) {
  try {
    yield setPatientsLoading(true);

    yield put({
      type: "SET_CURRENT_PATIENT_PROFILE_ID",
      payload: {
        currentProfile: action.payload.profileId
      }
    });
    let response;
    if (store.getState().auth.data.transactionId) {
      if (
        store.getState().patients.currentProfile &&
        !store.getState().patients.accessToken?.[
          store.getState().patients.currentProfile
        ] &&
        store.getState().patients.loginType !== "password"
      ) {
        if (store.getState().patients.authType === "create") {
          response = yield createHealthId(
            store.getState().auth.data.accessToken,
            "mobileEmail",
            {
              alreadyExistedPHR: true,
              phrAddress:
                store.getState().patients.profiles[
                  store.getState().patients.currentProfile
                ]?.healthId,
              transactionId: store.getState().auth.data.transactionId
            }
          );
        } else {
          if (
            store.getState().patients.profiles?.[
              store.getState().patients.currentProfile
            ]?.healthId
          ) {
            response = yield confirmLoginForAbdmAuth(
              store.getState().auth.data.accessToken,
              store.getState().patients.profiles[
                store.getState().patients.currentProfile
              ]?.healthId,
              store.getState().auth.data.transactionId,
              store.getState().patients.profiles[
                store.getState().patients.currentProfile
              ]?.healthId
                ? null
                : store.getState().patients.currentProfile
            );
          }
        }
        if (response.success) {
          yield setPatientsAccessToken(response.data.token);
        }
      }
    }

    yield setPatientsLoading(false);
  } catch (error) {
    yield setPatientsLoading(false);
    setErrorStatus(error);
  }
}

function* clearUnusedHealthId(action) {
  yield put({
    type: "SET_ABDM_UNUSED_HEALTH_ID",
    payload: {
      healthId: null
    }
  });
}
function* clearABDMLinkedProfiles() {
  yield put({
    type: "SET_LINKED_PROFILES",
    payload: { linkedProfiles: null }
  });
}

function* clearHealthIdCreatedStatus() {
  yield put({
    type: "SET_HEALTH_ID_CREATED",
    payload: {
      data: false
    }
  });
}
function* clearShowSuccess() {
  yield put({
    type: "SET_SHOW_SUCCESS",
    payload: {
      data: {
        healthId: {
          created: false,
          link: false,
          unLink: false
        },
        consentPin: {
          forget: false,
          create: false
        }
      }
    }
  });
}

function* sendOtpForAbdmAuthWorker(action) {
  try {
    yield setPatientsLoading(true);
    let response;
    yield put({
      type: "SET_ABDM_UNUSED_HEALTH_ID",
      payload: {
        healthId: null
      }
    });
    const accessToken = store.getState().auth.data.accessToken;

    if (getValidAuthMode(action.payload.authType) === "PASSWORD") {
      yield put({
        type: "SET_PATIENTS_LOGIN_TYPE",
        payload: {
          loginType: "password"
        }
      });
    } else {
      yield put({
        type: "SET_PATIENTS_LOGIN_TYPE",
        payload: {
          loginType: "OTP"
        }
      });
    }

    response = yield generateOtpForAbdmAuth(
      accessToken,
      action.payload.auth,
      getValidAbhaMethod(action.payload.method),
      action.payload.value,
      getValidAuthMode(action.payload.authType)
    );
    if (response?.data?.transactionId || response?.data?.sessionId) {
      setTransactionId(
        response?.data?.transactionId
          ? response?.data?.transactionId
          : response?.data?.sessionId,
        action.payload.value
      );

      yield put({
        type: "SET_AUTH_INFO",
        payload: {
          ...store.getState().auth.data,
          transactionId: response?.data?.transactionId
            ? response?.data?.transactionId
            : response?.data?.sessionId
        }
      });
    }
    yield setPatientsLoading(false);
  } catch (error) {
    yield setPatientsLoading(false);
    setErrorStatus(error);
  }
}

function* getModesForAbdmAuthWorker(action) {
  try {
    yield setPatientsLoading(true);
    const result = yield getModesForAbdmAuth(
      store.getState().auth.data.accessToken,
      action.payload.auth,
      getValidAbhaMethod(action.payload.method),
      action.payload.value,
      action.payload.yearOfBirth
    );
    if (isValidArray(result.data.authMethods)) {
      setAuthModes(result.data.authMethods);
    } else {
      setErrorStatus({
        code: "custom",
        message: "No valid auth mode available. Please try later"
      });
    }
    yield setPatientsLoading(false);
  } catch (error) {
    yield setPatientsLoading(false);
    setErrorStatus(error);
  }
}

function* setPatientsAccessToken(abdmAccessToken) {
  yield put({
    type: "SET_ABDM_ACCESS_TOKEN",
    payload: {
      abdmAccessToken: {
        ...store.getState().patients.accessToken,
        [store.getState().patients.currentProfile]: abdmAccessToken
      }
    }
  });
}

function* verifyAbdmAuthOtpWorker(action) {
  try {
    yield setPatientsLoading(true);

    const transactionId = store.getState().patients?.transaction?.id;

    let response;
    response = yield verifyOtpForAbdmAuth(
      store.getState().auth.data.accessToken,
      action.payload.auth,
      getValidAbhaMethod(action.payload.method),
      action.payload.otp,
      transactionId,
      getValidAbhaMethod(action.payload.method) === "healthId" &&
        !store.getState().patients.profiles[
          store.getState().patients.currentProfile
        ]?.healthId
        ? store.getState().patients.currentProfile
        : null,
      getValidAbhaMethod(action.payload.method) === "healthId" &&
        store.getState().patients.profiles[
          store.getState().patients.currentProfile
        ]?.healthId &&
        action.payload.healthId
        ? action.payload.healthId
        : null
    );
    switch (action.payload.auth) {
      case "login":
        if (
          response?.success === true &&
          response?.data?.otpValidation === true
        ) {
          //login using health id return access token
          if (
            response.data.patientId &&
            response.data.phoneNumber === store.getState().auth.data.phoneNumber
          ) {
            yield switchPatientProfile(response.data.patientId);
          }

          if (
            response?.data?.token &&
            store.getState().auth.data.phoneNumber ===
              response?.data?.phoneNumber
          ) {
            setSuccessStatus("login successfully");

            yield setPatientsAccessToken(response.data.token);

            if (!action.payload.skipHealthInfo) {
              yield setAbdmHealthIds(action.payload.requestValue);
            }

            setTransactionId(null);
            action.payload.push("/");
            setAbdmAuthCredentials(null, null);
          } else if (isValidArray(response?.data?.linkedProfiles)) {
            yield put({
              type: "SET_LINKED_PROFILES",
              payload: { linkedProfiles: response?.data?.linkedProfiles }
            });
            if (
              response?.data?.linkedProfiles.length === 1 &&
              response?.data?.linkedProfiles?.[0]?.customToken &&
              store.getState().auth.data.phoneNumber !==
                response?.data?.linkedProfiles?.[0]?.phoneNumber &&
              response?.data?.linkedProfiles?.[0]?.customToken
            ) {
              yield switchWithCustomToken(
                response?.data?.linkedProfiles?.[0]?.customToken
              );

              yield setAbdmHealthIds(
                response?.data?.linkedProfiles?.[0]?.linkedAbhaAddress
              );

              yield put({
                type: "SET_CURRENT_PATIENT_PROFILE_ID",
                payload: { currentProfile: null }
              });
              action.payload.push("/");
              setAbdmAuthCredentials(null, null);
            } else {
              yield put({
                type: "SET_IS_MULTIPLE_LOGIN",
                payload: { data: true }
              });
              yield setAbdmHealthIds(response.data.listOfProfiles);
            }
          }
        } else if (response?.data?.otpValidation === false) {
          throw throwError("custom", "Incorrect OTP");
        } else {
          setErrorStatus(response);
        }

        break;

      case "register":
        if (
          response?.success === true &&
          (response?.data?.transactionId || response?.data?.sessionId)
        ) {
          if (Array.isArray(response?.data?.mappedPhrAddress)) {
            yield setAbdmHealthIds(response?.data?.mappedPhrAddress);
          }
          if (response?.data?.name) {
            yield put({
              type: "SET_ABHA_NUMBER_DATA",
              payload: { data: response.data }
            });
          }

          setAbdmAuthCredentials(
            action.payload.requestMethod,
            response.data.transactionId
          );

          if (response?.data?.transactionId) {
            setTransactionId(
              response?.data?.transactionId,
              store.getState().patients.transaction.phoneNumber
            );
          }
          if (response?.data?.sessionId) {
            setTransactionId(
              response?.data?.sessionId,
              store.getState().patients.transaction.phoneNumber
            );
          }
          if (action.payload.phoneNumber) {
            action.payload.push(
              `/abdm/list/?authMode=${action.payload.method}&mobile=${action.payload.phoneNumber}`
            );
          } else {
            action.payload.push(
              `/abdm/list/?authMode=${action.payload.method}`
            );
          }
        } else {
          setErrorStatus(response);
        }

        break;

      default:
        throw throwError("custom", "Please try again");
    }
    yield setPatientsLoading(false);
  } catch (error) {
    yield setPatientsLoading(false);
    setErrorStatus(error);
  }
}

function* createHealthIdWorker(action) {
  try {
    yield setPatientsLoading(true);
    const response = yield createHealthId(
      store.getState().auth.data.accessToken,
      getValidAbhaMethod(action.payload.method),
      {
        ...action.payload.data,
        ...(action.payload.removeMobile
          ? {}
          : {
              patientProfileDetails: {
                ...action.payload.data.patientProfileDetails
              }
            })
      }
    );
    if (response?.success === true) {
      if (
        response?.data?.linkedProfiles &&
        isValidArray(response?.data?.linkedProfiles)
      ) {
        if (response.data?.linkedProfiles) {
          yield put({
            type: "SET_LINKED_PROFILES",
            payload: { linkedProfiles: response?.data?.linkedProfiles }
          });
        }
        if (
          isValidString(response?.data?.patientId) &&
          isValidString(response?.data?.token)
        ) {
          yield put({
            type: "SET_RECENTLY_CREATED_ABHA_ID_DATA",
            payload: {
              recentlyCreatedABHAIdData: {
                patientId: response?.data?.patientId,
                accessToken: response?.data?.token,
                healthId: response?.data?.healthId
              }
            }
          });
        }
      } else {
        if (isValidString(response?.data?.patientId)) {
          yield put({
            type: "SET_CURRENT_PATIENT_PROFILE_ID",
            payload: {
              currentProfile: response.data.patientId
            }
          });
        }
        if (
          isValidString(response?.data?.phrAddress) ||
          isValidString(response?.data?.healthId)
        ) {
          yield setAbdmHealthIds(
            response?.data?.phrAddress
              ? response.data.phrAddress
              : response?.data?.healthId
          );
        }

        if (isValidString(response?.data?.token)) {
          yield setPatientsAccessToken(response.data.token);
        }

        if (action.payload.removeMobile === false) {
          setSuccessStatus("ABHA ID created successfully");
        } else {
          yield put({
            type: "SET_PATIENTS_AUTH_TYPE",
            payload: {
              authType: "create"
            }
          });
        }
      }
      setSuccessStatus("ABHA Id created successfully");
      yield put({
        type: "SET_HEALTH_ID_CREATED",
        payload: {
          data: true
        }
      });
    } else {
      setErrorStatus({
        code: "custom",
        message: "Unable to create ABHA ID"
      });
    }

    yield setPatientsLoading(false);
  } catch (error) {
    yield setPatientsLoading(false);
    setErrorStatus(error);
  }
}

function* getPatientsCareContextWorker(action) {
  try {
    yield setPatientsLoading(true);
    const abdmAccessToken =
      store.getState().patients.accessToken?.[
        store.getState().patients.currentProfile
      ];
    if (abdmAccessToken) {
      const response = yield getCareContexts(
        abdmAccessToken,
        store.getState().auth.data.accessToken
      );
      if (response?.success === true) {
        let careContextsData = {};

        response?.data?.patient?.links.forEach((data) => {
          careContextsData = { ...careContextsData, [data.hip.id]: data };
        });

        if (Array.isArray(response?.data?.patient?.links)) {
          yield put({
            type: "SET_PATIENTS_CARE_CONTEXTS",
            payload: {
              ...store.getState().patients.careContexts,
              [store.getState().patients.currentProfile]: careContextsData
            }
          });
        }
      }
    }

    yield setPatientsLoading(false);
  } catch (error) {
    yield setPatientsLoading(false);
    setErrorStatus(error);
  }
}

function* loginWithHealthIdWorker(action) {
  try {
    yield setPatientsLoading(true);

    const response = yield confirmLoginForAbdmAuth(
      store.getState().auth.data.accessToken,
      action.payload.healthId,
      store.getState().patients?.transaction?.id,
      store.getState().patients.profiles[
        store.getState().patients.currentProfile
      ]?.healthId
        ? null
        : store.getState().patients.currentProfile
    );
    if (
      response?.success === true &&
      isValidString(response?.data?.token) &&
      isValidString(response.data.patientId)
    ) {
      switchPatientProfile(response.data.patientId);
      yield setPatientsAccessToken(response.data.token);

      setAbdmAuthCredentials(null, null);
      setTransactionId(null);
      action.payload.push("/");
    } else {
      throw throwError(response);
    }
    yield setPatientsLoading(false);
  } catch (error) {
    yield setPatientsLoading(false);
    setErrorStatus(error);
  }
}

function* getPatientsRequestsWorker() {
  try {
    yield setPatientsLoading(true);
    //fetch abdm consents API
    const response = yield getPatientRequests(
      store.getState().patients.accessToken[
        store.getState().patients.currentProfile
      ],
      store.getState().auth.data.accessToken
      // action.payload.status
    );
    let requests = {};
    const currentProfile = store.getState().patients.currentProfile;
    if (response?.success === true) {
      if (Array.isArray(response?.data?.consents?.requests)) {
        let consents = response.data.consents.requests;
        for (let index = 0; index < consents.length; index++) {
          consents[index].type = "consent";
        }
        requests = { ...requests, consents: consents };
      }
      if (Array.isArray(response?.data?.subscriptions?.requests)) {
        let subscriptions = response.data.subscriptions.requests;
        for (let index = 0; index < subscriptions.length; index++) {
          subscriptions[index].type = "subscription";
        }
        requests = { ...requests, subscriptions: subscriptions };
      }
      if (Array.isArray(response?.data?.lockerSetups?.requests)) {
        let lockerSetups = response.data.lockerSetups.requests;
        for (let index = 0; index < lockerSetups.length; index++) {
          if (
            Object.values(lockerSetups[index]).some(
              (e) => e.status === consentStatus.expired
            )
          ) {
            lockerSetups[index].status = consentStatus.expired;
          } else if (
            Object.values(lockerSetups[index]).some(
              (e) => e.status === consentStatus.denied
            )
          ) {
            lockerSetups[index].status = consentStatus.denied;
          } else if (
            Object.values(lockerSetups[index]).some(
              (e) => e.status === consentStatus.pending
            )
          ) {
            lockerSetups[index].status = consentStatus.pending;
          } else if (
            lockerSetups[index].authorization.status ===
            lockerSetups[index].subscription.status
          ) {
            lockerSetups[index].status =
              lockerSetups[index].authorization.status;
          }
          lockerSetups[index].id = lockerSetups[index].subscription.id;
          lockerSetups[index].type = "lockerSetup";
        }
        requests = { ...requests, lockerSetups: lockerSetups };
      }
      if (Array.isArray(response?.data?.authorizations?.requests)) {
        let authorizations = response.data.authorizations.requests;
        for (let index = 0; index < authorizations.length; index++) {
          authorizations[index].type = "authorization";
          authorizations[index].id = authorizations[index].requestId;
        }
        requests = { ...requests, authorizations: authorizations };
      }

      if (requests) {
        yield put({
          type: "SET_PATIENTS_REQUESTS",
          payload: {
            ...store.getState().patients.requests,
            [currentProfile]: requests
          }
        });
      }
    } else {
      throw throwError(response);
    }
    yield setPatientsLoading(false);
  } catch (error) {
    yield setPatientsLoading(false);
    setErrorStatus(error);
  }
}

function* linkingTokenDataWorker(action) {
  try {
    yield put({
      type: "SET_LINKING_TOKEN_DETAILS",
      payload: {
        ...store.getState().patients.linkingToken,
        linkingTokenData: action.payload.linkingTokenData
      }
    });
  } catch (error) {
    yield setPatientsLoading(false);
    setErrorStatus(error);
  }
}

function* denyConsentRequestWorker(action) {
  try {
    yield setPatientsLoading(true);
    const response = yield denyRequests(
      {
        requestId: action.payload.requestId,
        reason: action.payload.denyReason
      },
      "consentRequest",
      store.getState().auth.data.accessToken,
      store.getState().patients.accessToken[
        store.getState().patients.currentProfile
      ]
    );

    if (response?.success) {
      setSuccessStatus(response.data.message);
      action.payload.push("/settings");
    }

    yield setPatientsLoading(false);
  } catch (error) {
    yield setPatientsLoading(false);
    setErrorStatus(error);
  }
}

function* denySubscriptionRequestWorker(action) {
  try {
    yield setPatientsLoading(true);
    const response = yield denyRequests(
      {
        subscriptionRequestId: action.payload.requestId,
        reason: action.payload.denyReason
      },
      "subscription",
      store.getState().auth.data.accessToken,
      store.getState().patients.accessToken[
        store.getState().patients.currentProfile
      ]
    );

    if (response?.success) {
      setSuccessStatus(response.data.message);
      action.payload.push("/settings");
    }

    yield setPatientsLoading(false);
  } catch (error) {
    yield setPatientsLoading(false);
    setErrorStatus(error);
  }
}

function* denyAuthorisationRequestWorker(action) {
  try {
    yield setPatientsLoading(true);
    const response = yield denyRequests(
      {
        requestId: action.payload.requestId
        // reason: action.payload.denyReason
      },
      "authorization",
      store.getState().auth.data.accessToken,
      store.getState().patients.accessToken[
        store.getState().patients.currentProfile
      ]
    );

    if (response?.success) {
      setSuccessStatus(response.data.message);
      action.payload.push("/settings");
    }

    yield setPatientsLoading(false);
  } catch (error) {
    yield setPatientsLoading(false);
    setErrorStatus(error);
  }
}

function* manageConsentPinWorker(action) {
  try {
    yield setPatientsLoading(true);
    let response;
    switch (action.payload.method) {
      //consent pin create method
      case "create":
        response = yield createConsentPin(
          action.payload.newPin,
          store.getState().auth.data.accessToken,
          store.getState().patients.accessToken[
            store.getState().patients.currentProfile
          ]
        );
        if (
          response?.success &&
          !!response?.data?.message &&
          action.payload.navigate
        ) {
          yield setPatientsLoading(false);
          yield put({
            type: "SET_SHOW_SUCCESS",
            payload: {
              data: {
                consentPin: {
                  forget: false,
                  create: true
                }
              }
            }
          });
          getHealthIdInformation(store.getState().patients.currentProfile);
        } else {
          throw throwError(response);
        }
        break;

      case "update":
        response = yield updateConsentPin(
          action.payload.currentPin,
          action.payload.newPin,
          store.getState().auth.data.accessToken,
          store.getState().patients.accessToken[
            store.getState().patients.currentProfile
          ]
        );

        if (response?.success && !!response?.data?.message) {
          yield put({
            type: "SET_SHOW_SUCCESS",
            payload: {
              data: {
                consentPin: {
                  forget: true,
                  create: false
                }
              }
            }
          });
          yield setPatientsLoading(false);
        } else if (typeof response?.error?.message === "string") {
          if (
            response.error.message.includes("Invalid transaction pin") &&
            response.error.message.includes("attempts left") &&
            typeof response.error.message.split(";")[1] === "string"
          ) {
            //throw custom error to the user to know how many attempts left
            throw throwError(
              "custom",
              `Invalid Pin, ${response.error.message.split(";")[1]}`
            );
          } else if (
            response.error.message.includes("Invalid Pin attempts exceeded") &&
            typeof response.error.message.split(":")[1] === "string"
          ) {
            //throw custom error to the user that pin attempt exceeded
            throw throwError("custom", `Invalid Pin attempts exceeded`);
          }
        } else {
          throw throwError(response);
        }
        break;

      case "reset":
        let successMessage;
        const _forgotConsentPin =
          typeof action.payload.newPin === "string" ? "verifyOTP" : "sendOTP";
        switch (_forgotConsentPin) {
          case "sendOTP":
            response = yield sendOtpToResetConsentPin(
              store.getState().auth.data.accessToken,
              store.getState().patients.accessToken[
                store.getState().patients.currentProfile
              ]
            );

            if (response?.success === true && !!response?.data?.transactionId) {
              successMessage = !!response.data?.otpMediumValue
                ? `OTP sent to ${response.data?.otpMediumValue}`
                : "OTP sent successfully";
              setSuccessStatus(successMessage);
              yield setTransactionId(
                response.data.transactionId,
                store.getState().patients.transaction.phoneNumber
              );
              yield setPatientsLoading(false);
            } else {
              throw throwError(response);
            }
            break;
          case "verifyOTP":
            response = yield verifyResetConsentPinOtp({
              transactionId: store.getState().patients?.transaction?.id,
              otp: action.payload.otp,
              newPin: action.payload.newPin,
              nintoAccessToken: store.getState().auth.data.accessToken,
              abdmAccessToken:
                store.getState().patients.accessToken[
                  store.getState().patients.currentProfile
                ]
            });

            if (response?.success === true) {
              if (typeof action.payload.navigate === "function") {
                action.payload.navigate("/settings");
              }
              yield setPatientsLoading(false);
              setSuccessStatus("Pin updated successfully");
            } else {
              throw throwError(response);
            }
            break;
          default:
            break;
        }

        break;
      default:
        throw throwError("custom", "invalid consent method");
    }
    yield setPatientsLoading(false);
  } catch (error) {
    yield setPatientsLoading(false);
    setErrorStatus(error);
  }
}

function* logoutHealthIdWorker(action) {
  try {
    yield put({
      type: "REMOVE_ABDM_ACCESS_TOKEN",
      payload: {
        patientId: action.payload.patientId
      }
    });
  } catch (error) {
    setErrorStatus(error);
  }
}

function* createDemographicWorker(action) {
  yield setPatientsLoading(true);
  yield createDemographic(action.payload.profileName);
  yield setPatientsLoading(false);
}

function* setTransactionIdWorker(action) {
  yield put({
    type: "SET_TRANSACTION_ID",
    payload: {
      transactionId: action.payload.transactionId || null,
      phoneNumber: action.payload.phoneNumber || null
    }
  });
}

function* acceptConsentRequestWorker(action) {
  try {
    yield setPatientsLoading(true);
    const consentRequesterData = yield store
      .getState()
      .patients.requests[
        store.getState().patients.currentProfile
      ].consents.find((data) => data.id === action.payload.requestId);
    let consentRequestBodyData = [];
    let autoApproveData = {
      healthId: consentRequesterData.patient.id,
      pin: action.payload.pin,
      policyData: {
        isApplicableForAllHIPs: "",
        hiu: {
          id: consentRequesterData.hiu.id,
          name: consentRequesterData.hiu.name
        },
        includedSources: [],
        excludedSources: [] // Always empty array
      }
    };
    let autoApproveResponse;
    let providers = {};
    let healthLockers = {};
    if (action.payload.requestData.providers.allProviders) {
      Object.values(
        store.getState().patients.careContexts[
          store.getState().patients.currentProfile
        ]
      ).forEach((data) => {
        if (data.hip.type !== "HEALTH_LOCKER") {
          providers = {
            ...providers,
            [data.hip.id]: action.payload.requestData.providers.allProviders
          };
        }
      });
    } else {
      providers = action.payload.requestData.providers;
    }
    if (isValidObject(providers)) {
      Object.keys(providers).forEach((requestId) => {
        const careContextsData = Object.values(
          store.getState().patients.careContexts[
            store.getState().patients.currentProfile
          ]
        ).find((data) => data.hip.id === requestId);

        let careContextsBodyData = [];

        careContextsData.careContexts.forEach((data) => {
          careContextsBodyData.push({
            patientReference: store.getState().patients.currentProfile,
            careContextReference: data.referenceNumber
          });
        });

        consentRequestBodyData.push({
          careContexts: careContextsBodyData,
          purpose: {
            code: "CAREMGT"
          },
          hiTypes: providers[requestId].requestToAccess,
          hip: {
            id: careContextsData.hip.id
          },
          permission: {
            accessMode: "VIEW",
            dataEraseAt: providers[requestId].to,
            dateRange: {
              from: providers[requestId].from,
              to: providers[requestId].to
            },
            frequency: {
              value: 1,
              unit: "HOUR",
              repeats: 2
            }
          }
        });
      });
      if (action.payload.autoApprove) {
        autoApproveData = {
          ...autoApproveData,
          policyData: {
            ...autoApproveData.policyData,
            isApplicableForAllHIPs: false
          }
        };
        Object.keys(providers).forEach((requestId) => {
          const careContextsData = Object.values(
            store.getState().patients.careContexts[
              store.getState().patients.currentProfile
            ]
          ).find((data) => data.hip.id === requestId);

          autoApproveData.policyData.includedSources.push({
            hiTypes: providers[requestId].requestToAccess,
            period: {
              from: providers[requestId].from,
              to: providers[requestId].to
            },
            hip: {
              // hip should only be provided if isApplicableForAllHIPs is false. if isApplicableForAllHIPs is true, don't provide hip field
              id: careContextsData.hip.id,
              name: careContextsData.hip.id
            },
            purpose: {
              code: "CAREMGT",
              text: "Care Management"
            }
          });
        });
      }
    }

    if (action.payload.requestData.healthLockers.allHealthLockers) {
      Object.values(
        store.getState().patients.careContexts[
          store.getState().patients.currentProfile
        ]
      ).forEach((data) => {
        if (data.hip.type === "HEALTH_LOCKER") {
          healthLockers = {
            ...healthLockers,
            [data.hip.id]:
              action.payload.requestData.healthLockers.allHealthLockers
          };
        }
      });
    } else {
      healthLockers = action.payload.requestData.healthLockers;
    }

    if (isValidObject(healthLockers)) {
      Object.keys(healthLockers).forEach((requestId) => {
        const careContextsData = Object.values(
          store.getState().patients.careContexts[
            store.getState().patients.currentProfile
          ]
        ).find((data) => data.hip.id === requestId);

        let careContextsBodyData = [];

        careContextsData.careContexts.forEach((data) => {
          if (
            healthLockers[requestId].clone &&
            healthLockers[requestId].clone === true
          ) {
            careContextsBodyData.push({
              patientReference: store.getState().patients.currentProfile,
              careContextReference: data.referenceNumber
            });
          } else {
            data.referenceNumber.slice(-5) !== "clone" &&
              careContextsBodyData.push({
                patientReference: store.getState().patients.currentProfile,
                careContextReference: data.referenceNumber
              });
          }
        });

        consentRequestBodyData.push({
          careContexts: careContextsBodyData,
          purpose: {
            code: "CAREMGT"
          },
          hiTypes: healthLockers[requestId].requestToAccess,
          hip: {
            id: careContextsData.hip.id
          },
          permission: {
            accessMode: "VIEW",
            dataEraseAt: healthLockers[requestId].to,
            dateRange: {
              from: healthLockers[requestId].from,
              to: healthLockers[requestId].to
            },
            frequency: {
              value: 1,
              unit: "HOUR",
              repeats: 2
            }
          }
        });
      });
      if (action.payload.autoApprove) {
        autoApproveData = {
          ...autoApproveData,
          policyData: {
            ...autoApproveData.policyData,
            isApplicableForAllHIPs: false
          }
        };
        Object.keys(healthLockers).forEach((requestId) => {
          const careContextsData = Object.values(
            store.getState().patients.careContexts[
              store.getState().patients.currentProfile
            ]
          ).find((data) => data.hip.id === requestId);

          autoApproveData.policyData.includedSources.push({
            hiTypes: healthLockers[requestId].requestToAccess,
            period: {
              from: healthLockers[requestId].from,
              to: healthLockers[requestId].to
            },
            hip: {
              // hip should only be provided if isApplicableForAllHIPs is false. if isApplicableForAllHIPs is true, don't provide hip field
              id: careContextsData.hip.id,
              name: careContextsData.hip.id
            },
            purpose: {
              code: "CAREMGT",
              text: "Care Management"
            }
          });
        });
      }
    }

    if (
      (action.payload.autoApprove && autoApproveResponse.success) ||
      action.payload.autoApprove === false
    ) {
      const response = yield acceptConsentRequest(
        {
          pin: action.payload.pin,
          requestId: action.payload.requestId,
          consents: consentRequestBodyData
        },
        store.getState().patients.accessToken[
          store.getState().patients.currentProfile
        ],
        store.getState().auth.data.accessToken
      );
      if (response.success) {
        setSuccessStatus("Request approved successfully");
      }
      if (action.payload.push && response.success) {
        action.payload.push("/settings");
      }
    }
    yield setPatientsLoading(false);
  } catch (error) {
    yield setPatientsLoading(false);
    setErrorStatus(error);
  }
}

function* acceptSubscriptionRequestWorker(action) {
  try {
    yield setPatientsLoading(true);
    let autoApproveData = { policyData: {} };
    let autoApproveResponse;
    let subscriptionRequestBodyData = {
      subscriptionRequestId: "",
      isApplicableForAllHIPs: "",
      includedSources: [],
      excludedSources: []
    };

    const requesterData = yield store
      .getState()
      .patients.requests[
        store.getState().patients.currentProfile
      ].subscriptions.find((data) => data.id === action.payload.requestId);

    if (action.payload.requestData.allProviders) {
      subscriptionRequestBodyData = {
        subscriptionRequestId: action.payload.requestId,
        isApplicableForAllHIPs: true,
        includedSources: [
          {
            categories: ["LINK", "DATA"],
            hiTypes: action.payload.requestData.allProviders.requestToAccess,
            period: {
              from: action.payload.requestData.allProviders.from,
              to: action.payload.requestData.allProviders.to
            },
            purpose: {
              code: "PATRQT",
              text: "Self Requested"
            }
          }
        ],
        excludedSources: [] // Always empty array
      };

      if (action.payload.autoApprove) {
        autoApproveData = {
          ...autoApproveData,
          policyData: {
            ...autoApproveData.policyData,
            isApplicableForAllHIPs: true
          }
        };
        const includedSourcesData = [
          {
            hiTypes: action.payload.requestData.allProviders.requestToAccess,
            period: {
              from: action.payload.requestData.allProviders.from,
              to: action.payload.requestData.allProviders.to
            },
            purpose: {
              code: "CAREMGT",
              text: "Care Management"
            }
          }
        ];

        autoApproveData = {
          ...autoApproveData,
          pin: action.payload.pin,
          // healthId:
          //   store.getState().patients.profiles[
          //     store.getState().patients.currentProfile
          //   ].healthId,
          policyData: {
            ...autoApproveData.policyData,
            includedSources: includedSourcesData,
            excludedSources: [],
            hiu: {
              id: requesterData.hiu.id,
              name: requesterData.hiu.name
            }
          }
        };
      }
    } else {
      subscriptionRequestBodyData = {
        ...subscriptionRequestBodyData,
        subscriptionRequestId: action.payload.requestId,
        isApplicableForAllHIPs: action.payload.requestData.allProviders
          ? true
          : false
      };
      Object.keys(action.payload.requestData).forEach((requestId) => {
        const careContextsData = Object.values(
          store.getState().patients.careContexts[
            store.getState().patients.currentProfile
          ]
        ).find((data) => data.hip.id === requestId);
        subscriptionRequestBodyData.includedSources.push({
          categories: ["LINK", "DATA"],
          hiTypes: action.payload.requestData[requestId].requestToAccess,
          period: {
            from: action.payload.requestData[requestId].from,
            to: action.payload.requestData[requestId].to
          },
          hip: {
            id: careContextsData.hip.id,
            name: careContextsData.hip.name
          },
          purpose: {
            code: "PATRQT",
            text: "Self Requested"
          }
        });
      });

      if (action.payload.autoApprove) {
        let includedSourcesData = [];
        Object.keys(action.payload.requestData).forEach((requestId) => {
          const careContextsData = Object.values(
            store.getState().patients.careContexts[
              store.getState().patients.currentProfile
            ]
          ).find((data) => data.hip.id === requestId);

          includedSourcesData.push({
            hiTypes: action.payload.requestData[requestId].requestToAccess,
            period: {
              from: action.payload.requestData[requestId].from,
              to: action.payload.requestData[requestId].to
            },
            hip: {
              // hip should only be provided if isApplicableForAllHIPs is false. if isApplicableForAllHIPs is true, don't provide hip field
              id: careContextsData.hip.id,
              name: careContextsData.hip.id
            },
            purpose: {
              code: "CAREMGT",
              text: "Care Management"
            }
          });
        });
        autoApproveData = {
          ...autoApproveData,
          pin: action.payload.pin,
          // healthId:
          //   store.getState().patients.profiles[
          //     store.getState().patients.currentProfile
          //   ].healthId,
          policyData: {
            ...autoApproveData.policyData,
            isApplicableForAllHIPs: false,
            includedSources: includedSourcesData,
            hiu: {
              id: requesterData.hiu.id,
              name: requesterData.hiu.name
            },
            excludedSources: []
          }
        };
      }
    }

    if (action.payload.autoApprove) {
      autoApproveResponse = yield acceptAutoApprove(
        autoApproveData,
        store.getState().patients.accessToken[
          store.getState().patients.currentProfile
        ],
        store.getState().auth.data.accessToken
      );
    }

    if (
      ((action.payload.autoApprove && autoApproveResponse.success) ||
        action.payload.autoApprove === false) &&
      action.payload.edit === false
    ) {
      const response = yield acceptSubscriptionRequest(
        subscriptionRequestBodyData,
        store.getState().patients.accessToken[
          store.getState().patients.currentProfile
        ],
        store.getState().auth.data.accessToken
      );
      if (response.success) {
        setSuccessStatus("Request approved successfully");
      }
      if (action.payload.push && response.success) {
        action.payload.push("/settings");
      }
    }

    if (action.payload.edit === true) {
      let subscriptionEditBodyData = subscriptionRequestBodyData;
      delete subscriptionEditBodyData.subscriptionRequestId;

      const response = yield editSubscriptionRequest(
        {
          subscriptionId: requesterData.subscriptionId,
          ...(!action.payload.requestData.allProviders
            ? {
                isApplicableForAllHIPs: false
              }
            : {}),
          ...subscriptionEditBodyData
        },
        store.getState().patients.accessToken[
          store.getState().patients.currentProfile
        ],
        store.getState().auth.data.accessToken
      );
      if (response.success) {
        setSuccessStatus("Request edited successfully");
      }
      if (action.payload.push && response.success) {
        action.payload.push("/settings");
      }
    }
    yield setPatientsLoading(false);
  } catch (error) {
    yield setPatientsLoading(false);
    setErrorStatus(error);
  }
}

function* acceptLockerRequestWorker(action) {
  try {
    yield setPatientsLoading(true);
    const requesterData = yield store
      .getState()
      .patients.requests[
        store.getState().patients.currentProfile
      ].lockerSetups.find((data) => data.id === action.payload.requestId);

    let lockerRequestBodyData = {
      lockerRequestId: action.payload.requestId,
      lockerId: requesterData.subscription.hiu.id,
      authRequestId: requesterData.authorization.requestId,
      lockerName: requesterData.subscription.hiu.name,
      isApplicableForAllHIPsForSubscription: "", // True If User selects all current and future providers, false if unchecked
      isApplicableForAllHIPsForAutoApproval: "", // True If User selects all current and future providers, false if unchecked
      includedSubscriptionSources: [],
      includedAutoApprovalSources: []
    };
    if (action.payload.requestData.allProviders) {
      lockerRequestBodyData = {
        ...lockerRequestBodyData,
        isApplicableForAllHIPsForSubscription: true,
        isApplicableForAllHIPsForAutoApproval: true,
        includedSubscriptionSources: [
          {
            categories: ["LINK", "DATA"],
            hiTypes: action.payload.requestData.allProviders.requestToAccess,
            period: {
              from: action.payload.requestData.allProviders.from,
              to: action.payload.requestData.allProviders.to
            },
            purpose: {
              code: "CAREMGT",
              text: "Care Management"
            }
          }
        ]
      };

      if (action.payload.autoApprove) {
        lockerRequestBodyData = {
          ...lockerRequestBodyData,
          includedAutoApprovalSources: [
            {
              hiTypes: action.payload.requestData.allProviders.requestToAccess,
              period: {
                from: action.payload.requestData.allProviders.from,
                to: action.payload.requestData.allProviders.to
              },
              purpose: {
                code: "CAREMGT",
                text: "Care Management"
              }
            }
          ]
        };
      }
    } else {
      lockerRequestBodyData = {
        ...lockerRequestBodyData,
        isApplicableForAllHIPsForSubscription: false,
        isApplicableForAllHIPsForAutoApproval: false
      };

      Object.keys(action.payload.requestData).forEach((requestId) => {
        const careContextsData = Object.values(
          store.getState().patients.careContexts[
            store.getState().patients.currentProfile
          ]
        ).find((data) => data.hip.id === requestId);
        lockerRequestBodyData.includedSubscriptionSources.push({
          categories: ["LINK", "DATA"],
          hiTypes: action.payload.requestData[requestId].requestToAccess,
          period: {
            from: action.payload.requestData[requestId].from,
            to: action.payload.requestData[requestId].to
          },
          hip: {
            id: careContextsData.hip.id,
            name: careContextsData.hip.name
          },
          purpose: {
            code: "CAREMGT",
            text: "Care Management"
          }
        });
      });

      if (action.payload.autoApprove) {
        Object.keys(action.payload.requestData).forEach((requestId) => {
          const careContextsData = Object.values(
            store.getState().patients.careContexts[
              store.getState().patients.currentProfile
            ]
          ).find((data) => data.hip.id === requestId);
          lockerRequestBodyData.includedAutoApprovalSources.push({
            hiTypes: action.payload.requestData[requestId].requestToAccess,
            period: {
              from: action.payload.requestData[requestId].from,
              to: action.payload.requestData[requestId].to
            },
            hip: {
              id: careContextsData.hip.id,
              name: careContextsData.hip.name
            },
            purpose: {
              code: "CAREMGT",
              text: "Care Management"
            }
          });
        });
      }
    }
    const response = yield acceptLockerAuthorizationRequest(
      lockerRequestBodyData,
      store.getState().patients.accessToken[
        store.getState().patients.currentProfile
      ],
      store.getState().auth.data.accessToken
    );
    if (response.success) {
      setSuccessStatus("Request approved successfully");
    }
    yield setPatientsLoading(false);
    if (action.payload.push && response.success) {
      action.payload.push("/settings");
    }
  } catch (error) {
    yield setPatientsLoading(false);
    setErrorStatus(error);
  }
}

function* acceptAuthorizationRequestWorker(action) {
  try {
    yield setPatientsLoading(true);

    const response = yield acceptAuthorizationRequest(
      { requestId: action.payload.requestId },
      store.getState().patients.accessToken[
        store.getState().patients.currentProfile
      ],
      store.getState().auth.data.accessToken
    );
    if (response.success) {
      setSuccessStatus("Request approved successfully");
    }
    yield setPatientsLoading(false);
    if (action.payload.push && response.success) {
      action.payload.push("/settings");
    }
  } catch (error) {
    yield setPatientsLoading(false);
    setErrorStatus(error);
  }
}

function* healthLockerAuthorizationWorker() {
  try {
    yield setPatientsLoading(true);
    const abdmAccessToken =
      store.getState().patients.accessToken[
        store.getState().patients.currentProfile
      ];
    const nintoAccessToken = store.getState().auth.data.accessToken;
    const hipId = lockerSetup.locker;
    const response = yield lockerAuthorizationRequest(
      store.getState().patients?.currentProfile,
      abdmAccessToken,
      nintoAccessToken
    );

    if (response.success === true) {
      // yield setPatientsLoading(true);
      const getLockerAuthorizationResponse =
        yield getLockerAuthorizationRequest(abdmAccessToken, nintoAccessToken);
      let lockerData;
      getLockerAuthorizationResponse.data.lockerSetups.requests.forEach(
        (request) => {
          if (
            (lockerData === undefined &&
              request.authorization.requester.id === hipId) ||
            (new Date(request.authorization.createdAt) >
              new Date(lockerData?.authorization?.createdAt) &&
              request.authorization.requester.id === hipId)
          ) {
            lockerData = request;
          }
        }
      );

      if (isValidObject(lockerData)) {
        const data = {
          lockerRequestId: lockerData.subscription.id,
          lockerId: hipId,
          isApplicableForAllHIPsForSubscription: true,
          isApplicableForAllHIPsForAutoApproval: true,
          includedSubscriptionSources: [
            {
              hiTypes: [
                "Prescription",
                "OPConsultation",
                "DischargeSummary",
                "DiagnosticReport",
                "ImmunizationRecord",
                "HealthDocumentRecord",
                "WellnessRecord"
              ],
              categories: ["DATA", "LINK"],
              purpose: { text: "Self Requested", code: "PATRQT" },
              period: {
                from: lockerData.subscription.period.from,
                to: lockerData.subscription.period.to
              }
            }
          ],
          includedAutoApprovalSources: [
            {
              hiTypes: [
                "Prescription",
                "OPConsultation",
                "DischargeSummary",
                "DiagnosticReport",
                "ImmunizationRecord",
                "HealthDocumentRecord",
                "WellnessRecord"
              ],

              purpose: { text: "Self Requested", code: "PATRQT" },
              period: {
                from: lockerData.subscription.period.from,
                to: lockerData.subscription.period.to
              }
            }
          ],

          authRequestId: lockerData.authorization.requestId,
          lockerName: lockerData.subscription.hiu.name
        };
        const lockerRequestAcceptResponse =
          yield acceptLockerAuthorizationRequest(
            data,
            abdmAccessToken,
            nintoAccessToken
          );
        if (lockerRequestAcceptResponse.success === true) {
          setSuccessStatus("Successfully subscribed");
        }
      }
    }
    yield setPatientsLoading(false);
  } catch (error) {
    yield setPatientsLoading(false);
    setErrorStatus(error);
  }
}

function* addPatientsSubscriptionWorker(action) {
  try {
    let subscriptionData = {};

    Object.keys(action.payload.data).forEach((key) => {
      subscriptionData = {
        ...subscriptionData,
        [action.payload.data[key].patient.patientId]: {
          ...subscriptionData[key],
          [key]: action.payload.data[key]
        }
      };
    });

    yield put({
      type: "SET_PATIENT_SUBSCRIPTION",
      payload: {
        subscription: {
          ...store.getState().patients.subscriptions,
          ...subscriptionData
        }
      }
    });
  } catch (error) {
    console.error("ERROR addPatientsSubscriptionWorker", error);
    setErrorStatus(error);
  }
}

function* revokeConsentRequestWorker(action) {
  try {
    yield setPatientsLoading(true);

    const data = {
      pin: action.payload.pin,
      consentRequests: [action.payload.requestId]
    };
    const response = yield revokeConsentRequest(
      data,
      store.getState().patients.accessToken[
        store.getState().patients.currentProfile
      ],
      store.getState().auth.data.accessToken
    );

    if (response.success) {
      setSuccessStatus("Access revoked successfully");

      if (action.payload.push) {
        action.payload.push("/settings");
      }
    }

    yield setPatientsLoading(false);
  } catch (error) {
    yield setPatientsLoading(false);
    console.error("ERROR revokeConsentWorker", error);
    setErrorStatus(error);
  }
}

function* revokeAuthorizationRequestWorker(action) {
  try {
    yield setPatientsLoading(true);
    const data = {
      // pin: action.payload.pin,
      requestId: action.payload.requestId
    };

    const response = yield revokeAuthorizationRequest(
      data,
      store.getState().patients.accessToken[
        store.getState().patients.currentProfile
      ],
      store.getState().auth.data.accessToken
    );

    if (response.success) {
      setSuccessStatus("Access revoked successfully");

      if (action.payload.push) {
        action.payload.push("/settings");
      }
    }
    yield setPatientsLoading(false);
  } catch (error) {
    yield setPatientsLoading(false);
    console.error("ERROR addPatientsSubscriptionWorker", error);
    setErrorStatus(error);
  }
}

function* disableAutoApproveWorker(action) {
  try {
    yield setPatientsLoading(true);
    const accessToken =
      store.getState().patients.accessToken?.[
        store.getState().patients.currentProfile
      ];
    const authAccessToken = store.getState().auth.data.accessToken;
    if (isValidArray(action.payload.autoApprovalId)) {
      const promises = action.payload.autoApprovalId
        .filter((data) => data && data)
        .map((autoApprovalId) => {
          //some times comes undefined
          const data = {
            pin: action.payload.pin,
            autoApprovalId: autoApprovalId
          };

          return disableAutoApprovalPolicy(data, accessToken, authAccessToken);
        });
      const responses = yield Promise.all(promises);
      if (
        responses.length > 0 &&
        responses?.every((d) => d && d?.success === true)
      ) {
        setSuccessStatus("Auto approval policy disabled successfully");

        if (action.payload.push) {
          action.payload.push(`/abdm/subscription/${action.payload.requestId}`);
        }
      }
    } else {
      const data = {
        pin: action.payload.pin,
        autoApprovalId: action.payload.autoApprovalId
      };

      const response = yield disableAutoApprovalPolicy(
        data,
        accessToken,
        authAccessToken
      );

      if (response.success) {
        setSuccessStatus("Auto approval policy disabled successfully");

        if (action.payload.push) {
          action.payload.push(`/abdm/consent/${action.payload.requestId}`);
        }
      }
    }

    yield setPatientsLoading(false);
  } catch (error) {
    yield setPatientsLoading(false);
    console.error("ERROR disableAutoApproveWorker", error);
    setErrorStatus(error);
  }
}

function* enableAutoApproveWorker(action) {
  try {
    yield setPatientsLoading(true);
    const accessToken =
      store.getState().patients.accessToken?.[
        store.getState().patients.currentProfile
      ];
    const authAccessToken = store.getState().auth.data.accessToken;

    const requesterData = yield store
      .getState()
      .patients.requests[
        store.getState().patients.currentProfile
      ].subscriptions.find((data) => data.id === action.payload.requestId);

    const includedSourcesData = [
      {
        hiTypes: action.payload.requestData.allProviders.requestToAccess,
        period: {
          from: action.payload.requestData.allProviders.from,
          to: action.payload.requestData.allProviders.to
        },
        purpose: {
          code: "CAREMGT",
          text: "Care Management"
        }
      }
    ];
    const data = {
      pin: action.payload.pin,
      policyData: {
        includedSources: includedSourcesData,
        excludedSources: [],
        hiu: {
          id: requesterData.hiu.id,
          name: requesterData.hiu.name
        }
      }
    };

    const response = yield acceptAutoApprove(
      data,
      accessToken,
      authAccessToken
    );

    if (response.success) {
      setSuccessStatus("Auto approval policy enabled successfully");

      if (action.payload.push) {
        action.payload.push(`/abdm/subscription/${action.payload.requestId}`);
      }
    }

    yield setPatientsLoading(false);
  } catch (error) {
    yield setPatientsLoading(false);
    console.error("ERROR disableAutoApproveWorker", error);
    setErrorStatus(error);
  }
}

function* addAutoApprovalPolicyWorker(action) {
  try {
    yield put({
      type: "SET_AUTO_APPROVAL_POLICIES",
      payload: {
        autoApprovalPolicies: action.payload.data
      }
    });

    yield setPatientsLoading(false);
  } catch (error) {
    yield setPatientsLoading(false);
    console.error("ERROR addAutoApprovalPolicyWorker", error);
    setErrorStatus(error);
  }
}

function* getHealthIdInformationWorker(action) {
  try {
    let response;

    if (
      action.payload.profileId &&
      store.getState().patients.accessToken?.[action.payload.profileId]
    ) {
      response = yield getHealthIdData(
        store.getState().auth.data.accessToken,
        store.getState().patients.accessToken?.[action.payload.profileId]
      );

      if (response.success) {
        delete response.data.abhaAddresses;
        yield put({
          type: "SET_HEALTH_ID_INFORMATION",
          payload: {
            data: { [action.payload.profileId]: response.data }
          }
        });
      }
    }
  } catch (error) {
    setErrorStatus(error);
  }
}

function* editProfileWorker(action) {
  try {
    yield setPatientsLoading(true);
    let editedData = action.payload.data;
    Object.keys(editedData).forEach((key) => {
      if (editedData[key] === null || editedData[key] === "") {
        delete editedData[key];
      }
      if (key === "dateOfBirth" && editedData.dateOfBirth) {
        const dob = editedData.dateOfBirth;
        editedData = {
          ...editedData,
          dateOfBirth: {
            date: new Date(dob).getDate().toString(),
            month: (new Date(dob).getMonth() + 1).toString(),
            year: new Date(dob).getFullYear().toString()
          }
        };
      }
      if (key === "profilePhoto" && editedData.profilePhoto) {
        editedData = {
          ...editedData,
          profilePhoto: editedData.profilePhoto.split(",")[1]
        };
      }

      if (key === "gender") {
        switch (editedData[key]) {
          case "male":
            editedData = {
              ...editedData,
              gender: "M"
            };
            break;
          case "female":
            editedData = {
              ...editedData,
              gender: "F"
            };
            break;
          case "others":
            editedData = {
              ...editedData,
              gender: "O"
            };
            break;
          case "unknown":
            editedData = {
              ...editedData,
              gender: "U"
            };
            break;
          default:
            return;
        }
      }
    });

    const response = yield editHealthIdData(
      store.getState().auth.data.accessToken,
      store.getState().patients.accessToken[
        store.getState().patients.currentProfile
      ],
      editedData
    );
    if (response.success) {
      getHealthIdInformation(store.getState().patients.currentProfile);
      setSuccessStatus("Profile edited successfully");
    }
    yield setPatientsLoading(false);
  } catch (error) {
    yield setPatientsLoading(false);
    setErrorStatus(error);
  }
}

function* verifyOTPToUpdatePhoneNumberOrEmailWorker(action) {
  try {
    yield setPatientsLoading(true);
    const response = yield verifyOTPForUpdatePhoneNumberOrEmail(
      store.getState().auth.data.accessToken,
      store.getState().patients.accessToken[
        store.getState().patients.currentProfile
      ],
      action.payload.value,
      store.getState().patients?.transaction?.id,
      action.payload.data
    );

    if (response.success) {
      setSuccessStatus(
        action.payload.authMode === "Phone"
          ? "Phone number edited successfully"
          : "Email edited successfully"
      );
      yield setTransactionId(null);
      action.payload.navigate("/");
      if (action.payload.authMode === "Phone") {
        if (response.data?.idToken) {
          putAuthInfo({
            accessToken: response.data?.idToken,
            uid: store.getState().auth.data.uid,
            phoneNumber: action.payload.data,
            displayName: store.getState().auth.data.displayName
          });
        }
      } else {
        getHealthIdInformation(store.getState().patients.currentProfile);
      }
    }
    yield setPatientsLoading(false);
  } catch (error) {
    yield setPatientsLoading(false);
  }
}

function* generateOTPToUpdatePhoneNumberOrEmailWorker(action) {
  try {
    yield setPatientsLoading(true);

    const response = yield generateOTPForUpdatePhoneNumberOrEmail(
      store.getState().auth.data.accessToken,
      store.getState().patients.accessToken[
        store.getState().patients.currentProfile
      ],
      action.payload.value,
      getValidAuthMode(action.payload.authMode)
    );

    if (response?.data?.transactionId || response?.data?.sessionId) {
      setTransactionId(
        response?.data?.transactionId
          ? response?.data?.transactionId
          : response?.data?.sessionId,
        action.payload.value
      );
    }

    yield setPatientsLoading(false);
  } catch (error) {
    yield setPatientsLoading(false);
  }
}

function* resetAbhaPasswordWorker(action) {
  try {
    yield setPatientsLoading(true);

    const response = yield abhaAddressResetPassword(
      store.getState().auth.data.accessToken,
      store.getState().patients.accessToken[
        store.getState().patients.currentProfile
      ],
      action.payload.password
    );

    if (response.success) {
      setSuccessStatus("Password is successfully changed");
      if (typeof action.payload.navigate === "function") {
        action.payload.navigate("/settings");
      }
    }

    yield setPatientsLoading(false);
  } catch (error) {
    yield setPatientsLoading(false);
  }
}

function* setRequestsFilterDataWorker(action) {
  try {
    const filterType = yield store.getState().patients.requestsFilter
      .filterType;
    const clinics = yield store.getState().patients.requestsFilter.clinics;
    const requests = yield store.getState().patients.requestsFilter.requests;
    const status = yield store.getState().patients.requestsFilter.status;

    if (action.payload.filterBy === "filterType") {
      if (action.payload.id !== filterType) {
        yield put({
          type: "SET_FILTER_TYPE",
          payload: {
            id: action.payload.id
          }
        });
      }
    }

    if (action.payload.filterBy === "clinics") {
      if (clinics.includes(action.payload.id)) {
        const index = clinics.indexOf(action.payload.id);
        if (index > -1) {
          clinics.splice(index, 1);
        }
      } else {
        clinics.push(action.payload.id);
      }

      yield put({
        type: "SET_CLINICS_FILTER",
        payload: {
          clinics: clinics
        }
      });
    }

    if (action.payload.filterBy === "requests") {
      if (requests.includes(action.payload.id)) {
        const index = requests.indexOf(action.payload.id);
        if (index > -1) {
          requests.splice(index, 1);
        }
      } else {
        requests.push(action.payload.id);
      }

      yield put({
        type: "SET_REQUESTS_FILTER",
        payload: {
          requests: requests
        }
      });
    }

    if (action.payload.filterBy === "status") {
      if (status.includes(action.payload.id)) {
        const index = status.indexOf(action.payload.id);
        if (index > -1) {
          status.splice(index, 1);
        }
      } else {
        status.push(action.payload.id);
      }

      yield put({
        type: "SET_STATUS_FILTER",
        payload: {
          status: status
        }
      });
    }

    if (action.payload.filterBy === "clear") {
      yield put({
        type: "CLEAR_REQUEST_FILTER"
      });
    }
  } catch (error) {}
}

function* linkHealthIdNumberWorker(action) {
  try {
    yield setPatientsLoading(true);

    const currentProfile = store.getState().patients.currentProfile;

    if (action.payload.type === "search") {
      const response = yield linkAbhaNumber(
        store.getState().auth.data.accessToken,
        store.getState().patients.accessToken[currentProfile],
        action.payload.data,
        action.payload.type
      );
      if (response.success) {
        setAuthModes(response.data.authMethods);
      }
    }

    if (action.payload.type === "init") {
      const response = yield linkAbhaNumber(
        store.getState().auth.data.accessToken,
        store.getState().patients.accessToken[currentProfile],
        action.payload.data,
        action.payload.type
      );

      if (response.success) {
        setAuthModes(null);
        yield put({
          type: "SET_TRANSACTION_ID",
          payload: {
            transactionId: response.data.transactionId || null,
            phoneNumber: store.getState().auth.data.phoneNumber || null
          }
        });
      }
    }

    if (
      action.payload.type === "verifyOtpLink" ||
      action.payload.type === "verifyOtpDeLink"
    ) {
      const response = yield linkAbhaNumber(
        store.getState().auth.data.accessToken,
        store.getState().patients.accessToken[currentProfile],
        action.payload.data,
        "verifyOtp"
      );

      if (action.payload.type === "verifyOtpLink" && response.success) {
        const result = yield linkAbhaNumber(
          store.getState().auth.data.accessToken,
          store.getState().patients.accessToken[currentProfile],
          { transactionId: response.data.transactionId },
          "confirm"
        );

        if (result.success) {
          getHealthIdInformation(currentProfile);
          setTransactionId(null);
          yield put({
            type: "SET_SHOW_SUCCESS",
            payload: {
              data: {
                healthId: {
                  unLink: false,
                  link: true,
                  create: false
                }
              }
            }
          });

          // setSuccessStatus(
          //   "Congratulations! Your ABHA number is now linked to your existing ABHA address. ABHA number is visible on your profile."
          // );
        }
      }

      if (action.payload.type === "verifyOtpDeLink" && response.success) {
        const result = yield deLinkAbhaNumber(
          store.getState().auth.data.accessToken,
          store.getState().patients.accessToken[currentProfile],
          { transactionId: response.data.transactionId },
          "confirm"
        );

        if (result.success) {
          getHealthIdInformation(currentProfile);
          setTransactionId(null);
          yield put({
            type: "SET_SHOW_SUCCESS",
            payload: {
              data: {
                healthId: {
                  unLink: true,
                  link: false,
                  create: false
                }
              }
            }
          });
          // setSuccessStatus(
          //   "ABHA number is unlinked from existing ABHA address"
          // );
        }
      }
    }

    if (action.payload.modal) {
      action.payload.modal(false);
    }

    yield setPatientsLoading(false);
  } catch (error) {
    yield setPatientsLoading(false);
  }
}

function* setAuthModesWorker(action) {
  yield put({
    type: "PUT_AUTH_MODE",
    payload: {
      data: action.payload.data
    }
  });
}

function* generateLinkOrUnlinkAbhaAddressWorker(action) {
  try {
    yield setPatientsLoading(true);

    const response = yield generateOtpForUnlinkAbhaAddressFromAbhaNumber(
      store.getState().auth.data.accessToken,
      store.getState().patients.accessToken[
        store.getState().patients.currentProfile
      ],
      action.payload
    );

    if (response.success) {
      setAuthModes(null);
      setAuthModes(response.data.authMethods);
      yield put({
        type: "SET_TRANSACTION_ID",
        payload: {
          transactionId: response.payload.transactionId || null,
          phoneNumber: store.getState().auth.data.phoneNumber || null
        }
      });
    }
    yield setPatientsLoading(false);
  } catch (error) {
    yield setPatientsLoading(false);
    setErrorStatus(error);
  }
}

function* confirmLinkOrUnlinkAbhaAddressWorker(action) {
  try {
    yield setPatientsLoading(true);

    const response = yield confirmUnlinkAbhaAddressFromAbhaNumber(
      store.getState().auth.data.accessToken,
      store.getState().patients.accessToken[
        store.getState().patients.currentProfile
      ],
      action.payload
    );

    const result = yield linkOrUnlinkAbhaAddressFromAbhaNumber(
      store.getState().auth.data.accessToken,
      store.getState().patients.accessToken[
        store.getState().patients.currentProfile
      ],
      { token: response.data.token, abhaAddress: response.data.data.healthId },
      action.payload.linkAbhaAddress ? "link" : "delink"
    );

    if (result.success) {
      action.payload.linkAbhaAddress
        ? setSuccessStatus("ABHA address linked successfully")
        : setSuccessStatus("ABHA address unlinked successfully");
      getHealthIdInformationWorker();
      action.payload.navigate && action.payload.navigate("/settings");
    }

    yield setPatientsLoading(false);
  } catch (error) {
    yield setPatientsLoading(false);
  }
}

function* setSelectedLinkedFacilityWorker(action) {
  yield put({
    type: "SET_SELECTED_LINKED_FACILITY",
    payload: {
      linkedFacilityId: action.payload.linkedFacilityId
    }
  });
}

function* setActiveSettingsMenuWorker(action) {
  yield put({
    type: "SET_ACTIVE_SETTINGS_MENU",
    payload: {
      menu: action.payload.menu
    }
  });
}
function* setConsentToPersonalDataWorker() {
  try {
    yield setPatientsLoading(true);
    yield addConsentAgreeToPersonalData(
      store.getState().patients.currentProfile
    );
    setSuccessStatus("Agreed successfully");
    yield setPatientsLoading(false);
  } catch (error) {
    yield setPatientsLoading(false);
  }
}

export default function* patientsWatcher() {
  yield all([
    takeEvery("CREATE_HEALTH_ID", createHealthIdWorker),
    takeEvery("RESET_ABHA_PASSWORD", resetAbhaPasswordWorker),
    takeEvery("SET_AUTH_MODES", setAuthModesWorker),
    takeEvery("ADD_PATIENTS_DEMOGRAPHICS", addPatientsDemographicsWorker),
    takeEvery("GET_MODES_FOR_ABDM_AUTH", getModesForAbdmAuthWorker),
    takeEvery("SWITCH_PATIENT_PROFILE", switchPatientsWorker),
    takeEvery("SEND_OTP_FOR_ABDM_AUTHENTICATION", sendOtpForAbdmAuthWorker),
    takeEvery("VERIFY_ABDM_AUTHENTICATION_OTP", verifyAbdmAuthOtpWorker),
    takeEvery("LINKING_TOKEN_DETAILS", linkingTokenDataWorker),
    takeLatest("GET_PATIENTS_CARE_CONTEXTS", getPatientsCareContextWorker),
    takeEvery("LOGIN_WITH_HEALTH_ID", loginWithHealthIdWorker),
    takeEvery("LOGOUT_HEALTH_ID", logoutHealthIdWorker),
    takeEvery("MANAGE_CONSENT_PIN", manageConsentPinWorker),
    takeEvery("CREATE_PATIENTS_DEMOGRAPHIC", createDemographicWorker),
    takeEvery("ADD_ABDM_AUTH_CREDENTIALS", setAbdmAuthCredentialsWorker),
    takeEvery("PUT_TRANSACTION_ID", setTransactionIdWorker),
    takeEvery("GET_PATIENTS_REQUESTS", getPatientsRequestsWorker),
    takeEvery("ACCEPT_CONSENT_REQUEST", acceptConsentRequestWorker),
    takeEvery("ACCEPT_SUBSCRIPTION_REQUEST", acceptSubscriptionRequestWorker),
    takeEvery("ACCEPT_LOCKER_REQUEST", acceptLockerRequestWorker),
    takeEvery("ACCEPT_AUTHORIZATION_REQUEST", acceptAuthorizationRequestWorker),
    takeEvery("HEALTH_LOCKER_AUTHORIZATION", healthLockerAuthorizationWorker),
    takeEvery("DENY_CONSENT_REQUEST", denyConsentRequestWorker),
    takeEvery("DENY_SUBSCRIPTION_REQUEST", denySubscriptionRequestWorker),
    takeEvery("DENY_AUTHORIZATION_REQUEST", denyAuthorisationRequestWorker),
    takeEvery("CLEAR_ABDM_UNUSED_HEALTH_ID", clearUnusedHealthId),
    takeEvery("CLEAR_ABDM_LINKED_PROFILES", clearABDMLinkedProfiles),
    takeEvery("CLEAR_HEALTH_ID_CREATED_STATUS", clearHealthIdCreatedStatus),
    takeEvery("CLEAR_SHOW_SUCCESS", clearShowSuccess),
    takeEvery("ADD_PATIENTS_SUBSCRIPTION", addPatientsSubscriptionWorker),
    takeEvery("REVOKE_CONSENT_REQUEST", revokeConsentRequestWorker),
    takeEvery("REVOKE_AUTHORIZATION_REQUEST", revokeAuthorizationRequestWorker),
    takeEvery("DISABLE_CONSENT_AUTO_APPROVE", disableAutoApproveWorker),
    takeEvery("ENABLE_CONSENT_AUTO_APPROVE", enableAutoApproveWorker),
    takeEvery("ADD_AUTO_APPROVAL_POLICIES", addAutoApprovalPolicyWorker),
    takeEvery("GET_HEALTH_ID_INFORMATION", getHealthIdInformationWorker),
    takeEvery("EDIT_PROFILE", editProfileWorker),
    takeEvery(
      "VERIFY_OTP_TO_UPDATE_PHONE_NUMBER_OR_EMAIL",
      verifyOTPToUpdatePhoneNumberOrEmailWorker
    ),
    takeEvery(
      "GENERATE_OTP_TO_UPDATE_PHONE_NUMBER_OR_EMAIL",
      generateOTPToUpdatePhoneNumberOrEmailWorker
    ),
    takeEvery("SET_REQUESTS_FILTER_DATA", setRequestsFilterDataWorker),
    // takeEvery("SEARCH_HEALTH_ID_AUTH_MODE", searchHealthIdAuthModeWorker),
    takeEvery(
      "GENERATE_LINK_OR_UNLINK_ABHA_ADDRESS_OTP",
      generateLinkOrUnlinkAbhaAddressWorker
    ),
    takeEvery(
      "CONFIRM_LINK_OR_UNLINK_ABHA_ADDRESS",
      confirmLinkOrUnlinkAbhaAddressWorker
    ),
    takeEvery("SELECTED_LINKED_FACILITY", setSelectedLinkedFacilityWorker),
    takeEvery("LINK_HEALTH_ID_NUMBER", linkHealthIdNumberWorker),
    takeEvery("SET_CONSENT_TO_PERSONAL_DATA", setConsentToPersonalDataWorker),
    takeEvery("ACTIVE_SETTINGS_MENU", setActiveSettingsMenuWorker)
  ]);
}

function* setPatientsLoading(loadingState) {
  if (store.getState().patients.loading !== loadingState) {
    yield put({
      type: "SET_PATIENTS_LOADING",
      payload: {
        loading: loadingState
      }
    });
  }
}

function* setAbdmHealthIds(healthId) {
  const healthIds = !!healthId ? [healthId].flat() : null;

  const unUsedHealthIds = healthIds?.filter(
    (healthId) =>
      !Object.values(store.getState().patients.profiles).some(
        (profile) => profile.healthId === healthId
      )
  );

  const usedHealthIds = healthIds?.filter((healthId) =>
    Object.values(store.getState().patients.profiles).some(
      (profile) => profile.healthId === healthId
    )
  );

  yield put({
    type: "SET_ABDM_USED_HEALTH_ID",
    payload: {
      healthId: usedHealthIds
    }
  });

  yield put({
    type: "SET_ABDM_UNUSED_HEALTH_ID",
    payload: {
      healthId: unUsedHealthIds
    }
  });
}

function* switchWithCustomToken(token) {
  try {
    yield setCustomAuthLoading(true);
    yield logout();
    yield put({
      type: "FORCE_CLEAR_DOWNLOADS"
    });
    yield put({
      type: "FORCE_CLEAR_DOCUMENTS"
    });
    yield put({
      type: "FORCE_CLEAR_NOTIFICATION"
    });
    yield put({
      type: "FORCE_CLEAR_PATIENTS"
    });
    const response = yield signInWithAuthCustomToken(token);
    yield put({
      type: "SET_AUTH_INFO",
      payload: {
        ...store.getState().auth.data,
        accessToken: response.user.accessToken,
        uid: response.user.uid,
        phoneNumber: response.user.phoneNumber,
        displayName: response.user.displayName
      }
    });

    yield setCustomAuthLoading(false);
  } catch (error) {
    yield setCustomAuthLoading(false);
    setErrorStatus(error);
  }
}

function* setCustomAuthLoading(loadingState) {
  yield put({
    type: "SET_CUSTOM_AUTH_LOADING",
    payload: {
      loading: loadingState
    }
  });
}

// {
//   "success": true,
//   "data": {
//       "message": "phr address for patient is created",
//       "healthId": "test15test@sbx",
//       "token": "Bearer eyJhbGciOiJSUzUxMiJ9.eyJwaW5jb2RlIjoiODUyNDU2Iiwic3ViIjoidGVzdDE1dGVzdEBzYngiLCJjbGllbnRJZCI6IlNCWF8wMDE1OTUiLCJyZXF1ZXN0ZXJJZCI6IlBIUi1XRUIiLCJnZW5kZXIiOiJNIiwiZGlzdHJpY3ROYW1lIjoiQ0hFTk5BSSIsIm1vYmlsZSI6Ijg4MjU3MjY3MTciLCJmdWxsTmFtZSI6InRlc3R0ZXIiLCJhZGRyZXNzTGluZSI6IjEyMyIsImhlYWx0aElkTnVtYmVyIjpudWxsLCJtb250aE9mQmlydGgiOiIxMSIsInN5c3RlbSI6IkFCSEEtQSIsInN0YXRlTmFtZSI6IlRBTUlMIE5BRFUiLCJkYXlPZkJpcnRoIjoiMzAiLCJwaHJNb2JpbGUiOiI4ODI1NzI2NzE3IiwiZXhwIjoxNzEwMDM3NzU0LCJpYXQiOjE3MDk0MzI5NTQsInBockFkZHJlc3MiOiJ0ZXN0MTV0ZXN0QHNieCIsImVtYWlsIjoidGVzdEBlbWlhbC5jb20iLCJ0eG5JZCI6ImE2MzVmZTRmLTUzMWItNDRhNC05MTJiLTAxM2UxOGUxNDQ4MiIsInllYXJPZkJpcnRoIjoiMTk5OSJ9.CwlJColYVITbGQuMWG4qu0Gi8OA5FPLi-OXZ-rcGRXn0_f6rpk8c1vRNMty9x7feQ7YyuKivfHYig0csHVNhTPWX9_fpMjfKxJtdqUOE0lAXCbtqMb2yHM4KRZ_GcXjgjDzlN027FBfdMJCh4VtZS46Ifqf1iD1L3jne636PPJ8JAcYAX8LiHTaGtQFgcGmHnTaYgfvJwA-ArFPMbxVeGesGxD7w36p7jay9j0m4z5_pZPW2xnFhJ2vJ7W_t6wGmF3fE-QBaEHzvu5ItvLyyZinsQIewQRc7gMHqmk6hy40NV3Qa89vtSWEwLc0X1vfhXMDmVI7Zyxd4tYhi3s5BzQ",
//       "patientId": "X1x2NgEcO8C4C7JMgBlC"
//   }
// }
