import { CircleIcon, ExclamatoryIcon, TickIcon } from "../../assets/assets";
import { dateAndTimeConverter } from "../../utils/constants";
import "./Incidents.css";

export function IncidentsCard(props) {
  return (
    <>
      {props.incidents &&
        Object.values(props.incidents).map(
          (data, index) =>
            data.affectedUsers.includes(props.userType) && (
              <div
                key={index}
                data-cy={`${data.incidentName}-${data.status}`}
                className={`${
                  data.status === "CLOSED"
                    ? ""
                    : "background-red-linear-gradient"
                }  inherit-parent-width border-radius-4px padding-left-default padding-right-default padding-top-large padding-bottom-large margin-bottom-large flex-justify-content-space-between flex-align-items-center border-bottom-1px-e5e5e5`}
                onClick={() => {
                  props.incidentOnClick(data.incidentId);
                }}
              >
                <div className=" width-80-percentage">
                  <div className=" font-family-gilroy-medium text-capitalize text-overflow-ellipsis  ">
                    {data.incidentName}
                  </div>

                  <div className=" font-size-smaller padding-top-default">
                    {data.status === "CLOSED"
                      ? `COMPLETED AT: ${
                          data.resolutionAt
                            ? dateAndTimeConverter(
                                data.resolutionAt,
                                "cardDateWithoutDay"
                              )
                            : "N/A"
                        }`
                      : `ESTIMATED COMPLETION: ${
                          data.resolutionAt
                            ? getEstimateTime(data.resolutionAt)
                            : "N/A"
                        }`}
                  </div>

                  <div className=" font-size-smaller padding-top-default font-color-secondary">
                    {dateAndTimeConverter(
                      data?.createdAt,
                      "cardDateWithoutDay"
                    )}
                  </div>
                </div>
                <div
                  data-cy={
                    data.status === "CLOSED" ? "TickIcon" : "ExclamatoryIcon"
                  }
                >
                  {data.status === "CLOSED" ? (
                    <TickIcon color="#00A000" />
                  ) : (
                    <ExclamatoryIcon
                      exclamatoryColor="#E64539"
                      borderColor="#E64539"
                    />
                  )}
                </div>
              </div>
            )
        )}
    </>
  );
}

export function SelectedIncident(props) {
  return (
    <>
      <div
        className={` padding-left-default padding-right-default padding-top-medium padding-bottom-large border-bottom-1px-e5e5e5`}
      >
        <div className=" flex-justify-content-space-between flex-align-items-center">
          <div className=" font-family-gilroy-medium text-capitalize">
            {props.incident.incidentName}
          </div>

          <div
            className=" padding-left-large"
            data-cy={
              props.incident.status === "CLOSED"
                ? "TickIcon"
                : "ExclamatoryIcon"
            }
          >
            {props.incident.status === "CLOSED" ? (
              <TickIcon color="#00A000" />
            ) : (
              <ExclamatoryIcon
                exclamatoryColor="#E64539"
                borderColor="#E64539"
              />
            )}
          </div>
        </div>

        <div className=" flex-justify-content-space-between flex-align-items-center">
          <div className=" font-size-smaller padding-top-default">
            {`ESTIMATED COMPLETION: ${
              props.incident.resolutionAt
                ? getEstimateTime(props.incident.resolutionAt)
                : "N/A"
            }`}
          </div>

          <div className=" font-size-smaller padding-top-default font-color-secondary">
            {dateAndTimeConverter(
              props.incident.createdAt,
              "cardDateWithoutDay"
            )}
          </div>
        </div>
      </div>

      <div className=" padding-top-large">
        <TrackProgress data={props.incident.steps} />
      </div>
    </>
  );
}

function TrackProgress(props) {
  return (
    <>
      <div className=" font-size-small padding-bottom-large">
        TRACK PROGRESS
      </div>
      {props.data
        ?.sort((first, second) => {
          return second.updatedAt - first.updatedAt;
        })
        ?.map((data, index) => (
          <div key={index}>
            <div className="display-flex ">
              <div>
                <CircleIcon height={8} color={"#4E4E4E"} />
              </div>
              <div className="display-flex inherit-parent-width flex-justify-content-space-between">
                <div className="padding-left-large width-80-percentage text-overflow-ellipsis">
                  {data.name}
                </div>

                <div className=" display-flex flex-justify-content-center flex-align-items-center">
                  <div className="display-flex flex-align-items-center font-size-smaller font-color-secondary">
                    {data.updatedAt
                      ? dateAndTimeConverter(
                          data.updatedAt,
                          "cardDateWithoutDay"
                        )
                      : "N/A"}
                  </div>
                </div>
              </div>
            </div>
            <div className="display-flex">
              <div className="width-16px flex-align-items-center flex-justify-content-center">
                {!(index === props.data.length - 1) && (
                  <div
                    style={{ width: "2px" }}
                    className={`inherit-parent-height grey-dashed-border `}
                  />
                )}
              </div>
              <div className=" inherit-parent-width flex-justify-content-space-between">
                <div className="padding-left-large padding-top-small font-size-small parent-width_-68px ">
                  {data.description}
                </div>
                <div className=" font-size-smaller  font-color-secondary">
                  {dateAndTimeConverter(data.updatedAt, "Time")}
                </div>
              </div>
            </div>

            {data.description && !(index === props.data.length - 1) && (
              <div className="display-flex">
                <div className="width-16px flex-align-items-center flex-justify-content-center">
                  <div
                    style={{ width: "2px", height: "9px" }}
                    className="grey-dashed-border"
                  />
                </div>
                <div className="padding-left-large padding-top-large"></div>
              </div>
            )}
          </div>
        ))}
    </>
  );
}

const getEstimateTime = (timestamp) => {
  const days = Math.round((timestamp - +new Date()) / 86400000);
  const hours = Math.round((timestamp - +new Date()) / 3600000);
  const minutes = Math.round((timestamp - +new Date()) / 60000);

  if (days >= 1) {
    return `${days} DAY${days !== 1 ? "S" : ""}`;
  } else if (hours >= 1) {
    return `${hours} HR${days !== 1 ? "S" : ""}`;
  } else if (minutes >= 1) {
    return `${minutes} MIN${days !== 1 ? "S" : ""}`;
  } else {
    return "N/A";
  }
};
