import {
  isInteger,
  isPureNumber
} from "../front-end-global-components/services/validators";
import store from "../redux/store/store";
import { checkForConsecutiveCharacters } from "./constants";
import { Regex } from "./regex";

export const isValidObject = (object) => {
  if (Array.isArray(object)) return false;
  return (
    object !== null &&
    object !== undefined &&
    typeof object === "object" &&
    Object.keys(object).length > 0
  );
};

export const isValidArray = (array) => {
  return (
    array !== null &&
    array !== undefined &&
    Array.isArray(array) &&
    array.length > 0
  );
};

//To check
export const isProfilesLoaded = (object) => {
  if (
    object !== null &&
    object !== undefined &&
    Object.keys(object).length > 0 &&
    Object.values(object).every((profile) => {
      return (
        profile.hasOwnProperty("public") && profile.hasOwnProperty("private")
      );
    })
  ) {
    return true;
  } else {
    return false;
  }
};

//To check current profile is valid and its data exists in store
export const isValidProfileId = (profileId) => {
  if (
    typeof profileId === "string" &&
    isProfilesLoaded(store.getState().patients.profiles) &&
    store.getState().patients.profiles.hasOwnProperty(profileId)
  ) {
    return true;
  } else {
    return false;
  }
};

export const validation = (
  key,
  value,
  bloodPressurePrefix,
  bloodPressureSuffix
) => {
  switch (key) {
    case "phoneNumber":
      if (!value)
        return {
          status: true
        };
      if (Regex.phoneNumber.test(value) === false) {
        return {
          status: false,
          message: "Please enter a valid phone number"
        };
      } else {
        return {
          status: true
        };
      }

    case "confirmPassword":
    case "password":
      if (!value)
        return {
          status: true
        };
      if (Regex.password.test(value) === false) {
        return {
          status: false,
          message:
            "Password must contain 8 characters with uppercase, lowercase, special characters and numbers"
        };
      } else if (checkForConsecutiveCharacters(value) !== false) {
        return {
          status: false,
          message:
            "Password should not contains 3 consecutive numbers or alphabets"
        };
      } else {
        return {
          status: true
        };
      }

    case "aadhaarNumber":
      if (
        typeof value === "string" &&
        Regex.aadhaarNumber.test(value) === true
      ) {
        return {
          status: true
        };
      }
      return {
        status: false,
        message: "Please enter a valid aadhaar number"
      };

    case "abhaNumber":
      if (typeof value === "string" && Regex.abhaNumber.test(value) === true) {
        return {
          status: true
        };
      }
      return {
        status: false,
        message: "Please enter a valid ABHA number"
      };

    case "allergies": {
      if (!!value && Array.isArray(value) && value.length > 0) {
        return {
          status: true
        };
      }
      return {
        status: false,
        message: "Please enter a valid body temperature"
      };
    }
    case "date": {
      if (
        !!value &&
        new Date().getFullYear() - new Date(value).getFullYear() <= 100
      ) {
        return {
          status: true
        };
      }
      return {
        status: false,
        message: "Please enter a valid date"
      };
    }
    case "dateOfBirth":
    case "date of birth": {
      if (
        !!value &&
        new Date().getFullYear() - new Date(value).getFullYear() <= 100
      ) {
        return {
          status: true
        };
      }
      return {
        status: false,
        message: "Please enter a valid date"
      };
    }
    case "time": {
      if (!!value) {
        return {
          status: true
        };
      }
      return {
        status: false,
        message: "Please enter a valid time"
      };
    }
    case "heartRate":
    case "heart rate": {
      if (isInteger(value) && value > 0 && value <= 270) {
        return {
          status: true
        };
      }
      return {
        status: false,
        message: "Please enter a valid Heart rate"
      };
    }
    case "bloodOxygen":
    case "blood oxygen": {
      if (isInteger(value) && value > 0 && value <= 100) {
        return {
          status: true
        };
      }
      return {
        status: false,
        message: "Please enter a valid Oxygen level"
      };
    }
    case "bodyTemperature":
    case "body temperature": {
      if (isPureNumber(value) && value > 0 && value <= 200) {
        return {
          status: true
        };
      }
      return {
        status: false,
        message: "Please enter a valid body temperature"
      };
    }
    case "height": {
      if (isPureNumber(value) && value > 30 && value <= 270) {
        return {
          status: true
        };
      }
      return {
        status: false,
        message: "Please enter a valid body height"
      };
    }
    case "pinCode": {
      if (isPureNumber(value) && /^[1-9][0-9]{5}$/gm.test(value)) {
        return {
          status: true
        };
      }
      return {
        status: false,
        message: "Please enter a valid pincode"
      };
    }
    case "float": {
      if (isPureNumber(value)) {
        return {
          status: true
        };
      }
      return {
        status: false,
        message: "Please enter a valid value"
      };
    }
    case "weight": {
      if (isPureNumber(value) && value > 0 && value <= 650) {
        return {
          status: true
        };
      }
      return {
        status: false,
        message: "Please enter a valid body weight"
      };
    }
    case "bloodSugar":
    case "blood sugar": {
      if (isInteger(value) && value > 50 && value <= 999) {
        return {
          status: true
        };
      }
      return {
        status: false,
        message: "Please enter a valid blood sugar level"
      };
    }
    case "bloodPressurePrefix":
    case "blood pressurePrefix": {
      if (
        isInteger(bloodPressurePrefix) &&
        bloodPressurePrefix >= 10 &&
        bloodPressurePrefix <= 999 &&
        isInteger(bloodPressureSuffix) &&
        bloodPressureSuffix >= 10 &&
        bloodPressureSuffix <= 999
      ) {
        return {
          status: true
        };
      }
      return {
        status: false,
        message: "Please enter a valid blood pressure level"
      };
    }
    case "bloodPressureSuffix":
    case "blood pressureSuffix": {
      if (
        isInteger(bloodPressureSuffix) &&
        bloodPressureSuffix >= 10 &&
        bloodPressureSuffix <= 999 &&
        isInteger(bloodPressurePrefix) &&
        bloodPressurePrefix >= 10 &&
        bloodPressurePrefix <= 999
      ) {
        return {
          status: true
        };
      }
      return {
        status: false,
        message: "Please enter a valid blood pressure level"
      };
    }
    case "email": {
      if (Regex.email.test(value)) {
        return {
          status: true
        };
      }
      return {
        status: false,
        message: "Please enter a valid email address"
      };
    }

    case "firstName":
    case "middleName":
    case "lastName":
    case "name": {
      if (!Regex.name.test(value)) {
        return {
          status: true
        };
      }
      return {
        status: false,
        message: "Please enter a valid name"
      };
    }
    default:
      if (!!value) {
        return {
          status: true
        };
      } else {
        return {
          status: false
        };
      }
  }
};
