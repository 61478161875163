import React, { useEffect, useState } from "react";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import {
  isValidArray,
  isValidObject,
  validation
} from "../../utils/validators";
import { Regex } from "../../utils/regex";
import Button from "../../front-end-global-components/components/Button/Button";
import CheckBox from "../../front-end-global-components/components/CheckBox/CheckBox";
import NativeSelect from "../../front-end-global-components/components/NativeSelect/NativeSelect";
import {
  getDateForYearAndMonth,
  getValidGender,
  returnYearArrayForSelectBox,
  stateCodeWithDistricts,
  stateOptionsWithCode
} from "../../utils/constants";
import TextArea from "../../front-end-global-components/components/TextArea/TextArea";
// import { checkHealthIdExists } from "../../redux/abdm/actions";
import { healthIdSchema } from "../../utils/models";
import Header from "../../front-end-global-components/components/Header/Header";
import { connect } from "react-redux";
import {
  clearHealthIdCreatedStatus,
  createHealthId,
  setTransactionId
} from "../../redux/patients/actions";
import HandleBackClick from "../../front-end-global-components/components/HandleBackClick/HandleBackClick";
// import { setErrorStatus } from "../../redux/status/actions";
import Modal from "../../front-end-global-components/components/Modal/Modal";
import ProcessSuccess from "../../components/ProcessSuccess/ProcessSuccess";
import { ArrowIcon } from "../../front-end-global-components/assets/assets";
import { switchLoginWithCustomToken } from "../../redux/authentication/actions";

function ABDMCreate(props) {
  const [step, setStep] = useState("success");
  const [authMode, setAuthMode] = useState(null);
  const [mobile, setMobile] = useState(null);
  useEffect(() => {
    const authData = new URLSearchParams(window.location.search)?.get(
      "authMode"
    );
    const mobileData = new URLSearchParams(window.location.search)?.get(
      "mobile"
    );
    if (mobileData) {
      setMobile(`+${mobileData.trim()}`);
    }

    if (authData) {
      setAuthMode(authData);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!props.patients?.transaction?.id) {
      props.navigate("/abdm/authentication");
    }
    // eslint-disable-next-line
  }, [props.patients?.transaction?.id]);

  useEffect(() => {
    if (props.patients.isHealthIdCreated) {
      setStep("success");
    } else {
      setStep("initial");
    }
    // eslint-disable-next-line
  }, [props.patients.isHealthIdCreated]);

  return (
    <>
      <Header
        title="Create ABHA Address"
        backButtonOnClick={() => {
          if (props.patients.isHealthIdCreated) {
            props.clearHealthIdCreatedStatus();
          }

          if (step === "second") {
            setStep("initial");
          } else {
            props.navigate("/abdm/authentication");
          }
        }}
        hideSupport={true}
        incident={props.app.featureFlags?.incident?.patients}
        incidentOnClick={() => {
          props.navigate("/incidents");
        }}
      />
      <section className="remaining-body-height max-width-588px margin-horizontal-auto overflow-y-auto inherit-parent-width ">
        <CreateHealthId
          mobileNumber={mobile}
          navigate={props.navigate}
          checkHealthIdExists={(healthId) => {
            if (!healthId) {
              return;
            }
            props.checkHealthIdExists(healthId);
          }}
          auth={props.auth}
          confirmOnclick={() => {
            if (props.patients.linkedProfiles?.length === 1) {
              props.switchLoginWithCustomToken(
                props.patients.linkedProfiles?.[0]?.customToken
              );
              props.clearHealthIdCreatedStatus();
              props.navigate("/");
            } else {
              props.navigate("/");
              props.clearHealthIdCreatedStatus();
              props.setTransactionId(null);
            }
          }}
          backButtonOnClick={() => {
            props.navigate("/");
            props.clearHealthIdCreatedStatus();
            props.setTransactionId(null);
          }}
          loginWithDifferentNumber={(token) => {
            props.clearHealthIdCreatedStatus();
            props.switchLoginWithCustomToken(token);
          }}
          patients={props.patients}
          setStep={setStep}
          step={step}
          clearHealthIdExistStatus={() => {
            props.clearHealthIdExistStatus();
          }}
          loading={props.patients.loading || props.abdm.loading}
          authMode={authMode}
          isHealthIdExists={props.abdm.healthIdExists}
          createHealthIdOnSubmit={(data) => {
            // if (props.abdm.healthIdExists) {
            let _data = { ...data };
            _data.transactionId = props.patients?.transaction?.id;
            const responseData = { ...healthIdSchema(_data) };
            const editedData = {
              ...responseData,
              alreadyExistedPHR: false
            };
            props.createHealthId(editedData, authMode, props.navigate);
            // } else {
            //   setErrorStatus("Health Id exists");
            // }
          }}
        />
      </section>
    </>
  );
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  abdm: state.abdm,
  patients: state.patients,
  app: state.app
});

const mapDispatchToProps = function () {
  return {
    createHealthId: (data, requestMethod, push, removeMobile) =>
      createHealthId(data, requestMethod, push, removeMobile),
    clearHealthIdCreatedStatus: () => clearHealthIdCreatedStatus(),
    setTransactionId: (data) => setTransactionId(data),
    switchLoginWithCustomToken: (token) => switchLoginWithCustomToken(token)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ABDMCreate);

const CreateHealthId = (props) => {
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showLinkedProfileModal, setShowLinkedProfileModal] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [formData, setFormData] = useState({
    healthId: "",
    firstName: "",
    middleName: "",
    lastName: "",
    year: "",
    date: "",
    month: "",
    email: "",
    gender: "",
    stateCode: "",
    pinCode: "",
    address: "",
    districtCode: "",
    password: "",
    phoneNumber:
      typeof props.patients?.profiles?.[props.patients.currentProfile]
        ?.phoneNumber === "string"
        ? props.patients?.profiles?.[props.patients.currentProfile]?.phoneNumber
        : "",
    prevHealthId: null,
    patientId:
      typeof props.patients?.profiles?.[props.patients.currentProfile]
        ?.documentId === "string"
        ? props.patients?.profiles?.[props.patients.currentProfile]?.documentId
        : "",
    agreeTo: false
  });

  // check healthId exists
  // useEffect(() => {
  // healthIdExistCheckHandler();
  // eslint-disable-next-line
  // }, []);

  useEffect(() => {
    if (
      typeof props.mobileNumber === "string" &&
      formData.phoneNumber !== props.mobileNumber
    ) {
      setFormData({
        ...formData,
        phoneNumber: props.mobileNumber
      });
    }
    // eslint-disable-next-line
  }, [props.mobileNumber]);

  useEffect(() => {
    if (props.patients.abhaNumberData) {
      setFormData({
        ...formData,
        firstName: props.patients.abhaNumberData?.name?.first || "",
        middleName: props.patients.abhaNumberData?.name?.middle || "",
        lastName: props.patients.abhaNumberData?.name.last || "",
        year: props.patients.abhaNumberData?.dateOfBirth?.year | "",
        date: props.patients.abhaNumberData?.dateOfBirth?.date | "",
        month: props.patients.abhaNumberData?.dateOfBirth?.month | "",
        email: props.patients.abhaNumberData?.email || "",
        gender:
          (props.patients.abhaNumberData?.gender &&
            getValidGender(props.patients.abhaNumberData?.gender)) ||
          "",
        stateCode: props.patients.abhaNumberData?.stateCode || "",
        pinCode: props.patients.abhaNumberData?.pincode || "",
        address: props.patients.abhaNumberData?.address || "",
        districtCode: props.patients.abhaNumberData?.districtCode || ""
      });
    }
    // eslint-disable-next-line
  }, [props.patients.abhaNumberData]);

  // validate the formData
  useEffect(() => {
    let errorCount = 0;
    for (const [key, value] of Object.entries(formData)) {
      if (
        key === "email" ||
        key === "phoneNumber" ||
        key === "date" ||
        key === "month"
      ) {
        continue;
      }
      if (!["prevHealthId"].includes(key) && !["patientId"].includes(key)) {
        if (
          key === "healthId"
            ? Regex.healthId.test(value) === false
            : // || props.isHealthIdExists
              false
        ) {
          errorCount++;
        }
        const validate = validation(key, value);
        if (validate.status === false) {
          errorCount++;
        }
      }
    }
    if (errorCount > 0) {
      setError(true);
    } else {
      setError(false);
    }
  }, [formData, props.isHealthIdExists]);

  // const healthIdExistCheckHandler = () => {
  //   if (
  //     (formData.healthId && formData.healthId.trim().length < 4) ||
  //     formData.healthId.trim().length > 14 ||
  //     Regex.healthId.test(formData.healthId) === false ||
  //     props.loading ||
  //     formData.prevHealthId === formData.healthId
  //   ) {
  //     return checkHealthIdExists(null);
  //   } else {
  //     setFormData({ ...formData, prevHealthId: formData.healthId });
  //     checkHealthIdExists(formData.healthId);
  //   }
  // };

  const disableContinueButton = () => {
    if (
      formData.firstName &&
      formData.year &&
      formData.gender &&
      formData.stateCode &&
      formData.pinCode &&
      formData?.pinCode.length === 6 &&
      formData.address &&
      formData.districtCode &&
      formData.agreeTo
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <>
      {props.step === "initial" && (
        <form
          className="padding-horizontal-larger inherit-parent-height display-flex flex-direction-column flex-justify-content-space-between"
          onChange={(event) => {
            /** clear healthIdExists status in redux store */
            if (
              event.target.name === "healthId" &&
              typeof isHealthIdExists === "boolean" &&
              typeof clearHealthIdExistStatus === "function"
            ) {
              props.clearHealthIdExistStatus();
            }

            if (props.loading === true && event.target.name === "healthId") {
              return;
            }

            if (event.target.name === "agreeTo") {
              setFormData({
                ...formData,
                [event.target.name]: event.target.checked
              });
            } else {
              setFormData({
                ...formData,
                [event.target.name]: event.target.value
              });
            }
          }}
          onSubmit={() => {
            props.setStep("second");
          }}
        >
          <div>
            <div className="text-align-center letter-spacing-large font-family-gilroy-regular font-size-small font-color-secondary padding-top-larger padding-bottom-large">
              <div>YOUR ARE CREATING ABHA ADDRESS FOR</div>
              <div>
                {props.patients.transaction.phoneNumber.includes("+91")
                  ? "PHONE NO"
                  : props.patients.transaction.phoneNumber.includes("@")
                  ? "EMAIL"
                  : "ABHA NO"}{" "}
                : {props.patients.transaction.phoneNumber}
              </div>
            </div>

            <InputBox
              labelClassName="letter-spacing-4-percentage"
              className="inherit-parent-width border-radius-default"
              label="First Name *"
              type="text"
              required
              name="firstName"
              value={formData.firstName}
              disabled={
                props.patients.abhaNumberData?.name?.first ? true : false
              }
            />
            <div className="display-flex">
              <InputBox
                labelClassName="letter-spacing-4-percentage"
                className="inherit-parent-width border-radius-default margin-right-default padding-top-medium"
                label="Middle Name"
                type="text"
                name="middleName"
                size="half"
                value={formData.middleName}
                disabled={
                  props.patients.abhaNumberData?.name?.middle ? true : false
                }
              />
              <InputBox
                labelClassName="letter-spacing-4-percentage"
                className="inherit-parent-width border-radius-default margin-left-default padding-top-medium"
                label="Last Name"
                type="text"
                size="half"
                disabled={
                  props.patients.abhaNumberData?.name?.last ? true : false
                }
                name="lastName"
                value={formData.lastName}
              />
            </div>
            {!(props.authMode === "Email") && (
              <InputBox
                labelClassName="letter-spacing-4-percentage"
                className="inherit-parent-width border-radius-default padding-top-medium"
                label="Email"
                type="email"
                name="email"
                value={formData.email}
                disabled={props.patients.abhaNumberData?.email ? true : false}
                validation={(value) => validation("email", value)}
              />
            )}
            {!(props.authMode === "Phone") && (
              <InputBox
                labelClassName="letter-spacing-4-percentage"
                className="inherit-parent-width border-radius-default padding-top-medium"
                label="Phone Number"
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                validation={(value) => validation("phoneNumber", value)}
              />
            )}
            <TextArea
              type="text"
              className="margin-top-medium padding-top-default"
              value={formData.address}
              name="address"
              label="Address *"
              data-cy="address"
              required
              disabled={props.patients.abhaNumberData?.address ? true : false}
              category="textarea"
            />

            <div className="display-flex padding-top-large">
              <NativeSelect
                className="margin-right-large"
                labelClassName="letter-spacing-4-percentage"
                selectClassName="text-transform-capitalize padding-top-large"
                optionsClassName="text-transform-capitalize"
                name="year"
                label="YEAR *"
                required
                size="small"
                data-cy="year"
                disabled={props.patients.abhaNumberData?.year ? true : false}
                defaultValue={formData.year}
                options={["", ...returnYearArrayForSelectBox(1900)]}
              />
              <NativeSelect
                className="margin-right-large"
                labelClassName="letter-spacing-4-percentage"
                selectClassName="text-transform-capitalize padding-top-large"
                optionsClassName="text-transform-capitalize"
                name="month"
                label="MONTH"
                size="small"
                data-cy="month"
                disabled={formData?.year === "" ? true : false}
                defaultValue={formData.month}
                options={[
                  "",
                  "Jan",
                  "Feb",
                  "Mar",
                  "Apr",
                  "May",
                  "Jun",
                  "Jul",
                  "Aug",
                  "Sep",
                  "Oct",
                  "Nov",
                  "Dec"
                ]}
              />
              <NativeSelect
                labelClassName="letter-spacing-4-percentage"
                selectClassName="text-transform-capitalize padding-top-large"
                optionsClassName="text-transform-capitalize"
                name="date"
                label="DATE"
                size="small"
                data-cy="date"
                disabled={formData?.month === "" ? true : false}
                defaultValue={formData.date}
                options={[
                  "",
                  ...getDateForYearAndMonth(formData.year, formData.month)
                ]}
              />
            </div>
            <div className="display-flex flex-center-children-vertically padding-top-large">
              <NativeSelect
                labelClassName="letter-spacing-4-percentage"
                selectClassName="text-transform-capitalize padding-top-large padding-left-default"
                optionsClassName="text-transform-capitalize"
                name="gender"
                label="Gender *"
                required
                size="half"
                data-cy="gender"
                disabled={props.patients.abhaNumberData?.gender ? true : false}
                defaultValue={formData.gender}
                options={["", "male", "female", "others"]}
              />
            </div>
            <div className="display-flex flex-center-children-vertically padding-top-large">
              <NativeSelect
                className="margin-right-default"
                labelClassName="letter-spacing-4-percentage"
                selectClassName="text-transform-capitalize padding-top-large padding-right-default"
                optionsClassName="text-transform-capitalize"
                name="stateCode"
                label="State *"
                required
                size="half"
                data-cy="stateCode"
                disabled={
                  props.patients.abhaNumberData?.stateCode ? true : false
                }
                defaultValue={formData.stateCode}
                options={stateOptionsWithCode}
              />
              <NativeSelect
                className="margin-left-default"
                labelClassName="letter-spacing-4-percentage"
                selectClassName="text-transform-capitalize padding-top-large padding-left-default"
                optionsClassName="text-transform-capitalize"
                name="districtCode"
                label="District *"
                required
                size="half"
                data-cy="districtCode"
                disabled={
                  props.patients.abhaNumberData?.districtCode ? true : false
                }
                defaultValue={formData.districtCode}
                options={stateCodeWithDistricts[formData.stateCode]}
              />
            </div>
            <InputBox
              labelClassName="letter-spacing-4-percentage"
              className="inherit-parent-width border-radius-default padding-top-large"
              label="Pincode *"
              type="tel"
              required
              name="pinCode"
              disabled={props.patients.abhaNumberData?.pincode ? true : false}
              value={formData.pinCode}
              validation={(value) => validation("pinCode", value)}
            />
            <div className="inherit-parent-width display-flex padding-top-large text-align-left font-size-small margin-top-small">
              <CheckBox
                name="agreeTo"
                label={
                  <div className="font-size-small">
                    I agree to voluntarily share my identity information with
                    Byepo Technologies to create my ABHA Address{" "}
                    {
                      <span
                        className="cursor-pointer padding-left-small font-family-RHD-regular font-color-primary"
                        onClick={() => {
                          setShowModal(true);
                        }}
                      >
                        User Information Agreement
                      </span>
                    }
                  </div>
                }
              />
            </div>
          </div>
          <div className="padding-bottom-larger">
            <Button
              text="Continue"
              data-cy="continue-button"
              boxShadow={false}
              className={"margin-top-large "}
              type="submit"
              disabled={disableContinueButton()}
              loading={props.loading}
            />
          </div>
        </form>
      )}

      {props.step === "second" && (
        <HandleBackClick
          onClose={() => {
            props.setStep("initial");
          }}
          isBackEnabled={props.show}
        >
          <form
            className="padding-horizontal-larger inherit-parent-height display-flex flex-justify-content-space-between flex-direction-column"
            autoComplete="off"
            onChange={(event) => {
              /** clear healthIdExists status in redux store */
              if (
                event.target.name === "healthId" &&
                typeof isHealthIdExists === "boolean" &&
                typeof clearHealthIdExistStatus === "function"
              ) {
                props.clearHealthIdExistStatus();
              }
              if (props.loading === true && event.target.name === "healthId")
                return;
              setFormData({
                ...formData,
                [event.target.name]: event.target.value
              });
            }}
            onSubmit={(event) => {
              event.preventDefault();
              let preSubmitData = { ...formData };
              preSubmitData.healthId = formData.healthId + "@sbx";
              props.createHealthIdOnSubmit(preSubmitData);
            }}
          >
            <div>
              <div className="text-align-center letter-spacing-large font-family-gilroy-regular font-size-small font-color-secondary padding-top-larger padding-bottom-large">
                <div>YOUR ARE CREATING ABHA ADDRESS FOR</div>
                <div>
                  {props.patients.transaction.phoneNumber.includes("+91")
                    ? "PHONE NO"
                    : props.patients.transaction.phoneNumber.includes("@")
                    ? "EMAIL"
                    : "ABHA NO"}{" "}
                  : {props.patients.transaction.phoneNumber}
                </div>
              </div>
              <InputBox
                inputSuffixPlaceHolder={"@sbx"}
                labelClassName="letter-spacing-4-percentage"
                className="inherit-parent-width border-radius-default padding-top-large"
                label="ABHA Address *"
                type="text"
                name="healthId"
                autoComplete="new-data"
                autoFocus={true}
                value={formData.healthId}
                loading={props.loading}
                inputNotesClassName="font-color-primary font-size-smaller padding-top-small"
                notes={
                  props.isHealthIdExists === false
                    ? "This address is available!"
                    : ""
                }
                validation={() => {
                  if (
                    /^[a-zA-Z][a-zA-Z0-9]*[a-zA-Z]$/.test(formData.healthId) ===
                    false
                  ) {
                    return {
                      status: false,
                      message: "Id should starts and end with alphabet only"
                    };
                  } else if (
                    formData.healthId.length <= 18 &&
                    formData.healthId.length <= 8
                  ) {
                    return {
                      status: false,
                      message:
                        "Id should contains min 8 and a max 18 characters"
                    };
                  } else if (props.isHealthIdExists === true) {
                    return {
                      status: false,
                      message:
                        "This address is taken! Please try another address"
                    };
                  } else {
                    return {
                      status: true
                    };
                  }
                }}
                autocomplete="off"
                // onOutOfFocus={(event) => {
                //   healthIdExistCheckHandler(event);
                // }}
              />
              <InputBox
                className="inherit-parent-width border-radius-default margin-bottom-default"
                name="password"
                autoComplete="new-password"
                label="Password *"
                type="password"
                value={formData.password}
                validation={(value) => validation("password", value)}
                required
              />
              <InputBox
                className="inherit-parent-width border-radius-default margin-bottom-default"
                name="confirmPassword"
                autoComplete="new-password"
                label="Confirm Password *"
                type="password"
                validation={(value) => {
                  if (!(value === formData.password)) {
                    return {
                      status: false,
                      message: "Password does not match"
                    };
                  }
                }}
                value={formData.confirmPassword}
                required
              />
            </div>
            <div>
              <Button
                text="Submit"
                data-cy="continue-button"
                boxShadow={false}
                className="margin-top-large margin-bottom-larger"
                required
                type="submit"
                disabled={
                  formData.password === formData.confirmPassword && !error
                    ? // &&
                      // !props.isHealthIdExists
                      false
                    : true
                }
                loading={props.loading}
              />
            </div>
          </form>
        </HandleBackClick>
      )}

      {props.step === "success" && (
        <>
          <ProcessSuccess
            title="Congratulations! ABHA address is created successfully"
            description={`YOUR ABHA ADDRESS IS ${formData?.healthId}@sbx`}
            information={
              props.patients?.transaction?.phoneNumber ===
              props.auth.data.phoneNumber
                ? false
                : "It seems that you have created ABHA address with a different phone number than your Ninto account. Click confirm to login with that account"
            }
            alternative={
              props.patients?.transaction?.phoneNumber ===
              props.auth.data.phoneNumber ? (
                false
              ) : (
                <>
                  <div className="font-weight-normal padding-top-large font-family-gilroy-medium font-size-small font-color-secondary inherit-parent-width text-align-center">
                    Stay logged in with your previous account?
                    <span
                      onClick={props.backButtonOnClick}
                      className="padding-left-small font-color-primary"
                    >
                      Back
                    </span>
                  </div>
                </>
              )
            }
            confirmClick={() => {
              isValidArray(props.patients?.linkedProfiles) &&
              props.patients?.linkedProfiles.length > 1 &&
              isValidObject(
                props.patients?.linkedProfiles?.find((ele) => ele.customToken)
              )
                ? setShowLinkedProfileModal(true)
                : props.confirmOnclick();
            }}
          />

          <Modal
            show={showLinkedProfileModal}
            onClose={() => {
              setShowLinkedProfileModal(!showLinkedProfileModal);
            }}
            canIgnore={false}
            position="position-fixed bottom-0 left-0 right-0"
            boxShadow="none"
            borderRadius="none"
            width="inherit-parent-width"
            background="background-transparent"
            height="height-fit-to-content"
          >
            <div
              data-cy="multiple-login-modal"
              className="background-white padding-large font-family-gilroy-medium user-select-none"
            >
              <div className="inherit-parent-width inherit-parent-height">
                {typeof selectedIndex !== "number" && (
                  <>
                    <div className="text-align-center font-size-small padding-medium font-color-secondary">
                      SELECT TO VIEW CONNECTED HEALTH ID
                    </div>
                    {props.patients.linkedProfiles?.map((data, index) => (
                      <div
                        onClick={() => {
                          setSelectedIndex(index);
                        }}
                        className="display-flex flex-justify-content-space-between padding-bottom-default"
                        key={index}
                      >
                        <div>{data.phoneNumber}</div>
                        <ArrowIcon />
                      </div>
                    ))}

                    <div>
                      <div className="flex-center-children-vertically margin-vertical-large ">
                        <hr
                          style={{ width: "100%" }}
                          className="display-inline-block border-color-secondary-lite border-top-left-right-none flex-basis-1 "
                        />
                        <span className=" margin-horizontal-default display-inline-block font-color-tertiary font-size-small">
                          OR
                        </span>
                        <hr
                          style={{ width: "100%" }}
                          className="display-inline-block border-color-secondary-lite border-top-left-right-none flex-basis-1 "
                        />
                      </div>
                    </div>
                    <div>
                      <div className="font-size-small text-align-center">
                        Create health ID with same number?
                        <span className="font-color-primary padding-left-small">
                          Click here{" "}
                        </span>{" "}
                      </div>
                    </div>
                  </>
                )}
                {typeof selectedIndex === "number" && (
                  <>
                    <div className="text-align-center font-size-small padding-medium font-color-secondary">
                      CONNECTED HEALTH ID
                    </div>
                    <div className="text-align-center font-size-small font-color-secondary">
                      You will now logout from registered phone number then
                      login to this number to access the shown health id(s)
                    </div>

                    <div className="padding-top-large">
                      {
                        props.patients.linkedProfiles?.[selectedIndex]
                          ?.phoneNumber
                      }
                    </div>

                    <div
                      style={{
                        height: "100px"
                      }}
                      className="flex-direction-row gap-0-point-5em margin-top-small flex-wrap overflow-auto padding-top-large padding-bottom-default padding-right-medium"
                    >
                      {props.patients.linkedProfiles?.[
                        selectedIndex
                      ]?.linkedAbhaAddress.map((chip, index) => (
                        <div
                          className={`font-size-small text-overflow-ellipsis padding-small border-2px-lite-grey border-radius-default font-size-small font-family-gilroy-regular font-color-secondary`}
                          key={index}
                        >
                          <span>{chip}</span>
                        </div>
                      ))}
                    </div>

                    <section className="display-flex padding-top-larger ">
                      <Button
                        boxShadow={false}
                        type="button"
                        text="Continue"
                        className={"border-1px-e5e5e5 margin-right-small"}
                        onClick={() => {
                          props.loginWithDifferentNumber(
                            props.patients.linkedProfiles?.[selectedIndex]
                              ?.customToken
                          );
                          props.navigate("/");
                        }}
                        data-cy="confirm-login-button"
                      />
                      <Button
                        boxShadow={false}
                        text="Back"
                        variant={"secondary"}
                        onClick={() => {
                          setSelectedIndex(null);
                        }}
                        className={
                          "font-color-secondary border-1px-e5e5e5 margin-left-small"
                        }
                        data-cy={"cancel-login-button"}
                      />
                    </section>
                  </>
                )}
              </div>
            </div>
          </Modal>
        </>
      )}
      <HandleBackClick
        onClose={() => {
          setShowModal(false);
        }}
        isBackEnabled={showModal}
      >
        <Modal
          show={showModal}
          onClose={() => {
            setShowModal(!showModal);
          }}
          canIgnore={false}
          position="position-fixed bottom-0 left-0 right-0"
          boxShadow="none"
          borderRadius="none"
          width="inherit-parent-width"
          background="background-transparent"
        >
          <div className="background-white padding-default font-family-gilroy-medium user-select-none">
            <div className="inherit-parent-width inherit-parent-height padding-bottom-large">
              <div className="text-align-center font-size-small padding-medium font-color-secondary">
                TERMS AND CONDITION
              </div>
              <div
                style={{ height: "280px" }}
                className="hide-scroll-bar inherit-parent-height"
              >
                <p
                  style={{ lineHeight: "20px", textAlign: "justify" }}
                  className="padding-bottom-default font-size-small padding-left-default padding-right-default font-color-secondary"
                >
                  &nbsp; &nbsp; &nbsp; &nbsp; I, hereby declare that I am
                  voluntarily sharing my Aadhaar Number and demographic
                  information issued by UIDAI, with Byepo Technologies for the
                  sole purpose of creation of ABHA number . I understand that my
                  ABHA number can be used and shared for purposes as may be
                  notified by ABDM from time to time including provision of
                  healthcare services. Further, I am aware that my personal
                  identifiable information (Name, Address, Age, Date of Birth,
                  Gender and Photograph) may be made available to the entities
                  working in the National Digital Health Ecosystem (NDHE) which
                  inter alia includes stakeholders and entities such as
                  healthcare professionals (e.g. doctors), facilities (e.g.
                  hospitals, laboratories) and data fiduciaries (e.g. health
                  programmes), which are registered with or linked to the
                  Ayushman Bharat Digital Mission (ABDM), and various processes
                  there under.
                </p>
                <p
                  style={{ lineHeight: "20px", textAlign: "justify" }}
                  className="padding-bottom-larger font-size-small padding-left-default padding-right-default font-color-secondary"
                >
                  I authorize Byepo Technologies to use my Aadhaar number for
                  performing Aadhaar based authentication with UIDAI as per the
                  provisions of the Aadhaar (Targeted Delivery of Financial and
                  other Subsidies, Benefits and Services) Act, 2016 for the
                  aforesaid purpose. I understand that UIDAI will share my e-KYC
                  details, or response of “Yes” with Byepo Technologies upon
                  successful authentication. I have been duly informed about the
                  option of using other IDs apart from Aadhaar; however, I
                  consciously choose to use Aadhaar number for the purpose of
                  availing benefits across the NDHE. I am aware that my personal
                  identifiable information excluding Aadhaar number / VID number
                  can be used and shared for purposes as mentioned above. I
                  reserve the right to revoke the given consent at any point of
                  time as per provisions of Aadhaar Act and Regulations.
                </p>
              </div>
            </div>
          </div>
        </Modal>
      </HandleBackClick>
    </>
  );
};
