import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { EyeCloseIcon, EyeOpenIcon, VerifiedTick } from "../../assets/assets";
import inputErrorIcon from "../../assets/inputError.svg";
import inputValidIcon from "../../assets/inputValid.svg";
import "../../design-system.css";
import { isPureNumber } from "../../services/validators";
import { Regex } from "../../utils/constants";
import ConditionalRender from "../ConditionalRender/ConditionalRender";
import "./InputBox.css";

function InputBox(props) {
  const [value, setValue] = useState("");
  const [error, setError] = useState();
  const label = useRef(null);
  const inputRef = useRef(null);
  const previousLoadingState = useRef(false);
  const [complete, setComplete] = useState(false);

  //set complete status
  useEffect(() => {
    if (previousLoadingState.current === true && props.loading === false) {
      setComplete(true);
    } else {
      if (complete) setComplete(false);
    }
    previousLoadingState.current = props.loading;
    // eslint-disable-next-line
  }, [props.loading]);

  const handleTextInput = (event) => {
    setValue(event.target.value);
    props.onChange && props.onChange(event);
  };

  // set onchange value
  useEffect(() => {
    setValue(props.value);
    setComplete(false);
  }, [props.value]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validators = (value) => {
    //return if props.value length is "0"
    let isValidInput = true;
    if (!value) {
      isValidInput = false;
      props.isValidInput && props.isValidInput(isValidInput);
      return;
    }
    if (String(value).length === 0) return;
    const emailValidate = /^[a-zA-Z0-9.]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/;
    const userNameValidate = /^[a-zA-Z. ]+$/;
    const phoneNumberValidate = /^[0-9+]+$/;

    if (props.validation) {
      if (props.validation(value)?.status === false) {
        isValidInput = false;
        if (props.validation(value)?.message === error) return;
        setError(props.validation(value).message);
      } else {
        if (error) setError(null);
      }
    } else if (
      props.name &&
      props.name.toLowerCase().includes("year") &&
      props.name !== "yearOfExperience"
    ) {
      if (
        !(
          typeof value === "string" &&
          value.trim().length !== 0 &&
          value.length === 4
        )
      ) {
        isValidInput = false;
        if (error === "Please enter a valid Year") return;
        setError("Please enter a valid Year");
      } else {
        if (error) setError(null);
      }
    } else {
      switch (props.name) {
        case "phoneNumber":
          if (value.length > 3 && value.length < 13) {
            isValidInput = false;
            if (error === "Please enter a valid phone number") return;
            setError("Please enter a valid phone number");
          } else if (value.length < 13) {
            isValidInput = false;
            props.isValidInput && props.isValidInput(isValidInput);
            return;
          } else if (isNaN(value)) {
            isValidInput = false;
            if (error === "Please enter a valid phone number") return;
            setError("Please enter a valid phone number");
          } else if (value.length > 15) {
            isValidInput = false;
            if (error === "Please enter a valid phone number") return;
            setError("Please enter a valid phone number");
          } else if (!phoneNumberValidate.test(value)) {
            isValidInput = false;
            if (error === "Please enter a valid phone number") return;
            setError("Please enter a valid phone number");
          } else if (value.trim().length === 13) {
            if (error) setError(null);
          } else if (value.trim().length > 13) {
            isValidInput = false;
            if (error === "Please enter a valid phone number") return;
            setError("Please enter a valid phone number");
          } else {
            if (error) setError(null);
          }
          break;

        case "fullName":
        case "userName":
          if (value === "") {
            isValidInput = false;
            if (error === "Please enter a valid Name") return;
            setError("Please enter a valid Name");
          } else if (value.length <= 1) {
            isValidInput = false;
            if (error === "Please enter a valid Name") return;
            setError("Please enter a valid Name");
          } else if (!isNaN(value)) {
            isValidInput = false;
            if (error === "Please enter a valid Name") return;
            setError("Please enter a valid Name");
          } else if (!userNameValidate.test(value)) {
            isValidInput = false;
            if (error === "Please enter a valid Name") return;
            setError("Please enter a valid Name");
          } else {
            if (error) setError(null);
          }
          break;

        case "email": {
          let _value = value ? `${value}`.trimStart() : "";
          _value = _value.trimEnd();
          if (!Regex.email.test(value)) {
            isValidInput = false;
            if (error === "Please enter a valid Email") return;
            setError("Please enter a valid Email");
          } else if (!emailValidate.test(_value)) {
            isValidInput = false;
            if (error === "Please enter a valid Email") return;
            setError("Please enter a valid Email");
          } else {
            if (error) setError(null);
          }
          break;
        }

        case "aadhaarNumber": {
          let _value = value ? `${value}`.trimStart() : "";
          _value = _value.trimEnd();
          if (value.trim().length < 1) {
            isValidInput = false;
            if (error === "Please enter a valid aadhaar number") return;
            setError("Please enter a valid aadhaar number");
          } else if (!Regex.aadhaarNumber.test(_value)) {
            isValidInput = false;
            if (error === "Please enter a valid aadhaar number") return;
            setError("Please enter a valid aadhaar number");
          } else {
            if (error) setError(null);
          }
          break;
        }

        case "pincode": {
          if (!value) {
            isValidInput = false;
            if (error === "Please enter a valid pincode") return;
            setError("Please enter a valid pincode");
          }
          if (!(isPureNumber(value) && /^[1-9][0-9]{5}$/gm.test(value))) {
            isValidInput = false;
            if (error === "Please enter a valid pincode") return;
            setError("Please enter a valid pincode");
          } else {
            if (error) setError(null);
          }
          break;
        }

        case "date": {
          if (!value) {
            isValidInput = false;
            if (error === "Please enter a valid date") return;
            setError("Please enter a valid date");
          }
          const userEnteredDate = parseInt(value);
          const currentYear = new Date().getFullYear();
          const reducedYear = currentYear - 100;
          if (userEnteredDate < reducedYear || userEnteredDate > currentYear) {
            isValidInput = false;
            if (error === "Please enter a valid date") return;
            setError("Please enter a valid date");
          } else {
            if (error) setError(null);
          }
          break;
        }
        case "time": {
          if (!value) {
            isValidInput = false;
            if (error === "Please enter a valid time") return;
            setError("Please enter a valid time");
          }
          const userEnteredTime = value.split(":");
          const currentHour = +new Date().getHours();
          const currentMinute = +new Date().getMinutes();

          if (
            parseInt(userEnteredTime[0]) > currentHour ||
            parseInt(userEnteredTime[1]) > currentMinute
          ) {
            isValidInput = false;
            if (error === "Please enter a valid time") return;
            setError("Please enter a valid time");
          } else {
            if (error) setError(null);
          }
          break;
        }

        //height validation
        case "height":
          if (parseInt(value) > 270 || parseInt(value) < 30) {
            isValidInput = false;
            setError("Please enter a valid height");
          } else if (!isPureNumber(value)) {
            isValidInput = false;
            setError("Please enter a valid height");
          } else {
            if (error) setError(null);
          }
          break;

        //body temperature validation
        case "bodyTemperature":
          if (parseInt(value) > 200) {
            isValidInput = false;
            setError("Please enter a valid body temperature");
          } else if (!isPureNumber(value)) {
            isValidInput = false;
            setError("Please enter a valid body temperature");
          } else {
            if (error) setError(null);
          }
          break;

        //blood oxygen validation
        case "bloodOxygen":
          if (parseInt(value) > 100 || parseInt(value) <= -1) {
            isValidInput = false;
            setError("Please enter a valid blood oxygen");
          } else if (!isPureNumber(value)) {
            isValidInput = false;
            setError("Please enter a valid blood oxygen");
          } else {
            if (error) setError(null);
          }
          break;

        //blood sugar validation
        case "bloodSugar":
          if (parseInt(value) > 999 || parseInt(value) < 50) {
            isValidInput = false;
            setError("Please enter a valid blood sugar");
          } else if (!isPureNumber(value)) {
            isValidInput = false;
            setError("Please enter a valid blood sugar");
          } else {
            if (error) setError(null);
          }
          break;

        //heart beat rate validation
        case "heartRate":
          if (parseInt(value) > 270 || parseInt(value) < 0) {
            isValidInput = false;
            setError("Please enter a valid heart rate");
          } else if (!isPureNumber(value)) {
            isValidInput = false;
            setError("Please enter a valid heart rate");
          } else {
            if (error) setError(null);
          }
          break;

        //weight validation
        case "weight":
          if (parseInt(value) > 635 || parseInt(value) <= 2) {
            isValidInput = false;
            setError("Please enter a valid weight");
          } else if (!isPureNumber(value)) {
            isValidInput = false;
            setError("Please enter a valid weight");
          } else {
            if (error) setError(null);
          }
          break;

        default:
          if (error) setError(null);
          break;
      }
    }
    if (props.isValidInput) {
      props.isValidInput(isValidInput);
    }

    if (isValidInput) {
      inputRef.current.classList.remove("error");
    } else {
      inputRef.current.classList.add("error");
    }
  };

  //for validation
  useEffect(() => {
    validators(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, props.validation]);

  const InputSuffixIcon = () => {
    if (props.loading === true) {
      return (
        <div
          className="circular-loader"
          data-cy={`${props.name}-input-box-loader-icon`}
        />
      );
    } else if (props.setVerified) {
      return <VerifiedTick />;
    } else if (typeof error === "string") {
      return (
        <img
          className={`height-width-1em ${props.iconClassNames}`}
          src={inputErrorIcon}
          alt="inputErrorIcon"
          data-cy={`${props.name}-input-box-error-icon`}
        />
      );
    } else if (value && `${value}`.trim().length !== 0 && complete === true) {
      return (
        <img
          className={`height-width-1em ${props.iconClassNames}`}
          src={inputValidIcon}
          alt="inputValidIcon"
          data-cy={`${props.name}-input-box-complete-icon`}
        />
      );
    } else {
      return null;
    }
  };

  const InputInfo = () => {
    if (typeof error === "string" && error !== "") {
      return (
        <p className="font-size-smaller padding-top-small font-color-red">
          {error}
        </p>
      );
    } else if (typeof props.notes === "string") {
      return (
        <p
          className={` ${
            props.inputNotesClassName
              ? props.inputNotesClassName
              : "font-size-smaller font-color-secondary padding-top-small"
          } `}
        >
          {props.notes}
        </p>
      );
    } else {
      return <p className="font-size-smaller">&nbsp;</p>;
    }
  };

  return (
    <div
      className={`inherit-parent-width  ${
        props.className ? props.className : ``
      }`}
    >
      <div
        className={` position-relative display-flex flex-align-items-end height-48px inherit-parent-width  ${
          props.disabled === true ? " background-color-grey " : ""
        } ${
          props.size === "half"
            ? "min-width-138px"
            : props.size === "small"
            ? "min-width-120px"
            : "min-width-250px"
        }`}
      >
        <div className="flex-center-children-horizontally font-size-medium font-family-gilroy-medium inherit-parent-width">
          <div className="inherit-parent-width">
            <input
              onKeyPress={props.onKeyPress}
              onKeyUp={props.onKeyUp}
              id={props.name}
              data-cy={
                props["data-cy"] ? props["data-cy"] : `${props.name}-input-box`
              }
              readOnly={props.disabled}
              ref={inputRef}
              autoFocus={props.autoFocus}
              // defaultValue={props.defaultValue}
              value={props.value}
              className={`input-box background-transparent max-width-100-percentage inherit-parent-width font-family-gilroy-medium font-size-medium padding-top-large padding-bottom-default display-block  input-box-border-bottom-default input-box-disabled-padding-left-5px border-radius-0 ${
                typeof props.buttonTile === "string"
                  ? "padding-right-4p7-em"
                  : ""
              } ${(props.type === "date" || props.type === "time") &&
                "padding-right-0"} ${
                props.disabled === true
                  ? " font-color-secondary disabled"
                  : " font-color-secondary"
              }`}
              autoComplete={props.autoComplete}
              min={props.minDate}
              max={
                props.type === "date"
                  ? props.maxDate
                    ? props.maxDate
                    : new Date().toISOString().split("T")[0]
                  : null
              }
              onFocus={(event) => {
                if (props.disabled === true) return;
                if (label.current) {
                  label.current && label.current.classList.add("active");
                }
                if (!!inputRef.current) {
                  inputRef.current && inputRef.current.classList.add("active");
                }
              }}
              onBlur={(event) => {
                if (
                  !event.currentTarget.value &&
                  props.type !== "date" &&
                  props.type !== "time"
                )
                  label.current && label.current.classList.remove("active");
                inputRef.current && inputRef.current.classList.remove("active");
                if (event.target.value.trim().length === 0) {
                  setError(null);
                  inputRef.current.classList.remove("error");
                }
                if (typeof props.onOutOfFocus === "function") {
                  props.onOutOfFocus(event);
                }
              }}
              type={
                props.type === "number" ? null : props.type ? props.type : null
              }
              name={props.name}
              onChange={handleTextInput}
              required={props.required === true ? true : false}
              inputMode={
                props.type === "number"
                  ? "numeric"
                  : props.inputMode
                  ? props.InputBox
                  : null
              }
            />
            <span className="input-box-border-bottom"></span>
            <label
              htmlFor={props.name}
              ref={label}
              className={` ${
                props.removeResponsive ? "" : "adaptive-font-size"
              } input-box-label  font-color-secondary  position-absolute font-family-gilroy-medium font-size-medium ${
                props.labelClassName ? props.labelClassName : ""
              } ${
                inputRef.current === document.activeElement ||
                props.value ||
                props.defaultValue ||
                (props.type === "number" && parseInt(props.value) > 0
                  ? true
                  : false) ||
                props.type === "time" ||
                props.type === "date" ||
                props.alwaysActive ||
                props.autoFocus === true
                  ? "active"
                  : ""
              } ${props.disabled ? " disabled " : ""}
							`}
            >
              {props.label}
            </label>
            {typeof props.inputSuffixPlaceHolder === "string" && (
              <div className="position-absolute right-0 bottom-0 padding-bottom-default display-flex flex-align-items-center">
                <label
                  className={` font-color-secondary font-size-small font-family-gilroy-regular padding-left-small `}
                >
                  {props.inputSuffixPlaceHolder}
                </label>
              </div>
            )}
          </div>
          <div
            className={` position-absolute  flex-align-items-center flex-justify-content-center min-width-1em absolute-center-self-vertically
					 	${props.type === "date" || props.type === "time" ? " right-1p5em" : " right-0"}
					 `}
          >
            {typeof props.inputSuffixPlaceHolder !== "string" && (
              <InputSuffixIcon />
            )}
            {
              <ConditionalRender
                condition={
                  (props.name === "password" ||
                    props.name === "confirmPassword") &&
                  props.type === "password"
                    ? true
                    : false
                }
              >
                <TogglePasswordVisibility name={props.name} />
              </ConditionalRender>
            }

            {typeof props.buttonTile === "string" && (
              <button
                key={"otp-button"}
                id={props.buttonTileId}
                type={props.buttonType}
                data-cy={
                  props["button-data-cy"]
                    ? props["button-data-cy"]
                    : `${props.name}-input-box-suffix-button`
                }
                onClick={(event) => {
                  if (typeof props.onButtonClick === "function") {
                    props.onButtonClick(event);
                  }
                }}
                disabled={props.suffixButtonDisabled}
                className={`${props.buttonClassName} font-weight-normal margin-left-medium margin-right-medium padding-small font-color-secondary font-family-gilroy-regular font-size-smaller background-transparent margin-left-8px suffix-button-border border-radius-0p7em text-transform-uppercase font-size-small `}
              >
                {props.buttonTile}
              </button>
            )}
          </div>
        </div>
      </div>
      <InputInfo />
    </div>
  );
}

InputBox.propTypes = {
  /**
   * prop which shows loading spinner animation
   */
  loading: PropTypes.bool,
  /**
   * prop which return input onChange event as callback
   */
  onChange: PropTypes.func,
  /**
   * input element value
   */
  value: PropTypes.string,
  /**
   * callback function which returns the input is valid or not
   * `props.isValidInput(isValidInput)` returns `true` or `false`
   */
  isValidInput: PropTypes.func,
  /**
   * custom validation function
   */
  validation: PropTypes.func,
  /**
   * name attribute of input element
   */
  name: PropTypes.string,
  /**
   * class name for icons
   */
  iconClassNames: PropTypes.string,
  /**
   * notes
   */
  notes: PropTypes.string,
  /**
   * class name for parent element of the input component
   */
  className: PropTypes.string,
  /**
   * which is used to disable the input element
   */
  disabled: PropTypes.bool,
  /**
   * which is used to remove the adaptiveness of font size
   */
  removeResponsive: PropTypes.bool,
  /**
   * to control the min-width of the component
   */
  size: PropTypes.oneOf(["half", "small"]),
  /**
   * selector attribute for cypress testing, by default value is `-input-box` followed by the input name
   */
  "data-cy": PropTypes.string,
  /**
   * event callback prop
   */
  autoFocus: PropTypes.bool,
  /**
   * input element attribute which shows default value
   */
  defaultValue: PropTypes.string,
  /**
   * additional button which will reside inside the input component
   */
  buttonTile: PropTypes.string,
  /**
   * input type
   * `date` `time` `text` `number` `tel`
   */
  type: PropTypes.string,
  /**
   * attribute used to enable auto complete
   */
  autoComplete: PropTypes.oneOf(["on", "off"]),
  /**
   * for input `type="date"`
   */
  minDate: PropTypes.string,
  /**
   * onBlur event of the input element
   */
  onOutOfFocus: PropTypes.func,
  /**
   * which defines the input is required or not
   */
  required: PropTypes.bool,
  /**
   * class name label element
   */
  labelClassName: PropTypes.string,
  /**
   * property which sets the label as active state
   */
  alwaysActive: PropTypes.bool,
  /**
   * label for the input box component
   */
  label: PropTypes.string,
  /**
   * id for addition button tile
   */
  buttonTileId: PropTypes.string,
  /**
   * type of button tile
   */
  buttonType: PropTypes.oneOf(["submit", "button", "reset"]),
  /**
   * button tile onClick call back function
   */
  onButtonClick: PropTypes.func,
  /**
   * disable state for button tile
   */
  suffixButtonDisabled: PropTypes.bool
};

InputBox.defaultProps = {
  loading: false,
  removeResponsive: false,
  onChange: undefined,
  value: undefined,
  isValidInput: undefined,
  validation: undefined,
  name: "default",
  iconClassNames: "",
  notes: null,
  className: "",
  disabled: false,
  size: null,
  "data-cy": null,
  autoFocus: null,
  buttonTile: null,
  type: null,
  autoComplete: "off",
  onOutOfFocus: undefined,
  required: false,
  labelClassName: "",
  alwaysActive: false,
  label: null,
  buttonTileId: null,
  buttonType: "button",
  onButtonClick: undefined,
  suffixButtonDisabled: false
};

export default InputBox;

const TogglePasswordVisibility = (props) => {
  const [toggle, setToggle] = useState(false);

  const data = document.querySelector(`input[name=${props.name}]`);
  return (
    <>
      {data?.type === "password" ? (
        <div
          className="padding-left-default"
          key={toggle}
          onClick={() => {
            if (data) {
              data.type = "text";
              setToggle(true);
            }
          }}
        >
          <EyeCloseIcon />
        </div>
      ) : (
        <div
          className="padding-left-default"
          key={toggle}
          onClick={() => {
            if (data) {
              data.type = "password";
            }
            setToggle(false);
          }}
        >
          <EyeOpenIcon />
        </div>
      )}
    </>
  );
};
