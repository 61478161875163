import { all } from "redux-saga/effects";
import abdmWatcher from "./abdm/saga";
import appWatcher from "./app/saga";
import statusWatcher from "./status/saga";
import authWatcher from "./authentication/saga";
import downloadsWatcher from "./downloads/saga";
import queryWatcher from "./query/saga";
import fitnessWatcher from "./fitness/saga";
import mockWatcher from "./mock/saga";
import documentsWatcher from "./documents/saga";
import patientsWatcher from "./patients/saga";
import { notificationsWatcher } from "./notifications/saga";
import intentsWatcher from "./intent/saga";

export default function* rootSaga() {
  yield all([
    intentsWatcher(),
    abdmWatcher(),
    fitnessWatcher(),
    authWatcher(),
    documentsWatcher(),
    statusWatcher(),
    mockWatcher(),
    notificationsWatcher(),
    queryWatcher(),
    downloadsWatcher(),
    appWatcher(),
    patientsWatcher()
  ]);
}
