import { useEffect, useState } from "react";
import "./CreateAbhaNumber.css";
import Modal from "../../front-end-global-components/components/Modal/Modal";
import InputBox from "../../front-end-global-components/components/InputBox/InputBox";
import { validation } from "../../utils/validators";
import Button from "../../front-end-global-components/components/Button/Button";
import CheckBox from "../../front-end-global-components/components/CheckBox/CheckBox";
import OTPInputBox from "../../front-end-global-components/components/OTPInputBox/OTPInputBox";
import RoundedProfilePicture from "../../front-end-global-components/components/RoundedProfilePicture/RoundedProfilePicture";
import closeIcon from "../../assets/icons/close.svg";
import HandleBackClick from "../../front-end-global-components/components/HandleBackClick/HandleBackClick";

export default function CreateAbhaNumber(props) {
  const [aadharData, setAadharData] = useState({
    aadhaarNumber: "",
    checkbox: false
  });
  const [OTP, setOTP] = useState("");
  const [counter, setCounter] = useState(null);
  const [running, setRunning] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("+91");
  const [showModal, setShowModal] = useState(false);
  const [showTermsAndConditionModal, setShowTermsAndConditionModal] =
    useState(false);

  useEffect(() => {
    setShowModal(props.showModal);
    // eslint-disable-next-line
  }, [props.showModal]);

  useEffect(() => {
    let counterTimer;
    if (counter && typeof counter === "number" && running) {
      counterTimer = setTimeout(() => {
        setCounter(counter - 1);
      }, 1000);
    } else {
      setCounter(null);
      setRunning(false);
      return () => clearTimeout(counterTimer);
    }
    // eslint-disable-next-line
  }, [counter]);

  useEffect(() => {
    if (
      props.transactionId !== null &&
      !running &&
      !props.transactionId.includes("aadhaarVerified")
    ) {
      setCounter(60);
      setRunning(true);
    }

    if (
      running &&
      (props.transactionId === null ||
        props.transactionId.includes("aadhaarVerified"))
    ) {
      setCounter(null);
      setRunning(false);
    }

    // eslint-disable-next-line
  }, [props.transactionId]);

  const profilePhoto = () => {
    if (props.abhaNumberDetails.profilePhoto) {
      return `data:image/png;base64, ${props.abhaNumberDetails.profilePhoto}`;
    } else {
      return null;
    }
  };

  function resetStates() {
    setAadharData({
      aadhaarNumber: "",
      checkbox: false
    });
    setCounter(null);
    setRunning(false);
    setPhoneNumber("+91");
  }

  return (
    <>
      {/* <HandleBackClick
        onClose={() => {
          props.closeModal();
        }}
        isBackEnabled={showModal}
      > */}
      <Modal
        show={showModal}
        onClose={() => {
          props.closeModal();
        }}
        // canIgnore={true}
        position="position-fixed bottom-0 left-0 right-0"
        boxShadow="none"
        width="inherit-parent-width"
        background="background-transparent"
        height="height-fit-to-content"
      >
        <div className="background-white padding-larger box-shadow-default font-color-secondary">
          {!props.getOtp && !props.getMobileNumber && !props.abhaNumberDetails && (
            <>
              <div className=" display-flex flex-justify-content-space-between">
                <div />
                <div className=" text-align-center font-family-gilroy-regular font-size-small">
                  CREATE ABHA NUMBER
                </div>
                <img
                  src={closeIcon}
                  alt="close icon"
                  onClick={() => {
                    props.closeModal();
                    resetStates();
                  }}
                />
              </div>
              <div className=" text-align-center font-family-gilroy-regular font-size-small padding-top-large ">
                An OTP will be send to your mobile number which is registered
                with your Aadhar number
              </div>

              <form
                onChange={(event) => {
                  if (event.target.name === "aadhaarNumber") {
                    setAadharData({
                      ...aadharData,
                      [event.target.name]: event.target.value
                    });
                  }
                  if (event.target.name === "checkbox") {
                    setAadharData({
                      ...aadharData,
                      [event.target.name]: event.target.checked
                    });
                  }
                }}
                onSubmit={(event) => {
                  event.preventDefault();
                  if (aadharData.checkbox) {
                    props.registerNewAbhaNumber({
                      aadhaarNumber: aadharData.aadhaarNumber,
                      type: "sendAadharOtp"
                    });
                  }
                  // props.searchHealthIdAuthMode(abhaNumber);
                }}
              >
                <InputBox
                  className="inherit-parent-width border-radius-default margin-vertical-large"
                  name="aadhaarNumber"
                  label="Aadhar Number"
                  value={aadharData.aadhaarNumber}
                  type="number"
                  autoComplete="off"
                  required
                  data-cy="aadhar-number-field"
                  validation={(value) => validation("aadhaarNumber", value)}
                />
                <div className=" display-flex flex-align-items-center font-size-small padding-bottom-medium">
                  <CheckBox
                    data-cy="terms-and-conditions-check-box"
                    id="userAgreement"
                    checked={aadharData.checkbox}
                  />

                  <div className=" padding-left-default">
                    I Agree to
                    <spam
                      className=" font-color-primary"
                      onClick={() => {
                        setShowTermsAndConditionModal(true);
                      }}
                    >
                      Terms and conditions
                    </spam>
                  </div>
                </div>

                <Button
                  text="Continue"
                  variant={"primary"}
                  boxShadow={false}
                  type="submit"
                  disabled={
                    !aadharData.checkbox ||
                    aadharData.aadhaarNumber.length !== 12
                      ? true
                      : false
                  }
                  className={"margin-top-default"}
                  data-cy={"continue-button"}
                  loading={props.loading}
                />
              </form>
            </>
          )}

          {props.getOtp && !props.getMobileNumber && !props.abhaNumberDetails && (
            <>
              <form
                onSubmit={(event) => {
                  event.preventDefault();

                  if (props.getMobileOtp) {
                    props.registerNewAbhaNumber({
                      otp: OTP,
                      type: "verifyMobileOtp"
                    });
                  } else {
                    props.registerNewAbhaNumber({
                      otp: OTP,
                      type: "verifyAadharOtp"
                    });
                  }
                }}
              >
                <div className=" display-flex flex-justify-content-space-between">
                  <div />

                  <img
                    src={closeIcon}
                    alt="close icon"
                    onClick={() => {
                      props.closeModal();
                      resetStates();
                    }}
                  />
                </div>

                <OTPInputBox
                  name="otp"
                  autoFocus={true}
                  value={OTP}
                  setValue={(otpValue) => {
                    setOTP(otpValue);
                  }}
                  required
                  className="margin-bottom-large"
                />

                <div className="display-flex flex-justify-content-space-between font-size-medium margin-bottom-larger">
                  <div>
                    {counter &&
                      typeof counter === "number" &&
                      `${
                        counter?.toString().length === 1 ? "0" : ""
                      }${counter?.toString()} seconds`}
                  </div>
                  <div
                    className={`${
                      counter ? "font-color-tertiary" : "font-color-primary"
                    }`}
                    onClick={() => {
                      // setCounter(60);
                      if (!counter) {
                        props.registerNewAbhaNumber({
                          aadhaarNumber: aadharData.aadhaarNumber,
                          type: "sendAadharOtp"
                        });
                      }
                      // props.generateAbhaAddressOtp(abhaNumber, selectedAuthMode);
                    }}
                  >
                    Resend OTP
                  </div>
                </div>

                <Button
                  text="Continue"
                  variant="primary"
                  boxShadow={false}
                  required
                  type="submit"
                  disabled={OTP.length !== 6 ? true : false}
                  className="margin-top-default margin-left-default"
                  data-cy="Continue-button"
                  loading={props.loading}
                />
              </form>
            </>
          )}

          {props.getMobileNumber && props.getOtp && !props.abhaNumberDetails && (
            <>
              <div className=" display-flex flex-justify-content-space-between">
                <div />
                <div className=" text-align-center font-family-gilroy-regular font-size-small">
                  ENTER MOBILE NUMBER
                </div>
                <img
                  src={closeIcon}
                  alt="close icon"
                  onClick={() => {
                    props.closeModal();
                    resetStates();
                  }}
                />
              </div>

              <div className=" text-align-center font-family-gilroy-regular font-size-small padding-top-large ">
                Connect your mobile number with ABHA number
              </div>

              <form
                onChange={(event) => {
                  setPhoneNumber(event.target.value);
                }}
                onSubmit={(event) => {
                  event.preventDefault();
                  props.registerNewAbhaNumber({
                    mobileNumber: phoneNumber,
                    type: "sendMobileOtp"
                  });
                }}
              >
                <InputBox
                  className="inherit-parent-width border-radius-default margin-vertical-large"
                  name="phoneNumber"
                  label="Phone Number"
                  value={phoneNumber}
                  type="tel"
                  autoComplete="off"
                  required
                  data-cy="aadhar-number-field"
                />

                <Button
                  text="Continue"
                  variant={"primary"}
                  boxShadow={false}
                  required
                  type="submit"
                  disabled={phoneNumber.length !== 13 ? true : false}
                  className={"margin-top-default"}
                  data-cy={"continue-button"}
                  loading={props.loading}
                />
              </form>
            </>
          )}

          {props.abhaNumberDetails && (
            <>
              <div className=" text-align-center font-family-gilroy-regular font-size-small">
                ABHA NUMBER WAS CREATED SUCCESSFULLY
              </div>

              <div className=" display-flex flex-align-items-center flex-justify-content-space-between padding-vertical-larger">
                <div>
                  <div className=" font-size-small">NAME</div>
                  <div className=" padding-top-small">
                    {props.abhaNumberDetails.name}
                  </div>

                  <div className=" font-size-small padding-top-large">
                    ABHA NUMBER
                  </div>
                  <div className=" padding-top-small">
                    {props.abhaNumberDetails.healthIdNumber}
                  </div>
                </div>
                <RoundedProfilePicture src={profilePhoto()} size="medium" />
              </div>
              <Button
                text="Done"
                variant={"primary"}
                boxShadow={false}
                required
                type="button"
                className={"margin-top-default"}
                data-cy={"continue-button"}
                loading={props.loading}
                onClick={() => {
                  props.closeModal();
                  resetStates();
                }}
              />
            </>
          )}
        </div>
      </Modal>
      {/* </HandleBackClick> */}

      <HandleBackClick
        onClose={() => {
          setShowTermsAndConditionModal(false);
        }}
        isBackEnabled={showTermsAndConditionModal}
      >
        <Modal
          show={showTermsAndConditionModal}
          onClose={() => {
            setShowTermsAndConditionModal(!showTermsAndConditionModal);
          }}
          canIgnore={false}
          position="position-fixed bottom-0 left-0 right-0"
          boxShadow="none"
          borderRadius="none"
          width="inherit-parent-width"
          background="background-transparent"
        >
          <div className="background-white inherit-parent-height padding-default font-family-gilroy-medium user-select-none">
            <div className="inherit-parent-width inherit-parent-height ">
              <div className="text-align-center font-size-small padding-medium font-color-secondary">
                TERMS AND CONDITION
              </div>
              <div
                style={{ height: "280px" }}
                className="hide-scroll-bar inherit-parent-height"
              >
                <p
                  style={{ lineHeight: "20px", textAlign: "justify" }}
                  className="padding-bottom-default font-size-small padding-left-default padding-right-default font-color-secondary"
                >
                  &nbsp; &nbsp; &nbsp; &nbsp; I, hereby declare that I am
                  voluntarily sharing my Aadhaar Number and demographic
                  information issued by UIDAI, with Byepo Technologies for the
                  sole purpose of creation of ABHA number . I understand that my
                  ABHA number can be used and shared for purposes as may be
                  notified by ABDM from time to time including provision of
                  healthcare services. Further, I am aware that my personal
                  identifiable information (Name, Address, Age, Date of Birth,
                  Gender and Photograph) may be made available to the entities
                  working in the National Digital Health Ecosystem (NDHE) which
                  inter alia includes stakeholders and entities such as
                  healthcare professionals (e.g. doctors), facilities (e.g.
                  hospitals, laboratories) and data fiduciaries (e.g. health
                  programmes), which are registered with or linked to the
                  Ayushman Bharat Digital Mission (ABDM), and various processes
                  there under.
                </p>
                <p
                  style={{ lineHeight: "20px", textAlign: "justify" }}
                  className="padding-bottom-larger font-size-small padding-left-default padding-right-default font-color-secondary"
                >
                  I authorize Byepo Technologies to use my Aadhaar number for
                  performing Aadhaar based authentication with UIDAI as per the
                  provisions of the Aadhaar (Targeted Delivery of Financial and
                  other Subsidies, Benefits and Services) Act, 2016 for the
                  aforesaid purpose. I understand that UIDAI will share my e-KYC
                  details, or response of “Yes” with Byepo Technologies upon
                  successful authentication. I have been duly informed about the
                  option of using other IDs apart from Aadhaar; however, I
                  consciously choose to use Aadhaar number for the purpose of
                  availing benefits across the NDHE. I am aware that my personal
                  identifiable information excluding Aadhaar number / VID number
                  can be used and shared for purposes as mentioned above. I
                  reserve the right to revoke the given consent at any point of
                  time as per provisions of Aadhaar Act and Regulations.
                </p>
              </div>
            </div>
          </div>
        </Modal>
      </HandleBackClick>
    </>
  );
}

// {
//   "message": "phr address for patient is created",
//   "healthId": "test13test@sbx",
//   "token": "Bearer eyJhbGciOiJSUzUxMiJ9.eyJwaW5jb2RlIjoiMTQ1NjMyIiwic3ViIjoidGVzdDEzdGVzdEBzYngiLCJjbGllbnRJZCI6IlNCWF8wMDE1OTUiLCJyZXF1ZXN0ZXJJZCI6IlBIUi1XRUIiLCJnZW5kZXIiOiJNIiwiZGlzdHJpY3ROYW1lIjoiQ0hFTk5BSSIsIm1vYmlsZSI6Ijg4MjU3MjY3MTciLCJmdWxsTmFtZSI6Ind3IGVlIGVlIiwiYWRkcmVzc0xpbmUiOiJ0ZXN0IiwiaGVhbHRoSWROdW1iZXIiOm51bGwsIm1vbnRoT2ZCaXJ0aCI6IjExIiwic3lzdGVtIjoiQUJIQS1BIiwic3RhdGVOYW1lIjoiVEFNSUwgTkFEVSIsImRheU9mQmlydGgiOiIzMCIsInBock1vYmlsZSI6Ijg4MjU3MjY3MTciLCJleHAiOjE3MDk0MjI1MzcsImlhdCI6MTcwODgxNzczNywicGhyQWRkcmVzcyI6InRlc3QxM3Rlc3RAc2J4IiwiZW1haWwiOiJ3ZWVlQGdtYWlsLmNvbSIsInR4bklkIjoiMjY2MjE4NTMtZjc4Yy00N2M3LTkxOTItN2VlZGQ5M2NhYWNhIiwieWVhck9mQmlydGgiOiIxOTk5In0.wKm-suRAjGa7SGs_u88Ne2n5xYzEp_Y4TjDFZlcQIQoFGg6wlsKg0GFjDwoOQ1B-Jj7BqxpR6-HutOXAVdhv52SQDVmJgidsjhXXValonzHNPgauuvD_z3pRv7ZZQ0n2IH9QVJ4fvFpQYkE9lD-srX-tMxYTrMaYcG9RCjEQMKEKd-UbkkcZJAqcek1weyMaJxs8_ApGZ7t7Ne5UuKTOey-ePZTuw1L_jJiX-cZdzm-d20UVbps2nRBUL_r5jnwJd08C2j1UiyAzS5zV9UuFheB5jIC6LGQoehZ4zoD_k1RNUHHFH-HE6_HiKRS7CaQfseBJDLSrVb24BvDdsv1RQA",
//   "patientId": "l5wZotkiTpmo5SHoNY3K",
//   "linkedProfiles": [
//       {
//           "customToken": "eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJhdWQiOiJodHRwczovL2lkZW50aXR5dG9vbGtpdC5nb29nbGVhcGlzLmNvbS9nb29nbGUuaWRlbnRpdHkuaWRlbnRpdHl0b29sa2l0LnYxLklkZW50aXR5VG9vbGtpdCIsImlhdCI6MTcwODgxNzc0MCwiZXhwIjoxNzA4ODIxMzQwLCJpc3MiOiJmaXJlYmFzZS1hZG1pbnNkay02d2VsekBrYXJ0aGVhbHRoLWRldi5pYW0uZ3NlcnZpY2VhY2NvdW50LmNvbSIsInN1YiI6ImZpcmViYXNlLWFkbWluc2RrLTZ3ZWx6QGthcnRoZWFsdGgtZGV2LmlhbS5nc2VydmljZWFjY291bnQuY29tIiwidWlkIjoiZzFManBvRWJrUVowM2YwZ3JFMVA3VE5JUjRJMiJ9.leGvF-ilejrObhY0TqnkW0VFLyi6vTT_0vOETiVL6x96ShUNwqx_sdBYIBpj8N5x0QM5i2uyLpH3qp2WVpUZqK-NqEhkVpcJbjqd36TuactZWIsa4PIL1biKDnJDoAOWlfo53JtA-c33S_R4lsWA4N9_QvWLK8LW2HQh-krWldVHfbOFZ1g4_RiC6_79I_FEjSrjtB2TNph_CL0miPP8-6yZn-UglBhiUIcHijb9omtQQT6Ozqig4GUpO7HCcSo_ToCVrCbCgjcw1NQHCKaqMfEAjjVLWPCibYSr5b09yIAGVBM0HdpbcFdyhQZOaxPl2ZgFwxX-nmayMskP382j2g",
//           "phoneNumber": "+918825726717",
//           "linkedAbhaAddress": [
//               "wolf@sbx",
//               "wolfanbu@sbx",
//               "saymyname@sbx",
//               "freefire@sbx",
//               "noneedforthis@sbx",
//               "hellobebe@sbx",
//               "checkitout@sbx",
//               "gravitybounce@sbx",
//               "anbuchezhiyan@sbx",
//               "suma3333336717@sbx",
//               "mochatestanbu2@sbx",
//               "integrationtesttwo@sbx",
//               "anbutest@sbx",
//               "91675434702147@sbx",
//               "anbu2test@sbx",
//               "mochatestanbu@sbx",
//               "lovely1test@sbx",
//               "lovely2test@sbx",
//               "te14ting@sbx",
//               "valuesss@sbx",
//               "mochatest@sbx",
//               "integrationtestone@sbx",
//               "qazxswedc@sbx",
//               "allforone@sbx",
//               "mixedup2n@sbx",
//               "sampleabhaadd@sbx",
//               "ninto55test@sbx",
//               "wolf3559sample@sbx",
//               "test2test@sbx",
//               "test6test@sbx",
//               "test1test@sbx",
//               "test4test@sbx",
//               "test5test@sbx",
//               "hello2test@sbx",
//               "test7test@sbx",
//               "test8test@sbx",
//               "test9test@sbx",
//               "test10test@sbx",
//               "hello2dead@sbx",
//               "test13test@sbx",
//               "test11test@sbx",
//               "test12test@sbx",
//               "testwtest@sbx",
//               "dasdwsdf@sbx"
//           ]
//       }
//   ]
// }
