import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Header from "../../front-end-global-components/components/Header/Header";
import "./Settings.css";
import notificationIcon from "../../assets/icons/notification.svg";
import profileIcon from "../../assets/icons/profile.svg";
import connectionIcon from "../../assets/icons/connection.svg";
import notificationIconActive from "../../assets/icons/notification-active.svg";
import profileIconActive from "../../assets/icons/profile-active.svg";
import connectionIconActive from "../../assets/icons/connection-active.svg";
import { isValidObject } from "../../front-end-global-components/services/validators";
import Connections from "../../components/connections/Connections";
import { isValidArray } from "../../utils/validators";
import { CSSTransition } from "react-transition-group";
import Profile from "../../components/profile/Profile";
import { logout } from "../../redux/authentication/actions";
import { getProfilePictureDownloadUrl } from "../../redux/downloads/actions";
import {
  setNotificationRead,
  updateNotifications
} from "../../redux/notifications/actions";
import {
  createDemographic,
  getPatientsRequests,
  switchPatientProfile,
  setRequestsFilterData,
  setActiveSettingsMenu,
  setTransactionId
} from "../../redux/patients/actions";
import ConnectABDM from "../../components/ConnectABDM/ConnectABDM";
import { setNotificationPermission } from "../../redux/app/actions";
import Notification from "../../components/Notification/Notification";
// import ProfileSwitch from "../../components/ProfileSwitch/ProfileSwitch";
import { requestsValidation } from "../../utils/requestsValidation";
import { validLocationPath } from "../../utils/constants";
import FeatureFlagsFallback from "../../front-end-global-components/components/FeatureFlagsFallback/FeatureFlagsFallback";
import ConditionalRender from "../../front-end-global-components/components/ConditionalRender/ConditionalRender";

function returnNotificationCounts(profileId, notifications) {
  /**
   * profileId => props.patients.currentProfile
   * notification => props.notifications.data */
  if (
    typeof profileId === "string" &&
    notifications !== null &&
    notifications[profileId] &&
    isValidArray(Object.values(notifications[profileId]))
  ) {
    const count = Object.values(notifications[profileId]).filter(
      (notification) => notification.read === false
    ).length;
    return count;
  } else {
    return 0;
  }
}

const Settings = (props) => {
  const [activeMenu, setActiveMenu] = useState("notification");
  const [
    addProfile
    // setAddProfile
  ] = useState(false);

  useEffect(() => {
    if (props.patients.activeMenu === null) {
      setActiveSettingsMenu("notification");
    } else {
      setActiveMenu(props.patients.activeMenu);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.patients.activeMenu]);

  return (
    <section data-cy="settings-screen" className="inherit-parent-height">
      <Header
        title="Settings"
        backButtonOnClick={() => {
          props.navigate("/");
        }}
        supportIconOnClick={() => {
          props.navigate("/query");
        }}
        incident={props.app.featureFlags?.incident?.patients}
        incidentOnClick={() => {
          props.navigate("/incidents");
        }}
      />
      <section
        data-cy="profile-switcher"
        style={{ height: "calc(100% - 64px)" }}
        className="max-width-588px margin-horizontal-auto overflow-hidden padding-top-large position-relative"
      >
        {addProfile === false && (
          <SettingsMenu
            loading={props.patients.loading}
            show={addProfile === false ? true : false}
            userName={
              props.patients.profiles &&
              props.patients.currentProfile &&
              props.patients.profiles[props.patients.currentProfile]?.name
            }
            healthId={
              props.patients.currentProfile &&
              props.patients.profiles?.[props.patients.currentProfile]?.healthId
                ? props.patients.profiles?.[props.patients.currentProfile]
                    .healthId
                : null
            }
            // show={props.patients.p}
            phoneNumber={
              props.patients.profiles &&
              props.patients.currentProfile &&
              props.patients.profiles[props.patients.currentProfile]
                ?.phoneNumber
            }
            notificationCount={
              props.notifications.data &&
              props.patients.currentProfile &&
              returnNotificationCounts(
                props.patients.currentProfile,
                props.notifications.data
              )
            }
            connectionRequestCount={
              props.clinics?.requests &&
              props.patients.currentProfile &&
              isValidArray(props.clinics.requests)
                ? props.clinics.requests.filter(
                    (request) =>
                      request.recipient === props.patients.currentProfile
                  ).length
                : 0
            }
            activeMenu={activeMenu}
            menuOnClick={(menuType) => {
              if (activeMenu === menuType) return;
              props.setActiveSettingsMenu(menuType);
            }}
          />
        )}

        <ConditionalRender
          condition={activeMenu === "connections" && addProfile === false}
        >
          <ConditionalRender
            condition={
              props.patients.accessToken &&
              props.patients.currentProfile &&
              props.patients.accessToken[props.patients.currentProfile]
                ? true
                : false
            }
          >
            <ConditionalRender
              condition={!props.app.featureFlags?.consentManagement}
            >
              <FeatureFlagsFallback height="settings-screen-content-remaining-height" />
            </ConditionalRender>

            <ConditionalRender
              condition={props.app.featureFlags?.consentManagement}
            >
              <Connections
                connectOnClick={() => {
                  props.navigate("/abdm/authentication");
                }}
                navigate={props.navigate}
                loading={props.patients.loading}
                fetchAbdmRequests={() => {
                  if (
                    props.patients.accessToken &&
                    props.patients.currentProfile &&
                    props.patients.accessToken[props.patients.currentProfile]
                  ) {
                    props.getPatientsRequests();
                  }
                }}
                requests={
                  props.patients.requests &&
                  props.patients.currentProfile &&
                  props.patients.requests[props.patients.currentProfile] &&
                  requestsValidation(
                    props.patients.requests[props.patients.currentProfile],
                    props.patients.autoApprovalPolicies &&
                      props.patients.autoApprovalPolicies
                  )
                }
                isHealthIdLoggedIn={
                  props.patients.accessToken &&
                  props.patients.accessToken[props.patients.currentProfile]
                }
                abdmRequestOnClick={(abdmRequestType, abdmRequestId) => {
                  props.navigate(`/abdm/${abdmRequestType}/${abdmRequestId}`);
                }}
                autoApprovalPolicies={
                  props.patients.autoApprovalPolicies &&
                  props.patients.autoApprovalPolicies
                }
                setRequestsFilter={(filterBy, Id) => {
                  props.setRequestsFilterData(filterBy, Id);
                }}
                filters={props.patients.requestsFilter}
                isUserAgreed={
                  props.patients?.profiles?.[props.patients.currentProfile]
                    ?.consentToPersonalData?.userAgreed
                }
              />
            </ConditionalRender>
          </ConditionalRender>

          <ConditionalRender
            condition={
              !props.patients.accessToken ||
              !props.patients.currentProfile ||
              !props.patients.accessToken[props.patients.currentProfile]
                ? true
                : false
            }
          >
            <div className=" profile-setting-height  padding-horizontal-large flex-justify-content-center flex-align-items-center ">
              <ConnectABDM
                connectOnClick={() => {
                  props.navigate("/abdm/login/verify");
                }}
              />
            </div>
          </ConditionalRender>
        </ConditionalRender>

        {props.patients.loading && activeMenu === "profile" && (
          <ProfileSuspense />
        )}

        {!props.patients.loading &&
          activeMenu === "profile" &&
          addProfile === false && (
            <Profile
              changeConsentPinOnClick={() => {
                props.navigate("/abdm/changeconsentpin");
              }}
              abdmHealthIdQrOnClick={() => {
                props.navigate("/abdm/abdmHealthId");
              }}
              logoutOnClick={() => {
                props.logout();
              }}
              resetPasswordOnClick={() => {
                props.navigate("/resetPassword");
              }}
              editProfileOnClick={() => {
                props.navigate("/abdm/editProfile");
              }}
              abhaNumberOnClick={() => {
                props.navigate("/abdm/abhaNumber");
              }}
              linkedFacilitiesOnClick={() => {
                props.navigate("/abdm/linkedFacilities");
              }}
              navigate={props.navigate}
              abdmLoggedIn={
                props.patients.accessToken?.[props.patients.currentProfile]
                  ? true
                  : false
              }
              isUserAgreed={
                props.patients?.profiles?.[props.patients.currentProfile]
                  ?.consentToPersonalData?.userAgreed
              }
            />
          )}
        <Notification
          paginationLoading={props.notifications?.paginationLoading}
          // paginationHandler={() => {
          //   props.notificationPagination(
          //     props.patients.currentProfile,
          //     props.notifications.paginationCount
          //   );
          // }}
          app={props.app}
          onCloseClick={() => {
            props.setNotificationPermission(false, props.navigate);
          }}
          loading={props.notifications.loading}
          show={
            activeMenu === "notification" && addProfile === false ? true : false
          }
          notificationCount={returnNotificationCounts(
            props.patients.currentProfile,
            props.notifications.data
          )}
          notifications={
            props.notifications.data &&
            props.patients.currentProfile &&
            isValidObject(
              props.notifications.data[props.patients.currentProfile]
            ) &&
            Object.values(
              props.notifications.data[props.patients.currentProfile]
            )
          }
          notificationCardOnClick={(notification) => {
            if (notification.data?.documentType) {
              props.navigate(
                `/${validLocationPath(notification.data.documentType)}/${
                  notification.data.documentId
                }`
              );
            }
            if (notification?.read === false) {
              props.setNotificationRead(notification);
            }
          }}
          updateNotifications={() => {
            props.updateNotifications();
          }}
          pagination={
            props.patients.currentProfile &&
            props.notifications.pagination &&
            props.notifications.pagination[props.patients.currentProfile]
          }
          markAllAsReadOnClick={() => {
            props.setNotificationRead(null, "all");
          }}
        />
      </section>
    </section>
  );
};

const mapStateToProps = (state) => ({
  app: state.app,
  auth: state.auth,
  abdm: state.abdm,
  profile: state.profile,
  notifications: state.notifications,
  clinics: state.clinics,
  documents: state.documents,
  downloads: state.downloads,
  patients: state.patients
});

const mapDispatchToProps = function () {
  return {
    setNotificationPermission: (status) => setNotificationPermission(status),
    setTransactionId: (data) => setTransactionId(data),
    updateNotifications: () => updateNotifications(),
    getProfilePictureUrl: (profileType, profileId) =>
      getProfilePictureDownloadUrl(profileType, profileId),
    switchPatientProfile: (profileId) => {
      switchPatientProfile(profileId);
    },
    setNotificationRead: (notificationId, type) =>
      setNotificationRead(notificationId, type),
    logout: () => logout(),
    getPatientsRequests: () => {
      getPatientsRequests();
    },
    createDemographic: (profileName) => {
      createDemographic(profileName);
    },
    setRequestsFilterData: (filterBy, Id) =>
      setRequestsFilterData(filterBy, Id),
    setActiveSettingsMenu: (menu) => setActiveSettingsMenu(menu)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Settings);

//settings menu component
const SettingsMenu = ({
  loading,
  show,
  phoneNumber,
  userName,
  healthId,
  activeMenu,
  menuOnClick,
  notificationCount,
  connectionRequestCount
}) => {
  const menu = [
    {
      type: "notification",
      icon: notificationIcon,
      activeIcon: notificationIconActive,
      showCount: true,
      countDataCy: "notification-count",
      count: notificationCount,
      "data-cy": "notification-menu"
    },
    {
      type: "profile",
      icon: profileIcon,
      activeIcon: profileIconActive,
      showCount: false,
      countDataCy: "profile-count",
      "data-cy": "profile-menu"
    },
    {
      type: "connections",
      icon: connectionIcon,
      activeIcon: connectionIconActive,
      countDataCy: "connection-count",
      showCount: true,
      count: connectionRequestCount,
      "data-cy": "connections-menu"
    }
  ];

  return (
    <CSSTransition
      in={show}
      timeout={2000}
      classNames="fade-in-fade-out-transition"
    >
      <React.Fragment>
        {show === true && (
          <>
            <section className="padding-left-medium padding-right-medium flex-justify-content-space-between flex-align-items-center justify-between">
              <div>
                <h3 className="font-size-larger font-family-gilroy-medium font-weight-normal font-color-secondary">
                  {userName}
                </h3>
                <div className="font-color-secondary font-family-gilroy-medium font-size-small padding-bottom-large padding-top-small">
                  {healthId ? (
                    healthId
                  ) : (
                    <>
                      <span className="padding-right-small ">
                        {typeof phoneNumber === "string" &&
                          phoneNumber.substring(0, 3)}
                      </span>
                      <span className="padding-right-small">
                        {typeof phoneNumber === "string" &&
                          phoneNumber.substring(3, 8)}
                      </span>
                      <span className="padding-right-small">
                        {typeof phoneNumber === "string" &&
                          phoneNumber.substring(8, 13)}
                      </span>
                    </>
                  )}
                </div>
              </div>
              <div className="display-flex flex-align-items-center">
                {menu.map((menu) => (
                  <div
                    key={menu.type}
                    className="display-flex flex-direction-column margin-horizontal-small"
                  >
                    <div
                      data-cy={menu["data-cy"]}
                      className={`settings-menu-icon-size border-radius-default display-flex flex-align-items-center flex-justify-content-center cursor-pointer ${
                        activeMenu === menu.type
                          ? "background-color-grey "
                          : "background-color-white"
                      } ${menu.showCount === true ? "position-relative" : ""}`}
                      onClick={() => {
                        if (typeof menuOnClick === "function") {
                          menuOnClick(menu.type);
                        }
                      }}
                    >
                      <img
                        src={
                          activeMenu === menu.type ? menu.activeIcon : menu.icon
                        }
                        alt=""
                        className="settings-menu-icon-height"
                        width="auto"
                      />
                      {menu.count > 0 && menu.showCount === true && (
                        <div
                          data-cy={menu.countDataCy}
                          className={`settings-notification-icon-count-size${
                            menu.count > 99 ? "-large" : ""
                          } settings-notification-icon-count-position box-shadow-default flex-place-children-page-center border-radius-50-percentage font-color-secondary background-white position-absolute text-align-center font-size-extra-small ${
                            menu.count > 9
                              ? "height-and-width-for-double-digit-number"
                              : menu.count > 99
                              ? "height-and-width-for-triple-digit-number"
                              : "height-and-width-for-single-digit-number"
                          }`}
                        >
                          {menu.count > 99 ? "99+" : menu.count}
                        </div>
                      )}
                    </div>
                    <span
                      className={`padding-top-larger ${
                        activeMenu === menu.type
                          ? "active-menu-border-bottom-2px-green"
                          : "menu-border-bottom-2px"
                      }`}
                    />
                  </div>
                ))}
              </div>
            </section>
            <div className="settings-menu-horizontal-ruler inherit-parent-width background-white" />
          </>
        )}
      </React.Fragment>
    </CSSTransition>
  );
};

const ProfileSuspense = () => {
  return (
    <section
      className="padding-top-default padding-bottom-large overflow-hidden"
      data-cy="home-page-loading"
    >
      {[...Array(7)].map((_data, index) => (
        <div
          key={index}
          className="padding-top-large padding-left-large padding-right-large"
        >
          <div className="flex-center-children-vertically padding-bottom-default">
            <div className="margin-left-default">
              <div className="shimmer padding-small border-radius-default width-150px ">
                <div className="padding-smaller" />
              </div>
              <div className="shimmer margin-top-default padding-small border-radius-default width-200px " />
            </div>
          </div>
        </div>
      ))}
    </section>
  );
};
