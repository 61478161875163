import React, { useRef, useState, useEffect } from "react";
import PropTypes from "prop-types";

function PinInputBox(props) {
  const [digits, setDigits] = useState(0);
  const [pinValue, setPinValue] = useState(["", "", "", "", "", ""]);
  const [currentFocus, setCurrentFocus] = useState(0);
  const inputBoxes = [
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null),
    useRef(null)
  ];

  useEffect(() => {
    if (
      typeof props.digits === "number" &&
      props.digits > 3 &&
      props.digits < 7
    ) {
      setDigits(props.digits);
    }
  }, [props.digits]);

  useEffect(() => {
    if (digits > 3) {
      inputBoxes[currentFocus].current.focus();
      inputBoxes[currentFocus].current.select();
    }
    // eslint-disable-next-line
  }, [currentFocus]);

  useEffect(() => {
    typeof props.setValue === "function" && props.setValue(pinValue.join(""));
    // eslint-disable-next-line
  }, [pinValue]);

  const changeOTPValue = (key, refIndex) => {
    if (key === "") {
      let newOTPValue = [...pinValue];
      newOTPValue[refIndex] = "";
      setPinValue(newOTPValue);
      if (refIndex > 0) {
        setCurrentFocus(refIndex - 1);
      }
      return;
    }
    if (/^\d+$/.test(key)) {
      if (refIndex < digits - 1) {
        let newOTPValue = [...pinValue];
        for (const [index, number] of key.split("").entries()) {
          if (refIndex + index > digits - 1) {
            break;
          }
          newOTPValue[refIndex + index] = number;
        }
        setPinValue(newOTPValue);
        refIndex + key.length <= digits - 1
          ? setCurrentFocus(refIndex + key.length)
          : setCurrentFocus(digits - 1);
      } else if (refIndex === digits - 1) {
        let newOTPValue = [...pinValue];
        newOTPValue[refIndex] = key[key.length - 1];
        setPinValue(newOTPValue);
        setCurrentFocus(refIndex);
      }
    }
  };

  useEffect(() => {
    //resets the current focus and input value if props.digits is changes
    setPinValue(["", "", "", "", "", ""]);
    setCurrentFocus(0);
  }, [props.digits]);

  useEffect(() => {
    return () => {
      //clear the input value and focus while the component unmount
      setPinValue(["", "", "", "", "", ""]);
      setCurrentFocus(0);
    };
  }, []);

  const labelClassName = props.labelClassName ? props.labelClassName : "";

  const className =
    "flex-justify-content-space-between inherit-parent-width margin-top-small";
  return (
    <>
      <p
        className={`${labelClassName} text-align-center font-size-smaller letter-spacing-large`}
      >
        {props.label}
      </p>
      <div
        className={`${className} ${props.className ? props.className : ""}`}
        data-cy={"OTP-box"}
      >
        {digits > 0
          ? [...Array(digits)].map((r, index) => (
              <input
                key={index}
                inputMode="numeric"
                autoFocus={props.otpFocus}
                pattern="[0-9]*"
                data-cy={props.dataCy? `${props.dataCy}-pin${index + 1}` :`pin${index + 1}`}
                // onPaste={(event) => {
                //   event.preventDefault();
                //   const clipboardData = (
                //     event.clipboardData || window.clipboardData
                //   ).getData("text");
                //   if (
                //     typeof clipboardData === "string" &&
                //     clipboardData.trim().length !== 0 &&
                //     clipboardData.trim().length >= 6 &&
                //     /^\d+$/.test(clipboardData.trim())
                //   ) {
                //     setPinValue(clipboardData.trim().split(""));
                //     setCurrentFocus(5);
                //   }
                // }}
                onKeyUp={(event) => {
                  event.preventDefault();
                  if (event.key === "Backspace" && event.target.value === "") {
                    if (index === 0) {
                      setCurrentFocus(0);
                    } else {
                      setCurrentFocus(index - 1);
                    }
                  }
                }}
                className={`max-width-54px padding-bottom-default padding-right-default padding-left-default ${
                  props.error
                    ? "border-bottom-otp-box-error"
                    : "border-bottom-otp-box"
                } background-white font-size-medium otp-input-box-size flex-center-children-horizontally text-align-center font-family-gilroy-medium`}
                ref={inputBoxes[index]}
                type={props.type === "number" ? "text" : "password"}
                onClick={(event) => {
                  event.preventDefault();
                }}
                onInput={(event) => {
                  event.preventDefault();
                  event.stopPropagation();
                  changeOTPValue(event.target.value, index);
                }}
                onFocus={(event) => {
                  event.preventDefault();
                  inputBoxes?.[currentFocus]?.current?.focus();
                }}
                value={pinValue[index]}
                autoComplete={"off"}
              />
            ))
          : "please enter valid digits. digit must between 4-6."}
      </div>
    </>
  );
}

PinInputBox.propTypes = {
  /**
   * label -> pin input box label
   */
  label: PropTypes.string,
  /**
   * labelClassName -> pin input box label clsss name
   */
  labelClassName: PropTypes.string,
  /**
   * function which give OTP value as arguements
   */
  setValue: PropTypes.func,
  /**
   * digits -> number of pin input fields
   * min digit 4, max digit 6
   * should be number
   */
  digits: PropTypes.number,
  /**
   * which is used to style to style the parent element
   */
  className: PropTypes.string
};

PinInputBox.defaultProps = {
  digits: 4,
  setValue: undefined,
  className: "",
  labelClassName: "",
  label: "Pin input box"
};
export default PinInputBox;
