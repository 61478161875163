async function clearOldPreCache() {
  if ("caches" in window) {
    const keyList = await caches.keys();

    let precacheList = keyList
      .filter((key) => key.includes("ninto-patients-precache"))
      .sort();

    if (precacheList.length > 1) {
      for (const [index, key] of precacheList.entries()) {
        if (index === precacheList.length - 1) {
          continue;
        }
        await caches.delete(key);
      }
    }
  }
}

export const skipWaitingClearCacheAndReload = async () => {
  navigator.serviceWorker.ready.then(async (registration) => {
    if (registration.waiting) {
      navigator.serviceWorker.addEventListener("message", (event) => {
        if (event.data === "FORCE_UPDATE_COMPLETE") {
          window.location.reload(true);
        }
      });
      const dataBase = await indexedDB.databases();
      dataBase.forEach((dbName) => {
        indexedDB.deleteDatabase(dbName.name);
      });
      await clearOldPreCache();
      registration.waiting.postMessage("FORCE_UPDATE");
    }
  });
};