import { all, put, takeEvery } from "@redux-saga/core/effects";
import { setErrorStatus } from "../status/actions";
import { addRegData } from "../../services/database";
import store from "../store/store";

export const intentActionTypes = {
  PUT_REGISTRATION_DATA: "PUT_REGISTRATION_DATA",
  ADD_REGISTRATION_DATA: "ADD_REGISTRATION_DATA"
};

function* putRegistrationData(action) {
  try {
    yield setIntentLoading(true);
    yield put({
      type: "SET_REG_DATA",
      payload: {
        regToken: action.payload.data.regToken,
        device: action.payload.data.device,
        deviceId: action.payload.data.deviceId
      }
    });

    action.payload.navigate("/");

    yield setIntentLoading(false);
  } catch (error) {
    yield setIntentLoading(false);
    setErrorStatus(error);
  }
}

function* addRegistrationData(action) {
  try {
    yield setIntentLoading(true);
    yield addRegData(store.getState().intent.data.deviceId, {
      registrationToken: store.getState().intent.data.regToken,
      lastLogin: +new Date(),
      osVersion: store.getState().intent.data.device.toString(),
      phoneNumber: store.getState().auth.data.phoneNumber,
      type: "test",
      userTypes: ["patients"]
    });
    yield setIntentLoading(false);
  } catch (error) {
    yield setIntentLoading(false);
    setErrorStatus(error);
  }
}

export default function* intentsWatcher() {
  yield all([
    takeEvery("PUT_REGISTRATION_DATA", putRegistrationData),
    takeEvery("ADD_REGISTRATION_DATA", addRegistrationData)
  ]);
}

function* setIntentLoading(bool) {
  yield put({
    type: "SET_INTENT_LOADING",
    payload: {
      loading: bool
    }
  });
}
