import React, { useRef, useState } from "react";
import "./NativeSelect.css";
import "../../design-system.css";
import PropTypes from "prop-types";
import dropDownStatusIcon from "../../assets/Next.svg";

function NativeSelect(props) {
  const label = useRef(null);
  const multiSelectDropdownRef = useRef(null);
  const [showOptions, setShowOptions] = useState(false);
  const [tempSelectionIndex, setTempSelectionIndex] = useState(0);
  const selectElementRef = useRef(null);
  const isSelectElementFocusedRef = useRef(false);

  return (
    <>
      <div
        ref={selectElementRef}
        className={` position-relative inherit-parent-width font-size-default"
					${props.disabled === true ? " background-color-grey " : ""}
						${
              props.size === "half"
                ? "min-width-138px"
                : props.size === "small"
                ? "max-width-100px"
                : "min-width-250px"
            }
						${props.className ? props.className : ``}
					`}
        onClick={() => (isSelectElementFocusedRef.current = true)}
      >
        <div
          className={`height-48px inherit-parent-width flex-align-items-end`}
        >
          <div className="flex-center-children-horizontally  font-size-medium font-family-gilroy-medium inherit-parent-width">
            <div className="inherit-parent-width">
              <select
                required={props.required ? props.required : false}
                ref={multiSelectDropdownRef}
                className={`appearance-none native-select text-capitalize  font-color-secondary inherit-parent-width font-family-gilroy-medium font-size-medium padding-top-large padding-bottom-default max-width-100-percentage display-block text-overflow-ellipsis native-select-border-bottom-default 
								${
                  props.disabled === true
                    ? "font-color-secondary"
                    : props.selectClassName
                    ? props.selectClassName
                    : ""
                }`}
                name={props.name}
                id={props.name}
                disabled={props.disabled}
                data-cy={`${props.name}-select-element`}
                onFocus={(event) => {
                  if (props.disabled) return;
                  label.current.classList.add("active");
                  setShowOptions(true);
                  setTempSelectionIndex(event.target.selectedIndex);
                  event.target.selectedIndex = -1;
                }}
                onBlur={(event) => {
                  if (props.defaultValue) {
                    if (props.defaultValue.length === 0) {
                      label.current.classList.remove("active");
                    }
                  } else {
                    if (!event.target.value) {
                      label.current.classList.remove("active");
                    }
                  }
                  if (typeof props.onOutOfFocus === "function") {
                    props.onOutOfFocus(event);
                  }
                  if (event.target.selectedIndex === -1) {
                    event.target.selectedIndex = tempSelectionIndex;
                  }
                }}
                onChange={(event) => {
                  typeof props.onChange === "function" && props.onChange(event);
                }}
              >
                {Array.isArray(props.options) &&
                  props.options.map((data, index) => (
                    <option
                      value={data.value ? data.value : data}
                      selected={
                        typeof data === "object"
                          ? props.defaultValue === data.value
                          : props.defaultValue === data
                      }
                      key={index}
                      // disabled={data === ""}
                      hidden={data === ""}
                    >
                      {data.option ? data.option : data}
                    </option>
                  ))}
              </select>
              {showOptions !== true && (
                <span className="native-select-border-bottom"></span>
              )}
              <label
                htmlFor={props.name}
                ref={label}
                className={` ${
                  props.removeResponsive
                    ? "native-select-label"
                    : "adaptive-native-select-label"
                } ${
                  props.size === "half"
                    ? "top-45-percentage"
                    : "top-55-percentage"
                } font-color-secondary position-absolute font-family-gilroy-medium font-size-medium  ${
                  props.labelClassName ? props.labelClassName : ""
                }  ${
                  (props.defaultValue && props.defaultValue.length !== 0) ||
                  props.alwaysActive
                    ? "active"
                    : ""
                }`}
              >
                {props.label}
              </label>
            </div>
          </div>
          <img
            src={dropDownStatusIcon}
            alt="dropdown"
            className="select-suffix-icon"
            data-cy="select-toggle-icon"
            width="0.8em"
          />
        </div>
      </div>
      <p className="font-size-smaller">&nbsp;</p>
    </>
  );
}

NativeSelect.propTypes = {
  /**
   * which is used to disable the input element
   */
  disabled: PropTypes.bool,
  /**
   * to control the min-width of the component
   */
  size: PropTypes.oneOf(["half", "default", "small"]),
  /**
   * class name for parent element of the input component
   */
  className: PropTypes.string,
  /**
   * selected option class name
   */
  selectClassName: PropTypes.string,
  /**
   * name attribute of select input element, and use name attribute for id attribute
   */
  name: PropTypes.string,
  /**
   * prop which return input onChange event as callback
   */
  onChange: PropTypes.func,
  /**
   * sets default value for select input element
   */
  defaultValue: PropTypes.string,
  /**
   * which return onBlur event
   */
  onOutOfFocus: PropTypes.func,
  /**
   * array of options taken as input select input element
   */
  options: PropTypes.arrayOf(PropTypes.string),
  /**
   * which used to style the label
   */
  labelClassName: PropTypes.string,
  /**
   * which sets the label to active state
   */
  alwaysActive: PropTypes.bool,
  /**
   * which used to set the label for the select input element
   */
  label: PropTypes.string,
  /**
   * required `true` or `false`
   */
  required: PropTypes.bool
};

NativeSelect.defaultProps = {
  size: "default",
  disabled: false,
  className: "",
  selectClassName: null,
  name: undefined,
  onChange: undefined,
  defaultValue: null,
  required: false,
  onOutOfFocus: undefined,
  options: [""],
  labelClassName: "",
  label: null
};

export default NativeSelect;
