import store from "../store/store";
import { authActionTypes } from "./saga";
/**
 * @module AuthSaga
 */

/**
 * Sends OTP to the Phone number in payload
 * Type indicates the authentication type login or sign in
 * @param {string} phoneNumber
 * @param {string} type
 */
export function sendOtp(phoneNumber, type) {
  store.dispatch({
    type: authActionTypes.SEND_OTP,
    payload: {
      phoneNumber: phoneNumber,
      type: type
    }
  });
}

/**
 * Verifies OTP from the Phone number in payload
 * clinicName indicates the name from payload
 * Type indicates the authentication type login or sign in
 * @param {string} loginOtp
 * @param {string} clinicName
 * @param {string} phoneNumber
 * @param {string} type
 */
export function verifyOtp(loginOtp, userName, phoneNumber, type) {
  store.dispatch({
    type: authActionTypes.VERIFY_OTP,
    payload: {
      loginOtp: loginOtp,
      userName: userName,
      phoneNumber: phoneNumber,
      type: type
    }
  });
}

/**
 * Login in using OTP from payload
 * @param {object} data
 */
export function putAuthInfo(data) {
  store.dispatch({
    type: authActionTypes.PUT_AUTH_INFO,
    payload: {
      data: data
    }
  });
}

/**
 * Login in using OTP from payload
 * @param {string} loginOtp
 */
export function login(loginOtp) {
  store.dispatch({
    type: authActionTypes.LOGIN,
    payload: {
      loginOtp: loginOtp
    }
  });
}

/**
 * For logging out of user
 */
export function logout() {
  store.dispatch({
    type: authActionTypes.LOGOUT
  });
}

/**
 * Clears the credentials after login
 */
export function clearAuthCredentials() {
  store.dispatch({
    type: authActionTypes.CLEAR_AUTH_CREDENTIALS
  });
}

/**
 * set oAuth Access token
 * @param {string} accessToken
 * @param {string} email
 */
export function setOAuthAccessToken(accessToken, email) {
  store.dispatch({
    type: authActionTypes.SET_OAUTH_ACCESS_TOKEN,
    payload: {
      accessToken: accessToken,
      email: email
    }
  });
}
/**
 * Update Access token
 * @param {string} accessToken
 */
export function refreshAccessToken(accessToken, phoneNumber) {
  store.dispatch({
    type: authActionTypes.REFRESH_ACCESS_TOKEN,
    payload: {
      accessToken: accessToken,
      phoneNumber: phoneNumber
    }
  });
}
/**
 * send oAuth Access code
 * @param {string} accessCode
 */
export function sendOAuthAccessCode(accessCode) {
  store.dispatch({
    type: authActionTypes.SEND_OAUTH_ACCESS_CODE,
    payload: {
      accessCode: accessCode
    }
  });
}

/**
 * send accessToken
 * @param {string} accessToken
 */
export function switchLoginWithCustomToken(accessToken) {
  store.dispatch({
    type: authActionTypes.SWITCH_WITH_CUSTOM_TOKEN,
    payload: {
      token: accessToken
    }
  });
}
