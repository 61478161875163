import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Header from "../../front-end-global-components/components/Header/Header";
import "./AbdmHealthIdQr.css";
import { throwError } from "../../services/error";
import { getPatientQrDetails } from "../../services/api";
import { setErrorStatus } from "../../redux/status/actions";
import {
  DownloadIcon,
  ScanIcon,
  ShareIcon
} from "../../front-end-global-components/assets/assets";

import ConditionalRender from "../../front-end-global-components/components/ConditionalRender/ConditionalRender";
import FeatureFlagsFallback from "../../front-end-global-components/components/FeatureFlagsFallback/FeatureFlagsFallback";

function AbdmHealthIdQr(props) {
  const [qrDataUriLoading, setQrDataUriLoading] = useState(false);
  const [dataUrl, setDataUrl] = useState(null);

  const handleDownload = () => {
    const link = document.createElement("a");
    link.href = dataUrl;
    link.download = "image.jpg";
    link.click();
  };

  const handleShare = async () => {
    const blob = await (await fetch(dataUrl)).blob();
    const file = new File([blob], "ABHA Address details.png", {
      type: blob.type
    });
    if (
      !!navigator &&
      navigator.canShare &&
      navigator.canShare({ files: [file] })
    ) {
      await navigator?.share({
        title: "Ninto",
        text: "Ninto - ABHA Address",
        url: window.location.href,
        files: [file]
      });
    }
  };

  const getData = async () => {
    setQrDataUriLoading(true);
    try {
      const result = await getPatientQrDetails(
        props.auth.data.accessToken,
        props.patients.accessToken[props.patients.currentProfile]
      );
      if (
        result &&
        result.success === true &&
        result.data &&
        typeof result.data === "string"
      ) {
        const imageDataUri = result.data;
        setDataUrl(imageDataUri);
      } else {
        throwError("custom", "failed to fetch");
      }
    } catch (error) {
      console.error(error);
      props.setErrorStatus({
        code: "API",
        name: "API",
        message: "Unable to get QR details"
      });
    }
    setQrDataUriLoading(false);
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.patients.accessToken]);

  const data = [
    {
      title: "download",
      click: () => {
        handleDownload();
      },
      "data-cy": "abha-card-download-button",
      icon: <DownloadIcon />,
      name: "Download"
    },
    {
      title: "scan",
      click: () => {
        props.navigate("/scanClinic");
      },
      "data-cy": "abha-card-scan-button",
      icon: <ScanIcon />,
      name: "Scan"
    },
    {
      title: "share",
      click: () => {
        handleShare();
      },
      "data-cy": "abha-card-share-button",
      icon: <ShareIcon />,
      name: "Share"
    }
  ];

  return (
    <div className="inherit-parent-height">
      <Header
        title="ABHA Address"
        backButtonOnClick={() => {
          props.navigate(-1);
        }}
        supportIconOnClick={() => {
          props.navigate("/query");
        }}
        incident={props.app.featureFlags?.incident?.patients}
        incidentOnClick={() => {
          props.navigate("/incidents");
        }}
      />

      <ConditionalRender condition={!props.app.featureFlags?.abhaService}>
        <FeatureFlagsFallback height={"remaining-body-height"} />
      </ConditionalRender>

      <ConditionalRender condition={props.app.featureFlags?.abhaService}>
        <div className="inherit-parent-width text-align-center padding-larger">
          ABHA ADDRESS INFORMATION
        </div>
        <main className="display-flex flex-justify-content-center overflow-hidden padding-top-larger padding-bottom-larger position-relative ">
          {qrDataUriLoading === true ? (
            <div className="background-color-white padding-left-default padding-right-default padding-top-small padding-bottom-small border-radius-default ">
              <div className="qr-loader" data-cy="qr-loader" />
            </div>
          ) : qrDataUriLoading === false && dataUrl ? (
            <div className="flex-justify-content-center ">
              <img
                className="inherit-parent-height adaptive-width-for-abha-card"
                alt="qr"
                src={dataUrl}
                data-cy="abha-card"
              />
            </div>
          ) : (
            <p>No QR code Details</p>
          )}
        </main>
        {qrDataUriLoading === false && dataUrl && (
          <div className="display-flex inherit-parent-width overflow-hidden flex-justify-content-space-between border-top-2px-solid-e5e5e5 position-absolute position-bottom-0">
            {data.map((data, index) => (
              <div
                index={index}
                style={{
                  width: "124px"
                }}
                className="padding-left-larger padding-right-larger padding-top-default display-flex flex-direction-column flex-align-items-center flex-justify-content-center"
                data-cy={data?.["data-cy"]}
                onClick={data.click}
              >
                <div>{data.icon}</div>
                <div className="">{data.name}</div>
              </div>
            ))}
          </div>
        )}
      </ConditionalRender>
    </div>
  );
}

const mapStateToProps = (state) => ({
  abdm: state.abdm,
  auth: state.auth,
  profile: state.profile,
  patients: state.patients,
  app: state.app
});

const mapDispatchToProps = () => {
  return {
    setErrorStatus: (payload) => setErrorStatus(payload)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AbdmHealthIdQr);
