import { useEffect, useRef } from "react";
import "./ProcessSuccess.css";
import Button from "../../front-end-global-components/components/Button/Button";

export default function ProcessSuccess(props) {
  const data = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      if (data.current) {
        data.current?.classList?.add("drawn");
      }
    }, 100);
  }, []);

  return (
    <div className="inherit-parent-width inherit-parent-height display-flex flex-direction-column flex-justify-content-space-between flex-align-items-center">
      <div></div>
      <div className="display-flex flex-direction-column flex-justify-content-center flex-align-items-center inherit-parent-width">
        <div style={{ width: "30%" }}>
          <div ref={data} className="trigger"></div>
          <TickSvg />
        </div>
        <div className="padding-top-larger text-align-center font-size-large">
          {props.title}
        </div>
        {props.description && (
          <div className="padding-top-larger padding-bottom-larger padding-left-large padding-right-large font-size-medium text-align-center font-color-secondary">
            {props.description}
          </div>
        )}
        {props.information && (
          <div className="padding-top-larger padding-left-large padding-right-large text-align-center font-size-medium display-flex flex-justify-content-center font-color-secondary">
            {props.information}
          </div>
        )}
      </div>
      <div className="padding-bottom-larger inherit-parent-width display-flex flex-justify-content-center flex-direction-column flex-align-items-center">
        <div className="inherit-parent-width padding-left-larger padding-right-larger">
          <Button
            data-cy="confirm-button"
            type="button"
            className={`${
              props.alternative ? "" : "margin-bottom-larger"
            } inherit-parent-width`}
            text="Confirm"
            onClick={props.confirmClick}
            boxShadow={false}
          />
        </div>
        {props.alternative && (
          <div className="inherit-parent-width text-align-center">
            {props.alternative}
          </div>
        )}
      </div>
    </div>
  );
}

const TickSvg = () => {
  return (
    <svg
      version="1.1"
      id="tick"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 37 37"
      style={{ enableBackground: "new 0 0 37 37" }}
      xmlSpace="preserve"
    >
      <path
        className="circ path"
        style={{
          fill: "none",
          stroke: "#00a000",
          strokeWidth: 3,
          strokeLinejoin: "round",
          strokeMiterlimit: 10
        }}
        d="M30.5,6.5L30.5,6.5c6.6,6.6,6.6,17.4,0,24l0,0c-6.6,6.6-17.4,6.6-24,0l0,0c-6.6-6.6-6.6-17.4,0-24l0,0C13.1-0.2,23.9-0.2,30.5,6.5z"
      />
      <polyline
        className="tick path"
        style={{
          fill: "none",
          stroke: "#00a000",
          strokeWidth: 3,
          strokeLinejoin: "round",
          strokeMiterlimit: 10
        }}
        points="11.6,20 15.9,24.2 26.4,13.8"
      />
    </svg>
  );
};

// {
//   type: "SET_ABDM_CLINIC_SEARCH_RESULTS",
//   payload: {
//     searchResults: {
//       'ANBU-HI': {
//         identifier: {
//           name: 'ANBU-HI',
//           id: 'ANBU-HI'
//         },
//         telephone: null,
//         city: null,
//         latitude: null,
//         longitude: null,
//         address: null,
//         districtCode: null,
//         stateCode: null,
//         pinCode: null,
//         facilityType: [
//           'HIP',
//           'HIU'
//         ],
//         isHIP: true,
//         attributes: {
//           hipAttributes: null,
//           hiuAttributes: null,
//           healthLockerAttributes: null
//         },
//         timestamp: 1706180325708,
//         hipCode:"JFKQZxngza7pXgC6grxE",
//         token: "success"

//       }
//       }
//     }
//   }
