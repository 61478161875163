import React from "react";
import Button from "../../front-end-global-components/components/Button/Button";

export default function PersonalDataConsentForm(props) {
  return (
    <div className="inherit-parent-height inherit-parent-width padding-larger over-flow-hidden">
      <div
        className="overflow-scroll"
        style={{
          height: "calc(100% - 52px)"
        }}
      >
        <div className="text-align-center font-family-gilroy-medium position-sticky top-0 background-white padding-bottom-large">
          National Digital Health Mission: Personal Data Processing Model
          Consent Form
        </div>
        <div
          style={{ lineHeight: "20px", textAlign: "justify" }}
          className="padding-top-large"
        >
          [This consent form for the sharing of personal data is to be provided
          to the data principal by the data fiduciary at the time of, or prior
          to the collection or processing of any personal data.]
        </div>
        <div>
          <ol className="padding-left-large">
            <li
              style={{ lineHeight: "20px", textAlign: "justify" }}
              className="padding-top-large"
            >
              I have been provided with the Byepo Technologies Privacy Policy
              and Privacy Notice, which are also available on its website (
              available at:
              <a
                href="https://www.ninto.in/termsAndCondition"
                target="_blank"
                rel="noopener noreferrer"
                className="padding-left-small padding-right-small font-color-primary"
              >
                www.ninto.in
              </a>
              ), and have understood the contents thereof.
            </li>
            {data.map((data, index) => (
              <li
                key={index}
                style={{ lineHeight: "20px", textAlign: "justify" }}
                className="padding-top-large"
              >
                {data}
              </li>
            ))}
          </ol>
        </div>
        <p
          style={{ lineHeight: "20px", textAlign: "justify" }}
          className="padding-top-large"
        >
          Having read and understood the points mentioned above, I consent to
          allowing the Byepo Technologies to collect, use, share or process such
          personal data as may be necessary to provide health services availed
          by me.
        </p>
        <p
          style={{ lineHeight: "20px", textAlign: "justify" }}
          className="padding-top-large"
        >
          I understand and agree to Byepo Technologies providing these health
          services in a manner that is in accordance with the NDHM Health Data
          Management Policy, the Byepo Technologies Privacy Policy and Privacy
          Notice, any information security policy, data archival and retention
          guidelines, 2 any other policy that may be notified from time to time
          for the implementation of these above mentioned policies, and any
          other law that may be applicable.
        </p>
      </div>

      <Button
        type="button"
        data-cy="personal-data-consent-agree-button"
        className="margin-top-large"
        text="Agree"
        loading={props.loading}
        onClick={props.agreeClick}
        boxShadow={false}
      />
    </div>
  );
}

const data = [
  "I understand that the Privacy Notice and Privacy Policy mentioned in Paragraph 1 above provide information about how my personal data may be collected, used and disclosed by the Byepo Technologies or the NDHM or its ecosystem partners across the National Digital Health Ecosystem.",
  "I understand that my personal data may be collected only with my valid consent, and that it may be used or processed only in accordance with the NDHM Health Data Management Policy notified by the NDHM, and the Privacy Policy and Privacy Notice issued by Byepo Technologies.",
  "I understand that my personal data may be shared across various entities within the National Digital Health Ecosystem only with my consent, and for the following purposes: [Please insert specific purposes for which the data fiduciary may collect and process personal data. This must also be consistent with the purposes mentioned in the notification on permitted purposes issued by the NDHM.]",
  "I understand that I have certain rights that I enjoy as a data principal over my personal data, in relation to its collection, use and processing. These rights relate to confirmation and access, correction and erasure, objection of disclosure, and portability of my personal data. These rights are available to me in the manner set out in the NDHM Health Data Management Policy notified by the NDHM and the Privacy Policy and Privacy Notice issued by Byepo Technologies.",
  "I understand that the terms of the Privacy Notice may change, and I will be notified of the same before or at the time any further collection, use or processing of my personal data takes place. I understand that I will be provided the opportunity to provide fresh consent for the same.",
  "I understand that I have the right to revoke my consent at any time in the manner specified under the Privacy Notice, and such revocation will not result in penalty or loss of benefits to which I am otherwise entitled by the Central and respective State Government or any healthcare providers. "
];
