const intentReducer = (
  state = {
    loading: false,
    data: {
      regToken: null,
      device: null
    }
  },
  action
) => {
  switch (action.type) {
    case "SET_INTENT_LOADING":
      return {
        ...state,
        loading: action.payload.loading
      };

    case "SET_REG_DATA":
      return {
        ...state,
        data: {
          regToken: action.payload.regToken,
          device: action.payload.device,
          deviceId: action.payload.deviceId
        }
      };

    case "RESET":
      return {
        loading: false,
        data: {
          regToken: null,
          device: null,
          deviceId: null
        }
      };

    default:
      return state;
  }
};

export default intentReducer;
