import React, { useEffect, useState } from "react";
import "../../design-system.css";
import pdfIcon from "../../assets/PdfIcon.svg";
import unsupportedIcon from "../../assets/Unsupported.svg";
import "./FHIRRenderer.css";
import RoundedProfilePicture from "../RoundedProfilePicture/RoundedProfilePicture";
import { dateAndTimeConverter } from "../../utils/constants";
import dropDownArrow from "../../assets/DropDownArrow.svg";
import PropTypes from "prop-types";

export default function FHIRRenderer(props) {
  // for testing purpose
  // useEffect(() => {
  // console.log(props.data);
  // }, []);

  const Accordion = (props) => {
    const [show, setShow] = useState(false);
    return (
      <>
        <div
          className={`display-flex flex-justify-content-space-between padding-large ${
            show ? "" : "border-bottom-1px"
          }`}
          onClick={() => {
            if (show === false) {
              setShow(true);
            } else {
              setShow(false);
            }
          }}
        >
          <div
            className={`${
              show ? "font-family-gilroy-medium " : ""
            } font-size-default`}
          >
            {props.data.title}
          </div>
          <DropDownButton
            dropDownButton-data-cy="title-dropDown"
            dropDownClick={() => {
              if (show === false) {
                setShow(true);
              } else {
                setShow(false);
              }
            }}
            showData={show}
          />
        </div>
        {show && (
          <div className="border-bottom-1px">
            {props.data.entry.map((data, index) => (
              <div key={index}>
                {getComponents({
                  entryData: props.value,
                  type: data?.reference?.split("/")[0],
                  index: index,
                  data: data,
                  documentOnClick: props.documentOnClick
                })}
              </div>
            ))}
            <BasicInfo />
          </div>
        )}
      </>
    );
  };

  const getClinicSrc = (type) => {
    let value;
    switch (type) {
      case "clinic":
        value =
          props.connection?.brands[
            props.connection.data[props.document?.clinicId]?.brandId
          ]?.downloadURL;
        break;

      default:
        value = null;
        break;
    }
    return value;
  };

  const searchResource = (value) => {
    return props?.data.entry.find(
      (data) => data.resource.resourceType === value
    )?.resource;
  };

  const BasicInfo = (props) => {
    const [data, setData] = useState({
      clinic: "",
      doctor: "",
      patient: ""
    });
    useEffect(() => {
      setData({
        clinic: searchResource("Organization"),
        doctor: searchResource("Practitioner"),
        patient: searchResource("Patient")
      });
    }, []);

    return (
      <div className="display-flex padding-top-default padding-bottom-default padding-left-large padding-right-large flex-justify-content-space-between adaptive-flex-direction">
        {data?.clinic?.name && (
          <div
            data-cy="Organization-card"
            className="padding-default display-flex"
          >
            <div className="font-family-gilroy-medium">Source &nbsp; </div>
            :&nbsp;
            {data?.clinic?.name}
          </div>
        )}
        {data?.patient?.name && (
          <div
            data-cy="Patient-card"
            className="padding-default display-medium display-flex"
          >
            <div className="font-family-gilroy-medium">Patient &nbsp; </div>
            :&nbsp;
            {(data?.patient?.name && data?.patient?.name?.[0].text) ?? "--"}
          </div>
        )}
        {data?.doctor?.name && (
          <div
            data-cy="Practitioner-card"
            className="padding-default display-medium display-flex"
          >
            <div className=" font-family-gilroy-medium">Handled By &nbsp; </div>
            :&nbsp;
            {data?.doctor?.name?.[0]?.prefix?.[0] || ""}.
            {data?.doctor?.name?.[0]?.text || ""}{" "}
            {data?.doctor?.name?.[0]?.suffix?.[0] || ""}
          </div>
        )}
      </div>
    );
  };

  return (
    <div data-cy="FHIR-Component" className="inherit-parent-width">
      <div className="border-bottom-2px padding-top-medium padding-bottom-medium padding-left-larger padding-right-larger">
        <div className=" display-flex flex-align-items-center flex-justify-content-space-between">
          <div className="flex-center-children-vertically font-family-gilroy-regular  padding-right-larger">
            <RoundedProfilePicture
              data-cy="Organization-profile-picture"
              size="medium"
              userType="corporate"
              removeResponsive={true}
              src={getClinicSrc(props.userType)}
            />
            <div className="padding-left-large">
              <div className="font-size-large padding-right-default text-capitalize">
                {props.document?.clinicName}
              </div>
              <div className=" font-size-small padding-top-small">
                {props.document?.locality ? props.document?.locality : ""}
              </div>
            </div>
          </div>

          <div className=" font-size-small white-space-no-wrap">
            <div>
              {dateAndTimeConverter(
                +new Date(props.data.timestamp),
                "secondDate"
              )}
            </div>
            <div className=" padding-top-small white-space-no-wrap">
              {dateAndTimeConverter(+new Date(props.data.timestamp), "Time")}
            </div>
          </div>
        </div>
      </div>
      {props.data?.entry?.[0]?.resource && (
        <div>
          <Composition
            data={props.data?.entry?.[0]?.resource}
            value={props.data}
          />
        </div>
      )}
      <div className="padding-default overflow-auto">
        {props.data?.entry?.[0]?.resource?.section?.map((data, index) => (
          <div
            className="margin-bottom-default padding-left-larger padding-right-larger"
            data-cy={`accordion-${data.title}-card`}
            key={index}
          >
            <Accordion
              value={props.data}
              data={data}
              documentOnClick={props.documentOnClick}
            />
          </div>
        ))}
      </div>
    </div>
  );
}

const Practitioner = (props) => {
  const phoneNumber = props.data?.telecom;
  const address = props.data?.address?.[0];

  return (
    <div
      className="padding-left-larger padding-right-larger"
      data-cy="Practitioner-card"
    >
      <TitleFields title="Practitioner" />
      <div className="padding-medium">
        <DataFields title="Name" data={props.data?.name?.[0]?.text} />
        {props.data?.qualification && (
          <DataFields
            title="Qualification"
            data={props.data?.qualification?.code?.coding?.[0]?.display}
          />
        )}
        {props.data?.gender && (
          <DataFields title="Gender" data={props.data?.gender} />
        )}
        {props.data?.birthDate && (
          <DataFields title="DOB" data={props.data?.birthDate} />
        )}
        <DataFields
          title={props.data?.identifier?.[0]?.type?.coding?.[0]?.display}
          data={props.data?.identifier?.[0]?.value}
        />
        {props.data?.identifier?.assigner?.display && (
          <DataFields
            title="Assigner"
            data={props.data?.identifier?.assigner?.display}
          />
        )}
        {phoneNumber && (
          <DataFields
            title="Telecom"
            data={phoneNumber.map((data, index) => (
              <div className="font-size-large" key={`Practitioner-${index}`}>
                {data.system} : {data.value}
              </div>
            ))}
          />
        )}
        {address && (
          <DataFields
            title="Telecom"
            data={phoneNumber.map((data, index) => (
              <div className="font-size-large" key={`Practitioner-${index}`}>
                {address?.line ?? "--"} - {address.postalCode ?? "--"}
              </div>
            ))}
          />
        )}
      </div>
    </div>
  );
};

const Composition = (props) => {
  return (
    <div
      className="padding-left-larger padding-right-larger"
      data-cy="Composition-card"
    >
      <DataFields title="Title" data={props.data?.title} />
      <DataFields title="Status" data={props.data?.status} />
      <DataFields title="Type" data={props.data?.type?.text} />
      <DataFields
        title="Date"
        data={`${dateAndTimeConverter(props.data?.date, "secondDate")} ${" | "}
        ${dateAndTimeConverter(props.data?.date, "Time")}`}
      />
      <DataFields
        title="Subject"
        data={
          getResourceData(props.value, props.data?.subject?.reference)
            ?.name?.[0]?.text
        }
      />
      <DataFields title="Author" data={props.data?.author?.[0]?.display} />
      {props.data?.encounter && (
        <DataFields
          title="Encounter"
          data={
            <Encounter
              data={getResourceData(
                props.value,
                props.data?.encounter?.reference
              )}
            />
          }
        />
      )}
    </div>
  );
};

const Patient = (props) => {
  const name = props?.data?.name[0];
  return (
    <div className="padding-larger" data-cy="Patient-card">
      <div className="font-size-small text-uppercase">Patient</div>
      <div className="flex-center-children-vertically flex-justify-content-space-between flex-direction-row">
        <div className="padding-top-small">
          <div className="font-size-large">{name?.text ?? "--"}</div>
          <div className="font-size-small padding-top-default">
            {props.data?.id ?? "--"}
          </div>
          <div className="font-size-small padding-top-small  text-transform-capitalize ">
            {props.data?.gender ?? "--"}
          </div>
        </div>
        <div>
          <RoundedProfilePicture
            data-cy="Patient-profile-picture"
            className="margin-top-default"
            size={"medium"}
            // src={googleFit}
          />
        </div>
      </div>
    </div>
  );
};

const Encounter = (props) => {
  return (
    <div
      className="padding-left-larger padding-right-larger"
      data-cy={`Encounter-card`}
    >
      {/* <TitleFields title="Encounter" /> */}

      <div className="padding-medium">
        <DataFields
          title="Identifier"
          data={props.data?.identifier?.[0]?.value}
        />
        <DataFields title="Status" data={props.data?.status} />
        {props.data?.type?.text && (
          <DataFields title="Type" data={props.data?.type?.text} />
        )}
        <DataFields title="Class" data={props.data?.class?.display} />
        {props.data?.participant?.type?.text && (
          <DataFields
            title="Participant"
            data={props.data?.participant?.type?.text}
          />
        )}
        {props.data?.priority?.text && (
          <DataFields title="Priority" data={props.data?.priority?.text} />
        )}
        {props.data?.period && (
          <DataFields
            title="Period"
            data={`${dateAndTimeConverter(
              props.data?.period?.start,
              "secondDate"
            )} ${" | "}
          ${dateAndTimeConverter(props.data?.period?.start, "Time")}`}
          />
        )}
        {props.data?.reasonCode?.text && (
          <DataFields title="Reason Code" data={props.data?.reasonCode?.text} />
        )}
        {props.data?.diagnosis?.use?.text && (
          <DataFields
            title="Diagnosis"
            data={props.data?.diagnosis?.use?.text}
          />
        )}
        {props.data?.hospitalization && (
          <DataFields
            title="Hospitalization"
            data={props.data?.hospitalization}
          />
        )}
        {props.data?.class?.serviceType?.text && (
          <DataFields
            title="Service Type"
            data={props.data?.class?.serviceType?.text}
          />
        )}
      </div>
    </div>
  );
};

const DateAndTime = (props) => {
  const dataAndTime = +new Date(props.data?.date);
  return (
    <div
      className="padding-larger flex-direction-row"
      data-cy="DateAndTime-card"
    >
      <div className=" width-50-percentage text-align-center">
        <div className="font-size-small text-uppercase">Date</div>
        <div className=" padding-top-default">
          {dateAndTimeConverter(dataAndTime, "secondDate")}
        </div>
      </div>
      <div className=" width-50-percentage text-align-center">
        <div className="font-size-small">Time</div>
        <div className=" padding-top-default">
          {dateAndTimeConverter(dataAndTime, "Time")}
        </div>
      </div>
    </div>
  );
};

const Medication = (props) => {
  return (
    <div
      className="padding-left-larger padding-right-larger"
      data-cy={`Medication-card`}
    >
      <TitleFields title="Medication" />

      <div className="padding-medium">
        <DataFields
          title="Identifier"
          data={props.data?.identifier?.[0]?.type?.coding?.[0]?.display}
        />
        <DataFields
          title="Medicine"
          data={props.data?.code?.coding?.[0]?.display}
        />
        <DataFields title="Manufacturer" data={props.data?.manufacturer} />
        <DataFields
          title="Form"
          data={props.data?.form?.coding?.[0]?.display}
        />
        <DataFields title="Lot Number" data={props.data?.batch?.lotNumber} />
        <DataFields
          title="Expiration Date"
          data={props.data?.batch?.expirationDate}
        />
      </div>
    </div>
  );
};

const MedicationRequest = (props) => {
  const medicine = getResourceData(
    props.value,
    props.data?.medicationReference?.reference
  )?.code;

  const doctorInfo = getResourceData(
    props.value,
    props.data?.requester?.reference
  );
  return (
    <div
      className="padding-left-larger padding-right-larger"
      data-cy={`MedicationRequest-card-${
        medicine?.coding ? medicine?.coding[0]?.display : medicine?.text
      }`}
    >
      <TitleFields title="Medication Request" />

      <div className="padding-medium">
        <DataFields title="Status" data={props.data?.status} />
        <DataFields title="intent" data={props.data?.intent} />
        <DataFields title="Requester" data={props.data?.requester?.display} />
        <DataFields title="intent" data={props.data?.intent} />
        <DataFields
          title="Prescribed by"
          data={doctorInfo?.name[0]?.text ?? "--"}
        />

        {props.data?.authoredOn && (
          <DataFields
            title="Authored On"
            data={`${dateAndTimeConverter(
              +new Date(props.data.authoredOn),
              "secondDate"
            )}
         ${" | "}
         ${dateAndTimeConverter(+new Date(props.data.authoredOn), "Time")}`}
          />
        )}
        {props.data?.dosageInstruction && (
          <DataFields
            title="Dosage Instruction"
            data={props.data?.dosageInstruction?.map((data, index) => (
              <div
                className="padding-left-larger padding-right-larger"
                key={`medicationRequest-${index}`}
              >
                <div className="font-size-large text-capitalize padding-top-default">
                  {data.text}
                </div>
                <DataFields
                  title="Additional Instruction"
                  data={data?.additionalInstruction?.[0]?.coding?.[0]?.display}
                />
                <DataFields
                  title="Method"
                  data={data?.method?.coding?.[0]?.display}
                />
                <DataFields
                  title="Route"
                  data={data?.route?.coding?.[0]?.display}
                />
              </div>
            ))}
          />
        )}

        {props.data?.reasonReference && (
          <DataFields
            title="Reason"
            data={
              <Condition
                value={props.value}
                data={getResourceData(
                  props.value,
                  props.data.reasonReference?.[0]?.reference
                )}
              />
            }
          />
        )}
      </div>
    </div>
  );
};

const DocumentReference = (props) => {
  const resource = props.data?.content[0];
  const extension = resource?.attachment?.contentType?.split("/")[1];
  const documentBuffer = base64ToArrayBuffer(resource?.attachment?.data);

  return (
    <div
      className="padding-left-larger padding-top-larger padding-right-larger"
      data-cy="DocumentReference-card"
    >
      <TitleFields title="Document Reference" />
      <div className="padding-medium">
        <DataFields title="Status" data={props.data?.status} />

        <DataFields
          title="Data"
          data={
            allowSupportedFileFormat(resource?.attachment?.contentType) ? (
              <div
                className=" flex-justify-content-space-between flex-direction-column"
                onClick={() => {
                  props.documentOnClick(documentBuffer);
                }}
              >
                <div className="padding-top-small">
                  <div className="font-size-large">
                    {resource?.attachment?.title ?? "--"}.{extension ?? "--"}
                  </div>
                </div>
                <div
                  data-cy="pdfRenderer"
                  className="padding-top-large padding-bottom-default width-40-percentage"
                >
                  <div className="inherit-parent-width display-flex flex-direction-column border-radius-default flex-center-children-horizontally flex-center-children-vertically height-120px background-color-grey">
                    <img
                      data-cy="PDF-icon"
                      draggable="false"
                      className="height-width-48px"
                      src={pdfIcon}
                      alt="pdf icon"
                    />
                    <div className="adaptive-padding-top">View file</div>
                  </div>
                </div>
              </div>
            ) : (
              <div className="padding-top-large padding-bottom-default width-40-percentage">
                <div className="inherit-parent-width display-flex flex-direction-column border-radius-default flex-center-children-horizontally flex-center-children-vertically height-120px background-color-grey">
                  <img
                    data-cy="unSupported-icon"
                    draggable="false"
                    className="height-width-48px"
                    src={unsupportedIcon}
                    alt="unsupported icon"
                  />
                  <div className="adaptive-padding-top">UnSupported</div>
                </div>
              </div>
            )
          }
        />
      </div>
    </div>
  );
};

const Organization = (props) => {
  const name = props.data?.name?.split(",")[0];
  const area = props.data?.name?.split(",")[1];
  const address = props.data?.address[0];
  const phoneNumber = props.data?.telecom;
  return (
    <div className="padding-larger" data-cy="Organization-card">
      <div className="padding-left-larger padding-right-larger">
        Organization
      </div>
      <div className="padding-medium">
        <div className="flex-center-children-vertically flex-justify-content-space-between flex-direction-row">
          <div className="padding-top-small">
            <div className="font-size-large">{name ?? "--"}</div>
            <div className="font-size-small padding-top-small">
              {area ?? "--"}
            </div>
          </div>
          <div className=" flex-center-children-horizontally flex-center-children-vertically">
            <RoundedProfilePicture
              data-cy="Organization-profile-picture"
              className="margin-top-default"
              size={"medium"}
              // src={googleFit}
            />
          </div>
        </div>
        <div className="font-size-small text-uppercase">Contact</div>
        {phoneNumber.map((data, index) => (
          <div className="font-size-large" key={`organization-${index}`}>
            {data.system} : {data.value}
          </div>
        ))}
        <div className="padding-top-large">
          {address?.line ?? "--"} - {address.postalCode ?? "--"}
        </div>
      </div>
    </div>
  );
};

const Immunization = (props) => {
  const vaccine = props.data?.vaccineCode;
  const reasonReference = props.data?.reasonReference;
  const reaction = props.data?.reaction;
  return (
    <div
      className="padding-left-larger padding-right-larger"
      data-cy={`Immunization-card-${
        vaccine?.coding ? vaccine?.coding[0]?.display : vaccine?.text
      }`}
    >
      <TitleFields title="Immunization" />

      <div className="padding-medium">
        <DataFields
          title="Title"
          data={vaccine?.coding ? vaccine?.coding[0]?.display : vaccine?.text}
        />

        <DataFields title="Status" data={props.data?.status} />

        <DataFields
          title="Brand"
          data={props.data?.extension?.[0]?.valueString}
        />

        <DataFields
          title="Manufacturer"
          data={props.data?.manufacturer?.reference}
        />

        <DataFields title="Occurrence" data={props.data?.occurrenceDateTime} />

        <DataFields title="LotNumber" data={props.data?.lotNumber} />

        <DataFields title="Site" data={props.data?.site?.text} />
        <DataFields title="Route" data={props.data?.route?.text} />
        <DataFields
          title="Performer"
          data={
            getResourceData(
              props.value,
              props.data?.performer?.[0]?.actor?.reference
            )?.name?.[0]?.text
          }
        />
        <DataFields title="Reason Code" data={props.data?.reasonCode?.text} />

        <DataFields
          title="Location"
          data={
            getResourceData(props.value, props.data?.location?.reference)
              ?.description
          }
        />

        <DataFields
          title="Dose Number"
          data={props.data?.protocolApplied?.[0]?.doseNumberPositiveInt}
        />
      </div>
      {reasonReference?.map((data) => {
        return (
          data?.reference?.split("/")[0] === "DiagnosticReport" && (
            <div className="padding-bottom-default">
              <DiagnosticReport
                data={getResourceData(props.value, data?.reference)}
                value={props.value}
                documentOnClick={props.documentOnClick}
              />
            </div>
          )
        );
      })}
      {reaction?.map((data) => {
        return (
          data?.detail?.reference?.split("/")[0] === "Observation" && (
            <div className="padding-bottom-default">
              <Observation
                data={getResourceData(props.value, data?.detail?.reference)}
              />
            </div>
          )
        );
      })}
    </div>
  );
};

const Observation = (props) => {
  return (
    <div
      className="padding-left-larger padding-top-larger padding-right-larger"
      data-cy={`Observation-card-${props.data?.code?.text}`}
    >
      <TitleFields title="Observation" />

      <div className="padding-left-medium padding-right-medium">
        <DataFields title="Status :" data={props.data?.status} />

        {!props.data?.component &&
          !props.data?.code?.text &&
          props.data?.code?.coding &&
          props.data?.valueQuantity && (
            <DataFields
              title={props.data?.code?.coding[0]?.display}
              data={`${props.data?.valueQuantity?.value ?? "--"} ${" "}
                ${props.data?.valueQuantity?.unit ?? "--"}`}
            />
          )}

        {props.data?.code?.coding && props.data?.valueCodeableConcept && (
          <DataFields
            title={props.data?.code?.coding[0]?.display}
            data={props.data?.valueCodeableConcept?.text}
          />
        )}

        {!props.data?.component &&
          props.data?.code?.text &&
          props.data?.valueQuantity && (
            <DataFields
              title={props.data?.code?.text}
              data={`${props.data?.valueQuantity.value ?? "--"} ${" "}
              ${props.data?.valueQuantity.unit ?? "--"}`}
            />
          )}

        {props.data?.component && (
          <>
            <DataFields title="Title" data={props.data?.code?.text} />
            {props.data?.component?.map((data, index) => (
              <div key={`Observation-${index}`}>
                <DataFields
                  title={data.code?.coding[0]?.display}
                  data={`${data.valueQuantity?.value ?? "--"} ${" "}
              ${data.valueQuantity.unit ?? "--"}`}
                />
              </div>
            ))}
          </>
        )}

        {props.data?.effectiveDateTime && (
          <DataFields
            title="Effective date and time"
            data={`${dateAndTimeConverter(
              +new Date(props.data?.effectiveDateTime),
              "secondDate"
            )}
          ${"  |  "}
          ${dateAndTimeConverter(
            +new Date(props.data?.effectiveDateTime),
            "Time"
          )}`}
          />
        )}
      </div>
    </div>
  );
};

const Condition = (props) => {
  const dataAndTime = props.data?.recordedDate
    ? +new Date(props.data?.recordedDate)
    : "";

  return (
    <div
      className="padding-left-larger padding-right-larger"
      data-cy="Condition-card"
    >
      <TitleFields title="Condition" />

      <div className="padding-medium">
        <DataFields title="Description" data={props.data?.code?.text} />

        <DataFields
          title="Subject"
          data={
            getResourceData(props.value, props.data?.subject?.reference)
              ?.name?.[0]?.text
          }
        />
        {props.data?.severity && (
          <DataFields title="Severity" data={props.data?.severity?.text} />
        )}
        {props.data?.recordedDate && (
          <>
            <DataFields
              title="Recorded Date"
              data={`${dateAndTimeConverter(dataAndTime, "secondDate")} ${" | "}
              ${dateAndTimeConverter(dataAndTime, "Time")}`}
            />
          </>
        )}
      </div>
    </div>
  );
};

const Appointment = (props) => {
  const endDataAndTime = +new Date(props.data?.end);
  const StartDataAndTime = +new Date(props.data?.start);

  return (
    <div
      className="padding-left-larger padding-right-larger"
      data-cy="Appointment-card"
    >
      <TitleFields title="Appointment" />

      <div className="padding-medium">
        <DataFields
          title="Title"
          data={
            props.data.serviceCategory?.[0]?.coding?.[0]?.text ??
            props.data.serviceCategory?.[0]?.coding?.[0]?.display
          }
        />
        <DataFields title="Status" data={props.data?.status} />
        <DataFields
          title="Appointment Type"
          data={
            props.data.appointmentType?.coding?.[0]?.text ??
            props.data.appointmentType?.coding?.[0]?.display
          }
        />
        <DataFields
          title="Service Type"
          data={
            props.data.serviceType?.[0]?.coding?.[0]?.text ??
            props.data.serviceType?.[0]?.coding?.[0]?.display
          }
        />
        <DataFields
          title="Specialty"
          data={
            props.data.specialty?.[0]?.coding?.[0]?.text ??
            props.data.specialty?.[0]?.coding?.[0]?.display
          }
        />
        {props.data.reasonReference && (
          <DataFields
            title="Reason"
            data={
              getResourceData(
                props.value,
                props.data.reasonReference?.[0]?.reference
              )?.code.text
            }
          />
        )}

        {props.data.reasonCode && (
          <DataFields title="Reason" data={props.data.reasonCode?.text} />
        )}

        <DataFields
          title="Timing"
          data={
            <>
              <div className="font-size-medium padding-top-default">
                Start : {dateAndTimeConverter(StartDataAndTime, "secondDate")}{" "}
                {" | "}
                {dateAndTimeConverter(StartDataAndTime, "Time")}
              </div>
              <div className="font-size-medium padding-top-default">
                End : {dateAndTimeConverter(endDataAndTime, "secondDate")}{" "}
                {" | "}
                {dateAndTimeConverter(endDataAndTime, "Time")}
              </div>
            </>
          }
        />
      </div>
    </div>
  );
};

const CarePlan = (props) => {
  return (
    <div
      className="padding-left-larger padding-right-larger"
      data-cy="CarePlan-card"
    >
      <TitleFields title="CarePlan" />

      <div className="padding-medium">
        <DataFields title="Title" data={props.data?.title} />
        <DataFields title="Status" data={props.data?.status} />
        <DataFields title="Description" data={props.data?.description} />
        <DataFields title="Intent" data={props.data?.intent} />
        {props.data?.goal && (
          <DataFields title="Goal" data={props.data?.goal} />
        )}
        <DataFields
          title="Subject"
          data={
            getResourceData(props.value, props.data?.subject?.reference)
              ?.name?.[0]?.text
          }
        />
      </div>
    </div>
  );
};

const AllergyIntolerance = (props) => {
  return (
    <div
      className="padding-left-larger padding-right-larger"
      data-cy={`AllergyIntolerance-card-${props.data?.category ?? "--"}`}
    >
      <TitleFields title="Allergy" />

      <div className="padding-medium">
        <DataFields title="Title" data={props.data?.code?.coding[0]?.display} />

        <DataFields title="Severity" data={props.data?.criticality} />

        <DataFields title="Duration" data={props.data?.onsetString} />
        <DataFields
          title="Patient"
          data={
            getResourceData(props.value, props.data?.patient?.reference)
              .name?.[0]?.text
          }
        />

        {props.data?.note && (
          <>
            <DataFields
              title="Notes"
              data={props.data?.note.map((data, index) => (
                <div
                  key={`allergyIntolerance${index}`}
                  className="font-size-medium text-capitalize"
                >
                  {data.text}
                </div>
              ))}
            />
          </>
        )}
      </div>
    </div>
  );
};

const Procedure = (props) => {
  const dataAndTime = +new Date(props.data?.performedDateTime);
  return (
    <div
      className="padding-left-larger padding-right-larger"
      data-cy="Procedure-card"
    >
      <TitleFields title="Procedure" />
      <div className="padding-medium">
        <DataFields title="Title" data={props.data?.code?.coding[0]?.display} />
        <DataFields title="Status" data={props.data?.status} />
        <DataFields
          title="Status Reason"
          data={props.data?.statusReason?.text}
        />
        <DataFields title="Category" data={props.data?.category?.text} />
        <DataFields
          title="Performer"
          data={
            getResourceData(
              props.value,
              props.data?.performer?.[0]?.actor?.reference
            )?.name?.[0]?.text
          }
        />
        <DataFields title="Body Site" data={props.data?.bodySite?.text} />
        <DataFields title="Outcome" data={props.data?.outcome?.text} />
        <DataFields title="Used Code" data={props.data?.usedCode?.text} />
        <DataFields
          title="Subject"
          data={
            getResourceData(props.value, props.data?.subject?.reference)
              ?.name?.[0]?.text
          }
        />
        {props.data?.performer && (
          <>
            <DataFields
              title="Performer"
              data={props.data?.performer?.map((data, index) => {
                const doctor = getResourceData(
                  props.value,
                  data.actor?.reference
                );
                return (
                  <div key={props.index}>
                    <div className="font-size-large">
                      {doctor?.name[0]?.text ?? "--"}
                    </div>
                    <div className="font-size-small padding-top-small">
                      {doctor?.name[0]?.suffix[0] ?? "--"}
                    </div>
                  </div>
                );
              })}
            />
          </>
        )}

        <DataFields
          title="Performed Date"
          data={`${dateAndTimeConverter(dataAndTime, "secondDate")} ${" | "}
          ${dateAndTimeConverter(dataAndTime, "Time")}`}
        />

        <DataFields
          title="Complications"
          data={props.data?.complication?.map((data, index) => (
            <div
              key={`Procedure-${index}`}
              className="font-size-large padding-top-default"
            >
              {data.coding?.[0]?.display}
            </div>
          ))}
        />
      </div>
    </div>
  );
};

const DiagnosticReport = (props) => {
  const resource = props.data?.presentedForm?.[0];
  const extension = resource?.contentType?.split("/")?.[1];
  const documentBuffer = base64ToArrayBuffer(resource?.data);

  return (
    <div
      className="padding-left-larger padding-right-larger"
      data-cy={`DiagnosticReport-card-${
        props.data?.code?.text
          ? props.data?.code?.text
          : props.data?.code?.coding[0]?.display
      }`}
    >
      <TitleFields title="Diagnostic Report" />

      <div className="padding-medium">
        <DataFields
          title="Title"
          data={props.data?.code?.coding?.[0]?.display}
        />
        <DataFields title="Status" data={props.data?.status} />
        {props.data?.category && (
          <DataFields
            title="Category"
            data={props.data?.category?.[0]?.coding?.[0]?.display}
          />
        )}

        <DataFields
          title="Results Interpreter"
          data={
            getResourceData(
              props.value,
              props.data?.resultsInterpreter?.[0]?.reference
            )?.name?.[0]?.text
          }
        />
        <DataFields
          title="Specimen"
          data={
            getResourceData(props.value, props.data?.specimen?.[0]?.reference)
              ?.type?.coding?.[0]?.display
          }
        />
        <DataFields title="Conclusion" data={props.data?.conclusion} />

        {props.data?.basedOn && (
          <DataFields
            title="Based On"
            data={getComponents({
              entryData: props.value,
              type: getResourceData(
                props.value,
                props.data?.basedOn?.[0].reference
              )?.resourceType,
              index: 1,
              data: { reference: props.data?.basedOn?.[0].reference },
              documentOnClick: props.documentOnClick
            })}
          />
        )}

        {props.data?.result?.map((data, index) => (
          <div key={props.index}>
            <Observation data={getResourceData(props.value, data?.reference)} />
          </div>
        ))}

        {resource?.data && (
          <DataFields
            title="Data"
            data={
              allowSupportedFileFormat(resource?.contentType) ? (
                <div
                  className=" flex-justify-content-space-between flex-direction-column"
                  onClick={() => {
                    props.documentOnClick(documentBuffer);
                  }}
                >
                  <div className="padding-top-small">
                    <div className="font-size-large">
                      {resource?.title ?? "--"}.{extension ?? "--"}
                    </div>
                  </div>
                  <div
                    data-cy="pdfRenderer"
                    className="padding-top-large padding-bottom-default width-40-percentage"
                  >
                    <div className="inherit-parent-width display-flex flex-direction-column border-radius-default flex-center-children-horizontally flex-center-children-vertically height-120px background-color-grey">
                      <img
                        data-cy="PDF-icon"
                        draggable="false"
                        className="height-width-48px"
                        src={pdfIcon}
                        alt="pdf icon"
                      />
                      <div className="adaptive-padding-top">View file</div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="padding-top-large padding-bottom-default width-40-percentage">
                  <div className="inherit-parent-width display-flex flex-direction-column border-radius-default flex-center-children-horizontally flex-center-children-vertically height-120px background-color-grey">
                    <img
                      data-cy="unSupported-icon"
                      draggable="false"
                      className="height-width-48px"
                      src={unsupportedIcon}
                      alt="unsupported icon"
                    />
                    <div className="adaptive-padding-top">UnSupported</div>
                  </div>
                </div>
              )
            }
          />
        )}
      </div>
    </div>
  );
};

const Binary = (props) => {
  const extension = props.data?.contentType?.split("/")[1];
  const documentBuffer = base64ToArrayBuffer(props.data?.data);

  return (
    <div
      className="padding-left-larger padding-top-larger padding-right-larger"
      data-cy="Binary-card"
    >
      <TitleFields title="Binary" />
      <div className="padding-medium">
        {allowSupportedFileFormat(props.data?.contentType) ? (
          <div
            className=" flex-justify-content-space-between flex-direction-column"
            onClick={() => {
              props.documentOnClick(documentBuffer);
            }}
          >
            <div className="padding-top-small">
              <div className="font-size-large">
                {props.data?.id ?? "--"}.{extension ?? "--"}
              </div>
            </div>
            <div
              data-cy="pdfRenderer"
              className="padding-top-large padding-bottom-default width-40-percentage"
            >
              <div className="inherit-parent-width display-flex flex-direction-column border-radius-default flex-center-children-horizontally flex-center-children-vertically height-120px background-color-grey">
                <img
                  data-cy="PDF-icon"
                  draggable="false"
                  className="height-width-48px"
                  src={pdfIcon}
                  alt="pdf icon"
                />
                <div className="adaptive-padding-top">View file</div>
              </div>
            </div>
          </div>
        ) : (
          <div className="padding-top-large padding-bottom-default width-40-percentage">
            <div className="inherit-parent-width display-flex flex-direction-column border-radius-default flex-center-children-horizontally flex-center-children-vertically height-120px background-color-grey">
              <img
                data-cy="unSupported-icon"
                draggable="false"
                className="height-width-48px"
                src={unsupportedIcon}
                alt="unsupported icon"
              />
              <div className="adaptive-padding-top">UnSupported</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const ChargeItem = (props) => {
  return (
    <div
      className="padding-left-larger padding-right-larger"
      data-cy={`ChargeItem-card-${
        props.data?.code?.text
          ? props.data?.code?.text
          : props.data?.code?.coding[0]?.display
      }`}
    >
      <TitleFields title="Charge Item" />
      <div className="padding-medium">
        <DataFields
          title="Title"
          data={props.data?.code.coding?.[0]?.display}
        />
        <DataFields title="Status" data={props.data?.status} />
        <DataFields
          title="Product"
          data={
            getResourceData(props.data.productReference.reference)?.code
              .coding?.[0]?.display
          }
        />
        <DataFields
          title="Quantity"
          data={`${props.data?.quantity?.value} ${props.data?.quantity?.unit}`}
        />
        <DataFields
          title="Subject"
          data={
            getResourceData(props.value, props.data?.subject?.reference)
              ?.name?.[0]?.text
          }
        />
      </div>
    </div>
  );
};

const FamilyMemberHistory = (props) => {
  return (
    <div
      className="padding-left-larger padding-right-larger"
      data-cy={`FamilyMemberHistory-card`}
    >
      <TitleFields title="Family Member History" />
      <div className="padding-medium">
        <DataFields title="Status" data={props.data?.status} />
        <DataFields
          title="Patient"
          data={
            getResourceData(props.value, props.data?.patient?.reference)
              ?.name?.[0]?.text
          }
        />
        <DataFields
          title="Relationship"
          data={props.data?.relationship?.coding?.[0]?.display}
        />
        <DataFields title="Reason" data={props.data?.reasonCode?.text} />

        <DataFields
          title="Condition"
          data={props.data?.condition?.[0]?.code?.text}
        />
      </div>
    </div>
  );
};

const ImagingStudy = (props) => {
  return (
    <div
      className="padding-left-larger padding-right-larger"
      data-cy={`ImagingStudy-card-${
        props.data?.code?.text
          ? props.data?.code?.text
          : props.data?.code?.coding[0]?.display
      }`}
    >
      <TitleFields title="Imaging Study" />
      <div className="padding-medium">
        <DataFields
          title="Title"
          data={props.data?.code.coding?.[0]?.display}
        />
        <DataFields title="Status" data={props.data?.status} />
        <DataFields
          title="Number Of Series"
          data={props.data?.numberOfSeries}
        />
        <DataFields
          title="Number Of Instances"
          data={props.data?.numberOfInstances}
        />
        <DataFields title="Procedure" data={props.data?.procedureCode?.text} />
        <DataFields title="Reason" data={props.data?.reasonCode?.text} />
        <DataFields title="UID" data={props.data?.series?.[0]?.uid} />
        <DataFields
          title="Modality"
          data={props.data?.series?.[0]?.modality?.display}
        />
        <DataFields
          title="Instance"
          data={props.data?.series?.[0]?.instance?.[0]?.title}
        />
        <DataFields
          title="Subject"
          data={
            getResourceData(props.value, props.data?.subject?.reference)
              ?.name?.[0]?.text
          }
        />
      </div>
    </div>
  );
};

const ImmunizationRecommendation = (props) => {
  return (
    <div
      className="padding-left-larger padding-right-larger"
      data-cy={`ImmunizationRecommendation-card-${
        props.data?.code?.text
          ? props.data?.code?.text
          : props.data?.code?.coding[0]?.display
      }`}
    >
      <TitleFields title="Immunization Recommendation" />
      <div className="padding-medium">
        <DataFields
          title="Vaccine Code"
          data={
            props.data?.recommendation?.[0]?.vaccineCode?.[0]?.coding?.[0]
              ?.display
          }
        />
        <DataFields
          title="Target Disease"
          data={props.data?.recommendation?.[0]?.targetDisease?.text}
        />
        <DataFields
          title="Contraindicated VaccineCode"
          data={
            props.data?.recommendation?.[0]?.contraindicatedVaccineCode?.text
          }
        />
        <DataFields
          title="Forecast Status"
          data={
            props.data?.recommendation?.[0]?.forecastStatus?.coding?.[0]
              ?.display
          }
        />
        <DataFields
          title="Date Criterion"
          data={
            props.data?.recommendation?.[0]?.dateCriterion?.[0]?.code
              ?.coding?.[0]?.display
          }
        />
      </div>
    </div>
  );
};

const Invoice = (props) => {
  return (
    <div
      className="padding-left-larger padding-right-larger"
      data-cy="Invoice-card"
    >
      <TitleFields title="Invoice" />

      <div className="padding-medium">
        <DataFields title="Status" data={props.data?.status} />

        <DataFields
          title="Subject"
          data={
            getResourceData(props.value, props.data?.subject?.reference)
              ?.name?.[0]?.text
          }
        />
        <DataFields
          title="Identifier"
          data={props.data?.type?.identifier?.[0]?.value}
        />
        <DataFields
          title="Type"
          data={props.data?.type?.coding?.[0]?.display}
        />
        {props.data.inline?.map((data, index) => (
          <div key={props.index}>
            <DataFields
              title="Charge Item"
              data={data?.chargeItemReference?.reference}
            />
            {data?.priceComponent?.map((element, index) => (
              <div key={props.index}>
                <div>{element?.type}</div>
                <DataFields
                  title={element?.code?.coding?.[0]?.display}
                  data={`${element?.amount?.value} ${element?.amount?.currency}`}
                />
              </div>
            ))}
          </div>
        ))}

        <DataFields
          title="Total Gross"
          data={`${props.data?.totalGross?.value} ${props.data?.totalGross?.currency}`}
        />
        <DataFields
          title="Total Net"
          data={`${props.data?.totalNet?.value} ${props.data?.totalNet?.currency}`}
        />
      </div>
    </div>
  );
};

const Media = (props) => {
  const resource = props.data?.content;
  const extension = resource?.contentType?.split("/")[1];
  const documentBuffer = base64ToArrayBuffer(resource?.data);

  return (
    <div
      className="padding-left-larger padding-right-larger"
      data-cy={`Media-card-${
        props.data?.code?.text
          ? props.data?.code?.text
          : props.data?.code?.coding[0]?.display
      }`}
    >
      <TitleFields title="Media" />

      <div className="padding-medium">
        <DataFields title="Status" data={props.data?.status} />

        {allowSupportedFileFormat(resource?.contentType) ? (
          <div
            className=" flex-justify-content-space-between flex-direction-column"
            onClick={() => {
              props.documentOnClick(documentBuffer);
            }}
          >
            <div className="padding-top-small">
              <div className="font-size-large">
                {resource?.attachment?.title ?? "--"}.{extension ?? "--"}
              </div>
            </div>
            <div
              data-cy="pdfRenderer"
              className="padding-top-large padding-bottom-default width-40-percentage"
            >
              <div className="inherit-parent-width display-flex flex-direction-column border-radius-default flex-center-children-horizontally flex-center-children-vertically height-120px background-color-grey">
                <img
                  data-cy="PDF-icon"
                  draggable="false"
                  className="height-width-48px"
                  src={pdfIcon}
                  alt="pdf icon"
                />
                <div className="adaptive-padding-top">View file</div>
              </div>
            </div>
          </div>
        ) : (
          <div className="padding-top-large padding-bottom-default width-40-percentage">
            <div className="inherit-parent-width display-flex flex-direction-column border-radius-default flex-center-children-horizontally flex-center-children-vertically height-120px background-color-grey">
              <img
                data-cy="unSupported-icon"
                draggable="false"
                className="height-width-48px"
                src={unsupportedIcon}
                alt="unsupported icon"
              />
              <div className="adaptive-padding-top">UnSupported</div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const MedicationStatement = (props) => {
  return (
    <div
      className="padding-left-larger padding-right-larger"
      data-cy={`MedicationStatement-card`}
    >
      <TitleFields title="Medication Statement" />
      <div className="padding-medium">
        <DataFields title="Status" data={props.data?.status} />
        <DataFields
          title="Medication"
          data={props.data?.medicationCodeableConcept?.coding?.[0].display}
        />
        {props.data?.dosageInstruction && (
          <DataFields
            title="Dosage Instruction"
            data={props.data?.dosageInstruction?.map((data, index) => (
              <div
                className="padding-left-larger padding-right-larger"
                key={`medicationRequest-${index}`}
              >
                <div className="font-size-large text-capitalize padding-top-default">
                  {data.text}
                </div>
                <DataFields
                  title="Additional Instruction"
                  data={data?.additionalInstruction?.[0]?.coding?.[0]?.display}
                />
                <DataFields
                  title="Method"
                  data={data?.method?.coding?.[0]?.display}
                />
                <DataFields
                  title="Route"
                  data={data?.route?.coding?.[0]?.display}
                />
              </div>
            ))}
          />
        )}
        <DataFields title="Reason" data={props.data?.reasonCode?.text} />
        <DataFields
          title="Subject"
          data={
            getResourceData(props.value, props.data?.subject?.reference)
              ?.name?.[0]?.text
          }
        />
      </div>
    </div>
  );
};

const PractitionerRole = (props) => {
  return (
    <div
      className="padding-left-larger padding-right-larger"
      data-cy={`PractitionerRole-card`}
    >
      <TitleFields title="Practitioner Role" />
      <div className="padding-medium">
        <DataFields
          title="Title"
          data={props.data?.code?.[0]?.coding?.[0]?.display}
        />
        <DataFields
          title="Practitioner"
          data={props.data?.practitioner?.display}
        />
        <DataFields
          title="Assigner"
          data={props.data?.identifier?.assigner?.display}
        />
        <DataFields
          title="specialty"
          data={props.data?.specialty?.[0]?.coding?.[0]?.display}
        />
        <DataFields
          title={props.data?.identifier?.[0]?.type?.coding?.[0]?.display}
          data={props.data?.identifier?.[0]?.value}
        />
        {props.data?.telecom?.map((data, index) => (
          <div key={props.index}>
            <DataFields title={data?.system} data={data?.value} />
          </div>
        ))}
      </div>
    </div>
  );
};

const ServiceRequest = (props) => {
  return (
    <div
      className="padding-left-larger padding-right-larger"
      data-cy={`ServiceRequest-card-${
        props.data?.code?.text
          ? props.data?.code?.text
          : props.data?.code?.coding?.[0]?.display
      }`}
    >
      <TitleFields title="Service Request" />
      <div className="padding-medium">
        {props.data?.code?.text && (
          <DataFields title="Title" data={props.data?.code?.text} />
        )}
        <DataFields title="Status" data={props.data?.status} />
        <DataFields title="Intent" data={props.data?.intent} />
        <DataFields
          title="Subject"
          data={
            getResourceData(props.value, props.data?.subject?.reference)
              ?.name?.[0]?.text
          }
        />

        <DataFields
          title="Requester"
          data={getComponents({
            entryData: props.value,
            type: getResourceData(props.value, props.data?.requester?.reference)
              ?.resourceType,
            index: 1,
            data: { reference: props.data?.requester?.reference },
            documentOnClick: props.documentOnClick
          })}
        />
      </div>
    </div>
  );
};

const Specimen = (props) => {
  return (
    <div
      className="padding-left-larger padding-right-larger"
      data-cy="Specimen-card"
    >
      <TitleFields title="Specimen" />

      <div className="padding-medium">
        <DataFields
          title="Type"
          data={props.data?.type?.coding?.[0]?.display}
        />

        <DataFields
          title="Received Time"
          data={dateAndTimeConverter(props.data?.receivedTime, "secondDate")}
        />
      </div>
    </div>
  );
};

const TitleFields = (props) => {
  return (
    <>
      <div className="font-size-medium text-uppercase font-family-gilroy-medium">
        {props.title}
      </div>
    </>
  );
};

const DataFields = (props) => {
  return (
    <>
      {props.title !== "EMPTY" && (
        <div className="font-size-large padding-top-large font-family-gilroy-medium text-capitalize">
          {props.title ?? "--"}
        </div>
      )}
      {props.data !== "EMPTY" && (
        <div className="font-size-medium padding-top-small text-capitalize">
          {props.data ?? "--"}
        </div>
      )}
    </>
  );
};

const DropDownButton = (props) => {
  return (
    <div
      className={`${props.className} cursor-pointer`}
      onClick={props.dropDownClick}
      data-cy={props["dropDownButton-data-cy"]}
    >
      <img
        className={`${
          props.showData === true ? "rotate-180" : ""
        } padding-small`}
        src={dropDownArrow}
        alt="dropdown"
      />
    </div>
  );
};

const getResourceData = (value, fullUrl) => {
  const data = value?.entry?.find((data) => data?.fullUrl === fullUrl);
  return data?.resource;
};

const allowSupportedFileFormat = (data) => {
  switch (data) {
    case "application/pdf":
      return true;

    default:
      return false;
  }
};

const getComponents = (props) => {
  let value;
  switch (props.type) {
    case "Patient": {
      value = (
        <Patient
          key={props.index}
          data={getResourceData(props.entryData, props.data?.reference)}
        />
      );
      break;
    }

    case "Practitioner": {
      value = (
        <Practitioner
          key={props.index}
          data={getResourceData(props.entryData, props.data?.reference)}
        />
      );
      break;
    }
    case "Encounter": {
      value = (
        <Encounter
          key={props.index}
          data={getResourceData(props.entryData, props.data?.reference)}
        />
      );

      break;
    }
    case "Composition": {
      value = (
        <DateAndTime
          key={props.index}
          data={getResourceData(props.entryData, props.data?.reference)}
        />
      );
      break;
    }
    case "DocumentReference": {
      value = (
        <DocumentReference
          key={props.index}
          data={getResourceData(props.entryData, props.data?.reference)}
          documentOnClick={props.documentOnClick}
        />
      );
      break;
    }

    case "Media": {
      value = (
        <Media
          key={props.index}
          data={getResourceData(props.entryData, props.data?.reference)}
          documentOnClick={props.documentOnClick}
        />
      );
      break;
    }
    case "MedicationStatement": {
      value = (
        <MedicationStatement
          key={props.index}
          value={props.entryData}
          data={getResourceData(props.entryData, props.data?.reference)}
        />
      );
      break;
    }
    case "PractitionerRole": {
      value = (
        <PractitionerRole
          key={props.index}
          value={props.entryData}
          data={getResourceData(props.entryData, props.data?.reference)}
        />
      );
      break;
    }
    case "Immunization": {
      value = (
        <Immunization
          key={props.index}
          value={props.entryData}
          data={getResourceData(props.entryData, props.data?.reference)}
        />
      );
      break;
    }
    case "ImmunizationRecommendation": {
      value = (
        <ImmunizationRecommendation
          key={props.index}
          value={props.entryData}
          data={getResourceData(props.entryData, props.data?.reference)}
        />
      );
      break;
    }
    case "ChargeItem": {
      value = (
        <ChargeItem
          key={props.index}
          value={props.entryData}
          data={getResourceData(props.entryData, props.data?.reference)}
        />
      );
      break;
    }
    case "FamilyMemberHistory": {
      value = (
        <FamilyMemberHistory
          key={props.index}
          value={props.entryData}
          data={getResourceData(props.entryData, props.data?.reference)}
        />
      );
      break;
    }
    case "ImagingStudy": {
      value = (
        <ImagingStudy
          key={props.index}
          value={props.entryData}
          data={getResourceData(props.entryData, props.data?.reference)}
        />
      );
      break;
    }
    case "Invoice": {
      value = (
        <Invoice
          key={props.index}
          value={props.entryData}
          data={getResourceData(props.entryData, props.data?.reference)}
        />
      );
      break;
    }
    case "ServiceRequest": {
      value = (
        <ServiceRequest
          key={props.index}
          value={props.entryData}
          data={getResourceData(props.entryData, props.data?.reference)}
        />
      );
      break;
    }
    case "Specimen": {
      value = (
        <Specimen
          key={props.index}
          value={props.entryData}
          data={getResourceData(props.entryData, props.data?.reference)}
        />
      );
      break;
    }
    case "Binary": {
      value = (
        <Binary
          key={props.index}
          value={props.entryData}
          data={getResourceData(props.entryData, props.data?.reference)}
        />
      );
      break;
    }
    case "Appointment": {
      value = (
        <Appointment
          key={props.index}
          value={props.entryData}
          data={getResourceData(props.entryData, props.data?.reference)}
        />
      );
      break;
    }
    case "CarePlan": {
      value = (
        <CarePlan
          key={props.index}
          value={props.entryData}
          data={getResourceData(props.entryData, props.data?.reference)}
        />
      );
      break;
    }
    case "Condition": {
      value = (
        <Condition
          key={props.index}
          value={props.entryData}
          data={getResourceData(props.entryData, props.data?.reference)}
        />
      );
      break;
    }
    case "AllergyIntolerance": {
      value = (
        <AllergyIntolerance
          key={props.index}
          value={props.entryData}
          data={getResourceData(props.entryData, props.data?.reference)}
        />
      );
      break;
    }
    case "Procedure": {
      value = (
        <Procedure
          key={props.index}
          value={props.entryData}
          data={getResourceData(props.entryData, props.data?.reference)}
        />
      );
      break;
    }
    case "Observation": {
      value = (
        <Observation
          key={props.index}
          value={props.entryData}
          data={getResourceData(props.entryData, props.data?.reference)}
        />
      );
      break;
    }
    case "DiagnosticReport": {
      value = (
        <DiagnosticReport
          key={props.index}
          value={props.entryData}
          data={getResourceData(props.entryData, props.data?.reference)}
          documentOnClick={props.documentOnClick}
        />
      );
      break;
    }
    case "Organization": {
      value = (
        <Organization
          key={props.index}
          data={getResourceData(props.entryData, props.data?.reference)}
        />
      );
      break;
    }
    case "Medication": {
      value = (
        <Medication
          key={props.index}
          value={props.entryData}
          data={getResourceData(props.entryData, props.data?.reference)}
        />
      );
      break;
    }

    case "MedicationRequest": {
      value = (
        <MedicationRequest
          key={props.index}
          value={props.entryData}
          data={getResourceData(props.entryData, props.data?.reference)}
        />
      );
      break;
    }
    default: {
      value = <div key={props.index}></div>;
    }
  }
  return value;
};

function isValidBase64(str) {
  try {
    return btoa(atob(str)) === str;
  } catch (error) {
    return false;
  }
}

function base64ToArrayBuffer(base64) {
  if (!isValidBase64(base64)) {
    console.error("Invalid base64 string:", base64);
    return null;
  }

  try {
    let binary_string = window?.atob(base64);
    let len = binary_string.length;
    let bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  } catch (error) {
    console.error("Error decoding base64 string:", error);
    return null;
  }
}

FHIRRenderer.propTypes = {
  /**
   * JSON FHIR data
   */
  data: PropTypes.object,
  /**
   * view document on click
   */
  documentOnClick: PropTypes.func,
  /**
   * type of user
   */
  userType: PropTypes.oneOf(["clinic", "patient", "doctor"]),
  /**
   * to get profile picture
   */
  document: PropTypes.object
};

FHIRRenderer.defaultProps = {
  data: null,
  documentOnClick: undefined,
  userType: "",
  document: undefined
};
