/* eslint-disable react-hooks/exhaustive-deps */
import {
  doc,
  setDoc,
  query,
  collection,
  where,
  onSnapshot,
  addDoc,
  updateDoc,
  orderBy,
  collectionGroup,
  getDoc,
  getDocs,
  deleteDoc,
  endAt,
  limit,
  startAfter,
  startAt,
  writeBatch
} from "firebase/firestore";
import { db } from "./firebase";
import { getCurrentUser } from "./authentication";
import { useState, useEffect } from "react";
import { isValidArray, isValidObject } from "../utils/validators";
import { colors, lockerSetup, shard } from "../utils/constants";
import { setErrorStatus } from "../redux/status/actions";
import { setNotifications } from "../redux/notifications/actions";
import { setDataTransfer, setDocuments } from "../redux/documents/actions";
import {
  addPatientsDemographics,
  addPatientsSubscription,
  setLinkingTokenDetails,
  addAutoApprovalPolicies
} from "../redux/patients/actions";
import { logout } from "../redux/authentication/actions";
import { throwError } from "./error";
import { setFeatureFlags, setIncidents } from "../redux/app/actions";
// import { error } from "logrocket";

export async function useFeatureFlagsListener() {
  const [listener, setListener] = useState({
    document: null
  });

  const subscribeToFeatureFlags = () => {
    const featureFlagsQuery = query(doc(db, "config", "featureFlags"));

    return onSnapshot(
      featureFlagsQuery,
      (querySnapshot) => {
        setFeatureFlags(querySnapshot.data());
      },
      (error) => {
        console.error(error, "from useFeatureFlagsListener");
        setErrorStatus(error);
        logout();
      }
    );
  };
  useEffect(() => {
    if (listener.document === null) {
      setListener({
        document: subscribeToFeatureFlags()
      });
    }
    //eslint-disable-next-line
  }, []);
}

export async function useIncidentsListener(incident, isAuthed) {
  const [listener, setListener] = useState({
    document: null
  });

  const subscribeToIncidents = () => {
    const incidentsQuery = query(collection(db, "incidents"));

    return onSnapshot(
      incidentsQuery,
      (querySnapshot) => {
        let data = {};
        querySnapshot.forEach((doc) => {
          data = { ...data, [doc.id]: { ...doc.data(), incidentId: doc.id } };
        });
        setIncidents(data);
      },
      (error) => {
        console.error(error, "from useIncidentsListener");
        setErrorStatus(error);
        logout();
      }
    );
  };
  useEffect(() => {
    if (listener.document === null && isAuthed && incident) {
      setListener({
        document: subscribeToIncidents()
      });
    } else if (
      typeof listener.document === "function" &&
      (!isAuthed || !incident)
    ) {
      listener.document();
      setListener({ document: null });
    }
    //eslint-disable-next-line
  }, [isAuthed, incident]);
}

export const customAddDoc = async (ref, data) => {
  let doc;
  try {
    doc = await addDoc(ref, data);
  } catch (error) {
    if (error.message.includes("Document already exists")) {
      doc = {};
      doc.id = error.message.split("/")[error.message.split("/").length - 1];
      console.warn("Document creation ID collision at create profile");
    } else if (error.message.includes("entity already exists")) {
      if (
        typeof error.message.split(`"`)[5] === "string" &&
        error.message.split(`"`)[5].length > 10
      ) {
        doc = {};
        doc.id = error.message.split(`"`)[5];
      }
    } else {
      throw error;
    }
  }
  return doc.id;
};

//To create support query
export const createQuery = async (data) => {
  await customAddDoc(collection(db, "queries"), data);
};

//To check whether the query exists in database
export const checkQueryExists = async (docId, phoneNumber) => {
  const queriesRef = query(
    collection(db, "queries"),
    where("document.id", "==", docId),
    where("phoneNumber", "==", phoneNumber)
  );
  const queriesSnapshot = await getDocs(queriesRef);
  let queries = [];
  queriesSnapshot.forEach((query) => {
    queries.push(query.data());
  });
  if (queries.length === 0) {
    return false;
  } else return true;
};

//To delete the patient profile
export const deleteProfile = async (profileId, phoneNumber) => {
  const publicRef = doc(db, "patients", profileId, "patientsPublic", "profile");
  try {
    await updateDoc(publicRef, {
      phoneNumber: ""
    });
  } catch (error) {
    console.warn(
      "public profile update failed, checking if profile is updated..."
    );
    let data = null;
    try {
      data = (await getDoc(publicRef)).data();
    } catch (error) {
      console.warn(
        "public profile unable to be read, profile is possibly updated..."
      );
    }
    if (data.phoneNumber !== "") {
      throw error;
    }
  }
  const privateRef = doc(
    db,
    "patients",
    profileId,
    "patientsPrivate",
    "profile"
  );
  try {
    await updateDoc(privateRef, {
      phoneNumber: ""
    });
  } catch (error) {
    console.warn(
      "private profile update failed, checking if profile is updated..."
    );
    let data = null;
    try {
      data = (await getDoc(privateRef)).data();
    } catch (error) {
      console.warn(
        "private profile unable to be read, profile is possibly updated..."
      );
    }
    if (data !== null) {
      throw error;
    }
  }
  const rootRef = doc(db, "patients", profileId);
  try {
    await updateDoc(rootRef, {
      archive: {
        phoneNumber: phoneNumber,
        deletionRequestedAt: +new Date()
      },
      phoneNumber: "",
      uid: ""
    });
  } catch (error) {
    console.warn(
      "root profile update failed, checking if profile is updated..."
    );
    let data = null;
    try {
      data = (await getDoc(rootRef)).data();
    } catch (error) {
      console.warn(
        "root profile unable to be read, profile is possibly updated..."
      );
    }
    if (data !== null) {
      throw error;
    }
  }
};

//To create symptom
export async function addSymptom(data) {
  const symptomData = {
    ...data,
    url: {
      audio: typeof data.url.audio === "string" ? data.url.audio : "",
      images: [
        ...(isValidObject(data.url) && isValidArray(data.url.images)
          ? data.url.images
          : [])
      ]
    },
    location: {
      front: [
        ...data.location.front.map((value) => ({
          posX: value.posX,
          posY: value.posY
        }))
      ],
      back: [
        ...data.location.back.map((value) => ({
          posX: value.posX,
          posY: value.posY
        }))
      ]
    },
    to: {
      id: data.to.id,
      fullName: data.to.fullName,
      phoneNumber: data.to.phoneNumber
    }
  };
  const docRef = collection(db, "documents");
  const documentRes = await customAddDoc(docRef, symptomData);
  return documentRes;
}

//To update symptom
export async function updateSymptom(documentId, data) {
  const symptomData = {
    ...data,
    location: {
      front: [...data.location.front],
      back: [...data.location.back]
    },
    to: {
      id: data.to.id,
      phoneNumber: data.to.phoneNumber,
      fullName: data.to.fullName
    }
  };
  const docRef = doc(db, "documents", documentId);
  await updateDoc(docRef, symptomData);
  return documentId;
}

//create vitals
export const createVitals = async (data) => {
  const createVitalsResponse = await customAddDoc(
    collection(db, "documents"),
    data
  );
  return createVitalsResponse;
};

//To remove clinics connection
export async function deleteConnectedClinic(clinicId, currentProfile) {
  await deleteDoc(doc(db, "patients", currentProfile, "connections", clinicId));
}

//patient profile picture update
export async function setProfilePicturePath(phoneNumber, profileId) {
  const privateRef = doc(
    db,
    "patients",
    profileId,
    "patientsPrivate",
    "profile"
  );
  await setDoc(
    privateRef,
    {
      profilePicture: `gs://ninto-profile-pictures/patients/${phoneNumber}/${profileId}.png`
    },
    { merge: true }
  );
}

export async function addDocumentsToDatabase(data) {
  const documentRef = doc(db, "documents");
  return await customAddDoc(documentRef, data);
}

//mark all as read database function
export async function setAllNotificationAsRead(notificationsList) {
  if (isValidArray(notificationsList)) {
    const batch = writeBatch(db);
    notificationsList?.forEach((notification) => {
      const documentRef = doc(
        db,
        "patients",
        notification?.profileId,
        "notifications",
        notification?.documentId
      );
      batch.update(documentRef, { read: true });
    });
    await batch.commit();
    return { success: true };
  } else {
    throwError("custom", "Something went wrong");
  }
}

export async function setNotificationRead(notification, profileId) {
  const notificationRef = doc(
    db,
    "patients",
    profileId,
    "notifications",
    notification
  );
  await updateDoc(notificationRef, {
    read: true
  });
  return { success: true };
}

export async function getPaginatedNotifications(
  profileId,
  lastVisible,
  pageNumber,
  update
) {
  const notifications = {};
  const validDate = new Date().setHours(0, 0, 0, 0);
  const paginationNotifications = query(
    collection(db, "patients", profileId, "notifications"),
    // where("shard", "in", shard.value),
    where("timestamp", "<=", +validDate + 86400000 * 2),
    orderBy("timestamp", "desc"),
    startAfter(lastVisible),
    limit(50)
  );

  const querySnapshot = await getDocs(paginationNotifications);
  querySnapshot.forEach((doc) => {
    if (isValidObject(doc.data())) {
      notifications[doc.id] = {
        documentId: doc.id,
        ...(update
          ? { pageNumber: pageNumber }
          : { pageNumber: pageNumber + 1 }),
        ...doc.data()
      };
    }
  });
  const lastVisibleDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
  return {
    notifications: notifications,
    lastVisible: isValidObject(lastVisibleDoc) ? lastVisibleDoc : "end"
  };
}

export async function editProfile(pid, data) {
  //data filter
  const patientPublic = {
    ...(data.fullName ? { fullName: data.fullName } : {})
  };
  const patientPrivate = {
    ...(data.bloodGroup ? { bloodGroup: data.bloodGroup } : {}),
    ...(data.dateOfBirth ? { dateOfBirth: data.dateOfBirth } : {}),
    ...(data.gender ? { gender: data.gender } : {}),
    ...(data.height ? { height: parseInt(data.height) } : {}),
    ...(data.weight ? { weight: parseInt(data.weight) } : {}),
    ...(data.allergies ? { allergies: [...data.allergies] } : {})
  };

  //set patients public profile
  if (Object.keys(patientPublic).length > 0) {
    const publicRef = doc(db, "patients", pid, "patientsPublic", "profile");
    await updateDoc(publicRef, patientPublic);
  }
  //set patients public profile
  if (Object.keys(patientPrivate).length > 0) {
    const privateRef = doc(db, "patients", pid, "patientsPrivate", "profile");
    await updateDoc(privateRef, patientPrivate);
  }
}

export const deleteConnectionRequest = async (inviteCode) => {
  await deleteDoc(doc(db, "requests", inviteCode));
};

//update form
export const updateForm = async (documentId, data) => {
  const formRef = doc(db, "documents", documentId);
  await updateDoc(formRef, data);
  return true;
};

export async function useTransferredDocumentsListener(props) {
  const [listener, setListener] = useState({
    document: null
  });

  const subscribeToDocuments = (phoneNumber) => {
    if (typeof phoneNumber !== "string") {
      return null;
    }

    const currentProfileDocumentsQuery = query(
      collectionGroup(db, "uploadedDocuments"),
      where("patient.phoneNumber", "==", phoneNumber),
      where("clinicId", "==", lockerSetup.locker)
    );

    return onSnapshot(
      currentProfileDocumentsQuery,
      (querySnapshot) => {
        let documents = [];
        querySnapshot.forEach((doc) => {
          documents.push({ ...doc.data(), documentId: doc.id });
        });
        setDocuments(documents);
      },
      (error) => {
        console.error(error, "from useTransferredDocumentsListener");
        setErrorStatus(error);
        logout();
      }
    );
  };
  useEffect(() => {
    if (
      props &&
      props.isAuthed === true &&
      props.phoneNumber &&
      listener.document === null
    ) {
      setListener({
        document: subscribeToDocuments(props.phoneNumber)
      });
    } else if (
      props &&
      props.isAuthed === false &&
      typeof listener.document === "function"
    ) {
      listener.document();
      setListener({ document: null });
    }
    //eslint-disable-next-line
  }, [props.isAuthed, props.phoneNumber]);
}

export async function useDataTransferListener(props) {
  const [listener, setListener] = useState({
    document: null
  });

  const subscribeToDocuments = (phoneNumber) => {
    if (typeof phoneNumber !== "string") {
      return null;
    }
    const currentProfileDocumentsQuery = query(
      collectionGroup(db, "dataTransfers"),
      where("patient.phoneNumber", "==", phoneNumber),
      where("hiuClinicId", "==", lockerSetup.locker)
    );
    return onSnapshot(
      currentProfileDocumentsQuery,
      (querySnapshot) => {
        let dataTransfer = [];
        querySnapshot.forEach((doc) => {
          dataTransfer.push({ ...doc.data(), documentId: doc.id });
        });
        setDataTransfer(dataTransfer);
      },
      (error) => {
        console.error("ERROR useDataTransferListener", error);
        setErrorStatus(error);
        logout();
      }
    );
  };
  useEffect(() => {
    if (
      props &&
      props.isAuthed === true &&
      props.phoneNumber &&
      listener.document === null &&
      props.patients.currentProfile
        ? props.patients.accessToken?.[props.patients.currentProfile]
          ? true
          : false
        : false
    ) {
      setListener({
        document: subscribeToDocuments(props.phoneNumber)
      });
    } else if (
      props &&
      props.isAuthed === false &&
      typeof listener.document === "function"
    ) {
      listener.document();
      setListener({ document: null });
    }
    //eslint-disable-next-line
  }, [props.isAuthed, props.phoneNumber, props.patients]);
}

export async function useNotificationsListener(props) {
  const [notificationListener, setNotificationListener] = useState({
    listener: null
  });
  const subscribeToNotification = (auth, phoneNumber) => {
    if (!auth) {
      return;
    }

    const currentDate = +new Date().setHours(0, 0, 0, 0);
    const notificationQuery = query(
      collectionGroup(db, "notifications"),
      where("expiresAt.shard", "in", shard.value),
      where("userType", "==", "patient"),
      where("phoneNumber", "==", phoneNumber),
      where("timestamp", ">=", currentDate),
      orderBy("timestamp", "desc")
    );
    return onSnapshot(
      notificationQuery,
      (querySnapshot) => {
        let notifications = {};
        querySnapshot.forEach((doc) => {
          notifications[doc.data().profileId] = {
            ...notifications[doc.data().profileId],
            [doc.id]: {
              documentId: doc.id,
              pageNumber: 0,
              ...doc.data()
            }
          };
        });
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        setNotifications(notifications, lastVisible);
      },
      (error) => {
        console.error("from notification listener for", error);

        setErrorStatus(error);
        // logout();
      }
    );
  };

  // notification listener
  useEffect(() => {
    if (
      props.isAuthed === true &&
      props.phoneNumber &&
      notificationListener.listener === null
    ) {
      setNotificationListener({
        listener: subscribeToNotification(props.isAuthed, props.phoneNumber)
      });
    } else if (
      props.isAuthed === false &&
      notificationListener?.listener !== null
    ) {
      notificationListener?.listener();
      setNotificationListener({
        listener: null
      });
    }
    // eslint-disable-next-line
  }, [props.isAuthed, props.phoneNumber]);
}

export async function notificationPaginationQuery(
  profileId,
  phoneNumber,
  paginationNumber
) {
  const currentDate = new Date();
  const startingTimeStamp = +new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    (currentDate.getDate() - paginationNumber) * 7
  );
  const endingTimeStamp = +new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    (currentDate.getDate() - (paginationNumber + 1)) * 7
  );

  const collectionQueryRef = query(
    collectionGroup(db, "notifications"),
    where("phoneNumber", "==", phoneNumber),
    where("shard", "in", shard.value),
    orderBy("timestamp", "desc"),
    startAt(startingTimeStamp),
    endAt(endingTimeStamp)
  );
  const querySnapshot = await getDocs(collectionQueryRef);
  let notifications = {};
  querySnapshot.forEach((doc) => {
    if (doc.data()?.profileId === profileId) {
      notifications = {
        ...notifications,
        [doc.id]: {
          ...doc.data(),
          documentId: doc.id,
          paginationNumber: paginationNumber
        }
      };
    }
  });
  return notifications;
}

export async function useLinkingTokenListener(props) {
  const [listener, setListener] = useState({
    linkingToken: null
  });
  const subscribeToLinkingToken = (phoneNumber) => {
    if (typeof phoneNumber !== "string") {
      return null;
    }

    const linkingTokenQuery = query(
      collectionGroup(db, "authorisations"),
      where("patient.phoneNumber", "==", phoneNumber),
      where("hipId", "==", lockerSetup.locker)
    );
    return onSnapshot(
      linkingTokenQuery,
      (querySnapshot) => {
        let tokens = {};
        querySnapshot.forEach((doc) => {
          tokens[doc.data().patient.patientId] = {
            ...doc.data(),
            documentId: doc.id
          };
        });
        setLinkingTokenDetails(tokens);
      },
      (error) => {
        console.error("ERROR useLinkingTokenListener", error);
        setErrorStatus(error);
        logout();
      }
    );
  };

  useEffect(() => {
    if (
      props &&
      props.isAuthed === true &&
      props.phoneNumber &&
      listener.linkingToken === null
    ) {
      setListener({
        linkingToken: subscribeToLinkingToken(props.phoneNumber)
      });
    } else if (
      props &&
      props.isAuthed === false &&
      listener.linkingToken &&
      typeof listener.linkingToken === "function"
    ) {
      listener.linkingToken();
      setListener({ linkingToken: null });
    }
    //eslint-disable-next-line
  }, [props.isAuthed, props.phoneNumber]);
}

export async function useDemographicListener(props) {
  const [listener, setListener] = useState({
    patientDemographics: null
  });

  const subscribeToPatientDemographics = (phoneNumber) => {
    if (typeof phoneNumber !== "string") {
      return;
    }
    const patientDemographicsQuery = query(
      collection(db, "patients"),
      where("phoneNumber", "==", phoneNumber)
    );
    return onSnapshot(
      patientDemographicsQuery,
      // { includeMetadataChanges: true },
      (querySnapshot) => {
        let profiles = {};
        let index = 0;
        while (index < querySnapshot.docs.length) {
          const doc = querySnapshot.docs[index];
          profiles[doc.id] = {
            ...doc.data(),
            documentId: doc.id,
            color: colors?.[index]
          };
          index++;
        }

        addPatientsDemographics(profiles);
      },
      (error) => {
        console.error("ERROR patients-profiles", phoneNumber, error);
        setErrorStatus(error);
        logout();
      }
    );
  };

  useEffect(() => {
    if (
      props &&
      props.isAuthed === true &&
      typeof props.phoneNumber === "string"
    ) {
      setListener({
        patientDemographics: subscribeToPatientDemographics(props.phoneNumber)
      });
    } else if (
      props &&
      props.isAuthed === false &&
      listener.patientDemographics &&
      typeof listener.patientDemographics === "function"
    ) {
      listener.patientDemographics();
      setListener({ patientDemographics: null });
    }
    //eslint-disable-next-line
  }, [props.isAuthed, props.phoneNumber]);
}

export async function useSubscriptionListener(props) {
  const [listener, setListener] = useState({
    subscribeToPatientDemographics: null
  });

  const subscribeToPatientDemographics = (phoneNumber, clinicId) => {
    if (typeof phoneNumber !== "string") {
      return;
    }
    const patientDemographicsQuery = query(
      collectionGroup(db, "subscriptions"),
      where("patient.phoneNumber", "==", phoneNumber),
      where("clinicId", "==", clinicId)
    );
    return onSnapshot(
      patientDemographicsQuery,
      // { includeMetadataChanges: true },
      (querySnapshot) => {
        let subscription = {};
        querySnapshot.forEach((doc) => {
          subscription[doc.id] = {
            ...doc.data(),
            documentId: doc.id
          };
        });

        addPatientsSubscription(subscription);
      },
      (error) => {
        console.error("ERROR useSubscriptionListener", error);
        setErrorStatus(error);
        logout();
      }
    );
  };

  useEffect(() => {
    if (
      props &&
      props.isAuthed === true &&
      typeof props.phoneNumber === "string" &&
      listener.subscribeToPatientDemographics === null
    ) {
      setListener({
        subscribeToPatientDemographics: subscribeToPatientDemographics(
          props.phoneNumber,
          props.clinicId
        )
      });
    } else if (
      props &&
      props.isAuthed === false &&
      listener.subscribeToPatientDemographics &&
      typeof listener.subscribeToPatientDemographics === "function"
    ) {
      listener.subscribeToPatientDemographics();
      setListener({ subscribeToPatientDemographics: null });
    }
    //eslint-disable-next-line
  }, [props.isAuthed, props.phoneNumber]);
}

export async function useAutoApprovalPoliciesListener(props) {
  const [listener, setListener] = useState({
    autoApprove: null
  });

  const getAutoApprovalPolicies = (phoneNumber) => {
    if (typeof phoneNumber !== "string") {
      return;
    }
    const patientDemographicsQuery = query(
      collectionGroup(db, "autoApprovalPolicies"),
      where("patient.phoneNumber", "==", phoneNumber)
    );
    return onSnapshot(
      patientDemographicsQuery,
      (querySnapshot) => {
        let autoApprovalPolicies = {};
        querySnapshot.forEach((doc) => {
          autoApprovalPolicies[doc.id] = {
            ...doc.data(),
            documentId: doc.id
          };
        });
        addAutoApprovalPolicies(autoApprovalPolicies);
      },
      (error) => {
        console.error("ERROR useAutoApprovalPoliciesListener", error);
        setErrorStatus(error);
        logout();
      }
    );
  };

  useEffect(() => {
    if (
      props &&
      props.isAuthed === true &&
      props.phoneNumber &&
      listener.autoApprove === null
    ) {
      setListener({
        autoApprove: getAutoApprovalPolicies(props.phoneNumber)
      });
    } else if (
      props &&
      props.isAuthed === false &&
      listener.autoApprove &&
      typeof listener.autoApprove === "function"
    ) {
      listener.autoApprove();
      setListener({ autoApprove: null });
    }
    //eslint-disable-next-line
  }, [props.isAuthed, props.phoneNumber]);
}

//create new demographic
export async function createDemographic(profileName) {
  const currentUser = getCurrentUser();

  //To create patient demographic root
  const rootRef = collection(db, "patients");
  const rootProfile = await customAddDoc(rootRef, {
    name: profileName,
    phoneNumber: currentUser.phoneNumber
  });

  return rootProfile;
}

export async function addRegData(deviceId, data) {
  await setDoc(doc(db, "devices", deviceId), data);
}

export async function addConsentAgreeToPersonalData(documentId) {
  await updateDoc(doc(db, "patients", documentId), {
    consentToPersonalData: { userAgreed: true, agreedAt: +new Date() }
  });
}
